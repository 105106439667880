import {I18nFunction} from '../i18n/i18n';
import {HarvestCrop, Sample} from '../models/interfaces';
import {Loss} from '../models/types';
import {UnitSystem} from '../selectors/units';
import {getYieldCalcConfig} from '../yield-calc';
import {formatDate} from './date';

function lossDesc(t: I18nFunction, loss: Loss) {
  const lossDescItems: string[] = [];
  if (loss.loss) {
    const type = loss.loss.unit === 'percent' ? 'ValueUnit' : 'YieldUnit';
    lossDescItems.push(t({type, ...loss.loss}));
  }
  if (loss.loss_event_date) {
    lossDescItems.push(formatDate(t, loss.loss_event_date));
  }
  if (loss.loss_recognized != null) {
    lossDescItems.push(`${t('LossCauseRecognized')}: ${loss.loss_recognized ? t('Yes') : t('No')}`);
  }
  return t(loss.loss_cause ?? 'UnknownLoss') + (lossDescItems.length ? ' (' + lossDescItems.join(', ') + ')' : '');
}

// TODO(savv): Consider adding the distance between user location and sample location here.
export function getSampleDetails(
  t: I18nFunction,
  units: UnitSystem,
  sample: Sample,
  baseCrop?: null | HarvestCrop,
): string[] {
  const yieldCalcConfig = getYieldCalcConfig(baseCrop ?? null);
  const calculatorVariables =
    yieldCalcConfig &&
    (sample.kernels_per_unit != null || sample.units_per_plant != null || sample.weight_per_unit != null)
      ? [
          t('Method') + ': ' + t(yieldCalcConfig.method),
          yieldCalcConfig.kernelsPerUnitMsg && sample.kernels_per_unit != null
            ? t(yieldCalcConfig.kernelsPerUnitMsg) + ': ' + sample.kernels_per_unit
            : null,
          yieldCalcConfig.unitsPerPlantMsg && sample.units_per_plant != null
            ? t(yieldCalcConfig.unitsPerPlantMsg) + ': ' + sample.units_per_plant
            : null,
          yieldCalcConfig.weightPerUnitMsg && sample.weight_per_unit != null
            ? t(yieldCalcConfig.weightPerUnitMsg) + ': ' + sample.weight_per_unit.val + ' '
            : null,
        ]
      : [];
  return [
    sample.sample_date &&
      formatDate(t, sample.sample_date) + (sample.vegetation_stage ? ` (${t(sample.vegetation_stage)})` : ''),
    sample.sample_area && t('AffectedArea') + ': ' + t({type: 'AreaUnit', ...sample.sample_area}),
    sample.crop_condition && `${t('CropCondition')}: ${t(sample.crop_condition)}`,
    sample.estimated_yield && t({type: 'YieldUnit', ...sample.estimated_yield}),
    sample.fungicide ? t('FungicideApplied').replace('?', '') : null,
    sample.herbicide ? t('HerbicideApplied').replace('?', '') : null,
    sample.insecticide ? t('InsecticideApplied').replace('?', '') : null,
    ...sample.losses.map(loss => lossDesc(t, loss)),
    sample.plant_density == null ? null : t('PlantDensity') + ': ' + t({type: 'ValueUnit', ...sample.plant_density}),
    ...calculatorVariables,
    sample.sowing_date && t('SowingDate') + ': ' + formatDate(t, sample.sowing_date),
    sample.comments && t('Comments') + ': ' + sample.comments,
    ...sample.costs.map(x => `${t(x.cost_type ? x.cost_type : 'Costs')}: ${x.cost && x.cost.toLocaleString()}`),
    sample.external_sample_id && t('Reference') + ': ' + sample.external_sample_id,
  ].filter((x): x is string => !!x);
}

export function getYieldCalculationDetails(t: I18nFunction, units: UnitSystem, sample: Sample): string[] {
  return [
    sample.plant_density && t({type: 'PlantDensityValueUnit', ...sample.plant_density}),
    sample.space_between_rows && t('SpaceBetweenRows') + ': ' + t({type: 'ValueUnit', ...sample.space_between_rows}),
    sample.space_between_plants &&
      t('SpaceBetweenPlants') + ': ' + t({type: 'ValueUnit', ...sample.space_between_plants}),
    sample.harvester_platform_width &&
      t('HarvesterPlatformWidth') + ': ' + t({type: 'ValueUnit', ...sample.harvester_platform_width}),
    sample.harvester_distance_collected &&
      t('HarvesterDistanceHarvested') + ': ' + t({type: 'ValueUnit', ...sample.harvester_distance_collected}),
    sample.harvester_gross_sample &&
      t('GrossSampleProduction') + ': ' + t({type: 'ValueUnit', ...sample.harvester_gross_sample}),
    sample.units_per_plant && t('UnitsPerPlant') + ': ' + sample.units_per_plant,
    sample.kernels_per_unit && t('KernerlsPerUnit') + ': ' + sample.kernels_per_unit,
    sample.weight_per_unit && t('WeightPerUnit') + ': ' + t({type: 'ValueUnit', ...sample.weight_per_unit}),
    sample.vinification_ratio && t('VinificationRatio') + ': ' + sample.vinification_ratio,
    sample.harvest_loss_pct && t('HarvestLoss') + ': ' + sample.harvest_loss_pct + '%',
    sample.impurities_pct && t('Impurities') + ': ' + sample.impurities_pct + '%',
    sample.grain_moisture_pct && t('GrainMoisture') + ': ' + sample.grain_moisture_pct + '%',
    sample.grain_moisture_reduction_pct &&
      t('GrainMoistureReduction') + ': ' + sample.grain_moisture_reduction_pct + '%',
  ].filter((x): x is string => !!x);
}
