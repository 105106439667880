import {EventLog} from './models/interfaces';

// TODO(savv): Ideally, we should capture more complex screen param objects, however, this often causes the error
//  "cyclical structure in JSON object" when stringifying the object. Fix this.
type BasicDict = {[k: string]: null | boolean | number | string};

export interface AnalyticsI {
  curScreen: null | string;
  curScreenParams: null | BasicDict;
  logEvent: (e: Partial<EventLog> & Pick<EventLog, 'event_name'>) => void;
}

export function sanitizeCurScreenParams(x: any): null | BasicDict {
  if (!x || typeof x != 'object') {
    return null;
  }

  const res: BasicDict = {};
  for (const k in x) {
    const v = x[k];
    if (typeof v == 'boolean' || typeof v == 'number' || typeof v == 'string' || v === null) {
      res[k] = v;
    } else {
      try {
        JSON.stringify(v);
        res[k] = v;
      } catch (e) {
        res[k] = `<unserializable ${typeof v}>`;
      }
    }
  }

  return res;
}
