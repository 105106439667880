import {FormyI} from './Formy';
import {I18nSimpleKey} from './i18n/i18n';
import {DensityUnit, DensityValue, HarvestCrop, Sample, WeightUnit} from './models/interfaces';
import {YieldValue} from './models/types';
import {Cereals, Sunflower} from './selectors/harvest';
import {
  UnitSystem,
  convertDensity,
  convertDistance,
  convertWeight,
  convertYield,
  roundValueUnit,
} from './selectors/units';

const Method = ['PlantDensity', 'RowsAndWidth', 'Harvester'] as const;
export type Method = (typeof Method)[number];

export type YieldComponents = Pick<
  Sample,
  | 'conversion_ratio'
  | 'dead_plants_pct'
  | 'ear_density'
  | 'harvester_distance_collected'
  | 'harvester_gross_sample'
  | 'harvester_platform_width'
  | 'kernels_per_unit'
  | 'plant_density'
  | 'row_distance_collected'
  | 'row_plants_collected'
  | 'space_between_plants'
  | 'space_between_rows'
  | 'units_per_plant'
  | 'vinification_ratio'
  | 'weight_per_unit'
>;

export const cornSilageDefaultConversionRatio = 1.59;

// Return the plant density in the requested DensityUnit. If plant_density is set in the sampleForm, it will be used
// directly. If it's null, we will try to calculate plant_density based on the space_between_rows, space_between_plants
// and dead_plants_pct.
export function getPlantDensity(
  method: Method,
  sampleForm: Pick<
    YieldComponents,
    'dead_plants_pct' | 'plant_density' | 'space_between_plants' | 'space_between_rows'
  >,
  unit: DensityUnit,
): null | DensityValue {
  if (method != 'PlantDensity' && method != 'RowsAndWidth') {
    // Only defined for those 2 methods.
    return null;
  }
  const {plant_density} = sampleForm;
  // For "RowsAndWidth" method, the UI allows the user to overwrite the density manually.
  const density: null | DensityValue =
    method == 'RowsAndWidth' && plant_density == null
      ? (() => {
          const {space_between_rows, space_between_plants} = sampleForm;
          const rowWidth = convertDistance('meters', space_between_rows)?.val ?? 0;
          const plantWidth = convertDistance('meters', space_between_plants)?.val ?? 0;
          const productionAreaSqm = rowWidth * plantWidth;
          return productionAreaSqm > 0
            ? {
                unit: 'units-per-m2',
                // The calculation is based on the immediate user input, i.e. before the form is validated, which could
                // result in invalid values, we prevent them from spreading to the density by clamping the value here.
                val: (1 / productionAreaSqm) * Math.max(Math.min(1 - (sampleForm.dead_plants_pct ?? 0) / 100, 1), 0),
              }
            : null;
        })()
      : plant_density;
  return convertDensity(unit, density);
}

export function getEstimatedYield<F extends YieldComponents>(
  units: UnitSystem,
  method: Method,
  cropFamily: null | HarvestCrop,
  formy: FormyI<F>,
): null | YieldValue {
  if (method == 'Harvester') {
    return calcHarvester(units, cropFamily, formy);
  }

  const {units_per_plant, kernels_per_unit, weight_per_unit, vinification_ratio, conversion_ratio, ear_density} =
    formy.getValues();

  const plant_density_m2 = getPlantDensity(method, formy.getValues(), 'units-per-m2')?.val;
  const ear_density_m2 = convertDensity('units-per-m2', ear_density)?.val;
  if (plant_density_m2 == undefined && ear_density_m2 == undefined) {
    return null;
  }
  const density_m2 = (ear_density_m2 ?? plant_density_m2) as number;

  const config = getYieldCalcConfig(cropFamily);
  if (!config) {
    return null;
  }

  let yieldTHa = density_m2 * 10000;

  // If ear_density_m2 is set, this already includes the units_per_plant.
  if (config.unitsPerPlantMsg && ear_density_m2 == undefined) {
    if (units_per_plant != null) {
      yieldTHa *= units_per_plant;
    } else {
      return null;
    }
  }

  if (config.kernelsPerUnitMsg) {
    if (kernels_per_unit != null) {
      yieldTHa *= kernels_per_unit;
    } else {
      return null;
    }
  }

  if (config.weightPerUnitMsg) {
    const weight_per_unit_kg = convertWeight('kilograms', weight_per_unit)?.val;
    if (weight_per_unit_kg != null) {
      yieldTHa *= weight_per_unit_kg / 1000;
    } else {
      return null;
    }
  }

  if (cropFamily == 'grapes') {
    if (vinification_ratio != null) {
      return roundValueUnit({unit: 'hectoliters-per-hectare', val: (yieldTHa * 1000) / vinification_ratio});
    } else {
      return null;
    }
  }

  if (cropFamily == 'corn-silage') {
    if (conversion_ratio != null) {
      return roundValueUnit({unit: 'tons-per-hectare', val: yieldTHa * conversion_ratio});
    } else {
      return null;
    }
  }

  return composeYield(units, yieldTHa, cropFamily);
}

function calcHarvester<F extends YieldComponents>(
  units: UnitSystem,
  cropFamily: null | HarvestCrop,
  formy: FormyI<F>,
): null | YieldValue {
  const {harvester_platform_width, harvester_distance_collected, harvester_gross_sample} = formy.getValues();
  const widthM = convertDistance('meters', harvester_platform_width)?.val;
  const distanceM = convertDistance('meters', harvester_distance_collected)?.val;
  const areaSqm = widthM == null || distanceM == null ? null : widthM * distanceM;
  const productionKg = convertWeight('kilograms', harvester_gross_sample)?.val;
  const estimateTHa = areaSqm == null || productionKg == null ? null : productionKg / 1000 / (areaSqm / 10000);
  return composeYield(units, estimateTHa, cropFamily);
}

function composeYield(units: UnitSystem, estimateTHa: null | number, cropFamily: null | HarvestCrop) {
  if (estimateTHa == null) {
    return null;
  }

  return roundValueUnit(convertYield(units.yieldUnit, {val: estimateTHa, unit: 'tons-per-hectare'}, cropFamily));
}

interface YieldCalcConfig {
  calculatePlantDensity: boolean;
  calculateSpaceBetweenPlants: boolean;
  deadPlantsMsg: null | I18nSimpleKey;
  plantDensityMsg: null | I18nSimpleKey;
  unitsPerPlantMsg: null | I18nSimpleKey;
  kernelsPerUnitMsg: null | I18nSimpleKey;
  earDensityMsg: null | I18nSimpleKey;
  weightPerUnitMsg: null | I18nSimpleKey;
  weightPerUnitUnit: WeightUnit[];
  method: Method;
}

export function getYieldCalcConfig(cropFamily: null | HarvestCrop): null | YieldCalcConfig {
  if (cropFamily == 'grapes') {
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: false,
      deadPlantsMsg: 'DeadVinesPct',
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'BunchesPerPlant',
      earDensityMsg: null,
      kernelsPerUnitMsg: null,
      weightPerUnitMsg: 'BunchWeight',
      weightPerUnitUnit: ['grams'],
      method: 'RowsAndWidth',
    };
  } else if (cropFamily == 'soybeans') {
    return {
      calculatePlantDensity: false,
      calculateSpaceBetweenPlants: false,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'AvgPodsPerShaft',
      earDensityMsg: null,
      kernelsPerUnitMsg: 'AvgGrainsPerPod',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (cropFamily == 'corn-silage') {
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: true,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'AvgEarsPerPlant',
      earDensityMsg: null,
      kernelsPerUnitMsg: 'AvgGrainsPerEar',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'RowsAndWidth',
    };
  } else if (cropFamily == 'corn-grain' || cropFamily == 'corn-seeds') {
    // Note that this needs to come before cereals, as it's a special case.
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: true,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'AvgEarsPerPlant',
      earDensityMsg: null,
      kernelsPerUnitMsg: 'AvgGrainsPerEar',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'RowsAndWidth',
    };
  } else if (Cereals.includes(cropFamily!)) {
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: true,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'AvgEarsPerPlant',
      earDensityMsg: 'EarDensity',
      kernelsPerUnitMsg: 'AvgGrainsPerEar',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (cropFamily == 'linseed') {
    return {
      calculatePlantDensity: false,
      calculateSpaceBetweenPlants: false,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'AvgBollsPerPlant',
      earDensityMsg: null,
      kernelsPerUnitMsg: 'AvgKernelsPerBoll',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (Sunflower.includes(cropFamily!)) {
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: true,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'NumberOfRowsPerHead',
      earDensityMsg: null,
      kernelsPerUnitMsg: 'NumberOfGrainsPerRow',
      // There are 59 samples in prod with weight_per_unit != null, their semantics will change when we
      // move from "average seed weight per head" to "1000 kernel weight grams".
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'RowsAndWidth',
    };
  } else if (cropFamily == 'rapeseed') {
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: true,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: 'AvgSiliquesPerShaft',
      earDensityMsg: null,
      kernelsPerUnitMsg: 'AvgGrainsPerSilique',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (cropFamily == 'sugar-beet' || cropFamily == 'potatoes') {
    return {
      calculatePlantDensity: true,
      calculateSpaceBetweenPlants: true,
      deadPlantsMsg: null,
      plantDensityMsg: 'PlantDensity',
      unitsPerPlantMsg: null,
      earDensityMsg: null,
      kernelsPerUnitMsg: null,
      weightPerUnitMsg: 'WeightPerPlant',
      weightPerUnitUnit: ['grams'],
      method: 'RowsAndWidth',
    };
  }

  return null;
}

export function getEarDensity(plantDensity: null | DensityValue, numberOfEarsPerPlant: null | number) {
  if (plantDensity == null || numberOfEarsPerPlant == null) {
    return null;
  }
  return {unit: plantDensity.unit, val: plantDensity.val * numberOfEarsPerPlant};
}
