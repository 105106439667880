import {EnvironmentOutlined} from '@ant-design/icons';
import {ColumnSort, Row, createColumnHelper} from '@tanstack/react-table';
import {Button, Checkbox, Tooltip} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {CROP_COLORS, PALETTE_COLORS} from '../../../src/constants/colors';
import {getEnabledFlags} from '../../../src/feature-flags';
import {
  Farm,
  Field,
  GetFieldRowsRequest,
  GetFieldRowsRow,
  Harvest,
  HarvestCrop,
  HarvestData,
  HarvestYear,
  getFieldRows,
} from '../../../src/models/interfaces';
import {Uuid} from '../../../src/models/types';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {formatDate, formatDateRange} from '../../../src/text/date';
import {farmDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {ApisContext} from '../apis/ApisContext';
import {AreaHa} from '../components/Area';
import {CropTags} from '../components/CropTags';
import {DownloadButton} from '../components/DownloadButton';
import EntityInfo from '../components/EntityInfo';
import {FieldShape} from '../components/FieldShape';
import {InfinityTable, InfinityTableProps, defaultColumnSizes, getAllowedOrdering} from '../components/InfinityTable';
import {YieldTHa} from '../components/Yield';
import {ErrorBoundary} from '../util/ErrorBoundary';
import './Fields.css';
import {ExpanderCol} from './ListView';

const columnHelper = createColumnHelper<GetFieldRowsRow>();

// Explicitly list the sortable columns for get_field_rows here. If a new sortable column is added to get_field_rows,
// it must be added here as well. This is a safety measure to ensure we are only trying to sort on supported columns.
const SortableColumns = ['added_on', 'field_area'] as const;
type SortableColumn = (typeof SortableColumns)[number];
const initialSorting: ColumnSort = {
  id: 'added_on',
  desc: true,
};
const fetchSetSize = 100;

// Remember: field_id is not unique across multiple harvests. E.g. one field will be part of many harvests.
const getRowId: (row: GetFieldRowsRow) => Uuid = row => row.harvest?.harvest_id!;

const getSortValue: (row: GetFieldRowsRow, columnId: SortableColumn) => null | string = (row, columnId) =>
  columnId === 'added_on' ? row.field!.added_on : String(row.harvest_data?.field_area_ha ?? 0);

const placeholderData: GetFieldRowsRow[] = getFieldsPlaceholder();
function getFieldsPlaceholder(num: number = 15): GetFieldRowsRow[] {
  const placeholder: {
    farm: Partial<Farm>;
    field: Partial<Field>;
    harvest: Partial<Harvest>;
    harvest_data: Partial<HarvestData>;
  }[] = [];
  const randomStr = (min: number, max: number) => ''.padStart(min + Math.floor(Math.random() * (max - min)), 'x');
  const randomElement = <T,>(arr: T[] | readonly T[]): T => arr[Math.floor(Math.random() * arr.length)];
  const randomGenerator = <T,>(min: number, max: number, gen: () => T): T[] => {
    const num = min + Math.floor(Math.random() * (max - min));
    const arr: T[] = [];
    for (let i = 0; i < num; i++) {
      arr.push(gen());
    }
    return arr;
  };
  for (let i = 0; i < num; i++) {
    const cropId = randomElement(HarvestCrop as unknown as string[]);
    const randomDate = () => new Date(Date.now() - Math.random() * 1000 * 60 * 60 * 24 * 365);
    placeholder.push({
      farm: {
        farm_id: '__placeholder_' + i,
        farm_name: randomStr(5, 25),
        address: randomStr(10, 20) + '\n' + randomStr(4, 7) + ' ' + randomStr(10, 15),
        external_farm_id: randomStr(5, 25),
        farm_location: [0, 0],
        added_on: new Date().toISOString(),
      },
      field: {
        field_location: null,
        field_shape: null,
        external_field_id: randomStr(0, 10),
        field_area: {val: 2 + Math.random() * 50, unit: 'hectares'},
      },
      harvest: {
        // id must be unique, else react will not discard the placeholder rows.
        harvest_id: '__placeholder_' + i,
        crop_id: cropId,
      },
      harvest_data: {
        crop_id: cropId,
        harvest_year: randomElement(HarvestYear),
        emergence_date: randomDate().toISOString(),
        sample_dates: randomGenerator(0, 3, () => randomDate().toISOString()),
        feasible_yield_t_ha: Math.random() * 6,
        insured_yield_t_ha: Math.random() * 6,
        harvest_yield_t_ha: Math.random() * 4,
        total_loss_yield_t_ha: Math.random() * 2,
      },
    });
  }
  return placeholder as GetFieldRowsRow[];
}

const FieldDetails: React.FC<{row: Row<GetFieldRowsRow>}> = React.memo(({row}) => {
  const {t} = React.useContext(ApisContext);
  const crops = useSelector(getCrops);
  const {field, harvest, harvest_data} = row.original;

  if (!harvest?.harvest_id) {
    return <div>{t('NoData')}</div>;
  }
  const color = CROP_COLORS[getBaseCrop(crops, harvest.crop_id)!];
  const harvestDataObj = harvest_data ? {[harvest.harvest_id]: harvest_data} : null;
  return (
    <div className="field-details">
      <div className="field-info">
        <EntityInfo focus={{type: 'field', id: field!.field_id}} expanded={true} harvestDataObj={harvestDataObj} />
      </div>
      <div className="field-shape">
        <FieldShape polygon={field?.field_shape!} color={color} />
      </div>
    </div>
  );
});

const accessFieldLocation = (row: GetFieldRowsRow) => row.field?.field_location;
const accessShapeCrop = (row: GetFieldRowsRow) => ({shape: row.field?.field_shape, cropId: row.harvest?.crop_id});
const accessExternalFieldId = (row: GetFieldRowsRow) => row.field?.external_field_id;
const accessFieldArea = (row: GetFieldRowsRow) => row.harvest_data?.field_area_ha;
const accessCropId = (row: GetFieldRowsRow) => row.harvest_data?.crop_id;
const accessIrrigated = (row: GetFieldRowsRow) => row.harvest?.irrigated;
const accessOrganic = (row: GetFieldRowsRow) => row.harvest?.organic;
const accessVariety = (row: GetFieldRowsRow) => row.harvest?.aux_key;
const accessHarvestYear = (row: GetFieldRowsRow) => row.harvest_data?.harvest_year;
const accessEmergenceDate = (row: GetFieldRowsRow) => row.harvest_data?.emergence_date;
const accessSampleDates = (row: GetFieldRowsRow) => row.harvest_data?.sample_dates;
const accessInsuredArea = (row: GetFieldRowsRow) => row.harvest_data?.insured_area_ha ?? undefined;
const accessFeasibleYieldYHa = (row: GetFieldRowsRow) => row.harvest_data?.feasible_yield_t_ha ?? undefined;
const accessInsuredYield = (row: GetFieldRowsRow) => row.harvest_data?.insured_yield_t_ha ?? undefined;
const accessHarvestYieldTHa = (row: GetFieldRowsRow) => row.harvest_data?.harvest_yield_t_ha ?? undefined;
const accessTotalLossYieldTHa = (row: GetFieldRowsRow) => row.harvest_data?.total_loss_yield_t_ha ?? undefined;

const InfinityFields: React.FC = () => {
  const {authedFetcher, t} = React.useContext(ApisContext);
  const [hasData, setHasData] = React.useState<boolean>(false);
  const [selectedFields, setSelectedFields] = React.useState<GetFieldRowsRow[]>([]);
  const crops: IndexedCrops = useSelector(getCrops);

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: '__details__',
        cell: ({row}) =>
          row.getCanExpand() && (
            <span onClick={() => row.toggleExpanded()} title={row.getIsExpanded() ? undefined : t('Details')}>
              <ExpanderCol.Expander isExpanded={row.getIsExpanded()} />
            </span>
          ),
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof accessFieldLocation, ReturnType<typeof accessFieldLocation>>(accessFieldLocation, {
        id: 'field_location',
        header: '',
        cell: ({getValue, row}) =>
          getValue() ? (
            <Link
              to={{pathname: '/map/base', search: '?field_id=' + row.original.field?.field_id!}}
              title={t('ShowOnMap')}>
              <EnvironmentOutlined style={{color: PALETTE_COLORS.secondary}} />
            </Link>
          ) : null,
        size: defaultColumnSizes.xxs,
        enableSorting: false,
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.display({
        id: '__selection__',
        cell: ({row}) =>
          row.getCanSelect() && <Checkbox checked={row.getIsSelected()} onChange={() => row.toggleSelected()} />,
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof accessShapeCrop, ReturnType<typeof accessShapeCrop>>(accessShapeCrop, {
        id: 'field_shape',
        header: '',
        size: defaultColumnSizes.xxs,
        cell: React.memo(({getValue}) => {
          const value = getValue();
          if (!value || !value.shape) return null;
          const {shape, cropId} = value;
          const color = CROP_COLORS[getBaseCrop(crops, cropId)!];
          return <FieldShape polygon={shape} color={color} />;
        }),
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<'farm', Farm>('farm', {
        id: 'farm_desc',
        header: t('Farm'),
        size: defaultColumnSizes.l,
        cell: React.memo(({getValue}) => <span className="mask">{farmDesc(t, getValue())}</span>),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessExternalFieldId, ReturnType<typeof accessExternalFieldId>>(
        accessExternalFieldId,
        {
          id: 'external_field_id',
          header: t('Reference'),
          size: defaultColumnSizes.xs,
          cell: React.memo(({getValue}) => <span className="mask">{getValue()}</span>),
          enableSorting: false,
        },
      ),
      columnHelper.accessor<typeof accessFieldArea, ReturnType<typeof accessFieldArea>>(accessFieldArea, {
        id: 'field_area',
        header: t('FieldCultivatedArea'),
        size: defaultColumnSizes.s,
        cell: React.memo(({getValue}) => (
          <span className="mask numbers">
            <AreaHa value={getValue() ?? null} />
          </span>
        )),
        enableSorting: true,
      }),
      columnHelper.accessor<typeof accessCropId, ReturnType<typeof accessCropId>>(accessCropId, {
        id: 'harvest_crop',
        header: t('harvest_crop'),
        size: defaultColumnSizes.m,
        cell: React.memo(({getValue}) =>
          getValue() ? (
            <span className="mask">
              <CropTags cropIds={[getValue()!]} />
            </span>
          ) : null,
        ),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessIrrigated, ReturnType<typeof accessIrrigated>>(accessIrrigated, {
        id: 'irrigated',
        header: t('Irrigated'),
        size: defaultColumnSizes.xs,
        cell: ({getValue}) => {
          const value = getValue();
          return <span className="mask">{t(value === null ? '-' : value === true ? 'Yes' : 'No')}</span>;
        },
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessOrganic, ReturnType<typeof accessOrganic>>(accessOrganic, {
        id: 'organic',
        header: t('Organic'),
        size: defaultColumnSizes.xs,
        cell: ({getValue}) => {
          const value = getValue();
          return <span className="mask">{t(value === null ? '-' : value === true ? 'Yes' : 'No')}</span>;
        },
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessVariety, ReturnType<typeof accessVariety>>(accessVariety, {
        id: 'variety',
        header: t('CropVariety'),
        size: defaultColumnSizes.xs,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessHarvestYear, ReturnType<typeof accessHarvestYear>>(accessHarvestYear, {
        id: 'harvest_year',
        cell: React.memo(({getValue}) => <span className="mask numbers">{getValue()}</span>),
        header: t('HarvestYear'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessEmergenceDate, ReturnType<typeof accessEmergenceDate>>(accessEmergenceDate, {
        id: 'EmergenceDate',
        cell: React.memo(({getValue}) => {
          const date = getValue();
          return <span className="mask">{date ? formatDate(t, date) : '-'}</span>;
        }),
        header: t('EmergenceDate'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessSampleDates, ReturnType<typeof accessSampleDates>>(accessSampleDates, {
        id: 'sample_stats',
        header: t('Samples'),
        size: defaultColumnSizes.xs,
        cell: React.memo(({getValue}) => {
          const sampleDates = getValue() ?? [];
          const numSamples = sampleDates.length;
          // TODO(seb): Add popover with sample details.
          if (numSamples === 0) {
            return <span className="mask numbers">0</span>;
          } else if (numSamples === 1 && sampleDates[0]) {
            const date = sampleDates[0];
            return (
              <span className="mask numbers">
                {formatAsOptionalInteger(numSamples)} ({formatDate(t, date)})
              </span>
            );
          } else {
            const [min, max]: [string, string] = getExtent<string>(filterNulls(sampleDates)) as [string, string];
            return (
              <span className="mask numbers">
                {formatAsOptionalInteger(numSamples)} {min && max ? '(' + formatDateRange(t, min, max) + ')' : ''}
              </span>
            );
          }
        }),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessInsuredArea, ReturnType<typeof accessInsuredArea>>(accessInsuredArea, {
        id: 'insured_area',
        header: t('InsuredArea'),
        size: defaultColumnSizes.xs,
        cell: React.memo(({getValue}) => (
          <span className="mask numbers">
            <AreaHa value={getValue()} />
          </span>
        )),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessFeasibleYieldYHa, ReturnType<typeof accessFeasibleYieldYHa>>(
        accessFeasibleYieldYHa,
        {
          id: 'feasible_yield',
          header: t('FeasibleYield'),
          size: defaultColumnSizes.xs,
          cell: React.memo(({getValue, row}) => (
            <span className="mask numbers">
              <YieldTHa value={getValue()} cropId={accessCropId(row.original)!} />
            </span>
          )),
          enableSorting: false,
        },
      ),
      columnHelper.accessor<typeof accessInsuredYield, ReturnType<typeof accessInsuredYield>>(accessInsuredYield, {
        id: 'insured_yield',
        header: t('InsuredYield'),
        size: defaultColumnSizes.xs,
        cell: React.memo(({getValue, row}) => (
          <span className="mask numbers">
            <YieldTHa value={getValue()} cropId={accessCropId(row.original)!} />
          </span>
        )),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof accessHarvestYieldTHa, ReturnType<typeof accessHarvestYieldTHa>>(
        accessHarvestYieldTHa,
        {
          id: 'estimated_yield',
          header: t('EstimatedYield'),
          size: defaultColumnSizes.xs,
          cell: React.memo(({getValue, row}) => (
            <span className="mask numbers">
              <YieldTHa value={getValue()} cropId={accessCropId(row.original)!} />
            </span>
          )),
          enableSorting: false,
        },
      ),
      columnHelper.accessor<typeof accessTotalLossYieldTHa, ReturnType<typeof accessTotalLossYieldTHa>>(
        accessTotalLossYieldTHa,
        {
          id: 'avgLoss',
          header: t('TotalYieldPctLoss'),
          size: defaultColumnSizes.xs,
          cell: React.memo(({getValue, row}) => (
            <span className="mask numbers">
              <YieldTHa value={getValue()} cropId={accessCropId(row.original)!} />
            </span>
          )),
          enableSorting: false,
        },
      ),
    ],
    [crops, t],
  );

  const fetchData: InfinityTableProps<GetFieldRowsRow>['fetchData'] = ({pageParam, orderBy, filters}) => {
    const req: GetFieldRowsRequest = {
      ...filtersToRequest(filters),
      ...pageParam,
      // getFieldRows may fail in subtle ways if used with unsupported ordering.
      ordering: getAllowedOrdering(orderBy, SortableColumns, 'added_on'),
      row_count: fetchSetSize,
    };
    return getFieldRows(authedFetcher, req);
  };

  const onRowSelectionChange = React.useCallback((selection: GetFieldRowsRow[]) => {
    setSelectedFields(selection);
  }, []);

  return (
    <div className="list-view">
      <ErrorBoundary>
        <FieldListActions hasData={hasData} selectedFields={selectedFields} />
        <InfinityTable<GetFieldRowsRow>
          columns={columns}
          initialSorting={initialSorting}
          fetchData={fetchData}
          fetchSetSize={fetchSetSize}
          tableId="list/field-harvests"
          getRowId={getRowId}
          getSortValue={getSortValue}
          placeholderData={placeholderData}
          renderDetails={FieldDetails}
          indentDetails={1}
          onRowSelectionChange={onRowSelectionChange}
          onNumberOfDataItemsChange={num => setHasData(num > 0)}
          allowSelectAll={true}
        />
      </ErrorBoundary>
    </div>
  );
};

export default InfinityFields;

interface FieldListActionsProps {
  hasData: boolean;
  selectedFields: GetFieldRowsRow[];
}

const FieldListActions: React.FC<FieldListActionsProps> = React.memo(({hasData, selectedFields}) => {
  const {t} = React.useContext(ApisContext);
  const flags = useSelector(getEnabledFlags);
  const numFarms: number = new Set(selectedFields.map(selected => selected.farm!.farm_id)).size;

  return (
    <>
      <div className="list-view-action-buttons">
        <DownloadButton label="DownloadXLSX" downloadType="field-harvest-xlsx" enabled={hasData} />
        <DownloadButton label="DownloadGeojson" downloadType="field-harvest-geojson" enabled={hasData} />
        {flags.has('hideEditingFunctionality') ? null : selectedFields.length === 0 ? (
          <Tooltip title={t('PleaseSelectField')}>
            <Button disabled>{t('Edit')}</Button>
          </Tooltip>
        ) : (
          <Link to={'/edit/fields?field_ids=' + selectedFields.map(f => f.field!.field_id).join(',')}>
            <Button id="edit-fields">{t('Edit')}</Button>
          </Link>
        )}
        {flags.has('hideEditingFunctionality') ? null : selectedFields.length === 0 ? (
          <Tooltip title={t('PleaseSelectField')}>
            <Button disabled>{t('AddNewHarvest')}</Button>
          </Tooltip>
        ) : (
          <Link to={'/add/harvests?field_ids=' + selectedFields.map(f => f.field!.field_id).join(',')}>
            <Button id="edit-fields">{t('AddNewHarvest')}</Button>
          </Link>
        )}
        {!flags.has('hideEditingFunctionality') && (
          <Link to={'/add/fields'}>
            <Button>{t('AddField')}</Button>
          </Link>
        )}
        {flags.has('mergeEntities') &&
          !flags.has('hideEditingFunctionality') &&
          selectedFields.length > 1 &&
          (numFarms > 1 ? (
            <Tooltip title={t('SelectOneGroup')}>
              <Button disabled>{t('Merge')}</Button>
            </Tooltip>
          ) : (
            <Link to={'/edit/merge/field/' + selectedFields.map(x => x.field!.field_id).join(',')}>
              <Button id="merge">{t('Merge')}</Button>
            </Link>
          ))}
      </div>
    </>
  );
});

function getExtent<T extends number | string | Date>(values: T[]): [T, T] | [undefined, undefined] {
  return values.reduce<[T, T] | [undefined, undefined]>(
    ([min, max], value: T) => {
      return [!min || min > value ? value : min, !max || max < value ? value : max];
    },
    [undefined, undefined],
  );
}

function formatAsOptionalInteger(value: null | undefined | number) {
  return value ?? '-';
}
