import {QueryFunctionContext, QueryMeta, QueryState, useQuery, useQueryClient} from '@tanstack/react-query';
import {FeatureCollection, Point, Polygon} from 'geojson';
import {useSelector} from 'react-redux';
import {FailedToFetch} from '../../../src/FetcherFunc';
import {CROP_COLORS, MIN_ENTITY_FEATURES_ZOOM} from '../../../src/constants/colors';
import equal from '../../../src/fast-deep-equal';
import {Bbox, bboxContained, extendBbox} from '../../../src/geo';
import {EntityGeoProps} from '../../../src/models/geojson';
import {GetEntityFeatures3Request} from '../../../src/models/interfaces';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {getFilters} from '../../../src/selectors/filters';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {getMap} from '../redux/selectors';
import {reportErr} from '../util/err';
import {layerPackageToGeoJSON} from './layer-fetcher';
import {SumMapStat} from './useMapStatsLayer';

export type EntityFeatureCollection = FeatureCollection<Point | Polygon, EntityGeoProps>;

const cachePrefix = 'get_entity_features';
type CacheKey = [typeof cachePrefix, DbFilterState, number, null | Bbox, null | number];

// When fetching entity features, we also fetch the surrounding areas, so that:
// - the user doesn't have to wait for them to load if he pans;
// - we reduce the total number of requests, as larger requests are more efficient than multiple smaller ones.
const requestBboxFactor = 2;

// fetchEntityFeatures may decide to return a previous response. In that case, we need to store the response's
// viewport in the new cache entry. Then, when we inspect this cache entry in the future, we can correctly decide
// if it can satisfy that particular call to fetchEntityFeatures.
interface QueryMetadata extends QueryMeta {
  // The viewport of the cached response.
  // undefined is the initial state at the start of the request.
  // null means that the response was not cached (e.g. because we are too zoomed out).
  // 'whole-world' means that we don't provide a viewport, which fetches everything (which we only ever do
  //  for provably small datasets).
  cachedResponseBounds: null | undefined | 'whole-world' | Bbox;
}

export function useEntityFeatures(sumMapStats: null | SumMapStat): {
  entityFeatures: EntityFeatureCollection;
  loadingEntityFeatures: boolean;
} {
  const {authedFetcher} = useApis(),
    queryClient = useQueryClient();
  const filters = useSelector(getFilters),
    {lastZoom, lastBounds} = useSelector(getMap);

  const curKey: CacheKey = [cachePrefix, filters, lastZoom, lastBounds, sumMapStats?.num_fields ?? null];
  const {data, isLoading} = useQuery(curKey, fetchEntityFeatures, {
    keepPreviousData: true, // Without this, the map features flicker out of view.
    meta: {} as QueryMetadata,
  });
  return {entityFeatures: data ?? {type: 'FeatureCollection', features: []}, loadingEntityFeatures: isLoading};

  async function fetchEntityFeatures(ctx: QueryFunctionContext<CacheKey>): Promise<null | EntityFeatureCollection> {
    const smallDatasetThreshold = lastZoom < 4 ? 500 : 2000;
    const isSmallDataset = sumMapStats?.num_fields != null && sumMapStats.num_fields < smallDatasetThreshold;
    if (
      !lastZoom ||
      !lastBounds ||
      (!isSmallDataset && lastZoom < MIN_ENTITY_FEATURES_ZOOM) // Small datasets should always show up.
    ) {
      (ctx.meta as QueryMetadata).cachedResponseBounds = null;
      return null;
    }

    // If the requested bounding box is contained by a cached response then return it, unless:
    // - the filters changed
    // - the requested zoom level is deeper, and the previous response hit the limit (2000)
    // - the requested bounding box is close to the cached response's edges; in that case we fetch anyway, so that the
    //   user won't have to wait for new data as he continues panning.
    for (const queryState of queryClient.getQueryCache().findAll({queryKey: [cachePrefix]})) {
      if (equal(queryState.queryKey, curKey)) {
        continue; // Skip the query in which we find ourselves to prevent an infinite loop.
      }

      const [, cachedFilters, cachedZoom] = queryState.queryKey as CacheKey;
      const {cachedResponseBounds} = queryState.meta as QueryMetadata;
      if (cachedResponseBounds === undefined) {
        console.warn('useEntityFeatures: cache entry did not have cachedResponseBounds!', queryState.queryKey);
        continue;
      }
      let {data, status} = queryState.state as QueryState<null | EntityFeatureCollection>;
      if (
        !equal(cachedFilters, filters) ||
        !cachedResponseBounds ||
        // Make sure that the bbox extent of the data (not the request at the time) contains the map's current viewport,
        // with a 10% margin. See also:
        // https://user-images.githubusercontent.com/747519/217201631-126b2c64-1da8-438e-b1d1-de160d594a8d.png
        (cachedResponseBounds != 'whole-world' && !bboxContained(cachedResponseBounds, extendBbox(lastBounds, 1.1)))
      ) {
        continue;
      }

      if (status == 'loading') {
        // We are fetching a viewport that will most likely be enough, so wait for it to complete before considering
        // any other cached entries.
        data = (await queryState.fetch()) as null | EntityFeatureCollection;
      } else if (status == 'error') {
        continue; // If a cache entry failed, skip it.
      }
      if (data && (lastZoom <= cachedZoom || cachedResponseBounds == 'whole-world')) {
        (ctx.meta as QueryMetadata).cachedResponseBounds = cachedResponseBounds;
        return data;
      }
    }

    try {
      const requestBounds = isSmallDataset ? 'whole-world' : extendBbox(curKey[3]!, requestBboxFactor);
      let w = null,
        s = null,
        e = null,
        n = null;
      if (requestBounds != 'whole-world') {
        [w, s] = requestBounds.sw;
        [e, n] = requestBounds.ne;
      }
      let req: GetEntityFeatures3Request = {...filtersToRequest(filters), n, e, s, w, with_shapes: true};
      (ctx.meta as QueryMetadata).cachedResponseBounds = requestBounds;
      return responseToEntityFeatures(
        await authedFetcher({
          method: 'POST',
          path: 'api/rpc/get_entity_features3',
          json_body: req,
          headers: [['accept', 'application/octet-stream']],
        }),
      );
    } catch (e) {
      if (!(e instanceof FailedToFetch)) {
        reportErr(e, 'Map.fetchEntityFeatures');
      }

      throw e;
    }
  }
}

function responseToEntityFeatures(resp: Buffer): EntityFeatureCollection {
  const entityFeatures = layerPackageToGeoJSON(resp) as null | EntityFeatureCollection;
  if (!entityFeatures) {
    return {type: 'FeatureCollection', features: []};
  }

  injectIdsAndColorsToEntityFeatures(entityFeatures);
  return entityFeatures;
}

// Unfortunately, mapbox does not preserve feature.id, when querying for viewable features...
// Therefore, we copy feature.id into properties. See also note in EntityGeoProps.
export function injectIdsAndColorsToEntityFeatures(c: EntityFeatureCollection) {
  for (const feature of c.features) {
    feature.properties[(feature.properties.type + '_id') as `${typeof feature.properties.type}_id`] =
      feature.id as string;
    if (feature.properties.type == 'field') {
      const crop = feature.properties.crop;
      feature.properties.cropColor = (crop && CROP_COLORS[crop]) || 'white';
    }
  }
}
