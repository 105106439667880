import {createSelector} from 'reselect';
import {Flags, getEnabledFlags} from '../feature-flags';
import {LayerType} from '../models/interfaces';

export const shapeOpacity = 0.2;

type ExpressionName =
  // Types
  | '-'
  | '!'
  | '!='
  | '*'
  | '/'
  | '%'
  | '^'
  | '+'
  | '<'
  | '<='
  | '=='
  | '>'
  | '>='
  // Feature data
  | 'abs'
  | 'acos'
  | 'all'
  | 'any'
  | 'array'
  // Lookup
  | 'asin'
  | 'at'
  | 'atan'
  | 'boolean'
  // Decision
  | 'case'
  | 'ceil'
  | 'coalesce'
  | 'collator'
  | 'concat'
  | 'cos'
  | 'downcase'
  | 'e'
  | 'feature-state'
  | 'floor'
  | 'format'
  | 'geometry-type'
  // Ramps, scales, curves
  | 'get'
  | 'has'
  | 'heatmap-density'
  | 'id'
  // Variable binding
  | 'interpolate-hcl'
  | 'interpolate-lab'
  // String
  | 'interpolate'
  | 'is-supported-script'
  | 'length'
  | 'let'
  | 'line-progress'
  // Color
  | 'literal'
  | 'ln'
  // Math
  | 'ln2'
  | 'log10'
  | 'log2'
  | 'match'
  | 'max'
  | 'min'
  | 'number'
  | 'object'
  | 'pi'
  | 'properties'
  | 'resolved-locale'
  | 'rgb'
  | 'rgba'
  | 'round'
  | 'sin'
  | 'sqrt'
  | 'step'
  | 'string'
  | 'tan'
  | 'to-boolean'
  | 'to-color'
  | 'to-number'
  | 'to-string'
  | 'typeof'
  | 'upcase'
  // Zoom, Heatmap
  | 'var'
  | 'zoom';

type ExpressionField = boolean | number | string | Expression | ExpressionField[] | {[key: string]: ExpressionField};

export type Expression = [ExpressionName, ...ExpressionField[]];

export const samplePointLayerFilter: Expression = ['all', ['==', '$type', 'Point'], ['==', 'type', 'sample']];
export const samplePolyLayerFilter: Expression = ['all', ['==', '$type', 'Polygon'], ['==', 'type', 'sample']];
export const fieldPointLayerFilter: Expression = ['all', ['==', '$type', 'Point'], ['==', 'type', 'field']];
export const fieldPolyLayerFilter: Expression = ['all', ['==', '$type', 'Polygon'], ['==', 'type', 'field']];
export const farmPointLayerFilter: Expression = ['all', ['==', '$type', 'Point'], ['==', 'type', 'farm']];

export function isLayerType(x: null | undefined | string): x is LayerType {
  return LayerType.includes(x as any);
}

export const FieldLayerType = ['intrafield', 'interfield', 'interyield'] as const;
export type FieldLayerType = (typeof FieldLayerType)[number];

export function isFieldLayer(x: null | undefined | string): x is FieldLayerType {
  return FieldLayerType.includes(x as any);
}

export type RegionalLayerType = Exclude<LayerType, FieldLayerType>;

export function isRegionalLayer(x: null | undefined | string): x is RegionalLayerType {
  return isLayerType(x) && !isFieldLayer(x);
}

export type MapLayers = 'base' | 'satellite' | Exclude<LayerType, 'flood-risk'>;

export function isMapLayer(x: string): x is MapLayers {
  return x !== 'flood-risk' && (x == 'base' || x == 'satellite' || LayerType.includes(x as any));
}

export const MapLayers: MapLayers[] = [
  'base',
  'satellite',
  'intrafield',
  'interfield',
  'interyield',
  'soil-moisture',
  'vegetation',
  'temperature',
  'surface-temperature',
  'hail',
  'precipitation',
  'rainstorm',
  'wind',
  'crop-mon',
  'custom-a',
];

LayerType.forEach(x => {
  if (isMapLayer(x) && !MapLayers.includes(x)) {
    MapLayers.push(x);
    console.warn('Layer', x, 'was not explicitly added to MapLayers');
  }
});

const getClient = (_: Readonly<unknown>, client: 'app' | 'web') => client;

export function isEnabledLayer(flags: Set<Flags>, client: 'app' | 'web', layer: MapLayers) {
  switch (layer) {
    case 'hail':
      return flags.has('hail');
    case 'crop-mon':
      return client == 'web';
    case 'interyield':
      return client == 'web' && flags.has('interyield');
    case 'custom-a':
      return client == 'web' && flags.has('marSamplingLocations');
    default:
      return true;
  }
}

export const getEnabledLayers = createSelector(
  [getClient, getEnabledFlags],
  (client, enabledFlags) => new Set(MapLayers.filter(x => isEnabledLayer(enabledFlags, client, x))),
);

const layerTypology: {[P in MapLayers]: 'base' | 'satellite'} = {
  base: 'base',
  satellite: 'satellite',
  intrafield: 'satellite',
  interfield: 'satellite',
  interyield: 'satellite',
  'soil-moisture': 'base',
  vegetation: 'base',
  temperature: 'base',
  'surface-temperature': 'base',
  hail: 'base',
  precipitation: 'base',
  rainstorm: 'base',
  wind: 'base',
  'crop-mon': 'base',
  'custom-a': 'satellite',
};

export function isCompositeSatLayer(layer: MapLayers, canonical_date: null | string): boolean {
  return layer == 'satellite' && !!canonical_date?.match(/\d\d\d\d-\d\d-\d\d/);
}

export function getStyleUrl(flags: Set<Flags>, layer: MapLayers, canonical_date: null | string, apiUrl: string) {
  // We do not enable optimize=true in the styles below,
  // so that we only have to download pbox://mapbox.mapbox-streets-v8 once.

  // Do not show the background median zones, if the user is looking at the historical ones.
  const baseMapUrl = flags.has('marSamplingLocations')
    ? layer == 'custom-a'
      ? 'mapbox://styles/savvopoulos/cm3pzzym8002701sdcava2f9f'
      : 'mapbox://styles/savvopoulos/cm3pzzyvh002801sdcxguamwg'
    : 'mapbox://styles/savvopoulos/cm3pzzzyh001n01s657i06jew';
  const mapboxSatelliteStyleUrl = flags.has('marSamplingLocations')
    ? layer == 'custom-a'
      ? 'mapbox://styles/savvopoulos/cm3q0cj6o002b01sd11m83har'
      : 'mapbox://styles/savvopoulos/cm3pzzyls002401sgh33z389c'
    : 'mapbox://styles/savvopoulos/cm3pzzyla002i01sdadng4aym';

  let styleURL: string = layerTypology[layer] == 'base' ? baseMapUrl : mapboxSatelliteStyleUrl;
  if (isCompositeSatLayer(layer, canonical_date)) {
    styleURL = '/sat-composite.json';
  }
  if (layer == 'satellite' && canonical_date == 'high-res-mapbox') {
    styleURL = mapboxSatelliteStyleUrl;
  }
  if (layer == 'satellite' && canonical_date == 'most-recent') {
    styleURL = '/sat-recent.json';
  }
  if (styleURL[0] === '/') {
    if (apiUrl.endsWith('/')) {
      styleURL = styleURL.slice(1);
    }
    styleURL = apiUrl + styleURL;
  }

  return styleURL;
}

export function getSentinelHubTileUrl(canonical_date: string) {
  let tileUrl = 'https://services.sentinel-hub.com/ogc/wms/244b1505-5938-4ed1-a1f7-8a4645decdbe';
  tileUrl += '?service=WMS&request=GetMap&layers=MONTH&width=512&height=512&maxcc=100&priority=leastCC';
  tileUrl += '&format=image/jpg&gain=1.4';
  tileUrl += '&bbox={bbox-epsg-3857}';

  // For the canonical date 2020-05-01, we should generate the range 2020-05-01 to 2020-06-01.
  const nextMonth = new Date(canonical_date);
  nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1);
  tileUrl += `&TIME=${canonical_date}/${nextMonth.toISOString().slice(0, 10)}`;
  return tileUrl;
}
