import {MinusCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons';
import {useQuery} from '@tanstack/react-query';
import {Popconfirm, Tooltip} from 'antd';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {FormyI} from '../../../src/Formy';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {getI18nOptions} from '../../../src/Formy/FormyEnum';
import {useFormyValue} from '../../../src/Formy/hooks';
import {getEnabledFlags} from '../../../src/feature-flags';
import {ImportedData, ImportedFarm, ImportedField, ImportedHarvest} from '../../../src/gt-pack/gt-pack';
import {I18nFunction} from '../../../src/i18n/i18n';
import {Farm, Field, HarvestYear} from '../../../src/models/interfaces';
import {getSelectableHarvestYears} from '../../../src/selectors/harvest';
import {getUnitSystem} from '../../../src/selectors/units';
import {filterNulls} from '../../../src/util/arr-util';
import {fetchEntities, fetchEntity} from '../../../src/util/fetchEntity';
import {FormyAddress} from '../Formy/FormyAddress';
import {FormyBool} from '../Formy/FormyBool';
import FormyCropSelector from '../Formy/FormyCropSelector';
import FormyEditorsSelector from '../Formy/FormyEditorsSelector';
import {FormyEnum} from '../Formy/FormyEnum';
import {FormySuggest} from '../Formy/FormySuggest';
import {FormyTextStr, FormyUnit} from '../Formy/FormyText';
import FormyUserGroupSelector from '../Formy/FormyUserGroupSelector';
import {Apis} from '../apis/Apis';
import {ApisContext, useApis} from '../apis/ApisContext';
import {ExternalFieldIdInput} from './ExternalFieldIdInput';
import {FormyFieldModal} from './FormyFieldModal';

interface FieldFormRowProps extends FormyComponentProps<ImportedField, 'field_id'> {
  farmFormy: FormyI<ImportedFarm>;
  existingFields: undefined | Field[];
}

function ExistingFieldFormRow({field}: {field?: Field}) {
  const {t} = useApis();
  if (!field) {
    return null;
  }

  return (
    <>
      <span className="import-cell">
        {field.external_field_id} ({t('Existing')})
      </span>
      <span className="import-cell">{field.field_area ? t({type: 'AreaUnit', ...field.field_area}) : ''}</span>
    </>
  );
}

class FieldFormRow extends FormyComponent<ImportedField, 'field_id', FieldFormRowProps> {
  static contextType = ApisContext;
  context!: Apis;
  units = getUnitSystem(this.context.store.getState());
  farmIdCb = this.props.farmFormy.watchValue('farm_id', this);

  onFieldDupeSelected = (x: Field) => this.props.formy.getChangeHandler('field_id')(x.field_id);

  componentWillUnmount() {
    super.componentWillUnmount();
    this.props.farmFormy.unwatch(this);
  }

  render() {
    if (this.value) {
      return <ExistingFieldFormRow field={this.props.existingFields?.find(x => x.field_id == this.value)} />;
    }

    return (
      <>
        <span className="import-cell">
          <ExternalFieldIdInput
            farm_id={this.farmIdCb()}
            onEntitySelected={this.onFieldDupeSelected}
            field="external_field_id"
            formy={this.props.formy}
          />
        </span>
        <span className="import-cell">
          <FormyUnit
            className="field-area"
            field="field_area"
            label="FieldArea"
            formy={this.props.formy}
            units={[this.units.areaUnit] as const}
          />
          <FormyFieldModal
            field="field_shape"
            formy={this.props.formy}
            farm_location={this.props.farmFormy.getValue('farm_location')}
          />
        </span>
      </>
    );
  }
}

interface HarvestsFormProps extends FormyComponentProps<ImportedField, 'harvests'> {
  farmFormy: FormyI<ImportedFarm>;
  fieldIdx: number;
  existingFields: undefined | Field[];
}

class HarvestsForm extends FormyComponent<ImportedField, 'harvests', HarvestsFormProps> {
  static contextType = ApisContext;
  context!: Apis;

  render() {
    const formy = this.props.formy.getSectionFormy('harvests');
    const t = formy.t;

    // Special case - if this field doesn't have any harvests, render just the field info.
    if (this.value.length == 0) {
      return (
        <span className="import-row">
          <FieldFormRow
            formy={this.props.formy}
            field="field_id"
            farmFormy={this.props.farmFormy}
            existingFields={this.props.existingFields}
          />
          <Tooltip title={this.props.formy.t('RemoveField')}>
            <MinusCircleTwoTone
              className="imp-remove-item"
              twoToneColor="#f44336"
              data-testid="remove-field"
              onClick={() => this.removeField(this.props.fieldIdx)}
            />
          </Tooltip>
          <span className="import-cell harvest-cell">
            <Tooltip title={this.props.formy.t('AddHarvest')}>
              <PlusCircleTwoTone
                className="imp-add-harvest imp-add-harvest-end"
                onClick={this.addHarvest}
                twoToneColor="#4CAF50"
                data-testid="add-harvest"
              />
            </Tooltip>
          </span>
          <span className="import-cell harvest-cell" />
        </span>
      );
    }
    return this.value.map((_harvest, idx) => {
      const harvestFormy = formy.getSectionFormy(idx);
      return (
        <span className="import-row" key={idx}>
          {idx == 0 ? (
            <>
              <FieldFormRow
                formy={this.props.formy}
                field="field_id"
                farmFormy={this.props.farmFormy}
                existingFields={this.props.existingFields}
              />
            </>
          ) : (
            <>
              <span className="import-cell import-cell-repeat">»</span>
              <span className="import-cell import-cell-repeat">»</span>
            </>
          )}
          <span className="import-cell harvest-cell">
            <FormyCropSelector formy={harvestFormy} field="crop_id" />
            {idx < this.value.length - 1 ? null : (
              <Tooltip title={this.props.formy.t('AddHarvest')}>
                <PlusCircleTwoTone
                  className="imp-add-harvest imp-add-harvest-end"
                  onClick={this.addHarvest}
                  twoToneColor="#4CAF50"
                  data-testid="add-harvest"
                />
              </Tooltip>
            )}
          </span>
          <span className="import-cell harvest-cell">
            <FormyBool selectMsg="Irrigated" field="irrigated" formy={harvestFormy} />
          </span>
          <span className="import-cell harvest-cell">
            <FormyBool selectMsg="Organic" field="organic" formy={harvestFormy} />
          </span>
          <span className="import-cell harvest-cell">
            <FormyTextStr formy={harvestFormy} field="variety" placeholder="SelectVariety" />
          </span>
          <span className="import-cell harvest-cell">
            <FormyEnum<HarvestYear, ImportedHarvest, 'harvest_year'>
              selectMsg="SelectYear"
              field="harvest_year"
              formy={harvestFormy}
              options={getI18nOptions(t, getSelectableHarvestYears(this.context.clock))}
            />
          </span>
          <Tooltip title={this.props.formy.t('RemoveHarvest')} placement="left">
            <MinusCircleTwoTone
              className="imp-remove-item"
              twoToneColor="#f44336"
              data-testid="remove-harvest"
              onClick={() => this.removeHarvest(idx)}
            />
          </Tooltip>
        </span>
      );
    });
  }

  addHarvest = () => this.props.formy.getChangeHandler('harvests')([...this.value, new ImportedHarvest(null)]);

  removeField = (idx: number) => {
    const fields = this.props.farmFormy!.getValue('fields');
    this.props.farmFormy!.getChangeHandler('fields')([...fields.slice(0, idx), ...fields.slice(idx + 1)]);
  };

  removeHarvest = (idx: number) => {
    this.props.formy.getChangeHandler('harvests')([...this.value.slice(0, idx), ...this.value.slice(idx + 1)]);
  };
}

function FieldsForm({formy}: {formy: FormyI<ImportedFarm>}) {
  const fields = useFormyValue(formy, 'fields') ?? [];
  const {authedFetcher} = useApis();
  const fieldIds = filterNulls(fields.map(f => f.field_id));
  const {data} = useQuery(['fields', fieldIds], () => fetchEntities(authedFetcher, 'field', fieldIds));
  return (
    <>
      {fields.map((_field, idx) => (
        <HarvestsForm
          key={idx}
          fieldIdx={idx}
          farmFormy={formy}
          formy={formy.getSectionFormy('fields').getSectionFormy(idx)}
          field="harvests"
          existingFields={data}
        />
      ))}
    </>
  );
}

class FieldHeader extends FormyComponent<ImportedFarm, 'fields'> {
  render() {
    if (this.value.length == 0) {
      return null;
    }

    const t = this.props.formy.t;
    return (
      <span className="import-row header">
        <span className="import-cell">{t('FieldReference')}</span>
        <span className="import-cell">
          {t('FieldCultivatedArea')} / {t('Polygon')}
        </span>
        <span className="import-cell">{t('harvest_crop')}</span>
        <span className="import-cell">{t('Irrigated')}</span>
        <span className="import-cell">{t('Organic')}</span>
        <span className="import-cell">{t('CropVariety')}</span>
        <span className="import-cell">{t('harvest_year')}</span>
      </span>
    );
  }
}

type AllFieldsFormProps = FormyComponentProps<ImportedData, 'farms'> & {noSaveAsNew?: boolean};

export class AllFieldsForm extends FormyComponent<ImportedData, 'farms', AllFieldsFormProps> {
  render() {
    if (this.value.length == 0) {
      return null;
    }

    const formy = this.props.formy.getSectionFormy('farms');
    return this.value.map((_farm, idx) => (
      <span className="import-form" key={idx}>
        <h2>
          {formy.t('Farm')} #{idx + 1}{' '}
          <Popconfirm
            title={formy.t({type: 'SureRemoveFarm', fieldCount: formy.getValue(idx).fields.length})}
            okText={formy.t('Ok')}
            cancelText={formy.t('Cancel')}
            onConfirm={() => this.removeFarm(idx)}>
            <Tooltip title={formy.t('RemoveFarm')}>
              <MinusCircleTwoTone className="imp-remove-item" twoToneColor="#f44336" data-testid="remove-farm" />
            </Tooltip>
          </Popconfirm>
        </h2>
        <FarmsFormHeader formy={formy.getSectionFormy(idx)} field="farm_id" noSaveAsNew={this.props.noSaveAsNew} />
        <span className="import-form-nested">
          <FieldHeader formy={formy.getSectionFormy(idx)} field="fields" />
          <FieldsForm key={idx} formy={formy.getSectionFormy(idx)} />
          {this.value.length > 0 && (
            <Tooltip title={formy.t('AddField')}>
              <PlusCircleTwoTone onClick={() => this.addField(idx)} twoToneColor="#4CAF50" className="imp-add-item" />
            </Tooltip>
          )}
        </span>
      </span>
    ));
  }

  addField = (idx: number) => {
    const farms = this.props.formy.getValue('farms');
    const newField = new ImportedField(null);
    newField.harvests.push(new ImportedHarvest(null));
    this.props.formy.getSectionFormy('farms').getSectionFormy(idx).getChangeHandler('fields')([
      ...farms[idx].fields,
      newField,
    ]);
  };

  removeFarm = (idx: number) => {
    this.handleChange([...this.value.slice(0, idx), ...this.value.slice(idx + 1)]);
  };
}

function FarmHeaderRow({t}: {t: I18nFunction}) {
  const hasTelepac = useSelector(getEnabledFlags).has('hasTelepac');
  return (
    <span className="import-row header">
      <span className="import-cell">{t('UserGroup')}</span>
      <span className="import-cell">{t('FarmReference')}</span>
      {hasTelepac && <span className="import-cell">{t('TelepacNr')}</span>}
      <span className="import-cell">{t('FarmName')}</span>
      <span className="import-cell">{t('FarmAddress')}</span>
      <span className="import-cell">{t('Editors')}</span>
      <span className="import-cell">{t('PolicyNumber')}</span>
    </span>
  );
}

function ExistingFarmFormHeader({
  farm_id,
  formy,
  noSaveAsNew,
}: {
  farm_id: string;
  formy: FormyI<ImportedFarm>;
  noSaveAsNew?: boolean;
}) {
  const {authedFetcher, t, analytics} = useApis();
  const {data} = useQuery(['farm', farm_id], () => fetchEntity(authedFetcher, 'farm', farm_id));
  const removeFarmId = useCallback(() => {
    analytics.logEvent({event_name: 'Importer-FarmAddAsNew'});
    formy.getChangeHandler('farm_id')(null);
    formy
      .getValue('fields')
      .forEach((_field, idx) =>
        formy.getSectionFormy('fields').getSectionFormy(idx).getChangeHandler('field_id')(null),
      );
  }, [analytics, formy]);
  const hasTelepac = useSelector(getEnabledFlags).has('hasTelepac');
  if (!data) {
    return null;
  }

  return (
    <span>
      {t('ExistingFarm')}
      {noSaveAsNew ? null : <a onClick={removeFarmId}>{' ' + t('HereAddAsNew')}</a>}
      <span>
        <FarmHeaderRow t={t} />
        <span className="import-row">
          <span className="import-cell">{data.user_group}</span>
          <span className="import-cell">{data.external_farm_id}</span>
          {hasTelepac && <span className="import-cell">{data.custom_columns?.telepac_id}</span>}
          <span className="import-cell">{data.farm_name}</span>
          <span className="import-cell">{data.address}</span>
          <span className="import-cell">{data.editors.join(', ')}</span>
        </span>
      </span>
    </span>
  );
}

type FarmsFormHeaderProps = FormyComponentProps<ImportedFarm, 'farm_id'> & {noSaveAsNew?: boolean};

export class FarmsFormHeader extends FormyComponent<ImportedFarm, 'farm_id', FarmsFormHeaderProps> {
  static contextType = ApisContext;
  context!: Apis;
  hasTelepac = getEnabledFlags(this.context.store.getState()).has('hasTelepac');

  render() {
    const formy = this.props.formy;
    const farmHeaderRow = (
      <span className="import-row header">
        <span className="import-cell">{formy.t('UserGroup')}</span>
        <span className="import-cell">{formy.t('FarmReference')}</span>
        <span className="import-cell">{formy.t('TelepacNr')}</span>
        <span className="import-cell">{formy.t('FarmName')}</span>
        <span className="import-cell">{formy.t('FarmAddress')}</span>
        <span className="import-cell">{formy.t('Editors')}</span>
      </span>
    );

    if (this.value) {
      return <ExistingFarmFormHeader formy={formy} farm_id={this.value} noSaveAsNew={this.props.noSaveAsNew} />;
    }

    return (
      <span>
        {farmHeaderRow}
        <span className="import-row">
          <span className="import-cell">
            <FormyUserGroupSelector formy={formy} field="user_group" />
          </span>
          <span className="import-cell">
            <FormySuggest
              onEntitySelected={this.onFarmDupeSelected}
              label={'FarmReference'}
              field="external_farm_id"
              formy={formy}
            />
          </span>
          {this.hasTelepac && (
            <span className="import-cell">
              <FormyTextStr field="telepac_id" formy={formy} />
            </span>
          )}{' '}
          <span className="import-cell">
            <FormySuggest onEntitySelected={this.onFarmDupeSelected} label="FarmName" field="farm_name" formy={formy} />
          </span>
          <span className="import-cell">
            <FormyAddress formy={formy} />
          </span>
          <span className="import-cell">
            <FormyEditorsSelector formy={formy} />
          </span>
        </span>
      </span>
    );
  }

  onFarmDupeSelected = (x: Farm) => this.props.formy.getChangeHandler('farm_id')(x.farm_id);
}
