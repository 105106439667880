import {DatePicker} from 'antd';
import moment from 'moment';
import {Moment} from 'moment/moment';
import React, {useCallback} from 'react';
import {FormyI} from '../../../src/Formy';
import {useFormyValue} from '../../../src/Formy/hooks';
import {useApis} from '../apis/ApisContext';

const {RangePicker} = DatePicker;

type IsNullable<T, K> = null extends T ? K : never;
type NullableKey<T> = {[K in keyof T]-?: IsNullable<T[K], K>}[keyof T];

export interface FormyDateFromToProps<F> {
  formy: FormyI<F>;
  range_from_field: NullableKey<F>;
  range_to_field: NullableKey<F>;
  useTime?: boolean;
}

const width100 = {width: '100%'};

export const FormyDateRange = <F,>(props: FormyDateFromToProps<F>) => {
  const {t} = useApis();
  const handleChange = useCallback(
    async (range: null | [null | Moment, null | Moment], _: [string, string]) => {
      if (!range) {
        props.formy.getChangeHandler(props.range_from_field)(null!);
        props.formy.getChangeHandler(props.range_to_field)(null!);
        return;
      }
      const [startDate, endDate] = range;
      const dateFrom = startDate?.toISOString();
      const dateTo = endDate?.toISOString();
      props.formy.getChangeHandler(props.range_from_field)(dateFrom as any);
      props.formy.getChangeHandler(props.range_to_field)(dateTo as any);
    },
    [props.formy, props.range_from_field, props.range_to_field],
  );
  const rangeFrom = useFormyValue(props.formy, props.range_from_field);
  const rangeTo = useFormyValue(props.formy, props.range_to_field);
  return (
    <RangePicker
      disabled={props.formy.getMode() === 'view'}
      data-testid={`FormyDateRange-${String(props.range_from_field)}-${String(props.range_to_field)}`}
      showTime={props.useTime}
      style={width100}
      placeholder={[`${t('StartDateTime')}`, `${t('EndDateTime')}`]}
      value={[rangeFrom ? moment(rangeFrom) : null, rangeTo ? moment(rangeTo) : null]}
      format={props.useTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
      onChange={handleChange}
    />
  );
};
