import {fastDeepEqual} from '../../fast-deep-equal';
import {CropCondition, HarvestYear, LossCause, VisitType} from '../../models/interfaces';
import {Uuid} from '../../models/types';
import {DbActionTypes} from '../actions/db';
import {DbFilterActions} from '../actions/filters';

export interface DbFilterManager {
  dbFilters: DbFilterState;
  setDbFilter?: (filters: Partial<DbFilterState>) => void;
  resetDbFilter?: (key: keyof DbFilterState) => void;
  resetAllDbFilters?: () => void;
}

export interface DbFilterState {
  crop_id: string[]; // Note that this should be an aggregating crop_id, and exclude sub crop types.
  harvest_year: HarvestYear[];
  crop_condition: CropCondition[];
  loss_cause: LossCause[];
  sample_date: string[];
  policy_id: Uuid[];
  farm_id: Uuid[];

  added_by: string[];
  user_group: string[];
  added_on: {startDate: null | string; endDate: null | string};
  max_ndvi_date: {startDate: null | string; endDate: null | string};
  max_ndvi_value: {from: null | number; to: null | number};
  emergence_date: {startDate: null | string; endDate: null | string};
  regions: {label: string; value: string}[];
  claim_id: Uuid[];
  visit_type: VisitType[];

  // Filters out samples that whose added_on is older than N months ago.
  // Note that unlike other filters, this does not filter out farms, fields, or harvests.
  // The goal of this filter is to remove old samples from the app, but still allow the user to see last years'
  // data.
  // `null` means that the filter is not applied (all samples).
  // `undefined` allows clients to set their own default (e.g. 9 months on mobile app).
  sample_added_on_months_ago?: null | number;
}

export const dbFiltersInitialState: DbFilterState = {
  added_by: [],
  added_on: {startDate: null, endDate: null},
  crop_condition: [],
  loss_cause: [],
  sample_date: [],
  farm_id: [],
  policy_id: [],
  crop_id: [],
  harvest_year: [],
  user_group: [],
  emergence_date: {startDate: null, endDate: null},
  max_ndvi_value: {from: null, to: null},
  max_ndvi_date: {startDate: null, endDate: null},
  regions: [],
  claim_id: [],
  visit_type: [],
};

export const filters = (
  state: DbFilterState = dbFiltersInitialState,
  action: DbActionTypes | DbFilterActions,
): DbFilterState => {
  switch (action.type) {
    case 'RESET':
      return dbFiltersInitialState;
    case 'SET_DB_FILTER':
      const newState = {
        ...state,
        ...action.filters,
      };
      if (fastDeepEqual(newState, state)) {
        return state;
      } else {
        return newState;
      }
    case 'RESET_DB_FILTER': {
      return {
        ...state,
        [action.key]: dbFiltersInitialState[action.key],
      };
    }
    case 'RESET_ALL_DB_FILTERS': {
      return {...dbFiltersInitialState};
    }
    default:
      return state;
  }
};
