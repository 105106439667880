import React, {ChangeEventHandler} from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {FormyTextNumBase, FormyUnitBase} from '../../../src/Formy/FormyTextBase';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {ValueUnit} from '../../../src/models/types';
import {Label} from './FormyLabel';

interface FormyTextStrProps<F, Fk extends keyof F> extends FormyComponentProps<F, Fk> {
  type?: 'email' | 'text';
  placeholder?: I18nSimpleKey;
  label?: I18nSimpleKey;
  disabled?: boolean;
}

export class FormyTextStr<F extends {[P in Fk]: null | string}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyTextStrProps<F, Fk>
> {
  render() {
    let className = 'formy-item-style';
    if (this.error) {
      className += ' formy-item-error';
    }
    return (
      <>
        {this.props.label && <Label>{this.props.formy.t(this.props.label)}:</Label>}
        <input
          data-testid={`FormyTextStr-${String(this.props.field)}`}
          className={className}
          placeholder={
            this.props.placeholder && this.mode !== 'view' ? this.props.formy.t(this.props.placeholder) : undefined
          }
          type={this.props.type ?? 'text'}
          disabled={this.mode === 'view' || this.props.disabled}
          onChange={this.onChange}
          onBlur={this.handleBlur}
          value={this.value ?? ''}
        />
      </>
    );
  }

  onChange: ChangeEventHandler<HTMLInputElement> = x => this.handleChange(x.target.value as any);
}

export class FormyUnit<U extends ValueUnit, F extends {[P in Fk]: null | U}, Fk extends keyof F> extends FormyUnitBase<
  U,
  F,
  Fk
> {
  render() {
    let className = 'formy-item-style';
    if (this.error) {
      className += ' formy-item-error';
    }
    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    const disabled = this.mode === 'view' || this.props.disabled;
    return (
      <span style={this.props.inline ? {...this.props.style, display: 'flex'} : this.props.style}>
        <input
          data-testid={`FormyUnit-${String(this.props.field)}`}
          className={className}
          style={this.props.inline ? {minWidth: '100px'} : {}}
          disabled={disabled}
          onChange={x => this.onChangeText(x.target.value)}
          onBlur={this.handleBlur}
          value={this.state.valueStr ?? ''}
          placeholder={this.props.label && this.props.formy.t(this.props.label)}
        />
        {(this.props.alwaysShowUnits || this.props.units.length > 1) && (
          <select
            className="formy-item-style"
            disabled={disabled}
            value={this.value?.unit}
            style={this.props.inline ? {width: '100%'} : {}}
            onChange={x => this.onChangeUnit(x.target.value as any)}>
            {this.props.units.map(v => (
              <option value={v} key={v}>
                {this.props.formy.t(v)}
              </option>
            ))}
          </select>
        )}
      </span>
    );
  }
}

export interface FormyTextNumProps<F, Fk extends keyof F> extends FormyComponentProps<F, Fk> {
  placeholder?: I18nSimpleKey;
  label?: I18nSimpleKey;
  min?: number;
  max?: number;
  disabled?: boolean;
}

export class FormyTextNum<F extends {[P in Fk]: null | number}, Fk extends keyof F> extends FormyTextNumBase<
  F,
  Fk,
  FormyTextNumProps<F, Fk>
> {
  render() {
    let className = 'formy-item-style';
    if (this.error) {
      className += ' formy-item-error';
    }
    return (
      <>
        {this.props.label && <Label>{this.props.formy.t(this.props.label)}:</Label>}
        <input
          data-testid={`FormyTextStr-${String(this.props.field)}`}
          className={className}
          placeholder={this.props.placeholder && this.props.formy.t(this.props.placeholder)}
          type={'number'}
          min={this.props.min}
          max={this.props.max}
          disabled={this.mode === 'view' || this.props.disabled}
          onChange={event => this.onChangeText(event.target.value)}
          onBlur={this.handleBlur}
          value={this.value ?? ''}
        />
      </>
    );
  }
}
