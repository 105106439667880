import {Harvest, HarvestYear} from '../models/interfaces';
import cmp from '../util/cmp';

// Note that we do not force these types to be non-nullable; if a Harvest can have a null value for a column,
// we should be able to handle that.
export type HarvestKey = {
  harvest_year: null | HarvestYear;
  crop_id: null | string;
  aux_key: null | string;
};

export const nullHarvestKey = {
  crop_id: null,
  harvest_year: null,
  aux_key: null,
} as const;

export function getHarvestKey(harvest: HarvestKey): HarvestKey {
  // NOTE: it's important to construct this object in a way where stringifying it will give the same result for the same input.
  // For example, the keys should always be in one order.
  return {
    // Do not return unknown here, this should be done when presenting null to the user.
    // Do not allow undefined or empty, as it changes the key (undefined omits it); those should all be null.
    crop_id: harvest.crop_id || null,
    harvest_year: harvest.harvest_year || null,
    aux_key: harvest.aux_key?.trim() || null,
  };
}

// An ordering over harvests:
// - by REVERSE harvest year (2024 first, before 2023)
// - by crop_id & variety
// - by irrigated & organic (order: null, false, true)
export function compareHarvestAggregationKeys(a: HarvestKey, b: HarvestKey) {
  // Normalize to a HarvestAggregationKey.
  a = getHarvestKey(a);
  b = getHarvestKey(b);
  return (
    cmp(b.harvest_year ?? '', a.harvest_year ?? '') ||
    cmp(a.crop_id ?? '', b.crop_id ?? '') ||
    cmp(a.aux_key ?? '', b.aux_key ?? '')
  );
}

export function harvestAggregationKeyEq(a: null | undefined | HarvestKey, b: null | undefined | HarvestKey) {
  if (a == null || b == null) {
    return a == null && b == null;
  }
  return compareHarvestAggregationKeys(a, b) === 0;
}

export function harvestKeyToStr(key: HarvestKey) {
  return JSON.stringify(getHarvestKey(key));
}

export function strToHarvestKey(str: string): HarvestKey {
  return JSON.parse(str);
}

export type HarvestTemplate = HarvestKey & Partial<Harvest>;
