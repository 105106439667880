import {MonitoredCrop} from './models/crop-mon';
import {HarvestCrop} from './models/interfaces';

// A reasonable maximum yield for various crop families. Used to show a warning in sample form.
export const maxYieldTHa: {[P in HarvestCrop]?: number} = {
  wheat: 15,
  'wheat-hard': 15,
  barley: 10,
  'barley-malting': 10,
  oats: 10,
  buckwheat: 10,
  cotton: 10,
  rapeseed: 10,
  soybeans: 10,
  sunflower: 10,
  rye: 10,
  chickpeas: 10,
  mustard: 10,
  peas: 10,
  rice: 10,
  lentils: 10,
  linseed: 10,
  'corn-grain': 20,
  sorghum: 20,
  triticale: 20,
  'corn-silage': 100,
  'sorghum-silage': 100,
  // else: < 250 t/ha
};

export function getMaxYield(harvest_crop: null | undefined | HarvestCrop) {
  return maxYieldTHa[harvest_crop ?? 'unknown'] ?? 250;
}

// A reasonable yield range for various crop families. Used to determine the color range for crop monitoring.
export const yieldRangeTHa: {[P in MonitoredCrop]: [number, number]} = {
  'corn-grain': [4, 15],
  'corn-silage': [10, 40],
  wheat: [2, 10],
  'wheat-hard': [2, 10],
  rice: [2, 10],
  triticale: [2, 10],
  spelt: [2, 10],
  barley: [2, 10],
  'barley-malting': [2, 10],
  oats: [2, 6],
  rye: [2, 6],
  sorghum: [2, 5],
  rapeseed: [1, 6],
  soybeans: [1, 6],
  peas: [1, 6],
  beans: [1, 6],
  sunflower: [1, 4],
  chickpeas: [1, 4],
  'sugar-cane': [30, 128],
  'sugar-beet': [30, 90],
  potatoes: [5, 40],
  cotton: [0.25, 1.5],
  tomatoes: [10, 90],
};
