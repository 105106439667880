import {Harvest} from '../models/interfaces';
import {PriceAreaValue} from '../models/types';
import {derivePriceAreaUnit, deriveUnitPriceUnit} from '../selectors/units';

// Note that total sum insured is limited to the basic sum insured, as this will be the maximum indemnity limit.
// This means that if there is a total loss in replanting the remaining amount that could be paid for the
// basic coverage will be basic sum insured - replanting sum insured.
export function calculateBasicSumInsured(coveragePerArea: null | PriceAreaValue, selectedFieldsHarvestAreaHa: number) {
  return (coveragePerArea?.val ?? 0) * selectedFieldsHarvestAreaHa;
}

export function calculateBasicPremium(sumInsured: number, premiumRatePercent: number) {
  return (sumInsured * premiumRatePercent) / 100;
}

export function calculateCommodityPrice(
  referenceYield: Harvest['reference_yield'],
  coveragePerArea: null | PriceAreaValue,
  insuredYield: number,
) {
  return coveragePerArea && insuredYield && referenceYield
    ? {
        val: Number((coveragePerArea.val / insuredYield).toFixed(2)),
        unit: deriveUnitPriceUnit(coveragePerArea.unit, referenceYield.unit),
      }
    : null;
}

// The function assumes that the insuredYield has the same unit as reference yield.
export function calculateCoveragePerArea(
  referenceYield: Harvest['reference_yield'],
  commodityPrice: Harvest['commodity_price'],
  insuredYield: number,
) {
  return commodityPrice && referenceYield
    ? {
        val: Number((insuredYield * commodityPrice.val).toFixed(2)),
        unit: derivePriceAreaUnit(commodityPrice.unit, referenceYield.unit),
      }
    : null;
}

// Replanting sum insured is defined as a percent of total sum insured, which we currently hardcode to 25%.
const replantingSumInsuredPercent = 0.25;

export function calculateReplantingSumInsured(sumInsured: number) {
  return sumInsured * replantingSumInsuredPercent;
}

export function calculateReplantingPremium(replantingSumInsured: number, replantingPremiumRatePercent: number) {
  return replantingSumInsured * replantingPremiumRatePercent;
}
