import {Crop} from '../../models/interfaces';
import {CropActions} from '../actions/crops';

export type IndexedCrops = {[crop_id: string]: undefined | Crop};

export interface CropState {
  crops: IndexedCrops;
  lastUpdated: null | string;
  lastUsedCropIds: string[];
}

const initialCropState: CropState = {crops: {}, lastUpdated: null, lastUsedCropIds: []};

export function crops(state = initialCropState, action: CropActions): CropState {
  switch (action.type) {
    case 'SET_CROPS':
      return {
        ...state,
        lastUpdated: action.lastUpdated,
        crops: Object.fromEntries(action.crops.map(x => [x.crop_id, x])),
      };
    case 'ADD_USED_CROP_ID':
      const lastUsedCropIds = [action.crop_id];
      for (const x of state.lastUsedCropIds) {
        if (x != action.crop_id && lastUsedCropIds.length < 2) {
          lastUsedCropIds.push(x);
        }
      }
      return {...state, lastUsedCropIds};
    default:
      return state;
  }
}
