import {TelepacHarvestContents} from '../gt-pack/telepac';
import {FarmReportValues, ReportSignature, VisitReportOptions} from '../report/report-types';
import {parseDate} from '../util/date-util';
import {isBool, isNum} from '../validator-constraints';
import {CoverageType, LossCause, VisitType} from './interfaces';
import {SoilType} from './types';

// TODO(kristjan): Move to SQL and consequently to interfaces.ts
export const Gender = ['male', 'female'] as const;
export type Gender = (typeof Gender)[number];

export const MaritalStatus = ['single', 'married', 'divorced', 'widowed', 'separated'] as const;
export type MaritalStatus = (typeof MaritalStatus)[number];

export const InsuredStatus = ['verified', 'unverified'] as const;
export type InsuredStatus = (typeof InsuredStatus)[number];

export const PlantingType = ['conventional', 'direct'] as const;
export type PlantingType = (typeof PlantingType)[number];

export const ProductType = ['costing', 'productivity'] as const;
export type ProductType = (typeof ProductType)[number];

export const LegalEntityType = ['person', 'company'] as const;
export type LegalEntityType = (typeof LegalEntityType)[number];

export const ProductionUnit = ['brl-per-hectare'] as const;
export type ProductionUnit = (typeof ProductionUnit)[number];

export interface ProductionValue {
  val: number;
  unit: ProductionUnit;
}

export class FarmCustomColumns {
  address?: null | Address;

  constructor(x: null | Partial<FarmCustomColumns>) {
    this.address = x?.address ?? null;
  }
}

export interface Address {
  street: null | string;
  number: null | string;
  additional_information: null | string;
  neighborhood: null | string;
  city: null | string;
  state: null | string;
  zip_code: null | string;
  // Refers to country specific code (e.g. IBGE, INSEE...) that is used beside the zipcode
  external_code: null | string;
  country: null | string;
}

// Insured entity can be either a company or person.
export class LegalEntity {
  type: null | LegalEntityType;

  // External id is a unique identification number (e.g. tax number).
  // In Brazil, it's either CNPJ for companies or CPF for persons.
  external_id: null | string;
  address: null | Address;
  email: null | string;
  phone: null | string;
  gender: null | Gender;
  name: null | string;
  alternative_name: null | string;
  inception_date: null | string;
  insured_status: null | InsuredStatus;
  marital_status: null | MaritalStatus;

  constructor(x: null | Partial<LegalEntity>) {
    this.type = x?.type ?? null;
    this.external_id = x?.external_id ?? null;
    this.address = x?.address ?? null;
    this.email = x?.email ?? null;
    this.phone = x?.phone ?? null;
    this.gender = x?.gender ?? null;
    this.alternative_name = x?.alternative_name ?? null;
    this.name = x?.name ?? null;
    this.inception_date = x?.inception_date ?? null;
    this.insured_status = x?.insured_status ?? null;
    this.marital_status = x?.marital_status ?? null;
  }
}

export class Broker {
  entity: null | LegalEntity;
  registration_number: null | string;
  leader: null | boolean;
  percent: null | number;

  constructor(x: null | Partial<Broker>) {
    this.entity = x?.entity ?? null;
    this.registration_number = x?.registration_number ?? null;
    this.leader = x?.leader ?? null;
    this.percent = x?.percent ?? null;
  }
}

export class Beneficiary {
  entity: null | LegalEntity;
  registration_number: null | string;
  percent: null | number;

  constructor(x: null | Partial<Beneficiary>) {
    this.entity = x?.entity ?? null;
    this.registration_number = x?.registration_number ?? null;
    this.percent = x?.percent ?? null;
  }
}

export class MgaPolicyCustomColumns {
  // Proposal/policy is linked to multiple external systems (brokers, broker aggregation platforms (eCampo),
  // subsidies, insurance companies) and each of them has their own id below.
  ecampo_proposal_id: null | string;
  axa_policy_id: null | string;
  federal_subsidy_id: null | string;

  product_type: null | ProductType;

  // Product code is the number that the insurance company receives from the Brazilian insurance regulatory body (SUSEP).
  product_code: null | string;

  // Subsidy unit is Brazil Reals R$.
  federal_subsidy: null | number;
  state_subsidy: null | number;

  // The following attributes are based on 27th December 2024 AXA's policy template.
  previous_crop: null | string;
  crop_already_planted: null | boolean;
  crop_already_damaged: null | boolean;
  insured_invoice_recipient: null | boolean;
  aware_of_zoagro_mapa: null | boolean;
  total_planted_area_insured: null | boolean;
  preexisting_policy_for_same_area: null | boolean;
  first_or_second_year: null | boolean;
  planting_type: null | PlantingType;

  // The following attributes were not strictly required by AXA, but are present in eCampo, and we still collect them.
  certified_or_registered_seeds: null | boolean;
  drainage_problems: null | boolean;
  flood_or_waterlogging_past_5_years: null | boolean;
  indigenous_area: null | boolean;
  intercropped_planting: null | boolean;
  homemade_seed: null | boolean;
  crop_planted_after_pasture: null | boolean;
  same_crop_whole_area: null | boolean;
  losses_in_other_harvests: null | boolean;
  insured_area_settlement: null | boolean;
  insured_area_sandy: null | boolean;
  predominantly_ad1_ad2_soil: null | boolean;
  aware_of_new_soil_classification: null | boolean;
  insured_area_fully_owned: null | boolean;
  responsible_for_paying_invoice: null | boolean;

  // Production cost and sum insured are not strictly needed yet, but they are available in the payload and
  // may be useful for validation.
  production_cost: null | ProductionValue;
  sum_insured: null | number;
  total_payment_days: null | number;

  // We are not yet sure how generalized the replanting process is, and we keep the value on
  // custom columns for now. If the process is similar globally, it can be moved to harvest.
  coverage_type: null | CoverageType;
  installment_count: null | number;

  constructor(x: null | Partial<MgaPolicyCustomColumns>) {
    this.ecampo_proposal_id = x?.ecampo_proposal_id ?? null;
    this.axa_policy_id = x?.axa_policy_id ?? null;
    this.product_type = x?.product_type ?? null;

    this.product_code = x?.product_code ?? null;

    this.federal_subsidy_id = x?.federal_subsidy_id ?? null;

    this.federal_subsidy = x?.federal_subsidy ?? null;
    this.state_subsidy = x?.state_subsidy ?? null;

    this.previous_crop = x?.previous_crop ?? null;
    this.crop_already_planted = x?.crop_already_planted ?? null;
    this.crop_already_damaged = x?.crop_already_damaged ?? null;
    this.insured_invoice_recipient = x?.insured_invoice_recipient ?? null;
    this.aware_of_zoagro_mapa = x?.aware_of_zoagro_mapa ?? null;
    this.total_planted_area_insured = x?.total_planted_area_insured ?? null;
    this.preexisting_policy_for_same_area = x?.preexisting_policy_for_same_area ?? null;
    this.first_or_second_year = x?.first_or_second_year ?? null;
    this.planting_type = x?.planting_type ?? null;

    this.certified_or_registered_seeds = x?.certified_or_registered_seeds ?? null;
    this.intercropped_planting = x?.intercropped_planting ?? null;
    this.homemade_seed = x?.homemade_seed ?? null;
    this.crop_planted_after_pasture = x?.crop_planted_after_pasture ?? null;
    this.same_crop_whole_area = x?.same_crop_whole_area ?? null;
    this.losses_in_other_harvests = x?.losses_in_other_harvests ?? null;
    this.crop_already_planted = x?.crop_already_planted ?? null;
    this.indigenous_area = x?.indigenous_area ?? null;
    this.insured_area_settlement = x?.insured_area_settlement ?? null;
    this.insured_area_sandy = x?.insured_area_sandy ?? null;
    this.predominantly_ad1_ad2_soil = x?.predominantly_ad1_ad2_soil ?? null;
    this.aware_of_new_soil_classification = x?.aware_of_new_soil_classification ?? null;
    this.insured_area_fully_owned = x?.insured_area_fully_owned ?? null;
    this.responsible_for_paying_invoice = x?.responsible_for_paying_invoice ?? null;

    this.drainage_problems = x?.drainage_problems ?? null;
    this.flood_or_waterlogging_past_5_years = x?.flood_or_waterlogging_past_5_years ?? null;
    this.production_cost = x?.production_cost ?? null;
    this.sum_insured = x?.sum_insured ?? null;
    this.total_payment_days = x?.total_payment_days ?? null;
    this.coverage_type = x?.coverage_type ?? null;
    this.installment_count = x?.installment_count ?? null;
  }
}

// We allow everything to be null to avoid unnecessarily pollution of the custom columns.
export class PolicyCustomColumns {
  insured: null | LegalEntity;
  representative: null | LegalEntity;
  valid_from: null | string;
  valid_to: null | string;
  brokers: null | Broker[];
  beneficiaries: null | Beneficiary[];
  mga: null | MgaPolicyCustomColumns;

  constructor(x: null | Partial<PolicyCustomColumns>) {
    this.insured = x?.insured ?? null;
    this.representative = x?.representative ?? null;
    this.valid_from = x?.valid_from ?? null;
    this.valid_to = x?.valid_to ?? null;
    this.brokers = x?.brokers ?? null;
    this.beneficiaries = x?.beneficiaries ?? null;
    this.mga = x?.mga ?? null;
  }
}

export class FieldCustomColumns {
  constructor(_: null | any) {}
}

export type AffectedZone = {
  // TODO(seb): Remove optionality after Q2 2024.
  inseeCode?: string;
  identifiers: string[];
  affected: boolean;
  area: number;
  lossCauses: LossCause[];
  lossLabels: string[];
};

export class EtlCustomColumns {
  franchises: null | string[];
  cropCode: string;
  cropInfoCode: string;
  cngraCode: string;

  constructor(x: null | any) {
    this.franchises = getStrArray(x, 'franchises');
    this.cropCode = x?.cropCode ?? null;
    this.cropInfoCode = x?.cropInfoCode ?? null;
    this.cngraCode = x?.cngraCode ?? null;
  }
}

export class MgaHarvestCustomColumns {
  soil_type: null | SoilType;
  expected_planting_date: null | string;
  // We are not yet sure how generalized the replanting process is, and we keep the value on
  // custom columns for now. If the process is similar globally, it can be moved to the farm harvest.
  // Value is 0-100.
  replanting_premium_rate_percent: null | number;

  constructor(x: null | Partial<MgaHarvestCustomColumns>) {
    this.soil_type = x?.soil_type ?? null;
    this.expected_planting_date = x?.expected_planting_date ?? null;
    this.replanting_premium_rate_percent = x?.replanting_premium_rate_percent ?? null;
  }
}

export class HarvestCustomColumns {
  grpmFranchises: null | string[];
  grpmPastYields: null | [null | number, null | number, null | number, null | number, null | number];
  grpmAvgYield: null | number;
  grpmUpdatedYields: null | [null | number, null | number, null | number, null | number, null | number];
  grpmAffectedZones: null | AffectedZone[];
  grpmCulture: null | string;
  grpmCropDetails: null | {
    Espece: null | string;
    Variete: null | string;
    Saisonnalite: null | string;
    DetailRegional: null | string;
    Couleur: null | string;
    ModeDeProduction: null | string;
    Destination: null | string;
  };

  etl: null | EtlCustomColumns;
  telepacData: null | TelepacHarvestContents;
  mga: null | MgaHarvestCustomColumns;

  constructor(x: null | any) {
    Object.assign(this, x);
    this.grpmFranchises = getStrArray(x, 'grpmFranchises');
    this.grpmPastYields = x?.grpmPastYields ?? null;
    if (this.grpmPastYields?.length != 5 || this.grpmPastYields.some(x => !isNum(x))) {
      this.grpmPastYields = null;
    }
    this.grpmUpdatedYields = x?.grpmUpdatedYields ?? null;
    if (this.grpmUpdatedYields?.length != 5 || this.grpmUpdatedYields.some(x => !isNum(x))) {
      this.grpmUpdatedYields = null;
    }
    this.grpmAvgYield = getNum(x, 'grpmAvgYield');
    this.grpmAffectedZones = x?.grpmAffectedZones ?? null;
    if (this.grpmAffectedZones) {
      if (
        !this.grpmAffectedZones.every(y => getStrArray(y, 'identifiers') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossCauses') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossLabels') != null) ||
        !this.grpmAffectedZones.every(y => isBool(y?.affected)) ||
        !this.grpmAffectedZones.every(y => getNum(y, 'area') != null)
      ) {
        this.grpmAffectedZones = null;
      }
    }
    this.telepacData = x?.telepacData ?? null;
    this.grpmCulture = getStr(x, 'grpmCulture');
    this.grpmCropDetails = x?.grpmCropDetails
      ? {
          Espece: getStr(x.grpmCropDetails, 'Espece'),
          Variete: getStr(x.grpmCropDetails, 'Variete'),
          Saisonnalite: getStr(x.grpmCropDetails, 'Saisonnalite'),
          DetailRegional: getStr(x.grpmCropDetails, 'DetailRegional'),
          Couleur: getStr(x.grpmCropDetails, 'Couleur'),
          ModeDeProduction: getStr(x.grpmCropDetails, 'ModeDeProduction'),
          Destination: getStr(x.grpmCropDetails, 'Destination'),
        }
      : null;

    this.etl = x?.etl ? new EtlCustomColumns(x.etl) : null;
    this.mga = x?.mga ? new MgaHarvestCustomColumns(x.mga) : null;
  }
}

export class HowdenHarvestCustomColumns extends HarvestCustomColumns {
  sowingDate: string | null;
  deductibles: number | null;

  constructor(x: Partial<HowdenHarvestCustomColumns> | any) {
    super(x);
    this.sowingDate = parseDate(x.sowingDate)?.toISOString().slice(0, 10) ?? null;
    this.deductibles = x?.deductibles ?? null;
    if (!this.deductibles || !isNum(this.deductibles) || this.deductibles < 0 || this.deductibles > 100) {
      this.deductibles = null;
    } else {
      this.deductibles = x?.deductibles;
    }
  }
}

export function isHowdenHarvestCustomColumns(obj: any): obj is HowdenHarvestCustomColumns {
  return (
    obj &&
    typeof obj === 'object' &&
    'deductibles' in obj &&
    typeof obj.deductibles === 'number' &&
    'sowing_date' in obj &&
    typeof obj.sowing_date === 'string'
  );
}

function getStrArray(x: null | undefined | Record<string, any>, prop: string): null | string[] {
  const val = x?.[prop];
  if (val instanceof Array && val.every(x => typeof x == 'string')) {
    return val;
  }

  return null;
}

function getNum(x: null | undefined | Record<string, any>, prop: string): null | number {
  const val = x?.[prop];
  if (typeof val == 'number') {
    return val;
  }

  return null;
}

function getStr(x: null | undefined | Record<string, any>, prop: string): null | string {
  const val = x?.[prop];
  if (typeof val == 'string') {
    return val;
  }
  return null;
}

export const GrpmVisitAction = [
  'preinspection-none',
  'validation-without-review',
  'validation-with-review',
  'monitoring-without-review',
  'monitoring-without-review-provisioning',
  'monitoring-with-review',
  'monitoring-with-review-provisioning',
  'claim-some',
  'claim-all',
  'claim-delivery',
] as const;
export type GrpmVisitAction = (typeof GrpmVisitAction)[number];

export const GrpmVisitTypeToActionMapping: Record<VisitType, GrpmVisitAction[]> = {
  'preinspection-visit': ['preinspection-none'],
  'validation-visit': ['validation-without-review', 'validation-with-review'],
  'monitoring-visit': [
    'monitoring-without-review',
    'monitoring-without-review-provisioning',
    'monitoring-with-review',
    'monitoring-with-review-provisioning',
  ],
  'claims-visit': ['claim-some', 'claim-all', 'claim-delivery'],
};

export class VisitCustomColumnsGrpm {
  visitAction: null | GrpmVisitAction;
  inseeShapes: Record<string, string>;

  constructor(x: null | Partial<VisitCustomColumnsGrpm>) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.visitAction = x?.visitAction ?? null;
  }
}

export class VisitCustomColumns {
  grpm: null | VisitCustomColumnsGrpm;
  // The values used to generate the final (signed) report.
  signedFarmReportValues?: FarmReportValues;
  // The report options used to generate the final (signed) report.
  signedVisitReportOptions?: VisitReportOptions;
  signedSignatures?: Omit<ReportSignature, 'signature'>[];
  // The app version used when signing the report.
  signedOnVersion?: string;

  constructor(x: null | Partial<ClaimCustomColumns | VisitCustomColumns>) {
    this.grpm = x?.grpm ? new VisitCustomColumnsGrpm(x.grpm) : null;
    if (x && 'signedFarmReportValues' in x) {
      this.signedFarmReportValues = x?.signedFarmReportValues;
      this.signedVisitReportOptions = x?.signedVisitReportOptions;
      this.signedSignatures = x?.signedSignatures;
      this.signedOnVersion = x?.signedOnVersion;
    }
  }

  // Some custom_columns should not be duplicated when creating a duplicate visit. This
  // method ensures that all users of this class can rely on the same duplication logic.
  duplicate(): VisitCustomColumns {
    return new VisitCustomColumns({
      grpm: this.grpm,
    });
  }
}

export class ClaimCommentGrpm {
  issued_on: string;
  text: string;

  constructor(x: null | any) {
    this.issued_on = x?.issued_on;
    this.text = x?.text;
  }
}

export class ClaimCustomColumnsGrpm {
  inseeShapes: Record<string, string>;
  comments: ClaimCommentGrpm[];

  constructor(x: null | Partial<ClaimCustomColumnsGrpm>) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.comments = x?.comments ?? [];
  }
}

export class ClaimCustomColumns {
  grpm: null | ClaimCustomColumnsGrpm;

  constructor(x: null | Partial<ClaimCustomColumns>) {
    this.grpm = x?.grpm ? new ClaimCustomColumnsGrpm(x.grpm) : null;
  }
}
