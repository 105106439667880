import {MinusCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {Polygon} from 'geojson';
import {History} from 'history';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {FormyChanged, FormyErrors, keepChangedFields} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {getI18nOptions} from '../../../src/Formy/FormyEnum';
import {LngLat} from '../../../src/geo';
import {validateImportedHarvest} from '../../../src/gt-pack/validators';
import {HarvestData} from '../../../src/models/data';
import {Farm, Field, Harvest, HarvestYear, Policy} from '../../../src/models/interfaces';
import {transportToHarvest} from '../../../src/models/serialization';
import {AreaValue} from '../../../src/models/types';
import {insertHarvest, updateField, updateHarvest} from '../../../src/redux/actions/db';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getCrops} from '../../../src/selectors/crops';
import {getSelectableHarvestYears} from '../../../src/selectors/harvest';
import {getCountryCodeGroups, getUnitSystem} from '../../../src/selectors/units';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {isAreaValue, isArr, isGeojsonPolygon, isStr, isUuid} from '../../../src/validator-constraints';
import {FormyBool} from '../Formy/FormyBool';
import FormyCropSelector from '../Formy/FormyCropSelector';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {FormyEnum} from '../Formy/FormyEnum';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormySuggest} from '../Formy/FormySuggest';
import {FormyTextStr, FormyUnit} from '../Formy/FormyText';
import {Apis} from '../apis/Apis';
import {ApisContext, useApis} from '../apis/ApisContext';
import {FormyFieldModal} from '../import/FormyFieldModal';
import {reportErr} from '../util/err';
import {commitMutations, insertMissingPolicy} from './commit';

export class HarvestForm {
  harvest_id: null | string; // null == added harvest
  crop_id: null | string;
  harvest_year: null | HarvestYear;
  policy_id: null | string;
  policy_number: null | string; // if policy_id is null and policy_number is not empty, a new policy will be created.
  harvest_area: null | AreaValue;
  variety: null | string;
  aux_key: null | string;
  irrigated: null | boolean;
  organic: null | boolean;

  constructor(harvest: null | Harvest) {
    this.harvest_id = harvest?.harvest_id ?? null;
    this.crop_id = harvest?.crop_id ?? null;
    this.harvest_year = harvest?.harvest_year ?? null;
    this.policy_id = harvest?.policy_id ?? null;
    this.harvest_area = harvest?.harvest_area ?? null;
    this.variety = harvest?.variety ?? null;
    this.aux_key = harvest?.aux_key ?? null;
    this.policy_number = null;
    this.irrigated = harvest?.irrigated ?? null;
    this.organic = harvest?.organic ?? null;
  }
}

export class FieldForm {
  farm_id: null | string;
  external_field_id: null | string;
  field_shape: null | Polygon;
  field_location: null | LngLat;
  field_area: null | AreaValue;
  harvests: HarvestForm[];

  constructor(field: Field, harvests: Harvest[]) {
    this.farm_id = field.farm_id;
    this.external_field_id = field.external_field_id;
    this.field_shape = field.field_shape;
    this.field_location = field.field_location;
    this.field_area = field.field_area;
    this.harvests = harvests.map(x => new HarvestForm(x)) ?? [];
  }
}

interface FieldHarvestsRowProps extends FormyComponentProps<FieldForm, 'harvests'> {
  field_id: string;
  farm_location: null | Farm['farm_location'];
}

interface RemoveHarvestButtonProps extends FormyComponentProps<HarvestForm, 'harvest_id'> {
  onPress: () => void;
}

class RemoveHarvestButton extends FormyComponent<HarvestForm, 'harvest_id', RemoveHarvestButtonProps> {
  render() {
    if (this.value != null) {
      return null;
    }

    return (
      <Tooltip title={this.props.formy.t('RemoveHarvest')}>
        <MinusCircleTwoTone className="imp-remove-item" twoToneColor="#f44336" onClick={this.props.onPress} />
      </Tooltip>
    );
  }
}

export class FieldHarvestsRow extends FormyComponent<FieldForm, 'harvests', FieldHarvestsRowProps> {
  static contextType = ApisContext;
  context!: Apis;
  units = getUnitSystem(this.context.store.getState());
  countryGroups = getCountryCodeGroups(this.context.store.getState());

  render() {
    const t = this.props.formy.t;
    const fieldFormRow = (
      <>
        <span className="import-cell">
          <FormyEntitySelector field="farm_id" formy={this.props.formy} entityType="farm" onNewEntity={null} />
        </span>
        <span className="import-cell">
          <FormyTextStr field="external_field_id" formy={this.props.formy} placeholder={'ExternalFieldId'} />
        </span>
        <span className="import-cell">
          <FormyUnit
            className="field-area"
            field="field_area"
            formy={this.props.formy}
            label="FieldArea"
            units={[this.units.areaUnit] as const}
          />
          <FormyFieldModal field="field_shape" formy={this.props.formy} farm_location={this.props.farm_location} />
        </span>
      </>
    );

    // Special case - if this field doesn't have any harvests, render just the field info.
    if (this.value.length == 0) {
      return (
        <span className="import-row">
          {fieldFormRow}
          <span className="import-cell harvest-cell" />
          <span className="import-cell harvest-cell" />
          <span className="import-cell harvest-cell">
            <Tooltip title={this.props.formy.t('AddHarvest')}>
              <PlusCircleTwoTone
                className="imp-add-harvest imp-add-harvest-middle"
                onClick={this.addHarvest}
                twoToneColor="#4CAF50"
              />
            </Tooltip>
          </span>
          <span className="import-cell harvest-cell" />
        </span>
      );
    }

    const formy = this.props.formy.getSectionFormy('harvests');
    return this.value.map((_harvest, idx) => {
      const harvestFormy = formy.getSectionFormy(idx);
      return (
        <span className="import-row" key={idx}>
          {idx == 0 ? (
            <>{fieldFormRow}</>
          ) : (
            <>
              <span className="import-cell import-cell-repeat">»</span>
              <span className="import-cell import-cell-repeat">»</span>
              <span className="import-cell import-cell-repeat">»</span>
            </>
          )}
          <span className="import-cell harvest-cell">
            <FormyCropSelector formy={harvestFormy} field="crop_id" />
          </span>
          <span className="import-cell">
            <FormyBool<HarvestForm, 'irrigated'> selectMsg="Irrigated" field="irrigated" formy={harvestFormy} />
          </span>
          <span className="import-cell">
            <FormyBool<HarvestForm, 'organic'> selectMsg="Organic" field="organic" formy={harvestFormy} />
          </span>
          <span className="import-cell">
            <FormyTextStr formy={harvestFormy} field="variety" placeholder="SelectVariety" />
          </span>
          {/* TODO(savv): show aux_key here, but if filled for any row */}
          <span className="import-cell harvest-cell">
            <FormyEnum<HarvestYear, HarvestForm, 'harvest_year'>
              selectMsg="SelectYear"
              field="harvest_year"
              formy={harvestFormy}
              options={getI18nOptions(t, getSelectableHarvestYears(this.context.clock))}
            />
          </span>
          <span className="import-cell">
            <FormySuggest
              onEntitySelected={(x: Policy) => harvestFormy.getChangeHandler('policy_id')(x.policy_id)}
              label="PolicyNumber"
              field="policy_number"
              formy={harvestFormy}
            />
            <Tooltip title={this.props.formy.t('AddHarvest')}>
              <PlusCircleTwoTone
                className="imp-add-harvest imp-add-harvest-middle"
                onClick={this.addHarvest}
                twoToneColor="#4CAF50"
              />
            </Tooltip>
          </span>
          <RemoveHarvestButton formy={harvestFormy} field="harvest_id" onPress={() => this.removeHarvest(idx)} />
        </span>
      );
    });
  }

  removeHarvest = (idx: number) =>
    this.props.formy.getChangeHandler('harvests')([...this.value.slice(0, idx), ...this.value.slice(idx + 1)]);

  addHarvest = () => this.props.formy.getChangeHandler('harvests')([...this.value, new HarvestForm(null)]);
}

export function validateEditedField(crops: IndexedCrops, x: FieldForm): FormyErrors<FieldForm> {
  return {
    farm_id: !x.farm_id || !isUuid(x.farm_id),
    external_field_id: !isStr(x.external_field_id, 1),
    field_shape: !isGeojsonPolygon(x.field_shape),
    field_area: !isAreaValue(x.field_area),
    harvests: !isArr(x.harvests) || x.harvests.map(x => validateImportedHarvest(crops, x)),
  };
}

function validateFieldForms(crops: IndexedCrops, x: FieldForms): FormyErrors<FieldForms> {
  const res: FormyErrors<{[field_id: string]: FieldForm}> = {};
  for (const field_id in x.fields) {
    res[field_id] = validateEditedField(crops, x.fields[field_id]);
  }
  return {fields: res};
}

class FieldForms {
  fields: {[field_id: string]: FieldForm} = {};
}

interface FieldRowsProps extends FormyComponentProps<FieldForms, 'fields'> {
  fields: Field[];
  farms: Farm[];
}

class FieldRows extends FormyComponent<FieldForms, 'fields', FieldRowsProps> {
  static contextType = ApisContext;
  context!: Apis;
  units = getUnitSystem(this.context.store.getState());

  getFarmLocation(field_id: string) {
    const field = this.props.fields.find(x => x.field_id == field_id);
    const farm = this.props.farms.find(x => x.farm_id == field?.farm_id);
    return farm?.farm_location;
  }

  render() {
    const field_ids = Object.keys(this.value);
    const formy = this.props.formy.getSectionFormy('fields');
    return (
      <span className="import-form">
        <span>
          <span className="import-row header">
            <span className="import-cell">{formy.t('Farm')}</span>
            <span className="import-cell">{formy.t('FieldReference')}</span>
            <span className="import-cell">
              {formy.t('FieldCultivatedArea')} / {formy.t('Polygon')}
            </span>
            <span className="import-cell">{formy.t('harvest_crop')}</span>
            <span className="import-cell">{formy.t('Irrigated')}</span>
            <span className="import-cell">{formy.t('Organic')}</span>
            <span className="import-cell">{formy.t('CropVariety')}</span>
            <span className="import-cell">{formy.t('harvest_year')}</span>
            <span className="import-cell">{formy.t('PolicyNumber')}</span>
          </span>
          {field_ids.map(field_id => (
            <FieldHarvestsRow
              key={field_id}
              formy={formy.getSectionFormy(field_id)}
              field="harvests"
              field_id={field_id}
              farm_location={this.getFarmLocation(field_id) ?? null}
            />
          ))}
        </span>
      </span>
    );
  }
}

async function fetchFieldHarvests(authedFetcher: FetcherFunc, fieldIds: string[]) {
  if (fieldIds.length == 0) {
    return [];
  }

  const params: [string, string][] = getPostgrestQueryParams({column: 'field_id', operator: 'in', value: fieldIds});
  const resp: any[] = await authedFetcher({method: 'GET', path: 'api/harvest', params});
  return resp.map(x => transportToHarvest(x));
}

async function init(apis: Apis, history: History, crops: IndexedCrops, fieldIds: string[]) {
  const [fields, harvests] = await Promise.all([
    fetchEntities(apis.authedFetcher, 'field', fieldIds),
    fetchFieldHarvests(apis.authedFetcher, fieldIds),
  ]);
  const farms = await fetchEntities(apis.authedFetcher, 'farm', Array.from(new Set(fields.map(x => x.farm_id))));

  const initialData: FieldForms = new FieldForms();
  for (const field of fields) {
    const fieldHarvests = harvests.filter(x => x.field_id == field.field_id);
    initialData.fields[field.field_id] = new FieldForm({...field, field_shape: field.field_shape}, fieldHarvests);
  }

  async function onSubmit(values: FieldForms, changedCols: FormyChanged<FieldForms>) {
    try {
      const changedFields =
        typeof changedCols == 'object' && typeof changedCols.fields == 'object' ? changedCols.fields : {};
      for (const field_id in values.fields) {
        const valueField = values.fields[field_id];
        const changedField = changedFields[field_id];
        const changed = keepChangedFields(valueField, changedField);
        if (changed) {
          // changed.farm_id cannot be null due to validation; help TS figure that out.
          const {harvests, ...changed2} = changed as Partial<FieldForm> & {farm_id?: string};
          apis.store.dispatch(updateField(field_id, changed2));
        }

        const changedHarvests =
          typeof changedField == 'object' && typeof changedField.harvests == 'object' ? changedField.harvests : [];
        for (let i = 0; i < valueField.harvests.length; ++i) {
          const harvestForm = valueField.harvests[i],
            changed = keepChangedFields(harvestForm, changedHarvests[i]);
          if (changed) {
            const field = fields.find(x => x.field_id == field_id);
            const farm = farms.find(x => x.farm_id == field?.farm_id);
            if (!farm || !field) {
              throw new Error(`Farm or field for field id ${field_id} not found`);
            }
            insertMissingPolicy(apis, field_id, harvestForm, farm.user_group);
            if (harvestForm.harvest_id) {
              // Edit.
              const {harvest_id: _, policy_number: _2, ...changed2} = changed;
              apis.store.dispatch(updateHarvest(harvestForm.harvest_id, changed2));
            } else {
              const harvest = valueField.harvests[i];
              const data: HarvestData = {
                field_id,
                farm_id: null,
                policy_id: harvest.policy_id,
                insured_yield: null,
                insured_price: null,
                insured_area: null,
                irrigated: null,
                crop_id: harvest.crop_id,
                harvest_year: harvest.harvest_year,
                harvest_area: harvest.harvest_area,
                aux_key: harvest.aux_key,
                variety: harvest.variety,
                organic: null,
                comments: null,
                metadata: null,
                custom_columns: null,
                external_harvest_id: null,
                merged_ids: null,
                premium_rate_percent: null,
                commodity_price: null,
                reference_yield: null,
                insured_percent: null,
                realized_yield: null,
                machinery_yield: null,
                weighed_yield: null,
                settlement_yield: null,
              };
              harvest.harvest_id = apis.store.dispatch(insertHarvest(apis, data));
            }
          }
        }
      }

      if (await commitMutations(apis)) {
        history.goBack();
      }
    } catch (e) {
      reportErr(e, 'EditFields::onSubmit');
    }
  }

  const formy = new Formy('edit', initialData, apis.t, onSubmit, x => validateFieldForms(crops, x));
  return {formy, harvests, fields, farms};
}

export default function EditFields() {
  const apis = useApis(),
    location = useLocation(),
    history = useHistory();
  const crops = useSelector(getCrops);
  const field_ids = useMemo(() => new URLSearchParams(location.search).get('field_ids')?.split(',') ?? [], [location]);
  const content = useAsyncMemo(() => init(apis, history, crops, field_ids), [apis, history, field_ids, crops]);

  if (!content) {
    return null;
  }

  return (
    <span key={field_ids.join(',')}>
      <FieldRows formy={content.formy} field="fields" fields={content.fields} farms={content.farms} />
      <span className="import-save">
        <FormySubmit label="Save" formy={content.formy} doNotConfirmSubmissionToUser={true} />
      </span>
    </span>
  );
}
