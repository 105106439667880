import {
  grAllGroups,
  grHiddenName,
  mdaAllGroups,
  mdaHiddenName,
  paAllGroups,
  paHiddenName,
  txAllGroups,
  txHiddenName,
} from '../constants/group-consts';
import {UserGroup} from '../models/interfaces';

// TODO(savv): Remove these once they are removed from the DB.
const utilityGroups = ['europe', 'africa', 'asia', 'oceania', 'north-america', 'south-america', 'crop-mon'];
export type UserGroupCustomer =
  | 'other-customer'
  | typeof grHiddenName
  | typeof mdaHiddenName
  | typeof paHiddenName
  | typeof txHiddenName;
export type UserGroupType = 'country' | 'utility' | UserGroupCustomer;

export function getUserGroupType(userGroup: string): UserGroupType {
  if (userGroup.match(/^[A-Z]{3}(-.+)?/)) {
    return 'country';
  }
  if (utilityGroups.includes(userGroup)) {
    return 'utility';
  }
  if (grAllGroups.includes(userGroup)) {
    return grHiddenName;
  }
  if (paAllGroups.includes(userGroup)) {
    return paHiddenName;
  }
  if (txAllGroups.includes(userGroup)) {
    // We need to keep this here, while we still have the terrexpert groups, otherwise we will detect them as other.
    return txHiddenName;
  }
  if (mdaAllGroups.includes(userGroup)) {
    return mdaHiddenName;
  }

  return 'other-customer';
}

export function isUserGroupCustomer(type: UserGroupType): type is UserGroupCustomer {
  return type != 'country' && type != 'utility';
}

export function getCustomers(userEmail: undefined | string, userGroups: UserGroup[]): Set<UserGroupCustomer> {
  // TODO(savv): Rely on api.user_entity.customers or on api.user_group.parent once we have settled on the hierarchy.
  const customers = new Set(
    userGroups
      .map(x => x.user_group)
      .map(userGroup => getUserGroupType(userGroup))
      .filter(isUserGroupCustomer),
  );

  if (userEmail?.endsWith('@terrexpert.fr')) {
    // We detect terrexpert customers via their email address to avoid depending on the terrexpert user groups
    // as we would like to get rid of them, in order to simplify the user group hierarchy. The following set is empty:
    // select email from data.user_group_membership where user_group like 'terrexpert%' and email not like '%terrexpert.fr';
    customers.add(txHiddenName);
  }

  return customers;
}
