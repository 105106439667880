import {CheckCircleOutlined, ExclamationCircleFilled, SyncOutlined, WarningFilled} from '@ant-design/icons';
import {UploadOutlined} from '@ant-design/icons';
import {Upload, message} from 'antd';
import {RcFile} from 'antd/lib/upload/interface';
import {Buffer} from 'buffer';
import React, {useCallback} from 'react';
import {BadStatusResponse} from '../../../src/FetcherFunc';
import {TerrexpertParseErrorType} from '../../../src/gt-pack/terrexpert-parser';
import {ReportableErrorI} from '../../../src/util/err-util';
import {ContentTypes} from '../../../src/util/req-util';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import './import-data.css';

type Stage = 'upload-data' | 'uploading' | {type: 'done'; success: boolean; errors: string[]};

export default function ImportDataTerrexpert() {
  const apis = useApis();
  const {t} = apis;
  const [stage, setStage] = React.useState<Stage>('upload-data');
  const [errors, setErrors] = React.useState<string[]>([]);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      setStage('uploading');
      setErrors([]);
      processFile(apis, file)
        .then(result => {
          if (!result.success) {
            setErrors(result.errors ?? []);
            setStage({type: 'done', success: false, errors: result.errors});
          } else if (result.errors.length == 0) {
            setStage({type: 'done', success: true, errors: []});
          } else {
            setErrors(result.errors);
            setStage({type: 'done', success: true, errors: result.errors});
          }
        })
        .catch(e => {
          const msg = e instanceof ReportableErrorI ? e.stringify(t) : 'Une erreur inconnue est survenue.';
          message.error(msg);
          reportErr(e, 'ImportData');
          setStage({type: 'done', success: false, errors: []});
        });
    },
    [apis, t],
  );

  if (stage === 'upload-data') {
    return (
      <div className="upload-form">
        <span>
          <span>
            <Upload beforeUpload={beforeUpload}>
              <button className="formy-input formy-submit">
                <UploadOutlined /> Importer un fichier de mission
              </button>
            </Upload>
          </span>
        </span>
      </div>
    );
  } else if (stage === 'uploading') {
    return (
      <div>
        Importation en cours <SyncOutlined spin />
      </div>
    );
  } else if (stage.type === 'done' && stage.success && errors.length == 0) {
    return (
      <div>
        Le fichier a été importé. <CheckCircleOutlined />
        <div>
          <a onClick={() => setStage('upload-data')}>Réessayer</a>
        </div>
      </div>
    );
  } else if (stage.type === 'done' && stage.success && errors.length > 0) {
    return (
      <div>
        <div>
          Le fichier a été importé en partie, les erreurs suivantes sont apparues. <WarningFilled />
        </div>
        <ul>
          {errors.map((error, i) => (
            <li key={i}>{error}</li>
          ))}
        </ul>
        <div>
          <a onClick={() => setStage('upload-data')}>Réessayer</a>
        </div>
      </div>
    );
  } else if (stage.type === 'done' && !stage.success) {
    return (
      <div>
        <div>
          L'import du fichier a échoué. <ExclamationCircleFilled />
        </div>
        <div>
          <a onClick={() => setStage('upload-data')}>Réessayer</a>
        </div>
      </div>
    );
  } else {
    console.error('Unreachable stage:', stage);
    return null;
  }
}

interface UploadResult {
  success: boolean;
  errors: string[];
}

interface RowGroupedErrors {
  [key: number]: string[];
}

async function processFile(apis: Apis, file: RcFile): Promise<UploadResult> {
  const errorMessages: {[key in TerrexpertParseErrorType]: string} = {
    [TerrexpertParseErrorType.INVALID_EMAIL]: 'Adresse email invalide',
    [TerrexpertParseErrorType.INVALID_POLICY_NUMBER]: 'Numéro de police invalide',
    [TerrexpertParseErrorType.INVALID_DATE_SINISTRE]: 'Date de sinistre invalide',
    [TerrexpertParseErrorType.INVALID_HARVEST_YEAR]: 'Année de récolte invalide',
    [TerrexpertParseErrorType.INVALID_CONTRACT_TYPE]: 'Type de contrat invalide',
    [TerrexpertParseErrorType.INVALID_REF_COMPAGNIE]: 'Référence compagnie invalide',
    [TerrexpertParseErrorType.INVALID_LOSS]: 'Garantie invalide',
    [TerrexpertParseErrorType.INVALID_HEADER]: 'Entête invalide',
    [TerrexpertParseErrorType.HARVEST_NOT_FOUND]: 'Récolte introuvable',
    [TerrexpertParseErrorType.USER_NOT_FOUND]: 'Expert introuvable',
    [TerrexpertParseErrorType.FARM_NOT_FOUND]: 'Exploitation introuvable',
    [TerrexpertParseErrorType.POLICY_NOT_FOUND]: 'Police introuvable',
    [TerrexpertParseErrorType.INVALID_CROP]: 'Culture invalide',
    [TerrexpertParseErrorType.NOT_ENOUGH_LINES]: 'Le fichier ne contient pas de lignes à importer',
    [TerrexpertParseErrorType.NOT_ENOUGH_FIELDS]: 'Le nombre de colonnes est incorrect',
    [TerrexpertParseErrorType.UNKNOWN]: 'Erreur inconnue',
  };
  let fileBuf: null | string | ArrayBuffer = null;
  if (file.type === 'application/xlsx' || file.type === ContentTypes.xlsx) {
    fileBuf = await file.arrayBuffer();
    const b64 = Buffer.from(fileBuf).toString('base64');
    try {
      const res = await apis.authedFetcher({
        method: 'POST',
        path: 'api2/import/terrexpert-claims',
        json_body: {xlsx: b64},
        responseType: 'json',
        headers: [['Content-Type', 'application/json']],
        timeoutMs: 5 * 60 * 1000,
      });
      // Group errors by xlsxLineNumber.
      const groupedErrors: RowGroupedErrors = {};
      res.errors.forEach((error: any) => {
        groupedErrors[error.xlsxLineNumber] ??= [];
        groupedErrors[error.xlsxLineNumber].push(error.error);
      });
      // Translate and format errors.
      const errorLines = [];
      for (const [line, errors] of Object.entries(groupedErrors)) {
        const translatedErrors = errors.map((errId: string) => {
          const errorType = TerrexpertParseErrorType[errId as keyof typeof TerrexpertParseErrorType];
          return errorMessages[errorType] || errorMessages[TerrexpertParseErrorType.UNKNOWN];
        });
        errorLines.push('Ligne ' + line + ': ' + translatedErrors.join(', '));
      }
      return {success: true, errors: errorLines};
    } catch (e) {
      if (e instanceof BadStatusResponse && e.status == 400) {
        // Upload succeeded, but file could not be processed at all.
        return {success: false, errors: [e.text ?? e.message]};
      } else {
        throw e;
      }
    }
  } else {
    throw new Error("Ce type de fichier n'est pas pris en charge : " + file.type);
  }
}
