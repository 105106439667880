import React, {ReactElement} from 'react';
import {Marker} from './Mapbox';

type MarkerStrokeWidthProps = {
  strokeWidth?: number;
};

export function PinMarker() {
  // for sizing, keep in sync with values from
  // src/layers/map-layers.ts#{sampleLayer,farmLayer,fieldLayer}
  // might also need to check image/icon dimensions
  // svg copied from /app/components/IconSvg.tsx
  // Also keep in sync with Map.css -> .marker-type-lnglat
  const size = 40;
  const strokeWidth = 0.6,
    radius = 2,
    viewHeight = 15,
    viewWidth = viewHeight + strokeWidth * 2;
  return (
    <svg viewBox={`0 0 ${viewHeight} ${viewWidth}`} height={size} width={size}>
      <path
        d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"
        fill="#f84d4d"
        strokeWidth={strokeWidth}
        stroke="#333"
        transform={`translate(0 ${strokeWidth})`}
      />
      <circle cx="7.5" cy={viewWidth / 2 - radius} fill="white" stroke="black" strokeWidth={strokeWidth} r={radius} />
    </svg>
  );
}

type FarmMarkerProps = MarkerStrokeWidthProps;

export function FarmMarker({strokeWidth = 2}: FarmMarkerProps) {
  // for sizing, keep in sync with values from
  // src/layers/map-layers.ts#{sampleLayer,farmLayer,fieldLayer}
  // might also need to check image/icon dimensions
  // Also keep in sync with Map.css -> .marker-type-farm
  const size = 20;
  // react version of public/pin.svg
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${18 + strokeWidth} ${18 + strokeWidth}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="black"
        strokeWidth={strokeWidth}
        transform={`translate(${strokeWidth} ${strokeWidth})`}
        d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703
  C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"
      />
    </svg>
  );
}

type SampleMarkerProps = MarkerStrokeWidthProps;

export function SampleMarker({strokeWidth = 3}: SampleMarkerProps) {
  // for sizing, keep in sync with values from
  // src/layers/map-layers.ts#{sampleLayer,farmLayer,fieldLayer}
  // might also need to check image/icon dimensions
  // Also keep in sync with Map.css -> .marker-type-sample
  const size = 15;
  // react version of public/inverted-triangle.svg
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${36 + strokeWidth} ${30 + strokeWidth}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 30L0.679489 0H35.3205L18 30Z"
        fill="black"
        strokeWidth={strokeWidth}
        transform={`translate(${-strokeWidth / 2} ${strokeWidth})`}
      />
    </svg>
  );
}

export function getMarkerComponent(marker: Marker): undefined | ReactElement {
  switch (marker.type) {
    case 'lnglat':
      return <PinMarker />;
    case 'farm':
      return <FarmMarker />;
    // fields are rendered via css only
    case 'sample':
      return <SampleMarker />;
  }
}
