import {ClockI} from '../../Clock';
import {CommonApis} from '../../CommonApis';
import {BadStatusResponse, FailedToFetch, FetcherFunc, FetcherOpts} from '../../FetcherFunc';
import {deepCopy} from '../../deepCopy';
import {getUuid} from '../../get-uuid';
import {EntityFetcher} from '../../gt-pack/stateOrm';
import {
  ClaimDamageData,
  ClaimData,
  FarmData,
  FieldData,
  HarvestData,
  PolicyData,
  SampleData,
  VisitData,
} from '../../models/data';
import {
  Claim,
  ClaimDamage,
  Farm,
  Field,
  Harvest,
  Policy,
  Sample,
  UpdateLog,
  UserGroup,
  UserGroupMembership,
  Visit,
  VisitLite,
} from '../../models/interfaces';
import {EntityType, SqlTransportFormat, TableName} from '../../models/serialization';
import {DbFields, OmitDbColumns, Uuid} from '../../models/types';
import {toDateStr} from '../../util/date-util';
import {ValidateShape} from '../../util/obj-util';
import {DoesInterfaceCoverEnum} from '../../util/types';
import {entityToTransport, getPrimaryKeyName} from '../index';
import {
  DbMetaState,
  MutationQueueItem,
  MutationQueueItemInsert,
  MutationQueueItemInsertMany,
  MutationQueueItemUpdate,
} from '../reducers/db';
import {ThunkAction} from '../types';
import {prepInsertedEntity, prepInsertedEntityBase} from './prepInsertedEntity';
import {ResetAction} from './reset';

export type DbResponseContents = {
  farm: Farm[];
  field: Field[];
  harvest: Harvest[];
  sample: Sample[];
  policy: Policy[];
  visit: VisitLite[];
  claim: Claim[];
  claim_damage: ClaimDamage[];

  update_log: UpdateLog[];
};

'covered' as DoesInterfaceCoverEnum<DbResponseContents, 'update_log' | TableName>;

// Note that AddToDbAction, UpdateDbAction, and DeleteDbEntriesAction are only for internal use by insert/update DB
// actions. They need to use fully-featured entities (i.e. non-Lite ones) so that the omitted fields are persisted to
// storage.
export interface AddToDbAction {
  type: 'ADD_TO_DB';
  farm: Farm[];
  field: Field[];
  harvest: Harvest[];
  sample: Sample[];
  policy: Policy[];
  visit: Visit[];
  claim: Claim[];
  claim_damage: ClaimDamage[];
}

'covered' as DoesInterfaceCoverEnum<AddToDbAction, TableName>;

export interface UpdateDbAction {
  type: 'UPDATE_DB';
  farm: {[k: string]: Partial<Farm>};
  field: {[k: string]: Partial<Field>};
  harvest: {[k: string]: Partial<Harvest>};
  sample: {[k: string]: Partial<Sample>};
  policy: {[k: string]: Partial<Policy>};
  visit: {[k: string]: Partial<Visit>};
  claim: {[k: string]: Partial<Claim>};
  claim_damage: {[k: string]: Partial<ClaimDamage>};
}

'covered' as DoesInterfaceCoverEnum<UpdateDbAction, TableName>;

export interface DeleteDbEntriesAction {
  type: 'DELETE_DB_ENTRIES';
  farm_ids: Uuid[];
  field_ids: Uuid[];
  harvest_ids: Uuid[];
  sample_ids: Uuid[];
  policy_ids: Uuid[];
  visit_ids: Uuid[];
  claim_ids: Uuid[];
  claim_damage_ids: Uuid[];
}

'covered' as DoesInterfaceCoverEnum<DeleteDbEntriesAction, `${TableName}_ids`>;

export type DbActionTypes = AddToDbAction | DeleteDbEntriesAction | ResetAction | UpdateDbAction | UpdateUserAction;

export interface SetUpdatedAtAction {
  type: 'SET_UPDATED_AT';
  updated_at: string;
}

interface UpdateUserAction {
  type: 'UPDATE_USER';
  email: string;
  name: string;
}

export interface SetLastFullSyncAction {
  type: 'SET_LAST_FULL_SYNC';
  lastFullSync: null | string;
}

// Inserts an item to the mutation queue and locks it until UnlockMutationQueue is called.
export interface AddToMutationQueue {
  type: 'ADD_TO_MUTATION_QUEUE';

  item: MutationQueueItem;
}

export interface LockMutationQueue {
  type: 'LOCK_MUTATION_QUEUE';
}

export interface UnlockMutationQueue {
  type: 'UNLOCK_MUTATION_QUEUE';
}

export interface RemoveFromInsertQueue {
  type: 'REMOVE_FROM_INSERT_QUEUE';
  requestId: string;
}

export interface SetUserGroups {
  type: 'SET_USER_GROUPS';
  userGroups: UserGroup[];
}

export interface SetUserGroupMemberships {
  type: 'SET_USER_GROUP_MEMBERSHIPS';
  userGroupMemberships: UserGroupMembership[];
}

export interface SetTodaysDate {
  type: 'SET_TODAYS_DATE';
  todaysDateUtc: string;
}

// Retry the mutation under requestId.
export interface RetryMutationItem {
  type: 'RETRY_MUTATION_ITEM';
  requestId: string;
  // Timestamp after which we should retry the given mutation.
  retryAfter: number;
}

// Update the mutation item under requestId.
export interface UpdateMutationItem {
  type: 'UPDATE_MUTATION_ITEM';
  requestId: string;
  // Entity to update.
  entityTransport: Partial<SqlTransportFormat<EntityType>>;
}

export type DbMetaActions =
  | AddToMutationQueue
  | LockMutationQueue
  | RemoveFromInsertQueue
  | ResetAction
  | RetryMutationItem
  | SetLastFullSyncAction
  | SetTodaysDate
  | SetUpdatedAtAction
  | SetUserGroupMemberships
  | SetUserGroups
  | UnlockMutationQueue
  | UpdateMutationItem
  | UpdateUserAction;

export const _deleteDbEntries = ({
  farm,
  field,
  harvest,
  sample,
  policy,
  visit,
  claim,
  claim_damage,
}: Partial<{
  farm: Uuid[];
  field: Uuid[];
  harvest: Uuid[];
  sample: Uuid[];
  policy: Uuid[];
  visit: Uuid[];
  claim: Uuid[];
  claim_damage: Uuid[];
}>): DeleteDbEntriesAction => ({
  type: 'DELETE_DB_ENTRIES',
  farm_ids: farm || [],
  field_ids: field || [],
  harvest_ids: harvest || [],
  sample_ids: sample || [],
  policy_ids: policy || [],
  visit_ids: visit || [],
  claim_ids: claim || [],
  claim_damage_ids: claim_damage || [],
});

export function _addEntriesToDb({
  farm,
  field,
  harvest,
  sample,
  policy,
  visit,
  claim,
  claim_damage,
}: Partial<Omit<AddToDbAction, 'type'>>): AddToDbAction {
  return {
    type: 'ADD_TO_DB',
    farm: farm || [],
    field: field || [],
    harvest: harvest || [],
    sample: sample || [],
    policy: policy || [],
    visit: visit || [],
    claim: claim || [],
    claim_damage: claim_damage || [],
  };
}

export function setLastFullSync(lastFullSync: null | string): SetLastFullSyncAction {
  return {type: 'SET_LAST_FULL_SYNC', lastFullSync};
}

export function bindTodaysDate(clock: ClockI): ThunkAction<void, {dbMeta: DbMetaState}, SetTodaysDate> {
  return dispatch => {
    dispatch({type: 'SET_TODAYS_DATE', todaysDateUtc: toDateStr(clock.now())});

    clock.setInterval(
      () => {
        dispatch({type: 'SET_TODAYS_DATE', todaysDateUtc: toDateStr(clock.now())});
      },
      60 * 60 * 1000,
    );
  };
}

const kPostgresUniqueViolation = '23505';

// row level security violation happens when the referenced entity is not accessible, which can often mean that the
// entity does not exist (e.g. non-existing farm_id when inserting harvest):
// curl -i -X POST -H "Authorization: Bearer $TOKEN" "http://localhost:3000/api/harvest" -H "Content-Type: application/json"
// --data-raw '{"harvest_id":"00000000-0000-0000-0000-000000000001", "farm_id":"00000000-0000-0000-0000-000000000002"}'
// {"code":"42501","details":null,"hint":null,"message":"new row violates row-level security policy for table \"harvest\""}
const kPostgresRowLevelSecurityViolation = '42501';
// Below are the tables whose parent entities may be missing (e.g. after merge operation)
// and for which it is possible to find an alternative in entity.merged_ids.
// We don't include farm and policy, which may theoretically throw the row level security violation for wrong user group,
// but can't be fixed here. The user needs to refresh the app to get list of latest user_groups.
const supportedTablesForRowLevelSecurityViolation = [
  'harvest', // farm_id or field_id may be missing after merge operation
  'sample', // harvest_id may be missing after merge operation
  'claim', // farm_id may be missing after merge operation
  'field', // farm_id may be missing after merge operation
];

// foreign key constraint violation happens when the referenced entity doesn't exist and that reference is not used for
// access rights (e.g. non-existing policy when inserting harvest):
// curl -i -X POST -H "Authorization: Bearer $TOKEN" "http://localhost:3000/api/harvest" -H "Content-Type: application/json"
// --data-raw '{"harvest_id":"00000000-0000-0000-0000-000000000001", "farm_id":"00000000-0000-0000-0000-000000000001","policy_id":"00000000-0000-0000-0000-000000000001"}'
// {"code":"23503","details":"Key is not present in table \"policy\".","hint":null,"message":"insert or update on table \"harvest\" violates foreign key constraint \"harvest_policy_id_fkey\""}
const kPostgresForeignKeyConstraintViolation = '23503';
// Below are the tables whose referenced entities may be missing (e.g. after merge operation) and the references are not used for access rights.
const supportedTablesForForeignKeyConstraintViolation = [
  'claim', // policy_id may be missing after merge operation
  'claim_damage', // harvest_id may be missing after merge operation
  'harvest', // policy_id may be missing after merge operation
];

function extractConstraintColumnId(details: undefined | string): null | string {
  const regex = /key is not present in table "([a-z_]+)"/i;
  const match = details?.match(regex);
  if (match && match[1]) {
    return match[1] + '_id';
  }
  return null;
}

export const MUTATION_RETRY_DELAY_IN_SECONDS = [5, 5, 10, 10, 30, 60];

export async function sendMutationToDb(authedFetcher: FetcherFunc, mutationItem: MutationQueueItem) {
  if (
    mutationItem.mutationType === 'delete' &&
    ['farm', 'field', 'harvest', 'policy', 'claim'].includes(mutationItem.table)
  ) {
    // this handles the cases, where we have a special SQL function to delete the entity and all related entities
    return await authedFetcher({
      method: 'POST',
      path: 'api/rpc/delete_' + mutationItem.table,
      json_body: {[getPrimaryKeyName(mutationItem.table)]: mutationItem.primaryKey},
    });
  }
  const opts: FetcherOpts = {
    method:
      mutationItem.mutationType === 'update' ? 'PATCH' : mutationItem.mutationType === 'delete' ? 'DELETE' : 'POST',
    path: mutationItem.mutationType === 'insert-many' ? 'api/rpc/insert_many' : 'api/' + mutationItem.table,
  };
  if (mutationItem.mutationType == 'insert-many') {
    opts.headers = [['Prefer', 'params=single-object']];
  }
  if (mutationItem.mutationType != 'insert' && mutationItem.mutationType != 'insert-many') {
    opts.params = [[getPrimaryKeyName(mutationItem.table), 'eq.' + mutationItem.primaryKey]];
  }
  if (mutationItem.mutationType != 'delete') {
    opts.json_body = mutationItem.entityTransport;
  }
  return await authedFetcher(opts);
}

// Returns the action that must happen based on the mutation error.
// Function handles the following errors:
// - FailedToFetch errors, which can happen when inserting entities while offline or when getOneAuthToken throws FailedToFetch.
//   Return 'RETRY_INDEFINITELY' to stop processing the mutationQueue.
// - BadStatusResponse for already inserted objects (kPostgresUniqueViolation). Return 'REMOVE_FROM_QUEUE'
//   (as mutation item was already inserted) and continue processing the mutationQueue.
// - BadStatusResponse 408 and 5xx errors (e.g. if server is down). Return 'RETRY' to add a retry counter and stop processing the
//   mutationQueue.
// - BadStatusResponse for kPostgresRowLevelSecurityViolation / kPostgresForeignKeyConstraintViolation.
//   Mutation item may reference a non-existing or non-accessible entity. If we can find an existing entity reference,
//   return 'UPDATE' and replace the non-existing reference with the new one. Otherwise, return 'RETRY'.
// - For all others errors, log an error with onError to prevent data loss
//   and return 'REMOVE_FROM_QUEUE' if logServer was successful. Otherwise, return 'RETRY_INDEFINITELY'.
// Note that RETRY_INDEFINITELY and RETRY are very similar: both will do retry, the only difference is how long the code waits until it retries.
// RETRY_INDEFINITELY will run the mutationItem again immediately and will keep retrying indefinitely; whereas RETRY will
// run it after retryAfter has passed, and for a finite number of times.
// Tested requests documentation: https://github.com/greentriangle/agro/pull/3969#issuecomment-2258726679
type HandleMutationItemResult =
  | {type: 'REMOVE_FROM_QUEUE' | 'RETRY_INDEFINITELY' | 'RETRY'}
  | {
      type: 'UPDATE';
      updatedEntity: Partial<SqlTransportFormat<EntityType>>;
      table: TableName;
      primaryKey: string;
    };

// For each entity type, function tries to find the new reference after the merge. If the item is not found, the local database may not be up-to-date.
// In that case, we schedule the mutation item for retry, which will either succeed or fail after N times and log the item to the server.
async function handleRowLevelSecurityViolation(
  mutationItem: MutationQueueItemInsert | MutationQueueItemUpdate,
  entityFetcher: EntityFetcher,
): Promise<HandleMutationItemResult> {
  if (mutationItem.table === 'harvest') {
    // mutationItem.entityTransport can be SqlTransportFormat<EntityType> for insert
    // and Partial<SqlTransportFormat<Harvest>> for update.
    // We cast to Partial<SqlTransportFormat<Harvest>> for better type safety.
    const harvest = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<Harvest>>;
    if (harvest.farm_id) {
      const farms = await entityFetcher.fetchEntitiesBy('farm', {
        column: 'merged_ids',
        operator: 'cs',
        value: [harvest.farm_id],
      });
      if (!farms.length) {
        return {type: 'RETRY'};
      }
      harvest.farm_id = farms[0].farm_id;
    } else if (harvest.field_id) {
      const fields = await entityFetcher.fetchEntitiesBy('field', {
        column: 'merged_ids',
        operator: 'cs',
        value: [harvest.field_id],
      });
      if (!fields.length) {
        return {type: 'RETRY'};
      }
      harvest.field_id = fields[0].field_id;
    }
    return {
      type: 'UPDATE',
      updatedEntity: harvest,
      table: 'harvest',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      // TODO(kristjan): is there a way not to use "!" ?
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : harvest.harvest_id!,
    };
  } else if (mutationItem.table === 'sample') {
    const sample = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<Sample>>;
    const harvests = sample.harvest_id
      ? await entityFetcher.fetchEntitiesBy('harvest', {
          column: 'merged_ids',
          operator: 'cs',
          value: [sample.harvest_id],
        })
      : [];
    if (!harvests.length) {
      return {type: 'RETRY'};
    }
    sample.harvest_id = harvests[0].harvest_id;
    return {
      type: 'UPDATE',
      updatedEntity: sample,
      table: 'sample',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : sample.sample_id!,
    };
  } else if (mutationItem.table === 'claim') {
    const claim = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<Claim>>;
    const farms = claim.farm_id
      ? await entityFetcher.fetchEntitiesBy('farm', {
          column: 'merged_ids',
          operator: 'cs',
          value: [claim.farm_id],
        })
      : [];
    if (!farms.length) {
      return {type: 'RETRY'};
    }
    claim.farm_id = farms[0].farm_id;
    return {
      type: 'UPDATE',
      updatedEntity: claim,
      table: 'claim',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : claim.claim_id!,
    };
  } else if (mutationItem.table === 'field') {
    const field = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<Field>>;
    const farms = field.farm_id
      ? await entityFetcher.fetchEntitiesBy('farm', {
          column: 'merged_ids',
          operator: 'cs',
          value: [field.farm_id],
        })
      : [];
    if (!farms.length) {
      return {type: 'RETRY'};
    }
    field.farm_id = farms[0].farm_id;
    return {
      type: 'UPDATE',
      updatedEntity: field,
      table: 'field',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : field.field_id!,
    };
  }
  // This shouldn't happen, because this function is called only for the tables above.
  return {type: 'RETRY'};
}

async function handleForeignKeyConstraintViolation(
  mutationItem: MutationQueueItemInsert | MutationQueueItemUpdate,
  columnId: null | string,
  entityFetcher: EntityFetcher,
): Promise<HandleMutationItemResult> {
  if (mutationItem.table === 'claim' && columnId === 'policy_id') {
    const claim = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<Claim>>;
    const policies = claim.policy_id
      ? await entityFetcher.fetchEntitiesBy('policy', {
          column: 'merged_ids',
          operator: 'cs',
          value: [claim.policy_id],
        })
      : [];
    if (!policies.length) {
      return {type: 'RETRY'};
    }
    claim.policy_id = policies[0].policy_id;
    return {
      type: 'UPDATE',
      updatedEntity: claim,
      table: 'claim',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : claim.claim_id!,
    };
  } else if (mutationItem.table === 'claim_damage' && columnId === 'harvest_id') {
    const claimDamage = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<ClaimDamage>>;
    const harvests = claimDamage.harvest_id
      ? await entityFetcher.fetchEntitiesBy('harvest', {
          column: 'merged_ids',
          operator: 'cs',
          value: [claimDamage.harvest_id],
        })
      : [];
    if (!harvests.length) {
      return {type: 'RETRY'};
    }
    claimDamage.harvest_id = harvests[0].harvest_id;
    return {
      type: 'UPDATE',
      updatedEntity: claimDamage,
      table: 'claim_damage',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : claimDamage.claim_damage_id!,
    };
  } else if (mutationItem.table === 'harvest' && columnId === 'policy_id') {
    const harvest = deepCopy(mutationItem.entityTransport) as Partial<SqlTransportFormat<Harvest>>;
    const policies = harvest.policy_id
      ? await entityFetcher.fetchEntitiesBy('policy', {
          column: 'merged_ids',
          operator: 'cs',
          value: [harvest.policy_id],
        })
      : [];
    if (!policies.length) {
      return {type: 'RETRY'};
    }
    harvest.policy_id = policies[0].policy_id;
    return {
      type: 'UPDATE',
      updatedEntity: harvest,
      table: 'harvest',
      // MutationQueueItemUpdate contains only updated properties in mutationItem.entityTransport, and we need
      // to get the primary key from mutationItem.primaryKey.
      primaryKey: mutationItem.mutationType === 'update' ? mutationItem.primaryKey : harvest.harvest_id!,
    };
  }
  // This shouldn't happen, because this function is called only for the tables above.
  return {type: 'RETRY'};
}

async function handleOnError(
  onError: (item: MutationQueueItem, err: unknown) => Promise<void>,
  mutationItem: MutationQueueItem,
  err: unknown,
): Promise<HandleMutationItemResult> {
  try {
    await onError(mutationItem, err);
    console.info(
      'Successfully logged mutationItem',
      mutationItem.mutationType,
      mutationItem.requestId,
      'and will remove it from the queue.',
    );
    return {type: 'REMOVE_FROM_QUEUE'};
  } catch (e) {
    console.error('Failed to log db-mutation to server', e);
    return {type: 'RETRY_INDEFINITELY'};
  }
}

export async function processMutationError(
  err: unknown,
  mutationItem: MutationQueueItem,
  onError: (item: MutationQueueItem, err: unknown) => Promise<void>,
  entityFetcher: EntityFetcher,
): Promise<HandleMutationItemResult> {
  // IMPORTANT: all the cases that may return RETRY must be checked with canRetry logic to avoid infinite loop!
  const canRetry = !mutationItem.retries || mutationItem.retries < MUTATION_RETRY_DELAY_IN_SECONDS.length;
  if (err instanceof FailedToFetch) {
    // This can happen when inserting entities while offline or when getOneAuthToken throws FailedtoFetch.
    // Warn and stop processing the mutationQueue.
    // We kept this condition separated for now to minimize the changes. Later we may merge it with the RETRY condition.
    console.info(
      `Couldn't ${mutationItem.mutationType} ${mutationItem.requestId} to db (failed to fetch); item is in queue.`,
    );
    return {type: 'RETRY_INDEFINITELY'};
  } else if (
    err instanceof BadStatusResponse &&
    err.json &&
    err.json.code == kPostgresUniqueViolation &&
    (mutationItem.mutationType === 'insert' || mutationItem.mutationType === 'insert-many')
  ) {
    // We already inserted this object in the queue, but didn't receive the acknowledgement from the server.
    // Remove item from queue and continue processing the mutationQueue.
    // Note that kPostgresUniqueViolation for insert-many is atomic and if any item fails to insert, the whole request fails.
    // We also remove the item from the queue in this case, because keeping and logging it is not useful after all.
    console.warn(
      mutationItem.mutationType === 'insert-many'
        ? `Insert many for ${mutationItem.requestId} failed with ${err.text}`
        : `Removing already inserted mutation from queue ${mutationItem.mutationType} ${mutationItem.requestId}`,
    );
    return {type: 'REMOVE_FROM_QUEUE'};
  } else if (
    err instanceof BadStatusResponse &&
    err.json &&
    err.json.code == kPostgresRowLevelSecurityViolation &&
    (mutationItem.mutationType === 'insert' || mutationItem.mutationType === 'update') &&
    supportedTablesForRowLevelSecurityViolation.includes(mutationItem.table) &&
    canRetry
  ) {
    return await handleRowLevelSecurityViolation(mutationItem, entityFetcher);
  } else if (
    err instanceof BadStatusResponse &&
    err.json &&
    err.json.code == kPostgresForeignKeyConstraintViolation &&
    (mutationItem.mutationType === 'insert' || mutationItem.mutationType === 'update') &&
    supportedTablesForForeignKeyConstraintViolation.includes(mutationItem.table) &&
    canRetry
  ) {
    return handleForeignKeyConstraintViolation(
      mutationItem,
      extractConstraintColumnId(err.json.details),
      entityFetcher,
    );
  } else if (
    err instanceof BadStatusResponse &&
    ((err.status >= 500 && err.status < 600) || err.status === 408 || err.status === 429) &&
    canRetry
  ) {
    // Schedule 408 or 5xx errors for retry (e.g. if the server is down or 408 request time out).
    // If MUTATION_RETRY_DELAY_IN_SECONDS is reached, the mutationItem will be logged and removed from the queue (the if condition below).
    console.info('Scheduling mutationItem', mutationItem.mutationType, mutationItem.requestId, 'for retry.');
    return {type: 'RETRY'};
  } else {
    // For all other errors, we log the error and if successful, remove from queue. If unsuccessful, stop processing the queue.
    return await handleOnError(onError, mutationItem, err);
  }
}

export function syncQueuedMutations(
  // onError is a callback that will be called for each mutation that had an issue.
  // onError should try to resolve promptly as it blocks the mutation queue from proceeding.
  // In some cases, where we want to report errors and avoid data loss, it's Ok for it to take up to 1 minute.
  onError: (item: MutationQueueItem, err: unknown) => Promise<void>,
  authedFetcher: FetcherFunc,
  clock: ClockI,
  entityFetcher: EntityFetcher,
): ThunkAction<
  Promise<void>,
  {
    dbMeta: DbMetaState;
  },
  | LockMutationQueue
  | RemoveFromInsertQueue
  | RetryMutationItem
  | UnlockMutationQueue
  | UpdateDbAction
  | UpdateMutationItem
> {
  return async (dispatch, getState) => {
    const dbMeta = getState().dbMeta;
    const mutationQueue = dbMeta.mutationQueue;
    if (dbMeta.mutationQueueLocked || dbMeta.mutationQueue.length == 0) {
      console.info(
        `syncQueuedMutations: mutationQueue is locked (${dbMeta.mutationQueueLocked}) or empty (${dbMeta.mutationQueue.length}).`,
      );
      return;
    }
    dispatch({type: 'LOCK_MUTATION_QUEUE'});
    console.info('syncQueuedMutations: locked');
    console.info('Syncing', mutationQueue && mutationQueue.length, 'mutation queue items.');
    try {
      for (const mutationItem of mutationQueue) {
        if (mutationItem.retryAfter && clock.now() < mutationItem.retryAfter) {
          // Break executing mutationQueue when retryAfter is defined and not yet beyond current date,
          // because the mutation queue needs to be executed in order (e.g. need to make sure farm is inserted before harvest).
          console.info(
            'syncQueuedMutations: retryAfter is not reached yet for',
            mutationItem.requestId,
            mutationItem.retryAfter,
          );
          break;
        }

        let processResult: HandleMutationItemResult;
        try {
          await sendMutationToDb(authedFetcher, mutationItem);
          processResult = {type: 'REMOVE_FROM_QUEUE'};
        } catch (err) {
          console.info('syncQueuedMutations: error for', mutationItem.requestId);
          try {
            processResult = await processMutationError(err, mutationItem, onError, entityFetcher);
          } catch (e) {
            // Note that if onError is successfully called, the item will be removed from the queue to stop blocking it.
            // Otherwise, the processing will stop.
            processResult = await handleOnError(onError, mutationItem, e);
          }
        }

        if (processResult.type === 'REMOVE_FROM_QUEUE') {
          // Currently remove from queue only in the following cases:
          // - processing the item was successful
          // - mutation item was already inserted, but we didn't receive an acknowledgment from the server
          // - mutation item can't be updated, because it was deleted
          // - MUTATION_RETRY_DELAY_IN_SECONDS is reached and onError was successful
          // - any other error and onError was successful
          dispatch({type: 'REMOVE_FROM_INSERT_QUEUE', requestId: mutationItem.requestId});
        } else if (processResult.type === 'RETRY_INDEFINITELY') {
          // If we are unable to execute a mutation, we do not continue with the remaining mutations, because
          // mutations need to be executed in order. Otherwise, we would try to add a field before its farm, which
          // will cause an error.
          break;
        } else if (processResult.type === 'RETRY') {
          // Dispatch retry action to increase retries counter.
          dispatch({
            type: 'RETRY_MUTATION_ITEM',
            requestId: mutationItem.requestId,
            retryAfter: clock.now() + MUTATION_RETRY_DELAY_IN_SECONDS[mutationItem.retries] * 1000,
          });
          break;
        } else if (processResult.type === 'UPDATE') {
          // Update the entity in the local sqlite as well to ensure immediate consistency.
          dispatch({
            type: 'UPDATE_DB',
            farm: {},
            field: {},
            harvest: {},
            sample: {},
            policy: {},
            visit: {},
            claim: {},
            claim_damage: {},
            [processResult.table]: {[processResult.primaryKey]: processResult.updatedEntity},
          });
          dispatch({
            type: 'UPDATE_MUTATION_ITEM',
            requestId: mutationItem.requestId,
            entityTransport: processResult.updatedEntity,
          });
          break;
        }
      }
    } finally {
      dispatch({type: 'UNLOCK_MUTATION_QUEUE'});
      console.info('syncQueuedMutations: unlocked');
    }
  };
}

export type DbThunkAction<R> = ThunkAction<
  R,
  {dbMeta: DbMetaState},
  | AddToDbAction
  | AddToMutationQueue
  | DeleteDbEntriesAction
  | RemoveFromInsertQueue
  | UnlockMutationQueue
  | UpdateDbAction
>;

export function queueInsertMutation(table: TableName, entity: Partial<EntityType>): DbThunkAction<void> {
  return dispatch => {
    dispatch({
      type: 'ADD_TO_MUTATION_QUEUE',
      item: {
        requestId: getUuid(),
        mutationType: 'insert',
        table,
        entityTransport: entityToTransport(table, entity, {geo: 'wkt'}),
        retries: 0,
      },
    });
    dispatch(_addEntriesToDb({[table]: [entity]}));
  };
}

export function queueUpdateMutation(
  table: TableName,
  entity: Partial<EntityType>,
  primaryKey: string,
): DbThunkAction<void> {
  return dispatch => {
    if (!primaryKey) {
      throw new Error(`No primary key to update ${entity}!`);
    }
    dispatch({
      type: 'ADD_TO_MUTATION_QUEUE',
      item: {
        requestId: getUuid(),
        mutationType: 'update',
        table,
        entityTransport: entityToTransport(table, entity, {geo: 'wkt'}),
        retries: 0,
        primaryKey: primaryKey,
      },
    });
    dispatch({
      type: 'UPDATE_DB',
      farm: {},
      field: {},
      harvest: {},
      sample: {},
      policy: {},
      visit: {},
      claim: {},
      claim_damage: {},
      [table]: {[primaryKey!]: entity},
    });
  };
}

export function queueDeleteMutation(table: TableName, primaryKey: string): DbThunkAction<void> {
  return dispatch => {
    if (!primaryKey) {
      throw new Error(`No primary key to delete ${table}!`);
    }
    dispatch({
      type: 'ADD_TO_MUTATION_QUEUE',
      item: {
        requestId: getUuid(),
        mutationType: 'delete',
        table,
        retries: 0,
        primaryKey: primaryKey,
      },
    });
    dispatch(_deleteDbEntries({[table]: [primaryKey]}));
  };
}

type DbActionApis = Pick<CommonApis, 'authedFetcher' | 'clock' | 'getAuthToken'>;

export function insertFarm<T>(apis: DbActionApis, farm: ValidateShape<T, FarmData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeFarm: Farm = prepInsertedEntity(apis, getState(), farm, {farm_id: getUuid()});
    dispatch(queueInsertMutation('farm', completeFarm));
    return completeFarm.farm_id;
  };
}

export function updateEntity<T>(
  apis: DbActionApis,
  table: TableName,
  entity_id: Uuid,
  entity: ValidateShape<T, Partial<EntityType>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation(table, entity, entity_id));
}

export function updateFarm<T>(
  apis: DbActionApis,
  farm_id: Uuid,
  farm: ValidateShape<T, Partial<FarmData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('farm', farm, farm_id));
}

export function insertPolicy<T>(apis: DbActionApis, policy: ValidateShape<T, PolicyData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completePolicy: Policy = prepInsertedEntity(apis, getState(), policy, {policy_id: getUuid()});
    dispatch(queueInsertMutation('policy', completePolicy));
    return completePolicy.policy_id;
  };
}

export function updatePolicy<T>(
  apis: DbActionApis,
  policy_id: Uuid,
  policy: ValidateShape<T, Partial<PolicyData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('policy', policy, policy_id));
}

export function insertHarvest<T>(apis: DbActionApis, harvest: ValidateShape<T, HarvestData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeHarvest: Harvest = prepInsertedEntity(apis, getState(), harvest, {harvest_id: getUuid()});
    dispatch(queueInsertMutation('harvest', completeHarvest));
    return completeHarvest.harvest_id;
  };
}

export function insertClaim<T>(apis: DbActionApis, claim: ValidateShape<T, ClaimData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeClaim: Claim = prepInsertedEntity(apis, getState(), claim, {claim_id: getUuid()});
    dispatch(queueInsertMutation('claim', completeClaim));
    return completeClaim.claim_id;
  };
}

export function insertClaimDamage<T>(
  apis: DbActionApis,
  claimDamage: ValidateShape<T, ClaimDamageData>,
): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeClaimDamage = prepInsertedEntityBase(apis, getState(), claimDamage, {claim_damage_id: getUuid()});
    dispatch(queueInsertMutation('claim_damage', completeClaimDamage));
    return completeClaimDamage.claim_damage_id;
  };
}

export function updateClaim<T>(claim_id: Uuid, claim: ValidateShape<T, Partial<ClaimData>>): DbThunkAction<Uuid> {
  return dispatch => {
    dispatch(queueUpdateMutation('claim', claim, claim_id));
    return claim_id;
  };
}

export function updateClaimDamage<T>(
  claim_damage_id: Uuid,
  claimDamage: ValidateShape<T, Partial<ClaimDamageData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('claim_damage', claimDamage, claim_damage_id));
}

export function updateHarvest<T>(
  harvest_id: Uuid,
  harvest: ValidateShape<T, Partial<HarvestData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('harvest', harvest, harvest_id));
}

export function insertField<T>(
  apis: DbActionApis,
  field: ValidateShape<T, Omit<Field, 'field_id' | keyof DbFields>>,
): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeField: Field = prepInsertedEntity(apis, getState(), field, {field_id: getUuid()});
    dispatch(queueInsertMutation('field', completeField));
    return completeField.field_id;
  };
}

export function insertMany<F1, P, F2, H, V, C, CD>(
  apis: DbActionApis,
  // TODO(seb): Relax the entity types, to not require the <entity>_id to be present?
  partialFarms: ValidateShape<F1, OmitDbColumns<Farm>>[],
  partialPolicies: ValidateShape<P, OmitDbColumns<Policy>>[],
  partialFields: ValidateShape<F2, OmitDbColumns<Field>>[],
  partialHarvests: ValidateShape<H, OmitDbColumns<Harvest>>[],
  partialVisits: ValidateShape<V, OmitDbColumns<Visit>>[],
  partialClaims: ValidateShape<C, OmitDbColumns<Claim>>[],
  partialClaimDamages: ValidateShape<CD, OmitDbColumns<ClaimDamage>>[],
): DbThunkAction<{
  farm_ids: string[];
  policy_ids: string[];
  field_ids: string[];
  harvest_ids: string[];
  claim_ids: string[];
  claim_damage_ids: string[];
  visit_ids: string[];
}> {
  return (dispatch, getState) => {
    const farms: Farm[] = partialFarms.map(farm => prepInsertedEntity(apis, getState(), farm, {}));
    const policies: Policy[] = partialPolicies.map(policy => prepInsertedEntity(apis, getState(), policy, {}));
    const fields: Field[] = partialFields.map(field => prepInsertedEntity(apis, getState(), field, {}));
    const harvests: Harvest[] = partialHarvests.map(harvest => prepInsertedEntity(apis, getState(), harvest, {}));
    const claims: Claim[] = partialClaims.map(claim => prepInsertedEntity(apis, getState(), claim, {}));
    const claimDamages: ClaimDamage[] = partialClaimDamages.map(claimDamage =>
      prepInsertedEntityBase(apis, getState(), claimDamage, {}),
    );
    const visits: Visit[] = partialVisits.map(visit => prepInsertedEntity(apis, getState(), visit, {}));
    const item: MutationQueueItemInsertMany = {
      requestId: getUuid(),
      retries: 0,
      mutationType: 'insert-many',
      entityTransport: {
        farms: farms.map(farm => entityToTransport('farm', farm, {geo: 'wkt'})),
        policies: policies.map(policy => entityToTransport('policy', policy, {geo: 'wkt'})),
        fields: fields.map(field => entityToTransport('field', field, {geo: 'wkt'})),
        harvests: harvests.map(harvest => entityToTransport('harvest', harvest, {geo: 'wkt'})),
        claims: claims.map(claim => entityToTransport('claim', claim, {geo: 'wkt'})),
        claim_damages: claimDamages.map(claimDamage => entityToTransport('claim_damage', claimDamage, {geo: 'wkt'})),
        visits: visits.map(visit => entityToTransport('visit', visit, {geo: 'wkt'})),
      },
    };
    dispatch({type: 'ADD_TO_MUTATION_QUEUE', item: item});
    dispatch(
      _addEntriesToDb({
        farm: farms,
        policy: policies,
        field: fields,
        harvest: harvests,
        sample: [],
        claim: claims,
        claim_damage: claimDamages,
        visit: visits,
      }),
    );

    return {
      farm_ids: farms.map(x => x.farm_id),
      policy_ids: policies.map(x => x.policy_id),
      field_ids: fields.map(x => x.field_id),
      harvest_ids: harvests.map(x => x.harvest_id),
      claim_ids: claims.map(x => x.claim_id),
      claim_damage_ids: claimDamages.map(x => x.claim_damage_id),
      visit_ids: visits.map(x => x.visit_id),
    };
  };
}

export function updateField<T>(field_id: Uuid, field: ValidateShape<T, Partial<FieldData>>): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('field', field, field_id));
}

export function insertSample<T>(apis: DbActionApis, sample: ValidateShape<T, SampleData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeSample: Sample = prepInsertedEntity(apis, getState(), sample, {sample_id: getUuid()});
    dispatch(queueInsertMutation('sample', completeSample));
    return completeSample.sample_id;
  };
}

export function updateSample<T>(sample_id: Uuid, sample: ValidateShape<T, Partial<SampleData>>): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('sample', sample, sample_id));
}

export function insertVisit<T>(
  apis: DbActionApis,
  visit: ValidateShape<T, VisitData>,
  id: null | string,
): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeVisit: Visit = prepInsertedEntity(apis, getState(), visit, {visit_id: id ?? getUuid()});
    dispatch(queueInsertMutation('visit', completeVisit));
    return completeVisit.visit_id;
  };
}

export function updateVisit<T>(visit_id: Uuid, visit: ValidateShape<T, Partial<VisitData>>): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('visit', visit, visit_id));
}

export function deleteHarvest(harvest_id: Uuid): DbThunkAction<void> {
  return async dispatch => {
    if (!harvest_id) {
      throw new Error('deleteHarvest: harvest_id is null');
    }
    dispatch(queueDeleteMutation('harvest', harvest_id));
  };
}

export function deleteSample(sample_id: Uuid): DbThunkAction<void> {
  return async dispatch => {
    if (!sample_id) {
      throw new Error('deleteSample: sample_id is null');
    }
    dispatch(queueDeleteMutation('sample', sample_id));
  };
}

export function deleteClaimDamage<T>(claimDamageId: Uuid): DbThunkAction<void> {
  return dispatch => {
    dispatch(queueDeleteMutation('claim_damage', claimDamageId));
  };
}

export function deleteVisit(visit_id: Uuid): DbThunkAction<void> {
  return dispatch => {
    if (!visit_id) {
      throw new Error('deleteVisit: visit_id is null');
    }
    dispatch(queueDeleteMutation('visit', visit_id));
  };
}
