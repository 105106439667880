import {CloseSquareOutlined} from '@ant-design/icons';
import React from 'react';
import {FetcherFunc} from '../../../src/FetcherFunc';
import SpinningDots from '../components/SpinningDots';

interface AuthedImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'srcSet'> {
  authedFetcher: FetcherFunc;
}

interface AuthedImageState {
  src: string | 'Error' | 'Loading';
}

export class AuthedImage extends React.PureComponent<AuthedImageProps, AuthedImageState> {
  state: AuthedImageState = {src: 'Loading'};

  componentDidMount() {
    this.loadImage().catch(console.error);
  }

  componentDidUpdate(prevProps: Readonly<AuthedImageProps>) {
    if (this.props.src != prevProps.src) {
      this.loadImage().catch(console.error);
    }
  }

  async loadImage() {
    const src = this.props.src;
    if (this.state.src) {
      // Garbage collect previous image.
      URL.revokeObjectURL(this.state.src);
      await new Promise<void>(resolve => this.setState({src: 'Loading'}, resolve));
    }

    if (!src) {
      return;
    }

    try {
      const headers: [string, string][] = [['accept', 'image/*']];
      const blob = await this.props.authedFetcher({method: 'GET', path: src, responseType: 'blob', headers});
      if (src != this.props.src) {
        // We may have started loading another image in the meantime; abort in that case.
        return;
      }
      this.setState({src: URL.createObjectURL(blob)});
    } catch (e) {
      this.setState({src: 'Error'});
    }
  }

  render() {
    const {src} = this.state;
    const {authedFetcher, ...props} = this.props;

    if (src == 'Loading') {
      return (
        <div className="authed-image-placeholder">
          <SpinningDots className="spinning-dots" size={32} />
        </div>
      );
    } else if (src == 'Error') {
      return (
        <div className="authed-image-placeholder">
          <CloseSquareOutlined style={{color: '#8B0000', fontSize: 48}} />
        </div>
      );
    } else {
      return <img alt="" {...props} src={this.state.src} />;
    }
  }
}
