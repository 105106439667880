import {deepCopy} from '../deepCopy';

export type PostgrestQuery =
  | PostgrestQueryAnd
  | PostgrestQueryKeysetPagination
  | PostgrestQueryNot
  | PostgrestQueryOr
  | PostgrestQueryPredicate;

export interface PostgrestQueryNot {
  readonly not: PostgrestQuery;
}

export interface PostgrestQueryAnd {
  readonly and: PostgrestQuery[];
}

export interface PostgrestQueryOr {
  readonly or: PostgrestQuery[];
}

const IN_OPERATIONS = ['in', 'sl', 'sr', 'nxr', 'nxl', 'adj'] as const;

export interface PostgrestQueryInPredicate {
  readonly column: string;
  readonly operator: (typeof IN_OPERATIONS)[number];
  readonly value: Array<boolean | number | string>;
}

const CONTAINS_OPERATIONS = ['cs', 'cd', 'ov'] as const;

export interface PostgrestQueryContainsPredicate {
  readonly column: string;
  readonly operator: (typeof CONTAINS_OPERATIONS)[number];
  readonly value: Array<boolean | number | string>;
}

const VALUE_OPERATIONS = [
  'eq',
  'gt',
  'gte',
  'lt',
  'lte',
  'neq',
  'like',
  'ilike',
  'match',
  'imatch',
  'is',
  'fts',
  'pfts',
  'phfts',
  'wfts',
] as const;

// TODO(savv): this should not accept operator: eq with null.
export interface PostgrestQueryValuePredicate {
  readonly column: string;
  // See: https://postgrest.org/en/stable/api.html#operators
  readonly operator: (typeof VALUE_OPERATIONS)[number];
  readonly value: null | boolean | number | string;
}

// Special case to handle (a, b) > (x, y) keyset pagination queries on multiple columns.
export interface PostgrestQueryKeysetPagination {
  readonly columns: string[];
  readonly operator: 'gt' | 'lt';
  readonly values: (null | boolean | number | string)[];
}

type PostgrestQueryPredicate =
  | PostgrestQueryContainsPredicate
  | PostgrestQueryInPredicate
  | PostgrestQueryValuePredicate;

// Querying whether a column (array or single value) is contained in an empty set of values should return the empty set.
// However, the query is impossible to express in sqlite; so we throw this error instead, and catch it up the stack to
// return the empty set.
export class EmptyContainedInPredicateError extends Error {
  constructor() {
    super('EmptyContainedInPredicateError');
  }
}

// Querying whether an array column contains the empty set of values should return all values in the DB, which
// should not be supported, as it will usually be a mistake.
export class EmptyContainsPredicateError extends Error {
  constructor() {
    super('EmptyContainsPredicateError');
  }
}

function toString(query: PostgrestQuery | PostgrestQuery[], topLevel: boolean = false, not: boolean = false): string {
  if (Array.isArray(query)) {
    if (topLevel) {
      return `${query.map(r => toString(r, true)).join('&')}`;
    }
    return `(${query.map(r => toString(r)).join(',')})`;
  }
  if (isPostgrestQueryNot(query)) {
    return toString(query.not, topLevel, !not);
  }
  if (isPostgrestQueryAnd(query)) {
    return `${not ? 'not.' : ''}and${topLevel ? '=' : ''}${toString(query.and)}`;
  }
  if (isPostgrestQueryOr(query)) {
    return `${not ? 'not.' : ''}or${topLevel ? '=' : ''}${toString(query.or)}`;
  }
  if (isPostgrestQueryPredicate(query)) {
    if (topLevel) {
      const ops = query.operator === 'eq' && !not ? '=' : `=${not ? 'not.' : ''}${query.operator}.`;
      return `${escapeReservedPostgRESTChars(query.column)}${ops}${encodePredicateValue(query, true)}`;
    }
    // Negation must come after the column and just before the operator.
    return `${escapeReservedPostgRESTChars(query.column)}.${not ? 'not.' : ''}${query.operator}.${encodePredicateValue(
      query,
      false,
    )}`;
  }
  throw new Error('Unsupported PostgrestQuery construction (toString)');
}

function encodePredicateValue(predicate: PostgrestQueryPredicate, topLevel: boolean): string {
  if (isPostgrestQueryValuePredicate(predicate)) {
    return encodeValue(predicate.value, !topLevel);
  } else if (isPostgrestQueryInPredicate(predicate)) {
    return '(' + predicate.value.map(v => encodeValue(v, true)).join(',') + ')';
  } else if (isPostgrestQueryContainsPredicate(predicate)) {
    return '{' + predicate.value.map(v => encodeValue(v)).join(',') + '}';
  }
  throw new Error('Unhandled predicate type: ' + predicate);
}

function encodeValue(value: null | boolean | number | string, escapeReserved: boolean = false): string {
  switch (typeof value) {
    case 'string':
      return escapeReserved ? escapeReservedPostgRESTChars(value) : value;
    case 'number':
      return Number.isInteger(value) ? JSON.stringify(value) : '"' + JSON.stringify(value) + '"';
    case 'boolean':
      return JSON.stringify(value);
    case 'object': {
      if (value === null) {
        return 'null';
      }
      throw new Error('Unsupported value type');
    }
  }
}

/**
 * Generate a postgrest url query params[] array from a js object query.
 * {@link https://postgrest.org/en/stable/api.html}
 *
 * Note: We do not uri encode values as this is done by the base fetcher.
 * {@link ../FetcherFunc.ts#getUrl}
 */
export function getPostgrestQueryParams(
  query: null | undefined | PostgrestQuery | PostgrestQuery[],
): [string, string][] {
  if (query === null || query === undefined) {
    return [];
  }
  return toParams(query, undefined, true);
}

function toParams(
  query: PostgrestQuery | PostgrestQuery[],
  not: boolean = false,
  topLevel: boolean,
): [string, string][] {
  if (Array.isArray(query)) {
    return query.flatMap(q => toParams(q, not, topLevel));
  }
  if (isPostgrestQueryNot(query)) {
    return toParams(query.not, !not, false);
  }
  if (isPostgrestQueryAnd(query)) {
    if (query.and.length == 0) {
      throw new Error('Empty AND query');
    } else if (query.and.length == 1) {
      return toParams(query.and[0], not, topLevel);
    }
    return [[`${not ? 'not.' : ''}and`, toString(query.and, false, false)]];
  }
  if (isPostgrestQueryOr(query)) {
    if (query.or.length == 0) {
      throw new Error('Empty OR query');
    } else if (query.or.length == 1) {
      return toParams(query.or[0], not, topLevel);
    }
    return [[`${not ? 'not.' : ''}or`, toString(query.or, false, false)]];
  }
  if (isPostgrestQueryPredicate(query)) {
    const opPrefix = (not ? 'not.' : '') + query.operator + '.';
    return [[escapeReservedPostgRESTChars(query.column), opPrefix + encodePredicateValue(query, topLevel)]];
  }
  if (isPostgrestQueryKeysetPagination(query)) {
    // Logic: (x, y, z) > (a, b, c) => (x > a) or (x = a and y > b) or (x = a and y = b and z > c)
    return toParams(
      {
        or: query.columns.map((c, i) => {
          const predicate = {
            column: c,
            operator: query.operator,
            value: query.values[i],
          };
          if (i > 0) {
            const prefix: PostgrestQueryPredicate[] = query.columns.slice(0, i).map((p, i) => ({
              column: p,
              operator: 'eq',
              value: query.values[i],
            }));
            return {
              and: [...prefix, predicate],
            };
          } else {
            return predicate;
          }
        }),
      },
      not,
      topLevel,
    );
  }
  throw new Error('Unsupported PostgrestQuery construction (toParams)');
}

export function isPostgrestQueryNot(query: PostgrestQuery): query is PostgrestQueryNot {
  return (query as PostgrestQueryNot).not !== undefined;
}

export function isPostgrestQueryAnd(query: PostgrestQuery): query is PostgrestQueryAnd {
  return (query as PostgrestQueryAnd).and !== undefined;
}

export function isPostgrestQueryOr(query: PostgrestQuery): query is PostgrestQueryOr {
  return (query as PostgrestQueryOr).or !== undefined;
}

export function isPostgrestQueryPredicate(query: PostgrestQuery): query is PostgrestQueryPredicate {
  return (
    isPostgrestQueryValuePredicate(query) ||
    isPostgrestQueryInPredicate(query) ||
    isPostgrestQueryContainsPredicate(query)
  );
}

export function isPostgrestQueryValuePredicate(query: PostgrestQuery): query is PostgrestQueryValuePredicate {
  return (
    (query as PostgrestQueryPredicate).column != undefined &&
    VALUE_OPERATIONS.includes((query as PostgrestQueryValuePredicate).operator)
  );
}

export function isPostgrestQueryContainsPredicate(query: PostgrestQuery): query is PostgrestQueryContainsPredicate {
  return (
    (query as PostgrestQueryPredicate).column != undefined &&
    CONTAINS_OPERATIONS.includes((query as PostgrestQueryContainsPredicate).operator)
  );
}

export function isPostgrestQueryInPredicate(query: PostgrestQuery): query is PostgrestQueryInPredicate {
  return (
    (query as PostgrestQueryPredicate).column != undefined &&
    IN_OPERATIONS.includes((query as PostgrestQueryInPredicate).operator)
  );
}

export function isPostgrestQueryKeysetPagination(query: PostgrestQuery): query is PostgrestQueryKeysetPagination {
  return (query as PostgrestQueryKeysetPagination).columns !== undefined;
}

/**
 * {@link https://postgrest.org/en/stable/api.html#reserved-characters}
 */
const needsEscaping: RegExp = /["\\]/;
const needsQuotes: RegExp = /[,.:()]/;

function escapeReservedPostgRESTChars(str: string): string {
  const value = needsEscaping.test(str) ? str.replace(/\\/, '\\').replace(/"/, '"') : str;
  return needsQuotes.test(str) ? '"' + value + '"' : value;
}

// This is tailored for usage with sqlite, which differs in supported syntax from postgres.
export function getSqlLiteConditionAndParams(query?: PostgrestQuery): [string, any[]] {
  if (query === null || query === undefined || (isPostgrestQueryAnd(query) && query.and.length == 0)) {
    return ['', []];
  }
  if (isPostgrestQueryNot(query)) {
    const inner = getSqlLiteConditionAndParams(query.not);
    return ['not(' + inner[0] + ')', inner[1]];
  }
  if (isPostgrestQueryAnd(query)) {
    const parts = (Array.isArray(query.and) ? query.and : [query.and]).map(r => getSqlLiteConditionAndParams(r));
    return [
      parts
        .map(p => {
          if (p[1].length === 1) {
            return p[0];
          }
          return '(' + p[0] + ')';
        })
        .join(' and '),
      parts.flatMap(p => p[1]),
    ];
  }
  if (isPostgrestQueryOr(query)) {
    const parts = (Array.isArray(query.or) ? query.or : [query.or]).map(p => getSqlLiteConditionAndParams(p));
    return [
      parts
        .map(p => {
          if (p[1].length === 1) {
            return p[0];
          }
          return '(' + p[0] + ')';
        })
        .join(' or '),
      parts.flatMap(p => p[1]),
    ];
  }
  if (isPostgrestQueryPredicate(query)) {
    if (isPostgrestQueryKeysetPagination(query)) {
      throw new Error('Not Implemented: getSqlLiteConditionAndParams for PostgrestQueryValuesPredicate');
    } else {
      const value = typeof query.value === 'boolean' ? (query.value ? 1 : 0) : query.value;
      switch (query.operator) {
        case 'eq':
          return [`${query.column} = ?`, [value]];
        case 'neq':
          return [`${query.column} != ?`, [value]];
        case 'gt':
          return [`${query.column} > ?`, [value]];
        case 'gte':
          return [`${query.column} >= ?`, [value]];
        case 'lt':
          return [`${query.column} < ?`, [value]];
        case 'lte':
          return [`${query.column} <= ?`, [value]];
        case 'like':
        case 'ilike':
          return [`${query.column} like ?`, [((value ?? '') as string).replace(/\*/g, '%')]];
        case 'is':
          return [`${query.column} is ?`, [value]];
        case 'in':
          if (!(query as PostgrestQueryInPredicate).value.length) {
            throw new EmptyContainedInPredicateError();
          }
          return [`${query.column} in (${query.value.map(() => '?').join(',')})`, query.value];
        case 'cs':
          if (!(query as PostgrestQueryContainsPredicate).value.length) {
            throw new EmptyContainsPredicateError();
          }
          const questionmarks = query.value.map(() => '?').join(',');
          // Check whether all elements in query.value are present in query.column
          return [
            `(select count(*) from json_each(${query.column}) where value in (${questionmarks})) = ?`,
            [...query.value, query.value.length],
          ];

        // NOTE: new operators will also need to be supported in objectMatchesPostgrestQuery.
      }
    }
  }
  throw new Error(`Unsupported PostgrestQuery construction for ${query.operator} (getSqlLiteConditionAndParams)`);
}

export function traversePostgrestQuery(query: PostgrestQuery, fn: (node: PostgrestQuery) => void): void {
  transformPostgrestQuery(query, node => {
    fn(deepCopy(node));
    return node;
  });
}

export function transformPostgrestQuery(
  query: PostgrestQuery,
  fn: (node: PostgrestQuery) => PostgrestQuery,
): PostgrestQuery {
  if (isPostgrestQueryNot(query)) {
    return fn({
      ...query,
      not: transformPostgrestQuery(query.not, fn),
    });
  }
  if (isPostgrestQueryAnd(query)) {
    return fn({
      ...query,
      and: query.and.map(q => transformPostgrestQuery(q, fn)),
    });
  }
  if (isPostgrestQueryOr(query)) {
    return fn({
      ...query,
      or: query.or.map(q => transformPostgrestQuery(q, fn)),
    });
  }
  if (isPostgrestQueryPredicate(query)) {
    return fn(query);
  }
  throw new Error('Unsupported PostgrestQuery construction (transformPostgrestQuery)');
}

export function objectMatchesPostgrestQuery(row: any, query: PostgrestQuery): boolean {
  if (isPostgrestQueryAnd(query)) {
    if (!query.and.every(c => objectMatchesPostgrestQuery(row, c))) {
      return false;
    }
  } else if (isPostgrestQueryOr(query)) {
    if (!query.or.some(c => objectMatchesPostgrestQuery(row, c))) {
      return false;
    }
  } else if (isPostgrestQueryNot(query)) {
    return !objectMatchesPostgrestQuery(row, query.not);
  } else if (isPostgrestQueryPredicate(query)) {
    if (isPostgrestQueryKeysetPagination(query)) {
      throw new Error('Not Implemented: objectMatchesPostgrestQuery for PostgrestQueryValuesPredicate');
    } else {
      if (query.value == null) {
        if (query.operator == 'is') {
          // 'is' has the opposite meaning; "is null" is true if the value is null.
          return row[query.column] == null;
        }
        // Most operators will evaluate to null, which is falsy, with the value is null. For example `x > null` will evaluate to null / false.
        return false;
      }
      if (query.operator == 'eq') {
        return row[query.column] == query.value;
      } else if (query.operator == 'neq') {
        return row[query.column] != query.value;
      } else if (query.operator == 'in') {
        return query.value.includes(row[query.column]);
      } else if (query.operator == 'gt') {
        return row[query.column] > query.value;
      } else if (query.operator == 'gte') {
        return row[query.column] >= query.value;
      } else if (query.operator == 'lt') {
        return row[query.column] < query.value;
      } else if (query.operator == 'lte') {
        return row[query.column] <= query.value;
      } else if (query.operator == 'is') {
        return row[query.column] == query.value;
      } else if (query.operator == 'like' || query.operator == 'ilike') {
        if (typeof query.value !== 'string') {
          throw new Error(
            `objectMatchesPostgrestQuery called with 'like' on column ${query.column} but value was not a string`,
          );
        }
        return new RegExp(query.value.replace(/%/g, '.*'), query.operator == 'ilike' ? 'i' : '').test(
          row[query.column],
        );
      } else if (query.operator == 'cs') {
        const value = row[query.column];
        if (!Array.isArray(value)) {
          throw new Error(
            `objectMatchesPostgrestQuery called with 'contains' on column ${query.column} but value was not an array`,
          );
        }
        return query.value.every(x => value.includes(x));
      } else {
        throw new Error(`Predicate op not implemented: ${JSON.stringify(query)}`);
      }
    }
  } else {
    throw new Error(`Op not implemented: ${JSON.stringify(query)}`);
  }
  return true;
}

export function filterObjectsPostgrestQuery(rows: any[], query: PostgrestQuery) {
  return rows.filter(row => objectMatchesPostgrestQuery(row, query));
}
