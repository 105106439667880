import 'antd/dist/antd.css';
import React from 'react';
import {ClockI} from '../../../src/Clock';
import {FetchFn} from '../../../src/CommonApis';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {isBrazilPolicy} from '../../../src/feature-flags';
import {I18nFunction, I18nSimpleKey} from '../../../src/i18n/i18n';
import {brasilieraRuralLogo} from '../../../src/report/logos';
import {
  FarmReportPackage,
  PolicyReportPackage,
  PortfolioReportPackage,
  TelepacReportMultiPackage,
  TelepacReportPackage,
} from '../../../src/report/report-types';
import {getReportStyling} from '../../../src/report/report-util';
import {cropDesc} from '../../../src/text/desc';
import '../index.css';
import '../map/Map.css';
import '../map/MapOverlay.css';
import '../map/MapToolbox.css';
import '../report/Report.css';
import '../routers/App.css';
import {AxaPolicyReport} from './AxaPolicyReport';
import {FarmReport, ReportFooter, ReportHeader} from './FarmReport';
import {PortfolioReportCharts} from './PortfolioReport';
import {TelepacReport, TelepacReportApis} from './TelepacReport';

export interface StandaloneReportProps {
  t: I18nFunction;
  clock: ClockI;
  authedFetcher: null | FetcherFunc;
  fetchFn: FetchFn;
  package: FarmReportPackage | PortfolioReportPackage | TelepacReportMultiPackage | TelepacReportPackage | PolicyReportPackage;
}

export interface ReportApis {
  clock: ClockI;
  t: I18nFunction;
  fetchFn: FetchFn;
  authedFetcher: FetcherFunc;
}

export function TelepacReportMulti(props: TelepacReportMultiPackage & TelepacReportApis) {
  return (
    <div>
      {props.packages.map((x, i) => (
        <div>
          <a className="hidden-link" href="#new-page">
            X
          </a>
          <TelepacReport key={i} {...x} clock={props.clock} t={props.t} />
          <React.Fragment>
            <tr className="gt-report-page-separator-before">
              <td />
            </tr>
            <tr className="no-print">
              <td className="gt-report-page-separator"></td>
            </tr>
            <tr className="gt-report-page-separator-after">
              <td />
            </tr>
          </React.Fragment>
        </div>
      ))}
    </div>
  );
}

function PortfolioReportPdf(props: {
  package: PortfolioReportPackage;
  clock: ClockI;
  t: I18nFunction;
  fetchFn: FetchFn;
  authedFetcher: FetcherFunc;
}) {
  const {color, logo} = getReportStyling(props.package.user_group);

  return (
    <div className="gt-report-page-center">
      <span>
        <table className="gt-report-container gt-report-layout">
          <thead>
            <tr>
              <th>
                <ReportHeader t={props.t} clock={props.clock} type="PortfolioReport" logo={logo} color={color} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div>
                    {props.t('UserGroup')}: <strong>{props.package.user_group}</strong>
                  </div>
                  <div>
                    {props.t('HarvestYear')}: <strong>{props.package.harvest_year}</strong>
                  </div>
                  <div>
                    {props.t('RegionLevel')}:{' '}
                    <strong>{props.t(('RegionLevel_' + props.package.region_level) as I18nSimpleKey)}</strong>
                  </div>
                </div>
                <div style={{marginTop: '5px'}}>
                  <div>
                    {props.t('SelectedCrops')}:{' '}
                    <strong>
                      {props.package.selected_crops_ids
                        ?.map(value => cropDesc(props.t, props.package.crops, value))
                        .join('; ')}
                    </strong>
                  </div>
                </div>
                <PortfolioReportCharts
                  {...props.package}
                  viewType={'pdf'}
                  t={props.t}
                  clock={props.clock}
                  fetchFn={props.fetchFn}
                  authedFetcher={props.authedFetcher}
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className="gt-report-footer-placeholder">
                {/*required in combination with div below for finicky display of footer on last printed page*/}
              </td>
            </tr>
          </tfoot>
        </table>
        <ReportFooter color={color} />
      </span>
    </div>
  );
}

function AXAPolicyReportPdf(props: {
  package: PolicyReportPackage;
  clock: ClockI;
  t: I18nFunction;
  fetchFn: FetchFn;
  authedFetcher: FetcherFunc;
}) {
  return (
    <div className="gt-report-page-center">
      <span>
        <table className="gt-report-container gt-report-layout">
          <thead>
            <tr>
              <th>
                <span className="gt-report-header">
                  <span className="gt-report-header-logo">
                    <img className="gt-report-header-logo" src={'data:image/png;base64,' + brasilieraRuralLogo} />
                  </span>
                  <span className="gt-report-header-title right">Apólice de Seguros | Seguro Agrícola</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {
                  <AxaPolicyReport
                    {...props.package}
                    t={props.t}
                    clock={props.clock}
                    fetchFn={props.fetchFn}
                    authedFetcher={props.authedFetcher}
                  />
                }
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className="gt-report-footer-placeholder">
                {/*required in combination with div below for finicky display of footer on last printed page*/}
              </td>
            </tr>
          </tfoot>
        </table>
        <ReportFooter color={'white'} />
      </span>
    </div>
  );
}

export function StandaloneReport(props: StandaloneReportProps) {
  if (props.package.type == 'TelepacReport') {
    return <TelepacReport {...props.package} t={props.t} clock={props.clock} />;
  }
  if (props.package.type == 'TelepacReportMulti') {
    return <TelepacReportMulti {...props.package} t={props.t} clock={props.clock} />;
  }
  if (props.package.type == 'ExpertReport') {
    if (!props.authedFetcher) {
      return <div>Internal error for ExpertReport</div>;
    }

    return (
      <FarmReport
        {...props.package}
        t={props.t}
        clock={props.clock}
        fetchFn={props.fetchFn}
        authedFetcher={props.authedFetcher}
      />
    );
  } else if (props.package.type === 'PortfolioReport') {
    if (!props.authedFetcher) {
      return <div>Internal error for ExpertReport</div>;
    }

    return (
      <PortfolioReportPdf
        package={{...props.package}}
        t={props.t}
        clock={props.clock}
        fetchFn={props.fetchFn}
        authedFetcher={props.authedFetcher}
      />
    );
  }
  if (props.package.type === 'PolicyReport') {
    if (!props.authedFetcher) {
      return <div>Internal error for PolicyReport</div>;
    }

    if (isBrazilPolicy(props.package.policyData.policy.user_group)) {
      return (
        <AXAPolicyReportPdf
          package={{...props.package}}
          t={props.t}
          clock={props.clock}
          fetchFn={props.fetchFn}
          authedFetcher={props.authedFetcher}
        />
      );
    } else {
      return <div>Policy report not supported for portfolio ${props.package.policyData.policy.user_group}</div>;
    }
  }

  return <div>Unknown report type</div>;
}
