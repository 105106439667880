import {ErrorBoundary} from '@sentry/react';
import {createColumnHelper} from '@tanstack/react-table';
import {Select} from 'antd';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import fastDeepEqual from '../../../src/fast-deep-equal';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {MapStatsWithRegion, RegionSubset, aggregateMapStats} from '../../../src/map-stats';
import {GadmLevel, GetMapStatsRow, HarvestYear, getMapStats} from '../../../src/models/interfaces';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {chunk, remove} from '../../../src/util/arr-util';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {AreaHa} from '../components/Area';
import {CropTags} from '../components/CropTags';
import {DownloadButton} from '../components/DownloadButton';
import {InfinityTable, InfinityTableProps, defaultColumnSizes} from '../components/InfinityTable';
import {YieldTHa} from '../components/Yield';
import './ListView.css';

const regionLevelLabels: Record<GadmLevel, null | I18nSimpleKey> = {
  '0': 'RegionLevel_country',
  '1': 'RegionLevel_state',
  '2': null,
  '3': null,
  '4': null,
  '5': 'RegionLevel_commune',
};

const fetchSetSize = 100;
const columnHelper = createColumnHelper<MapStatsWithRegion>();
const getRegionId = (row: MapStatsWithRegion): string => row.region?.name ?? '-';
const getCropId = (row: MapStatsWithRegion): null | string => row.crop_id;
const getHarvestYear = (row: MapStatsWithRegion): null | HarvestYear => row.harvest_year;
const getTotalArea = (row: MapStatsWithRegion): number => row.total_area_ha ?? 0;
const getNumFields = (row: MapStatsWithRegion): string => String(row.num_fields ?? '-');
const getNumSamples = (row: MapStatsWithRegion): string => String(row.num_samples ?? '-');
const getWeightedEstimatedYield = (row: MapStatsWithRegion): null | number => row.weighted_estimated_yield_t_ha;
const getStraightEstimatedYield = (row: MapStatsWithRegion): null | number => row.straight_estimated_yield_t_ha;
const sortValue = (row: MapStatsWithRegion): null | string => row.region_id ?? null;
// Note: The row.region_id alone cannot be used as a unique identifier because it can be associated with multiple rows due to different crop_id and harvest_year values.
// Additionally, in some instances, region_id may be null.
const getRowId = (row: MapStatsWithRegion): string => `${row.region_id}-${row.crop_id}-${row.harvest_year}`;

export const RegionsList: React.FC = () => {
  const apis = useApis();
  const {authedFetcher, t} = apis;
  const [selectedRegionLevel, setSelectedRegionLevel] = useState<GadmLevel>('0');
  // To not reload the same data multiple times, we cache the map stats with region info as long as the global filters don't change.
  const mapStatCache = useRef<[undefined | DbFilterState, MapStatsWithRegion[]]>([undefined, []]);
  const [availableRegionLevels, setAvailableRegionLevels] = useState<GadmLevel[]>([]);

  const fetchRegionDetails = useCallback(
    async (mapStats: GetMapStatsRow[]) => {
      if (mapStats.length == 0) {
        return {};
      }
      // Making a get request for all regions is causing 431 Request Header Fields Too Large error.
      // So we are splitting the request into chunks of 300 regions.
      const chunks = chunk(mapStats, 300);
      const requests: Promise<RegionSubset[]>[] = chunks.map(chunk => {
        const params: [string, string][] = [
          ['select', 'gadm_level,name,region_id,region_info'],
          ...getPostgrestQueryParams({
            column: 'region_id',
            operator: 'in',
            value: chunk.map(row => row.region_id).filter(remove.nulls),
          }),
        ];
        return authedFetcher({
          method: 'GET',
          path: 'api/regions',
          params,
        });
      });
      const chunkedRegions = await Promise.all(requests);
      return Object.fromEntries<RegionSubset>(chunkedRegions.flat().map<[string, RegionSubset]>(r => [r.region_id, r]));
    },
    [authedFetcher],
  );

  const fetchDataFromApi: InfinityTableProps<MapStatsWithRegion>['fetchData'] = useCallback(
    async ({filters, pageParam}): Promise<MapStatsWithRegion[]> => {
      if (pageParam?.continue_from_id) {
        return [];
      }
      const [cachedFilters, cachedMapStats] = mapStatCache.current;
      if (!fastDeepEqual(cachedFilters, filters)) {
        const mapStats = await getMapStats(authedFetcher, {...filtersToRequest(filters)});
        const regionIdDetails = await fetchRegionDetails(mapStats);
        const mapStatsRowWithName: MapStatsWithRegion[] = mapStats.map(row => {
          const regionDetails = row.region_id ? regionIdDetails[row.region_id] : null;
          return {...row, region: regionDetails};
        });
        mapStatCache.current = [filters, mapStatsRowWithName];
        const availableRegionalLevels = mapStatsRowWithName.reduce((levels, x) => {
          x.region?.region_info?.forEach(r => {
            levels.add((r?.region_level as GadmLevel) ?? null);
          });
          return levels;
        }, new Set<null | GadmLevel>());
        setAvailableRegionLevels(Array.from(availableRegionalLevels).filter(remove.nulls));
      }
      return aggregateMapStats(mapStatCache.current[1], selectedRegionLevel);
    },
    [authedFetcher, fetchRegionDetails, selectedRegionLevel],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor<typeof getRegionId, ReturnType<typeof getRegionId>>(getRegionId, {
        id: 'region_name',
        header: t('Region'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
        cell: ({getValue}) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor<typeof getCropId, ReturnType<typeof getCropId>>(getCropId, {
        id: 'crop_id',
        header: t('harvest_crop'),
        size: defaultColumnSizes.s,
        enableSorting: false,
        cell: ({getValue}) => (
          <span className="mask">
            <CropTags cropIds={[getValue()]} />
          </span>
        ),
      }),
      columnHelper.accessor<typeof getHarvestYear, ReturnType<typeof getHarvestYear>>(getHarvestYear, {
        id: 'harvest_year',
        header: t('harvest_year'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
      }),
      columnHelper.accessor<typeof getNumSamples, ReturnType<typeof getNumSamples>>(getNumSamples, {
        id: 'num_samples',
        header: t('NumberOfSamples'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
        cell: ({getValue}) => <span className="mask numbers">{getValue()}</span>,
      }),
      columnHelper.accessor<typeof getNumFields, ReturnType<typeof getNumFields>>(getNumFields, {
        id: 'num_fields',
        header: t('NumberOfFields'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
        cell: ({getValue}) => <span className="mask numbers">{getValue()}</span>,
      }),
      columnHelper.accessor<typeof getTotalArea, ReturnType<typeof getTotalArea>>(getTotalArea, {
        id: 'total_area_ha',
        header: t('TotalCultivatedArea'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
        cell: ({getValue}) => (
          <span className="mask numbers">
            <AreaHa value={getValue()} />
          </span>
        ),
      }),
      columnHelper.accessor<typeof getWeightedEstimatedYield, ReturnType<typeof getWeightedEstimatedYield>>(
        getWeightedEstimatedYield,
        {
          id: 'weighted_estimated_yield',
          header: t('EstimatedYieldAreaWeighted'),
          size: defaultColumnSizes.xs,
          enableSorting: false,
          cell: ({getValue, row}) => (
            <span className="mask numbers">
              <YieldTHa value={getValue()} cropId={getCropId(row.original)} />
            </span>
          ),
        },
      ),
      columnHelper.accessor<typeof getStraightEstimatedYield, ReturnType<typeof getStraightEstimatedYield>>(
        getStraightEstimatedYield,
        {
          id: 'straight_estimated_yield',
          header: t('EstimatedYieldArithmeticMean'),
          size: defaultColumnSizes.xs,
          enableSorting: false,
          cell: ({getValue, row}) => (
            <span className="mask numbers">
              <YieldTHa value={getValue()} cropId={getCropId(row.original)} />
            </span>
          ),
        },
      ),
    ],
    [t],
  );

  const regionLevelOptions = useMemo(
    () => [
      ...availableRegionLevels.map(r => ({
        value: r,
        label: `${regionLevelLabels[r] ? t(regionLevelLabels[r]!) : `${t('RegionLevel')}: ${r}`}`,
      })),
    ],
    [t, availableRegionLevels],
  );

  return (
    <div className="list-view list-regions">
      <ErrorBoundary>
        <div className="list-view-action-buttons">
          <DownloadButton
            label={'DownloadXLSX'}
            downloadType="region"
            enabled
            params={{
              gadm_level: selectedRegionLevel,
            }}
          />
          <Select options={regionLevelOptions} value={selectedRegionLevel} onChange={setSelectedRegionLevel} />
        </div>
        <InfinityTable<MapStatsWithRegion, [GadmLevel]>
          columns={columns}
          initialSorting={{id: 'region_name', desc: true}}
          fetchData={fetchDataFromApi}
          fetchSetSize={fetchSetSize}
          tableId="list/Region"
          getRowId={getRowId}
          getSortValue={sortValue}
          additionalQueryKeys={[selectedRegionLevel]}
        />
      </ErrorBoundary>
    </div>
  );
};

export default RegionsList;
