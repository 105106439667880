// Returns the entityIds as labeled options, either by fetching them or using options.
import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {DeepNullable} from 'ts-essentials';
import {I18nFunction} from '../../../src/i18n/i18n';
import {Farm, Policy, UserEntity, searchFarms, searchPolicies} from '../../../src/models/interfaces';
import {farmDesc, farmDescShort} from '../../../src/text/desc';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {useApis} from '../apis/ApisContext';

// An interface for antd's Select options.
export type EntityOptionType = {
  value: string; // entity id (e.g. farm_id)
  label: string;
  shortLabel: string; // abbreviated version of label
  user_group?: string; // user_group, for farms or policies.
};

function farmToOpt(
  t: I18nFunction,
  f: DeepNullable<Pick<Farm, 'address' | 'external_farm_id' | 'farm_id' | 'farm_name' | 'user_group'>>,
) {
  return {label: farmDesc(t, f), value: f.farm_id!, shortLabel: farmDescShort(f), user_group: f.user_group!};
}

function policyToOpt(t: I18nFunction, f: DeepNullable<Pick<Policy, 'policy_id' | 'policy_number' | 'user_group'>>) {
  return {
    label: f.policy_number ?? f.policy_id!,
    value: f.policy_id!,
    shortLabel: f.policy_number ?? f.policy_id!,
    user_group: f.user_group!,
  };
}

export function useSearchEntity(entityType: 'farm' | 'policy', query: string, enabled: boolean) {
  const {authedFetcher, t} = useApis();
  const {isFetching, data = []} = useQuery<EntityOptionType[]>(
    [`${entityType}Filter`, query],
    () => {
      switch (entityType) {
        case 'policy':
          return searchPolicies(authedFetcher, {query}).then(policies => policies.map(p => policyToOpt(t, p)));
        case 'farm':
          return searchFarms(authedFetcher, {query}).then(farms => farms.map(f => farmToOpt(t, f)));
        default:
          throw new Error(`Unsupported search for entity type ${entityType}!`);
      }
    },
    {keepPreviousData: true, enabled},
  );
  return {isFetching, data};
}

export function useSelectedValues(
  options: EntityOptionType[],
  entityType: 'claim' | 'farm' | 'policy',
  entityIds: string[],
) {
  const {authedFetcher, t} = useApis();
  const {data = []} = useQuery<EntityOptionType[]>(
    ['selectedEntities', entityType, entityIds],
    () =>
      entityType == 'farm'
        ? fetchEntities(authedFetcher, 'farm', entityIds).then(x => x.map(f => farmToOpt(t, f)))
        : fetchEntities(authedFetcher, 'policy', entityIds).then(x => x.map(p => policyToOpt(t, p))),
    {enabled: entityIds.some(id => !options.find(o => o.value === id))},
  );
  return useMemo(
    () =>
      entityIds
        .map(
          id =>
            data.find(e => e.value === id) ??
            options.find(o => o.value === id) ?? {
              label: id,
              value: id,
              shortLabel: id,
            },
        )
        // For the selected options, we use the short label, so that the dropdown doesn't get too long.
        .map(x => ({...x, label: x.shortLabel})),
    [entityIds, data, options],
  );
}

export const useUserEntitiesByEmail = (): {isFetching: boolean; data: undefined | Record<string, UserEntity>} => {
  const {authedFetcher} = useApis();
  const {data, isFetching} = useQuery(['userEntities'], async () => {
    const rows: UserEntity[] = await authedFetcher({
      method: 'GET',
      path: 'api/user_entity',
    });
    return Object.fromEntries(rows.map(r => [r.email, r]));
  });
  return {data, isFetching};
};
