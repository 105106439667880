import {AnalyticsI, sanitizeCurScreenParams} from '../../../src/Analytics';
import {ClockI} from '../../../src/Clock';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {getUuid} from '../../../src/get-uuid';
import {EventLog} from '../../../src/models/interfaces';

// TODO(savv): if we switch to cookie-based authentication, use navigator.sendBeacon instead of authedFetcher,
//  and tie it to window.onunload so that we can properly record when the web app is closed.
// TODO(savv): consider parsing user agent string.

export class WebAnalytics implements AnalyticsI {
  public curScreen: null | string = null;
  public curScreenParams: null | Record<string, string> = null;
  private events: EventLog[] = [];
  private session_id: string;

  constructor(
    private email: string,
    private version: string,
    private locale: string,
    private clock: ClockI,
    private authedFetcher: FetcherFunc,
    private reportErr: (e: Error, ctx: string) => void,
    sessionLengthMin: number,
  ) {
    this.session_id = getUuid();
    clock.setInterval(() => (this.session_id = getUuid()), sessionLengthMin * 60 * 1000);
    clock.setInterval(this.send, 10 * 1000);
  }

  public send = () => {
    if (this.events.length == 0) {
      return;
    }

    this.authedFetcher({method: 'POST', path: 'api/event_log', json_body: [...this.events]}).catch(console.info);
    this.events = [];
  };

  logEvent = (e: Partial<EventLog> & Pick<EventLog, 'event_name'>): void => {
    console.info('logEvent', e.event_name);
    const entry: EventLog = {
      device: null,
      battery_percent: null,
      used_memory_mb: null,
      total_memory_mb: null,
      screen_width: null,
      screen_height: null,
      os: null,
      user_agent: null,
      ip: null, // filled server side
      props: null,
      ...e,
      email: this.email,
      ts: new Date(this.clock.now()).toISOString(),
      mode: 'web',
      session_id: this.session_id,
      url: window.location.href,
      cur_screen: this.curScreen,
      cur_screen_params: sanitizeCurScreenParams(this.curScreenParams),
      locale: this.locale,
      version: this.version,
    };

    this.events.push(entry);
  };
}
