import {
  excludeSamplesWithEmptyYieldOrLossInAggregationEnabled,
  unsampledFieldsAreUndamagedEnabled,
} from '../feature-flags';
import {HarvestCrop, LossCause, Sample} from '../models/interfaces';
import {AreaValue, Loss, YieldUnit, YieldValue} from '../models/types';
import {aggregate, filterNulls, remove} from '../util/arr-util';
import {roundDecimals} from '../util/roundDecimals';
import {convertArea, convertLossToUnit, convertYield} from './units';

// This interface describes four variables that describe the amount of loss, if any, on a farm.
export interface YieldStats {
  // The units for the values below. May be 'percent' iff only total Loss is filled.
  unit: YieldUnit;

  // For samples, this is the affected area covered by that sample. If there are no samples for a given field (for
  // whatever reason), it defaults to null, but if unsampledFieldsAreUndamaged, it will be equal to the field area.
  // For field aggregations, this is the area of the field, and for farm aggregations, the total area of all fields, that
  // are included in the aggregation.
  areaHa: null | number;

  // For samples, this is the feasible yield for the given area, else the insured yield.
  // For field aggregations, it is the weighted average by affected area. If affected areas are missing the residual
  // area is split evenly among the remaining samples with a feasible yield; else the insured yield; else null.
  // At the farm level, it is the weighted average by field area.
  feasible: null | number;

  // The estimated yield is aggregated in the same way as the feasible yield. When aggregating the estimated yield,
  // the residual area is assumed to achieve the feasible yield.
  estimated: null | number;

  // For samples, this should always be null, because we first need to aggregate by loss cause across the field.
  totalLoss: null | number;

  // BACK-FILLING VALUES
  // At the sample level we will attempt to back-fill values, as follows:
  // * if the feasible yield is missing, we will use the insured yield
  // * if one of feasible, estimated, or totalLoss is missing, we will attempt to back-fill it using the other two values
  //   including the back-filled feasible yield.

  // For samples, the amount of loss for each cause, in the given unit.
  // For fields/farms, we first aggregate by loss.
  lossByCause: null | {[P in LossCause]?: number};

  // For *samples*, this is the sampled area:
  // - samples with sample.sample_area will have that area as sampledAreaHa
  // - samples without one will have the residual area split evenly among them
  // - sometimes a residual sample gets added to a harvest to make up for the remaining area
  // The sum of sampledAreaHa over a harvest will always add up to that field's/harvest's area.
  // If there are no samples (also no samples without values), it will be null.
  //
  // For *harvests*, it's the sum of its samples sampleAreaHa, including samples without values. Effectively,
  // each harvest having at least one sample (including samples without values) is considered entirely sampled.
  // For *farms*, it's the sum of its harvests sampleAreaHa.
  sampledAreaHa: null | number;

  // For samples themselves, this should be 1 (unless the sample is a residual sample). For fields or samples, this
  // should be the number of samples that were considered in the aggregation, excluding the residual sample, if any.
  numInputSamples: number;
}

interface SampleYieldStats extends YieldStats {
  sample_id: null | string;
  isOverride: boolean;
}

interface HarvestYieldStats extends YieldStats {
  data: Omit<SampleYieldStats, 'isOverride'>[];
}

export type SampleColumns = Pick<
  Sample,
  'estimated_yield' | 'feasible_yield' | 'losses' | 'sample_area' | 'sample_date' | 'sample_id' | 'sample_location'
>;

// Calculates the aggregate yield (estimated, feasible, lost) across a set of samples, using the following methodology.
export function calculateYieldStats(
  farmUserGroup: string,
  unit: YieldUnit,
  area: null | AreaValue,
  harvest_crop: null | HarvestCrop,
  insured_yield: null | YieldValue,
  samples: SampleColumns[],
): HarvestYieldStats {
  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(farmUserGroup);
  const excludeSamplesWithEmptyYieldOrLossInAggregation =
    excludeSamplesWithEmptyYieldOrLossInAggregationEnabled(farmUserGroup);

  const totalNumSamples = samples.length;
  samples = samples.filter(s => {
    if (excludeSamplesWithEmptyYieldOrLossInAggregation) {
      // Only keep samples with at least 2 of the following: loss info, estimated yield, feasible yield. As we can
      // re-construct the third one from the other two.
      const hasLossInfo = !!s.losses?.some(l => l.loss != null);
      const hasEstimatedYield = s.estimated_yield != null;
      const hasFeasibleYield = s.feasible_yield != null;
      return Number(hasLossInfo) + Number(hasEstimatedYield) + Number(hasFeasibleYield) >= 2;
    }

    return true;
  });

  const insured = convertYield(unit, insured_yield, harvest_crop)?.val ?? null;
  const sampleStats: SampleYieldStats[] = samples.map(s => sampleToYieldStats(unit, harvest_crop, insured, s));
  const totalAffectedArea = sampleStats.map(x => x.sampledAreaHa).reduce(...aggregate.sum) ?? 0;
  const actuallySampledAreasHa = sampleStats.map(x => x.sampledAreaHa).filter(remove.nulls) as number[];
  // In case we have no harvest_area, we assume the area to be equal to the sum of the sampled areas. We cannot fall
  // back to the insured_area, as the context of the insured_area (farm-level harvest) and the context of this
  // calculation may not match (i.e. it could be that we are calculating a field-level harvest here).
  const areaHa =
    convertArea('hectares', area)?.val ??
    (actuallySampledAreasHa.length == samples.length ? (actuallySampledAreasHa.reduce(...aggregate.sum) ?? 0) : null);
  const residualArea = areaHa ? Math.max(0, areaHa - totalAffectedArea) : 0;

  if (samples.length === 0) {
    return {
      unit,
      areaHa: unsampledFieldsAreUndamaged ? areaHa : null,
      estimated: unsampledFieldsAreUndamaged ? insured : null,
      feasible: unsampledFieldsAreUndamaged ? insured : null,
      // TODO(savv): unsampledFieldsAreUndamaged should be setting the loss to 0 (undamaged).
      lossByCause: null,
      totalLoss: null,
      data: [],
      sampledAreaHa: totalNumSamples > 0 ? areaHa : null,
      numInputSamples: 0,
    };
  }

  const numInputSamples = sampleStats.length;
  let feasible = averageByAffectedArea(
    areaHa,
    // The residual value will only be used if there is at least some affected area.
    insured,
    sampleStats.map(x => ({
      affectedAreaHa: x.areaHa,
      value: x.feasible,
    })),
  );
  if (!feasible == null) {
    feasible = insured;
  }

  const lossCauses: Set<LossCause> = new Set(
    sampleStats.map(x => Object.keys(x.lossByCause ?? {}) as LossCause[]).flat(1),
  );
  let lossByCause: null | {[P in LossCause]?: number} = {};
  let totalLoss: null | number = 0;
  for (const lossCause of lossCauses) {
    // Aggregate visits by loss cause first.
    const lossData = sampleStats.map(x => ({
      affectedAreaHa: x.areaHa,
      value: x.lossByCause?.[lossCause] ?? 0,
    }));
    lossByCause[lossCause] = averageByAffectedArea(areaHa, 0, lossData) ?? undefined;
    if (lossByCause[lossCause] == undefined) {
      totalLoss = null;
    }
    if (totalLoss != null) {
      totalLoss += lossByCause[lossCause] ?? 0;
    }
  }

  // The flag "excludeSamplesWithEmptyLossInAggregation" effectively means a loss of a given cause in one sample,
  // (if the same loss cause is not used on any other sample in the field) will be interpreted as having been present
  // on all samples (with the same values).
  // This, in effect, means, we cannot average the calculated estimated yields, as the calculated estimated yield
  // on the sample without that loss cause does not know about the loss defined on another sample.
  // To work around this, we will calculate the estimated yield in this case as the feasible yield minus the total loss.
  let estimated = averageByAffectedArea(
    areaHa,
    // The residual value will only be used if there is at least some affected area, therefore doesn't need to be
    // conditional on unsampledFieldsAreUndamaged (as this field is not unsampled).
    // We use the insured yield as the residual area does not have a feasible yield by definition - it's the area
    // without samples.
    // However, if we don't have an insured yield, we fall back to the feasible yield as an approximation.
    insured ?? feasible,
    sampleStats.map(x => ({
      affectedAreaHa: x.areaHa,
      value: x.estimated,
    })),
  );

  // Complement finalVisitData with effective area used for the computation of the averages + add an entry representing
  // the residual used to complete the calculation over the whole field.
  const numSamplesWithoutAffectedArea = sampleStats.filter(x => x.sampledAreaHa == null).length;
  const residualAreaPerSample = numSamplesWithoutAffectedArea > 0 ? residualArea / numSamplesWithoutAffectedArea : 0;
  if (residualArea > 0) {
    if (numSamplesWithoutAffectedArea > 0) {
      // Distribute the residual area over all samples without sample_area.
      for (const data of sampleStats) {
        if (data.areaHa == null) {
          data.areaHa = data.sampledAreaHa = residualAreaPerSample;
        }
      }
    } else {
      // If we cannot distribute the residual area over existing samples without area,
      // we generate a dummy sample to make up for that remaining area.
      sampleStats.push({
        unit,
        areaHa: residualArea,
        estimated: estimated ? feasible : null,
        feasible: feasible,
        lossByCause: {},
        sampledAreaHa: residualArea,
        totalLoss: 0,
        sample_id: null,
        numInputSamples: 0,
        isOverride: false,
      });
    }
  }

  // Field-level samples act as override
  const override = sampleStats.find(x => x.isOverride);
  if (override) {
    if (override.estimated != null) {
      estimated = override.estimated;
    }
    if (override.feasible) {
      feasible = override.feasible;
    }
    if (override.lossByCause) {
      const losses = Object.values(override.lossByCause);
      if (losses.length > 0) {
        lossByCause = override.lossByCause;
        totalLoss = Object.values(lossByCause).reduce((a, b) => a + b, 0);
      }
    }
  }

  // We need to aggregate sampledAreaHa based on finalVisitData as we want the sampledAreaHa to also include any
  // residual area that was (re-)distributed to the samples.
  // But, we can only aggregate the sampleArea from the finalVisitData, if there is at least one sample. As else there
  // is a dummy entry for the whole area in the finalVisitData but as there never was any sampling done, it would be
  // wrong to aggregate that area.
  const sampledAreaHa = samples.length > 0 ? sampleStats.map(d => d.sampledAreaHa).reduce(...aggregate.sum) : null;

  return {
    unit,
    areaHa,
    estimated,
    feasible,
    lossByCause,
    totalLoss,
    data: sampleStats.map(({isOverride, ...x}) => x),
    sampledAreaHa,
    numInputSamples,
  };
}

// This method calculates the weighted averages of the array of values, using the affected area as the weight.
// Samples without a value are discarded, as they are unusable.
// If the sum of usable affected areas exceeds the field area, then the samples are used as weights.
// If the sum of usable affected areas is below the field area, then the residual area:
//  * is split amongst samples without an affected area, if any;
//  * else, the `residualValue` is applied to the residual area.
export function averageByAffectedArea(
  fieldArea: null | number,
  residualValue: null | number,
  arr: {affectedAreaHa: null | number; value: null | number}[],
) {
  const usableValues = arr.filter(x => x.value != null) as {affectedAreaHa: null | number; value: number}[];
  if (usableValues.length == 0) {
    return null;
  }
  const valuesWithArea = usableValues.filter(x => x.affectedAreaHa != null) as {
    affectedAreaHa: number;
    value: number;
  }[];
  const valuesWithoutArea = usableValues.filter(x => x.affectedAreaHa == null).map(x => x.value) as number[];

  // If we only have a set of samples without an affected area, return a simple average
  if (valuesWithArea.length == 0 && valuesWithoutArea.length > 0) {
    return valuesWithoutArea.reduce((acc, x) => acc + x, 0) / valuesWithoutArea.length;
  }

  const totalUsableAffectedArea = valuesWithArea.reduce((acc, x) => acc + x.affectedAreaHa, 0);

  const residualArea = fieldArea ? Math.max(0, fieldArea - totalUsableAffectedArea) : 0;
  const residualAreaPerSample = valuesWithoutArea.length > 0 ? residualArea / valuesWithoutArea.length : 0;

  const adjustmentFactor = Math.max(1, fieldArea ? totalUsableAffectedArea / fieldArea : 0);

  let sum = 0,
    weight = 0,
    residualAffectedAreaHa = fieldArea || 0;
  for (const {value, affectedAreaHa} of usableValues) {
    const adjustedAffectedArea = affectedAreaHa == null ? residualAreaPerSample : affectedAreaHa / adjustmentFactor;
    residualAffectedAreaHa -= adjustedAffectedArea;
    sum += adjustedAffectedArea * value;
    weight += adjustedAffectedArea;
  }

  if (weight == 0) {
    return null;
  }

  if (residualAffectedAreaHa > 0) {
    if (residualValue != null) {
      sum += residualValue * residualAffectedAreaHa;
      weight += residualAffectedAreaHa;
    }
  }

  return sum / weight;
}

// Returns the sample's losses by type, in the target unit. Returns null, if any loss value is missing.
export function getSampleLosses(
  losses: Loss[],
  unit: YieldUnit,
  harvest_crop: null | HarvestCrop,
  feasible: null | number,
): null | {[P in LossCause]?: number} {
  let yieldLosses: {[P in LossCause]?: number} = {};
  for (const {loss, loss_cause} of losses) {
    if (!loss) {
      continue;
    }

    if (loss.unit == 'percent' && unit != 'percent') {
      if (feasible) {
        yieldLosses[loss_cause ?? 'unknown'] =
          (yieldLosses[loss_cause ?? 'unknown'] ?? 0) + (loss.val / 100) * feasible;
      } else {
        return null; // Can't calculate this without the feasible yield.
      }
    } else {
      const yldLoss = convertYield(unit, loss, harvest_crop);
      if (!yldLoss) {
        return null;
      }
      // Sum up losses of the same cause
      // (e.g. sometimes the same loss event may happen twice, on different dates)
      yieldLosses[loss_cause ?? 'unknown'] = (yieldLosses[loss_cause ?? 'unknown'] ?? 0) + yldLoss.val;
    }
  }

  return yieldLosses;
}

export function getTotalSampleLoss(losses: Loss[]): null | YieldValue {
  if (losses.length == 0) {
    return {unit: 'percent', val: 0};
  }

  const lossUnits = Array.from(new Set(filterNulls(losses.map(x => x.loss?.unit))));
  if (lossUnits.length != 1) {
    return null;
  }

  let totalLoss = 0;
  for (const {loss, loss_cause} of losses) {
    if (loss_cause == 'none') {
      continue;
    }
    // User enter a loss with a loss cause, but no value (e.g. to note damage without an estimation)
    // skip this entry and continue processing the other ones
    if (loss == null) {
      continue;
    }
    totalLoss += loss.val;
  }
  return {unit: lossUnits[0], val: totalLoss};
}
export function deriveFeasible(
  estimated: null | YieldValue,
  totalLoss: null | YieldValue,
  harvestCrop: null | HarvestCrop,
): null | undefined | YieldValue {
  if (!estimated || !totalLoss) {
    return undefined;
  }

  if (totalLoss.unit == 'percent') {
    const val = roundDecimals(estimated.val / (1 - totalLoss.val / 100));
    // If the total loss is 100%, then there is no way to derive the feasible yield.
    return isFinite(val) ? {val, unit: estimated.unit} : null;
  } else {
    if (totalLoss.unit != estimated.unit) {
      totalLoss = convertYield(estimated.unit, totalLoss, harvestCrop);
    }
    if (totalLoss?.unit == estimated.unit) {
      return {val: estimated.val + totalLoss.val, unit: estimated.unit};
    }
  }

  return undefined;
}

export function deriveEstimated(
  feasible: null | YieldValue,
  totalLoss: null | YieldValue,
  harvestCrop: null | HarvestCrop,
): null | undefined | YieldValue {
  if (!feasible || !totalLoss) {
    return undefined;
  }

  if (totalLoss.unit == 'percent') {
    const val = roundDecimals(feasible.val * (1 - totalLoss.val / 100));
    // val should always be finite given the checks on other values, but keeping as a precaution
    return isFinite(val) ? {val, unit: feasible.unit} : null;
  } else {
    if (totalLoss.unit != feasible.unit) {
      totalLoss = convertYield(feasible.unit, totalLoss, harvestCrop);
    }
    if (totalLoss?.unit == feasible.unit) {
      return {val: roundDecimals(feasible.val - totalLoss.val), unit: feasible.unit};
    }
  }

  return undefined;
}

export function deriveTotalLoss(
  estimated: null | YieldValue,
  feasible: null | YieldValue,
  harvestCrop: null | HarvestCrop,
  usePercent: boolean,
): undefined | YieldValue {
  if (!estimated || !feasible?.val) {
    return undefined;
  }

  if (usePercent) {
    // estimated = feasible * (1 - loss%) => 1 - loss% = estimated / feasible => loss% = 1 - estimated / feasible
    return {unit: 'percent', val: roundDecimals(100 * (1 - estimated.val / feasible.val))};
  } else if (feasible.val > estimated.val) {
    return {unit: estimated.unit, val: roundDecimals(feasible.val - estimated.val)};
  }
}

function sampleToYieldStats(
  unit: YieldUnit,
  harvest_crop: null | HarvestCrop,
  insured: null | number,
  s: SampleColumns,
): SampleYieldStats {
  const areaHa = convertArea('hectares', s.sample_area ?? null)?.val || null;
  // On the sample level, areaHa and sampledAreaHa are the same.
  const sampledAreaHa = areaHa;
  let totalLoss: null | YieldValue = getTotalSampleLoss(s.losses);

  const isOverride = !s.sample_location && !s.sample_area;

  if (unit == 'percent') {
    return {
      unit: 'percent',
      totalLoss: totalLoss?.val ?? null,
      areaHa,
      sampledAreaHa,
      lossByCause: getSampleLosses(s.losses, 'percent', harvest_crop, null),
      feasible: null,
      estimated: null,
      sample_id: s.sample_id,
      numInputSamples: 1,
      isOverride,
    };
  }

  let estimated: null | number = convertYield(unit, s.estimated_yield ?? null, harvest_crop)?.val ?? null;
  let feasible: null | number = convertYield(unit, s.feasible_yield ?? null, harvest_crop)?.val ?? insured;

  totalLoss = convertLossToUnit(unit, harvest_crop, !feasible ? null : {unit, val: feasible}, totalLoss);

  if (feasible == null && estimated != null && totalLoss != null) {
    feasible = estimated + totalLoss.val;
  }
  if (estimated == null && feasible != null && totalLoss != null) {
    estimated = feasible - totalLoss.val;
  }

  const lossByCause = getSampleLosses(s.losses, unit, harvest_crop, feasible);

  return {
    unit,
    areaHa,
    estimated,
    feasible,
    lossByCause,
    sampledAreaHa,
    totalLoss: totalLoss?.val ?? null,
    sample_id: s.sample_id ?? null,
    numInputSamples: 1,
    isOverride,
  };
}
