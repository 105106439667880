import {AutoComplete} from 'antd';
import React from 'react';
import {FormyI} from '../../../src/Formy';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {OptionType, SuggestionTextFields} from '../../../src/Formy/Suggestion';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {Farm, Policy} from '../../../src/models/interfaces';
import {fetchEntity} from '../../../src/util/fetchEntity';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {getItems} from './suggest';

// EntitySuggest/FormySuggest are legacy Formy components that allow the user to enter an entity attribute (farm_name,
// policy_number, external_farm_id) and show suggestions for existing entity from the DB.

interface BaseSuggestPropsPolicy {
  field: 'policy_number';
  onEntitySelected: null | ((o: Policy) => void);
}

interface BaseSuggestPropsFarm {
  field: Exclude<SuggestionTextFields, 'policy_number'>;
  onEntitySelected: null | ((o: Farm) => void);
}

type BaseSuggestProps<F extends {[P in Fs]: null | string}, Fs extends SuggestionTextFields> = (
  | BaseSuggestPropsFarm
  | BaseSuggestPropsPolicy
) & {
  formy: FormyI<F>;
  label: I18nSimpleKey;
};

export type FormySuggestProps<F extends {[P in Fs]: null | string}, Fs extends SuggestionTextFields> = BaseSuggestProps<
  F,
  Fs
> & {
  formy: FormyI<F>;
  field: Fs;
  required?: boolean;
};

// A component that shows suggestions based on existing farms / policies; otherwise allows the user to type a new
// farm name (or reference, or policy number).
export class FormySuggest<F extends {[P in Fs]: null | string}, Fs extends SuggestionTextFields> extends FormyComponent<
  F,
  Fs,
  FormySuggestProps<F, Fs>
> {
  static contextType = ApisContext;
  context!: Apis;
  ref: null | {blur: () => void} = null;
  state: {options: (OptionType & {label: string; value: string})[]} = {options: []};

  onSelect = async (_: string, x: OptionType) => {
    if (x.type == 'entity' && this.props.onEntitySelected) {
      if (this.props.field == 'policy_number') {
        const policy = await fetchEntity(this.context.authedFetcher, 'policy', x.id);
        this.handleChange((policy[this.props.field] ?? '') as F[Fs]);
        this.props.onEntitySelected(policy as Policy & Farm);
      } else {
        const farm = await fetchEntity(this.context.authedFetcher, 'farm', x.id);
        this.handleChange((farm[this.props.field] ?? '') as F[Fs]);
        this.props.onEntitySelected(farm as Policy & Farm);
      }
    } else {
      const err = `FormySuggest: invalid option ${JSON.stringify(x)}`;
      reportErr(new Error(err), 'FormySuggest');
    }

    this.ref?.blur();
  };

  onFocus = () => this.fetchOptions('').catch(console.error);

  onChange = (q: string) => {
    this.handleChange(q as F[Fs]);
    this.fetchOptions(q).catch(console.error);
  };

  fetchOptions = async (q: string) => {
    if (!this.props.onEntitySelected || q.trim() == '') {
      return [];
    }

    const items = await getItems(this.context.t, this.context.authedFetcher, this.props.field, q);
    const options = items.flat(1).map(item => ({
      ...item,
      value: item.id,
      label: item.description,
    }));
    if (q == this.value) {
      // Don't use the results if the query changed in the meantime.
      this.setState({options});
    }
  };

  render() {
    return (
      <span className="formy-text-str-deduping">
        <AutoComplete
          data-testid={`FormySuggest-${String(this.props.field)}`}
          ref={ref => (this.ref = ref)}
          className={`formy-item-style ${this.error ? 'formy-item-error' : ''}`}
          onFocus={this.onFocus}
          onBlur={this.handleBlur}
          options={this.state.options}
          onSelect={this.onSelect}
          onChange={this.onChange}
          disabled={this.mode == 'view'}
          showSearch
          allowClear
          value={this.value ?? ''}
          placeholder={this.context.t(this.props.label)}
        />
      </span>
    );
  }
}
