import {DatePicker} from 'antd';
import moment from 'moment';
import React from 'react';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {FormyTextBaseProps} from '../../../src/Formy/FormyTextBase';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {Label} from './FormyLabel';

export interface FormyDatePickerProps<F, Fk extends keyof F> extends FormyTextBaseProps<F, Fk> {
  placeholder?: I18nSimpleKey;
}

export class FormyDatePicker<F extends {[P in Fk]: null | string}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyDatePickerProps<F, Fk>
> {
  render() {
    return (
      <>
        {this.props.label && <Label>{this.props.formy.t(this.props.label)}:</Label>}
        <DatePicker
          data-testid={`FormyDatePicker-${String(this.props.field)}`}
          placeholder={this.props.placeholder && this.props.formy.t(this.props.placeholder)}
          value={this.value ? moment(this.value) : null}
          className={'formy-item-style'}
          disabled={this.mode === 'view'}
          onChange={(date, dateString) => this.handleChange(dateString as any)}
        />
      </>
    );
  }
}
