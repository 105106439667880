import {I18nFunction, I18nParametric, I18nSimpleKey} from '../i18n/i18n';

export function isErrorLike(e: unknown): e is Error {
  return e instanceof Error || (typeof e == 'object' && typeof (e as any).message == 'string');
}

export class ReportableErrorI extends Error {
  stringify(t: I18nFunction) {
    return this.toString();
  }
}

// An error that can be reported verbatim back to the user and which is readable by them.
// I18nError is usually a better choice, as it allows for translations; but this class can also be useful for
// integrations which are customer specific and which don't require translations.
// When reporting those errors, do not include the word error, if isWarning is true.
// this.
export class ReportableError extends ReportableErrorI {
  constructor(
    public msg: string,
    isWarning = false,
  ) {
    super(msg);
    this.name = isWarning ? 'Warning' : 'Error';
  }
}

// A Reportable that can be thrown and which can be translated into any language.
export class I18nError extends ReportableErrorI {
  constructor(
    public msg: I18nParametric | I18nSimpleKey,
    public isWarning = false,
  ) {
    super(typeof msg == 'string' ? msg : msg.type);
  }

  stringify(t: I18nFunction) {
    // Note, we do not internationalize the word error, as it's a technical term, and we need it as such for monitoring.
    return (this.isWarning ? '' : 'Error: ') + t(this.msg);
  }
}
