import {Tabs} from 'antd';
import React from 'react';
import {ConnectedProps, connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {getEnabledFlags} from '../../../src/feature-flags';
import {I18nFunction} from '../../../src/i18n/i18n';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import Header from '../components/Header';
import Claims from '../list/Claims';
import FarmHarvests from '../list/FarmHarvests';
import Farms from '../list/Farms';
import FieldHarvests from '../list/FieldHarvests';
import FieldScores from '../list/FieldScores';
import Policies from '../list/Policies';
import Regions from '../list/Regions';
import Samples from '../list/Samples';
import Visits from '../list/Visits';
import {State} from '../redux';

const mapState = (state: State, ownProps: RouteComponentProps) => ({
  activeTab: ownProps.location.pathname.split('/')[2] || 'samples',
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ListProps = PropsFromRedux & RouteComponentProps & {};

export class List extends React.Component<ListProps> {
  static contextType = ApisContext;
  context!: Apis;

  render() {
    const t: I18nFunction = this.context.t;
    const {activeTab, history} = this.props;
    const onChange = (key: string) => history.push({pathname: `/list/${key}`});
    const enabledFlags = getEnabledFlags(this.context.store.getState());
    const items = [
      {label: t('Policies'), key: 'policies', children: <Policies />},
      {label: t('Farms'), key: 'farms', children: <Farms />},
      {label: t('FarmHarvests'), key: 'farm_harvests', children: <FarmHarvests />},
      {label: t('FieldHarvests'), key: 'field_harvests', children: <FieldHarvests />},
      {label: t('Regions'), key: 'regions', children: <Regions />},
    ];
    if (enabledFlags.has('fieldScoring')) {
      items.push({label: t('FieldScoring'), key: 'scoring', children: <FieldScores />});
    }
    items.push({label: t('Samples'), key: 'samples', children: <Samples />});
    if (enabledFlags.has('visitListWeb')) {
      items.push({label: t('VisitReport'), key: 'reports', children: <Visits />});
    }
    if (enabledFlags.has('claims')) {
      items.push({label: t('Claims'), key: 'claims', children: <Claims />});
    }
    // Map previously used tab-key "fields" to new tab-key "field_harvests", in case anyone uses list/fields in a
    // link/bookmark.
    const mappedActiveTab = activeTab === 'fields' ? 'field_harvests' : activeTab;
    return (
      <>
        <Header showFilters={true} />
        <Tabs items={items} onChange={onChange} activeKey={mappedActiveTab} className="list-tabs" />
      </>
    );
  }
}

export default withRouter(connector(List));
