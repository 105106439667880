import React from 'react';
import {deepCopy} from '../deepCopy';
import {fastDeepEqual} from '../fast-deep-equal';
import {I18nFunction} from '../i18n/i18n';
import {remove} from '../util/arr-util';
import {isObjEmpty} from '../util/obj-util';
import {
  BlurEventHandler,
  ChangeEventHandler,
  CreateRefFn,
  FormyChanged,
  FormyElementRef,
  FormyErrors,
  FormyEventListener,
  FormyI,
  FormyInternals,
  FormyMode,
  FormyOnSubmit,
  FormyRefs,
  FormyRootRefKey,
  FormyScrollToView,
  FormySectionInternals,
  FormyState,
  FormyUpdatable,
  SubmitEventHandler,
} from './index';

// Formy is a class that:
// * holds Form data (type F)
// * provides change and blur handlers that the various FormyX components can use
// * notifies Formy components when they should re-render (provided they register themselves with watch)
// * provides handlers onChange and onSubmit, which is useful for interrelated changes (it is otherwise assumed that
//   each field is independent).
export abstract class FormyBase<F> implements FormyI<F> {
  public t: I18nFunction;
  private _changeHandler: {[Fk in keyof F]?: ChangeEventHandler<F, Fk>} = {};
  private _blurHandler: {[Fk in keyof F]?: BlurEventHandler} = {};
  private _refHandler: {[Fk in FormyRootRefKey | keyof F]?: CreateRefFn} = {};
  private components: {[P in '*' | keyof F]?: FormyUpdatable[]} = {};
  private sections: {[P in keyof F]?: FormyI<F[P]> & FormySectionInternals<F[P]>} = {};
  private onChangeHandlers: FormyEventListener<F>[] = [];
  private onBlurHandlers: FormyEventListener<F>[] = [];
  private onSubmit: FormyOnSubmit<F>;
  private stateComponents: {[event in 'mode']: FormyUpdatable[]};
  private scrollToView: FormyScrollToView = async () => {};

  constructor(
    t: I18nFunction,
    onSubmit: FormyOnSubmit<F>,
    protected validate: (x: F) => FormyErrors<F>,
  ) {
    this.onSubmit = onSubmit;
    this.t = t;
    this.components = {} as {[field in '*' | keyof F]: React.Component[]};
    this.stateComponents = {mode: []};
  }

  protected abstract get values(): F;

  protected abstract get initialValues(): F;

  protected abstract get changedValues(): FormyChanged<F>;

  protected abstract set changedValues(e: FormyChanged<F>);

  protected abstract get errors(): FormyErrors<F>;

  protected abstract set errors(e: FormyErrors<F>);

  protected abstract get refs(): FormyRefs<F>;

  protected abstract set refs(e: FormyRefs<F>);

  protected abstract get state(): {mode: FormyMode}; // The state is only maintained by the top Formy.

  protected abstract get scrollView(): FormyElementRef;

  getMode(): FormyMode {
    return this.state.mode;
  }

  setMode(value: FormyMode) {
    this.state.mode = value;
    this.forceModeUpdate();
    for (const field in this.sections) {
      const section = this.sections[field];
      section?.forceModeUpdate();
    }
  }

  getChangeHandler = (field: keyof F): ChangeEventHandler<F, typeof field> => {
    if (!this._changeHandler[field]) {
      this._changeHandler[field] = (v: F[typeof field]) => this.handleFieldChange(field, v);
    }
    return this._changeHandler[field]!;
  };

  getBlurHandler = (field: keyof F): BlurEventHandler => {
    if (!this._blurHandler[field]) {
      this._blurHandler[field] = () => this.handleFormFieldBlurred(field);
    }
    return this._blurHandler[field]!;
  };

  getSubmitHandler = (): SubmitEventHandler => {
    return this.submitHandler;
  };

  getValues(): F {
    return this.values;
  }

  getValue<Fk extends keyof F>(field: Fk): F[Fk] {
    return this.values[field];
  }

  getError<Fk extends keyof F>(field: Fk): undefined | boolean | FormyErrors<F>[Fk] {
    return this.errors[field];
  }

  watchField(field: '*' | keyof F, component: (() => void) | React.Component) {
    let arr = this.components[field];
    if (!arr) {
      arr = this.components[field] = [];
    }

    if (!arr.includes(component)) {
      arr.push(component);
    }
  }

  unwatch(component: (() => void) | React.Component) {
    this.stateComponents.mode = this.stateComponents.mode.filter(x => x != component);
    for (const k in this.components) {
      this.components[k as '*' | keyof F] = this.components[k as '*' | keyof F]?.filter(x => x != component);
    }
  }

  watchValue<Fk extends keyof F>(field: Fk, component: (() => void) | React.Component): () => F[Fk] {
    this.watchField(field, component);
    return () => this.values[field];
  }

  watchError<Fk extends keyof F>(field: Fk, component: (() => void) | React.Component): () => boolean {
    this.watchField(field, component);
    return () => isFormyErroneous(this.errors, field);
  }

  watchObj(component: (() => void) | React.Component) {
    this.watchField('*', component);
    return () => this.values;
  }

  watchState(
    state: keyof FormyState,
    component: (() => void) | React.Component,
  ): Readonly<Pick<FormyState, typeof state>> {
    this.stateComponents.mode.push(component);
    return this.state;
  }

  forceUpdateField(field: keyof F) {
    this.components[field]?.forEach(component =>
      typeof component == 'function' ? component() : component.forceUpdate(),
    );
    this.components['*']?.forEach(component =>
      typeof component == 'function' ? component() : component.forceUpdate(),
    );
  }

  forceUpdateAll() {
    for (const field in this.components) {
      this.components[field as '*' | keyof F]?.forEach(component =>
        typeof component == 'function' ? component() : component.forceUpdate(),
      );
    }
  }

  forceUpdate(field: keyof F, changes: FormyChanged<F[keyof F]>) {
    if (!changes) {
      return;
    }

    this.forceUpdateField(field);

    const section = this.sections[field];
    if (section) {
      if (changes === true) {
        this.forceUpdateAll();
      } else {
        for (const field in changes) {
          section.forceUpdate(field, changes[field]);
        }
      }
    }
  }

  forceModeUpdate(): void {
    for (const component of this.stateComponents.mode) {
      typeof component == 'function' ? component() : component.forceUpdate();
    }
  }

  handleFieldChange = (field: keyof F, value: F[keyof F]) => {
    let changes: FormyChanged<F[typeof field]> = getFormyChanges(this.values[field], value);
    if (!changes) {
      return;
    }
    this.values[field] = value;
    this.setChanged(field);
    this.runOnChangeHandlers(field);
    this.forceUpdate(field, changes);
  };

  setChanged = (field: keyof F) => {
    if (typeof this.changedValues != 'object') {
      this.changedValues = {};
    }

    // isFormyChanged check was added to prevent overwriting a changed object with true
    // corresponding test is 'Formy section does handle changeValues properly'
    if (!isFormyChanged(this.changedValues, field)) {
      this.changedValues[field] = true;
    }
  };

  getSectionFormy = <Fs extends keyof F>(field: Fs): FormyI<F[Fs]> => {
    if (!this.sections[field]) {
      if (!this.values[field]) {
        throw new Error('Formy: section does not exist; cannot create FormySection for ' + getFormyPath(this, field));
      }
      const castedThis = this as unknown as FormyInternals<F> & FormyI<F>;
      this.sections[field] = new SectionFormy<F[Fs], F, Fs>(castedThis, field);
    }

    return this.sections[field]!;
  };

  addOnChangeListener(listener: FormyEventListener<F>) {
    this.onChangeHandlers.push(listener);
  }

  removeOnChangeListener(listener: FormyEventListener<F>) {
    this.onChangeHandlers = this.onChangeHandlers.filter(x => x != listener);
  }

  runOnChangeHandlers(field: keyof F, subfield?: keyof F[keyof F]) {
    try {
      this.onChangeHandlers.forEach(x => x(field, this.values, subfield));
    } catch (e) {
      console.error('FormyForm.onChange threw error for field', field, e);
    }
  }

  addOnBlurListener(listener: FormyEventListener<F>) {
    this.onBlurHandlers.push(listener);
  }

  removeOnBlurListener(listener: FormyEventListener<F>) {
    this.onBlurHandlers = this.onBlurHandlers.filter(x => x != listener);
  }

  runOnBlurHandlers(field: keyof F, subfield?: keyof F[keyof F]) {
    try {
      this.onBlurHandlers.forEach(x => x(field, this.values, subfield));
    } catch (e) {
      console.error('FormyForm.onBlur threw error for field', field, e);
    }
  }

  handleFormFieldBlurred = (field: keyof F): void => {
    const changes = this.validateField(field);
    this.forceUpdate(field, changes);
    this.runOnBlurHandlers(field);
  };

  validateField = (field: keyof F): FormyChanged<F[typeof field]> => {
    const values = this.values;
    if (typeof values != 'object') {
      return;
    }

    const fieldErrors = this.validate(values)?.[field];
    if (!isFormyErroneous(fieldErrors)) {
      if (typeof this.errors == 'object' && this.errors[field]) {
        delete this.errors[field];
        return diffFormyErrors(this.errors[field], {});
      }
    } else {
      // If we are editing an existing value, don't give errors for unchanged fields.
      if (
        this.state.mode === 'edit' &&
        this.initialValues &&
        fastDeepEqual(this.values[field], this.initialValues[field])
      ) {
        return;
      }

      console.debug(
        'handleFormFieldBlurred failed for',
        field,
        'with value',
        this.values[field],
        '; errors:',
        fieldErrors,
      );
      if (this.errors[field]) {
        const changed = diffFormyErrors(this.errors[field], fieldErrors);
        this.errors[field] = fieldErrors;
        return changed;
      } else {
        this.errors[field] = true;
        return true;
      }
    }
  };

  validateForm = (): boolean => {
    if (typeof this.values != 'object') {
      return false;
    }

    let errors = this.validate(this.values);
    if (this.state.mode == 'edit') {
      errors = removeErrorsForUnchanged(errors, this.changedValues);
    }
    const changed = diffFormyErrors(this.errors, errors);
    this.errors = errors;
    if (typeof changed == 'object') {
      for (const field in changed) {
        this.forceUpdate(field, changed[field]);
      }
    }

    const erroneous = isFormyErroneous(errors);
    if (erroneous) {
      const shownErrors = deepCopy(errors);
      for (const k in shownErrors) {
        if (!shownErrors[k]) {
          delete shownErrors[k];
        }
      }

      const erroredRefs: FormyElementRef[] = [];
      (function findErroredRefs<G>(errors: FormyErrors<G>, refs: FormyRefs<G>) {
        for (const k_ in refs) {
          const k = k_ as keyof FormyRefs<G>;
          if (k != FormyRootRefKey) {
            const ref = refs[k];
            const error = errors[k];
            if (ref) {
              if (error == true) {
                // We only check for == true here instead of isFormyErroneous; as we want to only
                // include the most specific error (rather than sections that are erroneous due to containing
                // errors).
                erroredRefs.push(ref[FormyRootRefKey]);
              } else if (error) {
                findErroredRefs(error, ref);
              }
            }
          }
        }
      })(shownErrors, this.refs);

      this.scrollToView((this as unknown as FormyInternals<F>).scrollView, erroredRefs);

      console.warn('Formy submit failed validation with:', JSON.stringify(shownErrors, null, 2));
    }
    return !erroneous;
  };

  submitHandler = async (): Promise<boolean> => {
    if (!this.validateForm()) {
      return false;
    }

    for (const field in this.values) {
      // HACK: coerce empty strings to null.
      if ((this.values[field] as any) === '') this.values[field] = null as unknown as F[typeof field];
    }

    const mode = this.state.mode;
    this.setMode('view');
    try {
      await this.onSubmit(this.values, this.changedValues, this);
      return true;
    } finally {
      this.setMode(mode);
    }
  };

  createRef = (field: FormyRootRefKey | keyof F): CreateRefFn => {
    const refs = this.refs;
    if (!this._refHandler[field]) {
      this._refHandler[field] = r => {
        const refsK = refs[field];
        if (!refsK) {
          refs[field] = {__ROOT_REF__: undefined} as FormyRefs<F>[typeof field];
        }
        if (field == FormyRootRefKey) {
          refs![FormyRootRefKey] = r ?? undefined;
        } else {
          refs[field]![FormyRootRefKey] = r ?? undefined;
        }
      };
    }
    return this._refHandler[field]!;
  };

  registerScrollToView = (f: FormyScrollToView) => {
    this.scrollToView = f;
  };

  // Returns true if the values are the same as the initial ones.
  hasChangedValues: () => boolean = () => {
    return !fastDeepEqual(this.initialValues, this.values);
  };

  hasChangedValue: (key: keyof F) => boolean = key => {
    return !fastDeepEqual(this.initialValues[key], this.values[key]);
  };

  reset = () => {
    if (!this.initialValues || typeof this.initialValues !== 'object') {
      return;
    }
    (Object.keys(this.initialValues) as unknown as (keyof F)[]).forEach(key => {
      this.getChangeHandler(key)(this.initialValues[key]);
    });
    this.changedValues = {};
    this.errors = {};
    this.forceUpdateAll();
  };
}

///////////////////////////////////////////////////////////////////////////////////////////////////////// FORMY

export class Formy<F> extends FormyBase<F> {
  protected values: F;
  protected initialValues: F;
  protected changedValues: FormyChanged<F> = {};
  protected errors: FormyErrors<F> = {};
  protected state: {mode: FormyMode};
  protected refs: FormyRefs<F> = {__ROOT_REF__: undefined};

  constructor(
    mode: FormyMode,
    initialValues: F,
    t: I18nFunction,
    onSubmit: FormyOnSubmit<F>,
    validate: (x: F) => FormyErrors<F>,
  ) {
    super(t, onSubmit, validate);
    this.state = {mode: mode};
    this.initialValues = initialValues;
    // Without a recursive clone `this.values` would be using the same child obj instance(s) as `this.initialValues`.
    // Which would disable the ability to detect changes in the child objects.
    this.values = recursiveClone(initialValues);
  }

  get scrollView(): FormyElementRef {
    return this.refs.__ROOT_REF__;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////// FORMY SECTION

// Sections are a special case, in that we access everything directly from the parent.
class SectionFormy<G, F extends {[P in Fk]: G}, Fk extends keyof F>
  extends FormyBase<G>
  implements FormySectionInternals<G>
{
  private parent: FormyI<F> & FormyInternals<F>;
  private field: Fk;

  constructor(parent: FormyI<F> & FormyInternals<F>, field: Fk) {
    super(
      parent.t,
      () => {
        throw new Error('SectionFormy cannot be submitted!');
      },
      () => parent.validate(parent.values)?.[field] as FormyErrors<G>,
    );
    this.parent = parent;
    this.field = field;
    // Note: the following two lines will call the respective setter functions.
    this.errors = {};
    this.refs = {__ROOT_REF__: undefined};
  }

  protected get values(): G {
    return this.parent.values[this.field];
  }

  protected set values(v) {
    throw new Error('SectionFormy::set values is not implemented; value was: ' + JSON.stringify(v));
  }

  protected get changedValues(): FormyChanged<G> {
    return typeof this.parent.changedValues == 'object' ? this.parent.changedValues[this.field] : undefined;
  }

  protected set changedValues(v) {
    if (typeof this.parent.changedValues != 'object') {
      this.parent.changedValues = {[this.field]: v} as FormyChanged<F>;
    } else {
      this.parent.changedValues[this.field] = v as FormyChanged<F[Fk]>;
    }
  }

  protected get errors(): FormyErrors<G> {
    if (!this.parent) {
      throw new Error(`SectionFormy couldn't get parent!`);
    }
    if (!this.parent.errors) {
      throw new Error(`SectionFormy couldn't get parent's errors!`);
    }
    if (!this.parent.errors[this.field]) {
      if (typeof this.parent.errors != 'object') {
        this.parent.errors = {};
      }
      this.parent.errors[this.field] = {};
    }
    return this.parent.errors[this.field] as FormyErrors<G>;
  }

  protected set errors(v) {
    this.parent.errors[this.field] = v as FormyErrors<F[Fk]>;
  }

  protected get refs(): FormyRefs<G> {
    if (!this.parent || !this.parent.refs) {
      throw new Error(`SectionFormy couldn't get parent: ${!!this.parent}; or its refs: ${!!this.parent.refs}`);
    }
    if (!this.parent.refs[this.field]) {
      this.parent.refs[this.field] = {__ROOT_REF__: undefined} as FormyRefs<F>[Fk];
    }
    return this.parent.refs[this.field] as FormyRefs<G>;
  }

  protected set refs(v) {
    this.parent.refs[this.field] = v as FormyRefs<F>[Fk];
  }

  protected get initialValues(): G {
    return this.parent.initialValues && this.parent.initialValues[this.field];
  }

  protected set initialValues(_) {
    throw new Error('SectionFormy::set initialValues is not implemented.');
  }

  protected get state() {
    return this.parent.state;
  }

  protected set state(_) {
    throw new Error('SectionFormy::set state is not implemented.');
  }

  getMode(): FormyMode {
    return this.parent.getMode();
  }

  setMode(value: FormyMode) {
    this.parent.setMode(value);
  }

  getSubmitHandler = (): SubmitEventHandler => {
    throw new Error('SectionFormy cannot handle submits!');
  };

  runOnChangeHandlers = (field: keyof G, subfield?: keyof G[keyof G]) => {
    this.parent.runOnChangeHandlers(this.field, field);
    super.runOnChangeHandlers(field, subfield);
  };

  runOnBlurHandlers = (field: keyof G, subfield?: keyof G[keyof G]) => {
    this.parent.runOnBlurHandlers(this.field, field);
    super.runOnBlurHandlers(field, subfield);
  };

  get scrollView(): FormyElementRef {
    return this.parent.scrollView;
  }
}

// Useful for debugging.
export function getFormyPath<F>(formy: FormyI<F>, field?: keyof F) {
  const path = [];
  let curFormy = formy as any;
  while (curFormy && curFormy.field) {
    path.push(curFormy.field);
    curFormy = curFormy.parent;
  }
  path.reverse();
  if (field != undefined) {
    path.push(field);
  }

  return path.join('.');
}

function getFormyChanges<X>(prev: X, cur: X): FormyChanged<X> {
  if (fastDeepEqual(prev, cur)) {
    return undefined;
  } else if (prev && cur && typeof prev == 'object' && typeof cur == 'object') {
    const changed: FormyChanged<X> = {};
    const keys = Object.keys(prev).concat(Object.keys(cur)) as (keyof X)[];
    for (const key of keys) {
      changed[key] = getFormyChanges(prev[key], cur[key]);
    }
    return changed;
  } else {
    return true;
  }
}

export function isFormyErroneous<H>(errors: undefined | true | FormyErrors<H>, field?: keyof H): boolean {
  if (typeof errors != 'object') {
    return !!errors;
  }

  if (field !== undefined) {
    return isFormyErroneous(errors[field]);
  }

  for (const k in errors) {
    if (isFormyErroneous(errors[k])) {
      return true;
    }
  }

  return false;
}

function diffFormyErrors<H>(
  before: undefined | boolean | FormyErrors<H>,
  after: undefined | boolean | FormyErrors<H>,
): FormyChanged<H> {
  if (typeof before != 'object' && typeof after != 'object') {
    return !!before != !!after;
  }

  const changed: FormyChanged<H> = {};
  if (typeof before == 'object') {
    for (const k in before) {
      changed[k] = diffFormyErrors(before[k], typeof after == 'object' ? after[k] : after);
    }
  }
  if (typeof after == 'object') {
    for (const k in after) {
      changed[k] = diffFormyErrors(typeof before == 'object' ? before[k] : before, after[k]);
    }
  }

  return changed;
}

export function removeErrorsForUnchanged<K>(errors: FormyErrors<K>, changedValues: FormyChanged<K>): FormyErrors<K> {
  if (!changedValues) {
    return {};
  }

  const newErrors: FormyErrors<K> = {};
  for (const k in errors) {
    const error = errors[k];
    if (typeof error == 'object') {
      newErrors[k] = removeErrorsForUnchanged(
        error,
        // When 'errors' is an array and 'k' is in the changed values, it means that some property of the object
        // in the 'errors' array changed. In such cases, we consider the whole object changed, and we don't want to
        // remove any errors for such object. This can happen when object is added to formy array, and specific
        // property is required, but that property hasn't changed, and some other property did change. Such object
        // should be fully validated with the validation rules, and we don't want to remove any errors for it.
        changedValues == true || (Array.isArray(errors) && k in changedValues) ? true : changedValues[k],
      );
      if (typeof newErrors[k] == 'object' && isObjEmpty(newErrors[k] as object)) {
        delete newErrors[k];
      }
    } else if (error == true) {
      const changed = changedValues == true || changedValues[k];
      if (changed) {
        newErrors[k] = true;
      }
    }
  }

  return newErrors;
}

function isFormyChanged<F>(changedValues: FormyChanged<F>, field: keyof F): boolean {
  return typeof changedValues === 'object' && Object.keys(changedValues).length > 0 && !!changedValues[field];
}

// Create a recursive deep clone of an object, important to be able to compare initial values for section objects.
function recursiveClone<T>(obj: T): T {
  if (!obj || typeof obj != 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(recursiveClone) as T;
  }
  const copy = Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
  Object.entries(copy).forEach(([k, v]) => {
    copy[k] = Array.isArray(v) ? v.map(recursiveClone) : recursiveClone(v);
  });
  return copy;
}

export function logErrors<F>(errors: undefined | boolean | FormyErrors<F>, key: null | string, values: any): void {
  if (errors == true) {
    console.info(`Erroneous formy value (${key}):`, values);
  }
  if (!errors || typeof errors !== 'object' || Object.keys(errors).length === 0) {
    return;
  }

  errors = deepCopy(errors);
  for (const k in errors) {
    logErrors(errors[k], k, values[k]);
  }
  if (errors instanceof Array) {
    errors = errors.filter(remove.falsy);
  }

  if (Object.keys(errors).length === 0) {
    return;
  }
}
