import {read, utils} from 'xlsx';
import {ReportableError} from '../util/err-util';

export function parseTerrexpertXlsx(buffer: ArrayBuffer): {rows: TerrexpertXlsxRow[]; errors: TerrexpertParseError[]} {
  const wb = read(buffer, {type: 'buffer', dateNF: 'dd/mm/yyyy'});
  const sheetNameList = wb.SheetNames;
  const lines: string[][] = utils.sheet_to_json(wb.Sheets[sheetNameList[0]], {header: 1, raw: false, defval: ''});
  return parseTerrexpertJson(lines);
}

export function parseTerrexpertJson(lines: string[][]): {rows: TerrexpertXlsxRow[]; errors: TerrexpertParseError[]} {
  const headers = [
    'Réf Dossier',
    'Expert',
    'email Expert',
    'Date de réception',
    "Date d'ouverture",
    'Date de sinistre',
    'Compagnie',
    'Réf Compagnie',
    'Campagne',
    'Assuré',
    'Référence sinistre',
    'N° police',
    'Adresse',
    'CP sinistre',
    'Département',
    'Ville sinistre',
    'Contrat',
    'Garantie',
    'Famille culture',
    'Culture',
  ];

  if (lines.length < 2) {
    throw new TerrexpertParseError(TerrexpertParseErrorType.NOT_ENOUGH_LINES);
  }

  if (lines[0].join(';') !== headers.join(';')) {
    throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_HEADER);
  }

  const errors: TerrexpertParseError[] = [];
  const rows: TerrexpertXlsxRow[] = [];
  for (let i = 1; i < lines.length; i++) {
    try {
      rows.push(new TerrexpertXlsxRow(i, lines[i]));
    } catch (e) {
      let error = e as TerrexpertParseError;
      error =
        error ?? ({error: TerrexpertParseErrorType.UNKNOWN, message: (e as Error).message} as TerrexpertParseError);
      error.rowNumber = i;
      errors.push(error);
    }
  }
  return {rows: rows, errors: errors};
}

export class TerrexpertXlsxRow {
  refDossier: string;
  expert: string;
  expertEmail: string;
  dateReception: string;
  dateOuverture: string;
  dateSinistre: string;
  compagnie: string;
  refCompagnie: string;
  campagne: string;
  assure: string;
  referenceSinistre: string;
  numPolice: string;
  adresse: string;
  cpSinistre: string;
  departement: string;
  villeSinistre: string;
  contrat: string;
  garantie: string;
  familleCulture: string;
  culture: string;

  // The fields below are used by the import process.
  line_id: number;
  pacificaContractNumber: string;
  pacificaDosclimNumber: string;
  farmId: null | string;
  policyId: null | string;
  claimId: null | string;
  cropId: null | string;
  lossId: null | string;
  harvestId: null | string;
  userExists: boolean;
  harvestYear: null | string;

  constructor(lineNumber: number, fields: string[]) {
    if (fields.length !== 20) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.NOT_ENOUGH_FIELDS);
    }
    this.line_id = lineNumber;
    this.refDossier = fields[0];
    this.expert = fields[1];
    this.expertEmail = fields[2];
    this.dateReception = fields[3];
    this.dateOuverture = fields[4];
    this.dateSinistre = fields[5];
    this.compagnie = fields[6];
    this.refCompagnie = fields[7];
    this.campagne = fields[8];
    this.assure = fields[9];
    this.referenceSinistre = fields[10];
    this.numPolice = fields[11];
    this.adresse = fields[12];
    this.cpSinistre = fields[13];
    this.departement = fields[14];
    this.villeSinistre = fields[15];
    this.contrat = fields[16];
    this.garantie = fields[17];
    this.familleCulture = fields[18];
    this.culture = fields[19];

    if (!this.expertEmail.toLowerCase().match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_EMAIL);
    }

    if (!this.numPolice.match(/^\d{15}$/)) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_POLICY_NUMBER);
    }

    this.pacificaContractNumber = this.contrat;
    let prefix = '';
    if (this.contrat === 'PACIFICA RECOLTE CULTURE') {
      prefix = 'MC-';
    } else if (this.contrat === 'PACIFICA GRELE') {
      prefix = 'AC-';
    } else {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_CONTRACT_TYPE);
    }
    this.pacificaContractNumber = prefix + this.numPolice;

    const matches = this.refCompagnie.match(/^(\d{10})\/(\d{4})$/);
    if (matches?.length !== 3) {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_REF_COMPAGNIE);
    }
    this.pacificaDosclimNumber = matches[1];

    if (this.dateSinistre !== null && this.dateSinistre !== '') {
      if (!this.dateSinistre.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_DATE_SINISTRE);
      }
    }

    this.lossId = null;
    this.cropId = null;
    this.farmId = null;
    this.policyId = null;
    this.claimId = null;
    this.harvestId = null;
    this.userExists = false;

    const year = parseInt(this.campagne);
    if (year && year >= 2016 && year <= 2035) {
      this.harvestYear = '' + year;
    } else {
      throw new TerrexpertParseError(TerrexpertParseErrorType.INVALID_HARVEST_YEAR);
    }
  }
}

export enum TerrexpertParseErrorType {
  INVALID_EMAIL,
  INVALID_POLICY_NUMBER,
  INVALID_DATE_SINISTRE,
  INVALID_HARVEST_YEAR,
  INVALID_CONTRACT_TYPE,
  INVALID_REF_COMPAGNIE,
  INVALID_LOSS,
  INVALID_HEADER,
  HARVEST_NOT_FOUND,
  USER_NOT_FOUND,
  FARM_NOT_FOUND,
  POLICY_NOT_FOUND,
  INVALID_CROP,
  NOT_ENOUGH_LINES,
  NOT_ENOUGH_FIELDS,
  UNKNOWN,
}

export class TerrexpertParseError extends ReportableError {
  rowNumber: number;
  error: TerrexpertParseErrorType;
  constructor(errorType: TerrexpertParseErrorType, rowNumber: number = 0, message?: string) {
    message ? super(message) : super(TerrexpertParseErrorType[errorType].toString() + ' at row ' + rowNumber);
    this.rowNumber = rowNumber;
    this.error = errorType;
  }
}
