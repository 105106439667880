import {Button, Spin, message} from 'antd';
import {saveAs} from 'file-saver';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {getFilters} from '../../../src/selectors/filters';
import {ContentTypes, filtersToRequest, parseContentDisposition} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';

export interface DownloadButtonProps {
  label: I18nSimpleKey;
  downloadType:
    | 'farm-harvest'
    | 'farm'
    | 'field-harvest-geojson'
    | 'field-harvest-xlsx'
    | 'field-scoring'
    | 'policy'
    | 'region'
    | 'sample'
    | 'visit';
  params?: {};
  enabled: boolean;
}

// File Download Button. Handles the actual download within the browser, provides user feedback and error handling.
// Utilizes the global filter state for the request.
export const DownloadButton: React.FC<DownloadButtonProps> = props => {
  const filters = useSelector(getFilters);
  return <FilterableDownloadButton {...props} filters={filters} />;
};

export interface FilterableDownloadButtonProps extends DownloadButtonProps {
  filters: DbFilterState;
}

// File Download Button. Handles the actual download within the browser, provides user feedback and error handling.
// Accepts a custom filter state for the request.
export const FilterableDownloadButton: React.FC<FilterableDownloadButtonProps> = React.memo(
  ({label, downloadType, enabled, filters, params = {}}) => {
    const {authedFetcher, t, locale} = useApis();
    const [isFetching, setIsFetching] = React.useState<boolean>(false);

    const onDownload = useCallback(async () => {
      if (!enabled || isFetching) {
        return;
      }

      setIsFetching(true);
      try {
        const entityType =
          downloadType === 'field-harvest-xlsx' || downloadType == 'field-harvest-geojson'
            ? 'field-harvest'
            : downloadType;
        const response: Response = await authedFetcher({
          method: 'POST',
          path: `api2/export/${entityType}-list`,
          headers: [['Accept', ContentTypes[downloadType == 'field-harvest-geojson' ? 'geojson' : 'xlsx']]],
          json_body: {
            ...filtersToRequest(filters),
            locale,
            ...params,
          },
          responseType: 'response',
          timeoutMs: 600000,
        });

        const defaultExt = downloadType == 'field-harvest-geojson' ? 'json' : 'xlsx';
        const fileName = parseContentDisposition(response.headers.get('Content-Disposition')) ?? `export.${defaultExt}`;
        const blob = await response.blob();
        console.info('Downloading file', fileName);
        saveAs(blob, fileName);
        message.success({content: t('Done'), duration: 2, key: 'download'});
      } catch (e) {
        reportErr(e);
        const msgKey = 'download-btn-error';
        message.error({
          content: (
            <span>
              <span>{t('Error')}</span>
              {
                <Button onClick={onDownload} type="link">
                  {t('YouMayTryAgain')}
                </Button>
              }
            </span>
          ),
          duration: 10,
          key: msgKey,
          onClick: () => message.destroy(msgKey),
        });
      } finally {
        setIsFetching(false);
      }
    }, [enabled, isFetching, downloadType, authedFetcher, filters, locale, params, t]);

    return (
      <Button onClick={onDownload} disabled={!enabled || isFetching}>
        {isFetching && <Spin />}
        {isFetching && ' '}
        {t(label)}
      </Button>
    );
  },
);
