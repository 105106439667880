import {Divider, Table} from 'antd';
import React, {MouseEventHandler, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {CROP_COLORS} from '../../../src/constants/colors';
import {getEnabledFlags} from '../../../src/feature-flags';
import {MapNavFocus} from '../../../src/map/map-focus';
import {Farm, Harvest, HarvestData, Policy, Sample} from '../../../src/models/interfaces';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {applyLegacy14DayRule, getAggregationUnit} from '../../../src/selectors/aggregators';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {convertYield, getCountryCodeGroups, getUnitSystem} from '../../../src/selectors/units';
import {calculateYieldStats} from '../../../src/selectors/yield';
import {formatDate} from '../../../src/text/date';
import {farmDesc, fieldDesc, harvestDesc} from '../../../src/text/desc';
import {getSampleDetails} from '../../../src/text/line';
import {remove} from '../../../src/util/arr-util';
import {useApis} from '../apis/ApisContext';
import {SelectedEntity, useSelectedEntity} from '../map/useCurrentMarker';
import Gallery from './Gallery';
import SvgImage from './SvgImage';
import {UserEntityInfo} from './UserEntityInfo';
import {YieldTHa} from './Yield';

const OverlayImage = ({src, onClick}: {src: string; onClick: MouseEventHandler}) => (
  <a onClick={onClick} className="SampleImage">
    <img alt="Sample" src={src} width={80} />
  </a>
);

type EntityLine = (null | string | false)[];

interface EntityInfoProps {
  expanded: null | boolean;
  harvestDataObj: null | {[harvest_id: string]: HarvestData};
  focus: null | MapNavFocus;
}

export default function EntityInfo({focus, expanded, harvestDataObj}: EntityInfoProps) {
  const selectedEntity = useSelectedEntity(focus);

  if (focus?.type == 'lnglat') {
    return (
      <div className="entity-info-line half-padding">
        Lat: {focus.location[1].toFixed(5)} Lng: {focus.location[0].toFixed(5)}
      </div>
    );
  }

  return <EntityInfoView selectedEntity={selectedEntity ?? null} harvestDataObj={harvestDataObj} expanded={expanded} />;
}

interface EntityInfoViewProps {
  selectedEntity: null | SelectedEntity;
  harvestDataObj: null | Record<string, HarvestData>;
  expanded: null | boolean;
}

export function EntityInfoView({selectedEntity, harvestDataObj, expanded}: EntityInfoViewProps) {
  const {t} = useApis(),
    units = useSelector(getUnitSystem),
    crops = useSelector(getCrops);
  const countryGroups = useSelector(getCountryCodeGroups);
  const flags = useSelector(getEnabledFlags);

  if (!selectedEntity) {
    return null;
  }

  selectedEntity.harvests.sort((a: Harvest, b: Harvest) => {
    if (!a.harvest_year && !b.harvest_year) {
      return 0;
    } else if (!a.harvest_year) {
      return 1;
    } else if (!b.harvest_year) {
      return -1;
    }
    return b.harvest_year.localeCompare(a.harvest_year);
  });

  const {farm, field, sample, harvests} = selectedEntity;
  let fieldLine: null | EntityLine = null;
  let harvestLine: null | EntityLine = null;

  const harvest = harvests.length > 0 ? harvests[0] : null;
  const policy = harvest?.policy;

  const cropFamily = getBaseCrop(crops, harvest?.crop_id);
  if (farm && field) {
    const samples = harvest ? applyLegacy14DayRule(harvest.samples) : [];
    const stats =
      harvest &&
      calculateYieldStats(
        farm.user_group,
        getAggregationUnit(units, getBaseCrop(crops, harvest.crop_id), harvest.insured_yield, samples),
        field.field_area,
        cropFamily,
        harvest.insured_yield,
        samples,
      );
    const harvestData = harvestDataObj ? harvest && harvestDataObj[harvest.harvest_id] : null;
    const emergenceDate = harvestData && harvestData.emergence_date;
    const peakVegetationDate = harvestData && harvestData.max_vi_date;
    const estimatedHarvestDate = harvestData && harvestData.harvest_date;
    const predictedYield = flags.has('showPredictedYield') ? harvestData && harvestData.predicted_yield_t_ha : null;
    const insuredYield =
      stats?.unit && harvestData?.insured_yield_t_ha
        ? convertYield(stats.unit, {val: harvestData.insured_yield_t_ha, unit: 'tons-per-hectare'}, cropFamily)
        : null;

    fieldLine = [
      fieldDesc(t, crops, countryGroups, field, harvest),
      insuredYield && t({type: 'InsuredYieldValueUnit', ...insuredYield}),
      stats?.feasible != null && t({type: 'FeasibleYieldValueUnit', unit: stats.unit, val: stats.feasible}),
      stats?.estimated != null && t({type: 'EstimatedYieldValueUnit', unit: stats.unit, val: stats.estimated}),
      stats?.totalLoss != null &&
        t('TotalLoss') + ': ' + t({type: 'YieldUnit', unit: stats.unit, val: stats.totalLoss}),
      field.comments && t('Comments') + ': ' + field.comments,
      emergenceDate && t('EmergenceDate') + ': ' + formatDate(t, emergenceDate),
      peakVegetationDate && t('PeakVegetationDate') + ': ' + formatDate(t, peakVegetationDate),
      estimatedHarvestDate && t('EstimatedHarvestDate') + ': ' + formatDate(t, estimatedHarvestDate),
      predictedYield != null && t({type: 'PredictedYieldValueUnit', unit: 'tons-per-hectare', val: predictedYield}),
    ];
  } else if (harvest) {
    harvestLine = [harvestDesc(t, crops, harvest, null, countryGroups, policy)];
  }

  const totalHarvestsThisYear =
    harvests.filter(value => value.harvest_year === new Date().getFullYear().toString()).length - 1;

  return (
    <>
      <FarmLine farm={farm ?? null} />
      {fieldLine && !expanded ? (
        <div className="entity-info-line half-padding">
          <CropCircle harvest={harvests[0]} />
          <Line text={fieldLine} />
          {totalHarvestsThisYear > 0 && (
            <div style={{textDecoration: 'underline'}}>
              +{totalHarvestsThisYear} {t('Harvests')}
            </div>
          )}
        </div>
      ) : null}
      {harvestLine ? (
        <div className="entity-info-line half-padding">
          <CropCircle harvest={harvests[0]} />
          <Line text={harvestLine} />
        </div>
      ) : null}
      <SampleLine sample={sample ?? null} crop_id={harvest ? harvest.crop_id : null} />
      <EntityViewDetails harvests={selectedEntity.harvests} expanded={expanded} harvestData={harvestDataObj ?? {}} />
    </>
  );
}

type HarvestWithSamples = Harvest & {samples: Sample[]; policy: null | Policy};

function EntityViewDetails({
  expanded,
  harvests,
  harvestData,
}: {
  expanded: null | boolean;
  harvests: HarvestWithSamples[];
  harvestData: Record<string, HarvestData>;
}) {
  const {t} = useApis();
  const crops = useSelector(getCrops);
  const flags = useSelector(getEnabledFlags);

  const columns = useMemo(() => {
    return [
      {
        title: t('Harvest'),
        dataIndex: 'harvest_id',
        key: 'harvest',
        className: 'compact-cell',
        render: (harvest_id: string, harvest: Harvest & {policy: null | Policy}) => {
          return (
            <>
              <CropCircle harvest={harvest} />
              {harvestDesc(t, crops, harvest, null, [], harvest.policy)}
            </>
          );
        },
      },
      {
        title: t('PeakVegetationDate'),
        dataIndex: 'harvest_id',
        key: 'peak_vegetation_date',
        render: (harvest_id: string) => formatDate(t, harvestData[harvest_id]?.max_vi_date ?? ''),
      },
      {
        title: t('EmergenceDate'),
        dataIndex: 'harvest_id',
        key: 'emergence_date',
        render: (harvest_id: string) => formatDate(t, harvestData[harvest_id]?.emergence_date ?? ''),
      },
      {
        title: t('FeasibleYield'),
        dataIndex: 'harvest_id',
        key: 'feasible_yield',
        render: (harvest_id: string) => (
          <YieldTHa value={harvestData[harvest_id]?.feasible_yield_t_ha} cropId={harvestData[harvest_id]?.crop_id} />
        ),
        sorter: (a: Harvest, b: Harvest) =>
          (harvestData[a.harvest_id].feasible_yield_t_ha ?? 0) - (harvestData[b.harvest_id].feasible_yield_t_ha ?? 0),
      },
      {
        title: t('InsuredYield'),
        dataIndex: 'harvest_id',
        key: 'insured_yield',
        render: (harvest_id: string) => (
          <YieldTHa value={harvestData[harvest_id]?.insured_yield_t_ha} cropId={harvestData[harvest_id]?.crop_id} />
        ),
        sorter: (a: Harvest, b: Harvest) =>
          (harvestData[a.harvest_id].insured_yield_t_ha ?? 0) - (harvestData[b.harvest_id].insured_yield_t_ha ?? 0),
      },
      {
        title: t('EstimatedYield'),
        dataIndex: 'harvest_id',
        key: 'estimated_yield',
        render: (harvest_id: string) => (
          <YieldTHa value={harvestData[harvest_id]?.harvest_yield_t_ha} cropId={harvestData[harvest_id]?.crop_id} />
        ),
        sorter: (a: Harvest, b: Harvest) =>
          (harvestData[a.harvest_id].harvest_yield_t_ha ?? 0) - (harvestData[b.harvest_id].harvest_yield_t_ha ?? 0),
      },
      {
        title: t('EstimatedHarvestDate'),
        dataIndex: 'harvest_id',
        key: 'harvest_date',
        render: (harvest_id: string) => formatDate(t, harvestData[harvest_id]?.harvest_date ?? ''),
      },
      flags.has('showPredictedYield')
        ? {
            title: t('PredictedYield'),
            dataIndex: 'harvest_id',
            key: 'predicted_yield_t_ha',
            render: (harvest_id: string) => (
              <YieldTHa
                value={harvestData[harvest_id]?.predicted_yield_t_ha}
                cropId={harvestData[harvest_id]?.crop_id}
              />
            ),
          }
        : null,
      {
        title: t('YieldLoss'),
        dataIndex: 'harvest_id',
        key: 'yield_loss',
        render: (harvest_id: string) => (
          <YieldTHa value={harvestData[harvest_id]?.total_loss_yield_t_ha} cropId={harvestData[harvest_id]?.crop_id} />
        ),
        sorter: (a: Harvest, b: Harvest) =>
          (harvestData[a.harvest_id].total_loss_yield_t_ha ?? 0) -
          (harvestData[b.harvest_id].total_loss_yield_t_ha ?? 0),
      },
    ].filter(remove.nulls);
  }, [t, flags, crops, harvestData]);

  if (!expanded) {
    return null;
  }

  return (
    <>
      <Divider />
      <Table
        columns={columns}
        className="compact-columns"
        dataSource={harvests.filter(value => value.field_id)}
        size="small"
        loading={false}
        pagination={{pageSize: harvests.length >= 8 ? 6 : harvests.length}}
        rowClassName={record => (record.samples.length > 0 ? 'clickable-row' : '')}
        expandable={{
          expandRowByClick: true,
          rowExpandable: record => record.samples.length > 0,
          expandedRowRender: record =>
            record.samples.map(s => <SampleLine key={s.sample_id} sample={s} crop_id={record.crop_id} />),
        }}
        rowKey={(harvest: HarvestWithSamples) => harvest.harvest_id}
      />
    </>
  );
}

function Line({text}: {text: null | EntityLine}) {
  if (!text || text.length == 0) {
    return null;
  }

  return (
    <>
      {text
        .filter(x => !!x)
        .map((x, i) => (
          <React.Fragment key={i}>
            {x}
            <Divider type="vertical" />
          </React.Fragment>
        ))}
    </>
  );
}

function FarmLine({farm}: {farm: null | Farm}) {
  const {t} = useApis();
  const farmLine: null | EntityLine = farm && [
    farmDesc(t, farm),
    t({type: 'CurPortfolio', user_group: farm.user_group}),
    farm.comments && t('Comments') + ': ' + farm.comments,
  ];

  return (
    <div className="entity-info-line half-padding">
      <img alt="Pin" src="/pin.svg" width={20} />
      <Line text={farmLine} />
    </div>
  );
}

function CropCircle({harvest}: {harvest: undefined | Harvest}) {
  const crops = useSelector(getCrops),
    crop = getBaseCrop(crops, harvest?.crop_id);
  return (
    <span style={{margin: 2.5}}>
      <SvgImage name="circle" color={crop ? CROP_COLORS[crop] : undefined} width={15} />
    </span>
  );
}

function SampleLine({sample, crop_id}: {sample: null | Sample; crop_id: null | string}) {
  const {t} = useApis();
  const units = useSelector(getUnitSystem);
  const crops: IndexedCrops = useSelector(getCrops);

  if (!sample) {
    return null;
  }

  const sampleLine = getSampleDetails(t, units, sample, getBaseCrop(crops, crop_id));
  const imageCount = sample.images.length;
  return (
    <span key={sample.sample_id} className={imageCount == 1 ? 'flex' : undefined}>
      <div className="entity-info-line half-padding">
        <span style={{margin: 2.5}}>
          <img alt="Sample" src="/inverted-triangle.svg" width={15} />
        </span>
        <Line text={sampleLine} />
      </div>
      <Gallery showPreview={true} images={sample.images} ImageComponent={OverlayImage} />
      {sample.added_by && (
        <div className="entity-info-line half-padding">
          {`${t('AddedBy')}: `}
          <UserEntityInfo email={sample.added_by} />
        </div>
      )}
    </span>
  );
}
