import {DbFilterState} from '../reducers/filters';
import {ResetAction} from './reset';

export interface SetDbFilterAction {
  type: 'SET_DB_FILTER';
  filters: Partial<DbFilterState>;
}

interface ResetDbFilterAction {
  type: 'RESET_DB_FILTER';
  key: keyof DbFilterState;
}

interface ResetAllDbFiltersAction {
  type: 'RESET_ALL_DB_FILTERS';
}

export type DbFilterActions = ResetAction | ResetAllDbFiltersAction | ResetDbFilterAction | SetDbFilterAction;

export const setDbFilter = (filters: Partial<DbFilterState>): DbFilterActions => ({type: 'SET_DB_FILTER', filters});

export const resetDbFilter = (key: keyof DbFilterState): DbFilterActions => ({type: 'RESET_DB_FILTER', key});

export const resetAllDbFilters = (): DbFilterActions => ({type: 'RESET_ALL_DB_FILTERS'});
