// An element for selecting values out of an enum field.
import React, {CSSProperties, ChangeEventHandler} from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {I18nSimpleKey} from '../../../src/i18n/i18n';

const labelTopStyle: CSSProperties = {display: 'flex', flexDirection: 'column', alignItems: 'baseline'};

interface FormyCheckboxProps<F extends {[P in Fk]?: boolean}, Fk extends keyof F> extends FormyComponentProps<F, Fk> {
  label: (() => string) | I18nSimpleKey;
  labelPosition?: 'left' | 'right' | 'top';
}

export class FormyCheckbox<F extends {[P in Fk]?: boolean}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyCheckboxProps<F, Fk>
> {
  onChange: ChangeEventHandler<HTMLInputElement> = event => {
    this.handleChange(event.target.checked as F[Fk]);
    this.handleBlur();
  };

  render() {
    const {
      label,
      labelPosition,
      formy: {t},
    } = this.props;
    const id = `formy-checkbox-${String(this.props.field)}`;

    const checkbox = (
      <input
        id={id}
        data-testid={id}
        type="checkbox"
        checked={this.value}
        className="formy-input"
        disabled={this.mode === 'view'}
        onChange={this.onChange}
      />
    );

    return (
      <span className={this.error ? 'formy-error' : ''} style={labelPosition === 'top' ? labelTopStyle : {}}>
        {labelPosition === 'right' && checkbox}
        <label htmlFor={id} className={labelPosition ? 'formy-checkbox-label-right' : 'formy-checkbox-label'}>
          {typeof label == 'string' ? t(label) : label()}
        </label>
        {labelPosition !== 'right' && checkbox}
      </span>
    );
  }
}
