import {EditOutlined} from '@ant-design/icons';
import {Col, PageHeader, Row} from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {useFormy, useFormyValue} from '../../../src/Formy/hooks';
import fastDeepEqual from '../../../src/fast-deep-equal';
import {
  Field,
  Harvest,
  HarvestCrop,
  HarvestData,
  HarvestYear,
  YieldUnit,
  YieldValue,
  getFarmHarvestRows,
  getFieldRows,
} from '../../../src/models/interfaces';
import {updateHarvest} from '../../../src/redux/actions/db';
import {DbFilterState, dbFiltersInitialState} from '../../../src/redux/reducers/filters';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {getUnitSystem, getYieldUnits} from '../../../src/selectors/units';
import {remove, unique} from '../../../src/util/arr-util';
import {useAsyncMemo} from '../../../src/util/hooks';
import {filtersToRequest} from '../../../src/util/req-util';
import {getMaxYield} from '../../../src/yield-meta';
import {InnerFormyEntitySelector} from '../Formy/FormyEntitySelector';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyUnit} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';
import {Area} from '../components/Area';
import {CropTags} from '../components/CropTags';
import {FilterableDownloadButton} from '../components/DownloadButton';
import {YieldTHa} from '../components/Yield';
import './EditFieldHarvests.css';
import {commitMutations} from './commit';

type FinalYieldFilter = {farm_id: null | string; harvest_year: null | HarvestYear};

export default function EditFieldHarvests() {
  const {t} = useApis();
  const location = useLocation();
  const history = useHistory();

  const filter = useMemo(() => {
    const url = new URLSearchParams(location.search);
    return {
      farm_id: url.get('farm_id'),
      harvest_year: url.get('harvest_year') as HarvestYear,
    };
  }, [location.search]);

  const setFilter = useCallback(
    (filter: FinalYieldFilter) => {
      const url = new URLSearchParams(location.search);
      url.set('farm_id', filter.farm_id ?? '');
      url.set('harvest_year', (filter.farm_id && filter.harvest_year) ?? '');
      history.replace({pathname: location.pathname, search: `?${url.toString()}`});
    },
    [history, location.pathname, location.search],
  );

  return (
    <div className="edit-field-harvests">
      <Row>
        <Col span={24}>
          <PageHeader
            title={t('EditFieldHarvests')}
            className="no-print" //Hide header when printing
            avatar={{icon: <EditOutlined />}}
          />
        </Col>
      </Row>
      <FinalYieldFilterSelector filter={filter} onFilter={setFilter} />
      <FinalYieldEditor filter={filter} />
    </div>
  );
}

interface EditFieldHarvestsForm {
  unit: YieldUnit;
  fieldHarvests: {
    field: Field;
    harvest: null | Harvest;
    harvestData: null | HarvestData;
    realized_yield: null | YieldValue;
    machinery_yield: null | YieldValue;
    weighed_yield: null | YieldValue;
    settlement_yield: null | YieldValue;
  }[];
}

async function init(authedFetcher: FetcherFunc, filter: FinalYieldFilter, yieldUnit: YieldUnit) {
  if (!filter.farm_id || !filter.harvest_year) {
    return {
      unit: yieldUnit,
      fieldHarvests: [],
    };
  }
  const filters: DbFilterState = {
    ...dbFiltersInitialState,
    farm_id: [filter.farm_id],
    harvest_year: [filter.harvest_year],
  };
  const fieldHarvests = await getFieldRows(authedFetcher, {
    ...filtersToRequest(filters),
    ordering: 'added_on-desc',
    // Fetching 500 rows should be enough for the user to see all fields in 99.9% of cases.
    row_count: 500,
  });
  if (!fieldHarvests) {
    return {
      unit: yieldUnit,
      fieldHarvests: [],
    };
  }
  return {
    unit: yieldUnit,
    fieldHarvests: fieldHarvests
      .filter(r => !!r.field)
      .map(r => ({
        field: r.field!,
        harvest: r.harvest,
        harvestData: r.harvest_data,
        realized_yield: r.harvest?.realized_yield ?? null,
        machinery_yield: r.harvest?.machinery_yield ?? null,
        weighed_yield: r.harvest?.weighed_yield ?? null,
        settlement_yield: r.harvest?.settlement_yield ?? null,
      })),
  };
}

const FinalYieldEditor: React.FC<{filter: FinalYieldFilter}> = ({filter}) => {
  const apis = useApis();
  const {authedFetcher, store, t} = apis;
  const crops = useSelector(getCrops);
  // Auto-select the yield unit based on the unit system
  const units = useSelector(getUnitSystem);
  const [update, setUpdate] = useState<number>(0);
  // Must make sure to keep track of any filter changes, triggering a formy re-creation if the filter changes.
  const formyKey = useMemo(
    () => [filter.farm_id, filter.harvest_year, update].join('-'),
    [filter.farm_id, filter.harvest_year, update],
  );

  const onSubmit = useCallback(
    async (values: EditFieldHarvestsForm) => {
      const harvestsToUpdate = values.fieldHarvests
        .filter(r => {
          return (
            r.harvest &&
            ((r.realized_yield && !fastDeepEqual(r.harvest.realized_yield, r.realized_yield)) ||
              (r.machinery_yield && !fastDeepEqual(r.harvest.machinery_yield, r.machinery_yield)) ||
              (r.weighed_yield && !fastDeepEqual(r.harvest.weighed_yield, r.weighed_yield)) ||
              (r.settlement_yield && !fastDeepEqual(r.harvest.settlement_yield, r.settlement_yield)))
          );
        })
        .map(r => ({
          harvest_id: r.harvest!.harvest_id,
          update: {
            realized_yield: r.realized_yield,
            machinery_yield: r.machinery_yield,
            weighed_yield: r.weighed_yield,
            settlement_yield: r.settlement_yield,
          },
        }))
        .filter(remove.nulls);

      if (harvestsToUpdate.length === 0) {
        console.info('No harvests to update');
        return;
      }

      console.info('Updating', harvestsToUpdate.length, 'harvests', harvestsToUpdate);
      harvestsToUpdate.map(harvest => store.dispatch(updateHarvest(harvest.harvest_id, harvest.update)));

      if (await commitMutations(apis)) {
        setUpdate(u => u + 1);
      }
    },
    [apis, store],
  );
  const onValidate = useCallback(
    (values: EditFieldHarvestsForm) => {
      const invalidYield = (value: null | YieldValue, harvestCrop: null | HarvestCrop) =>
        value && (value.val < 0 || value.val > getMaxYield(harvestCrop));
      return {
        unit: false,
        fieldHarvests: values.fieldHarvests.map(r => {
          const harvestCrop = getBaseCrop(crops, r.harvest?.crop_id);
          return {
            field: false,
            harvest: false,
            harvestData: false,
            realized_yield: invalidYield(r.realized_yield, harvestCrop),
            machinery_yield: invalidYield(r.machinery_yield, harvestCrop),
            weighed_yield: invalidYield(r.weighed_yield, harvestCrop),
            settlement_yield: invalidYield(r.settlement_yield, harvestCrop),
          };
        }),
      };
    },
    [crops],
  );
  const formy = useFormy<EditFieldHarvestsForm>(
    'new',
    () => init(authedFetcher, filter, units.yieldUnit),
    t,
    onSubmit,
    onValidate,
    formyKey,
  );

  const formyFieldHarvests = useFormyValue(formy, 'fieldHarvests', false);

  // This is a quick+dirty solution to allow the user to navigate between inputs using the Enter key (similar to Excel)
  useEffect(() => {
    const listener = function (event: KeyboardEvent) {
      if (event.key === 'Enter') {
        const table = (document.activeElement as HTMLElement)?.closest('table');
        if (!table) {
          return;
        }
        // This assumes all rows have the same number of inputs.
        const inputCount = Array.from(table.querySelectorAll('tbody tr:first-child input')).length;

        event.preventDefault();
        const inputs = Array.from(document.querySelectorAll('input'));
        const currentIndex = inputs.indexOf(document.activeElement as HTMLInputElement);
        const nextIndex = event.shiftKey ? currentIndex - inputCount : currentIndex + inputCount;
        if (nextIndex >= 0 && nextIndex < inputs.length) {
          inputs[nextIndex].focus();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, []);

  const downloadFilters: undefined | DbFilterState = useMemo(() => {
    if (!filter.farm_id || !filter.harvest_year) {
      return undefined;
    }
    return {
      ...dbFiltersInitialState,
      farm_id: [filter.farm_id],
      harvest_year: [filter.harvest_year],
    };
  }, [filter.farm_id, filter.harvest_year]);

  if (!formy || !downloadFilters) {
    return null;
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <table>
            <thead>
              <tr>
                <th>{t('ExternalFieldId')}</th>
                <th>{t('HarvestArea')}</th>
                <th>{t('CropType-s')}</th>
                <th>{t('InsuredYieldAbbr')}</th>
                <th>{t('EstimatedYieldAbbr')}</th>
                <th>{t('PredictedYieldAbbr')}</th>
                <th>{t('Unit')}</th>
                <th>{t('RealizedYieldAbbr')}</th>
                <th>{t('MachineryYieldAbbr')}</th>
                <th>{t('WeighedYieldAbbr')}</th>
                <th>{t('SettlementYieldAbbr')}</th>
              </tr>
            </thead>
            {formyFieldHarvests?.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={10}>{t('NoData')}</td>
                </tr>
              </tbody>
            )}
            <tbody>
              {formyFieldHarvests?.map((r, index) => {
                const fieldFormy = formy.getSectionFormy('fieldHarvests').getSectionFormy(index);
                const cropId = r.harvest!.crop_id;
                const yieldUnits = getYieldUnits(units, getBaseCrop(crops, cropId));
                return (
                  <tr key={r.field!.field_id}>
                    {r.harvest !== null ? (
                      <>
                        <td>{r.field.external_field_id}</td>
                        <td>
                          <Area value={r.harvest.harvest_area || r.field.field_area} />
                        </td>
                        <td>
                          <CropTags cropIds={[r.harvest.crop_id]} />
                        </td>
                        <td>
                          <YieldTHa value={r.harvestData?.insured_yield_t_ha} cropId={cropId} />
                        </td>
                        <td>
                          <YieldTHa value={r.harvestData?.harvest_yield_t_ha} cropId={cropId} />
                        </td>
                        <td>
                          <YieldTHa value={r.harvestData?.predicted_yield_t_ha} cropId={cropId} />
                        </td>
                        <td>{yieldUnits.map(t).join(', ')}</td>
                        <td>
                          <FormyUnit units={yieldUnits} formy={fieldFormy} field={'realized_yield'} />
                        </td>
                        <td>
                          <FormyUnit units={yieldUnits} formy={fieldFormy} field={'machinery_yield'} />
                        </td>
                        <td>
                          <FormyUnit units={yieldUnits} formy={fieldFormy} field={'weighed_yield'} />
                        </td>
                        <td>
                          <FormyUnit units={yieldUnits} formy={fieldFormy} field={'settlement_yield'} />
                        </td>
                      </>
                    ) : (
                      <td colSpan={8}>{t('NoFieldHarvest')}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
        <Col span={12}>
          <FilterableDownloadButton
            label="DownloadXLSX"
            downloadType="field-harvest-xlsx"
            enabled={true}
            filters={downloadFilters}
          />
        </Col>
        <Col span={12}>
          <div className="edit-field-harvests-bottom">
            <FormySubmit formy={formy} label="Save" doNotConfirmSubmissionToUser={true} />
          </div>
        </Col>
      </Row>
    </>
  );
};

const FinalYieldFilterSelector: React.FC<{
  filter: FinalYieldFilter;
  onFilter: (state: FinalYieldFilter) => void;
}> = ({filter, onFilter}) => {
  const apis = useApis();
  const {t, authedFetcher} = apis;
  const {farm_id, harvest_year} = filter;

  const harvestYearsForFarm: undefined | HarvestYear[] = useAsyncMemo(async () => {
    if (!farm_id) {
      return undefined;
    }
    const filters: DbFilterState = {
      ...dbFiltersInitialState,
      farm_id: [farm_id],
    };
    const res = await getFarmHarvestRows(authedFetcher, {
      ...filtersToRequest(filters),
      ordering: 'added_on-desc',
      row_count: 500,
    });
    return unique(
      res.map(row => row.harvest_year as HarvestYear),
      'desc',
    );
  }, [authedFetcher, farm_id]);

  useEffect(() => {
    if (harvestYearsForFarm) {
      if (harvestYearsForFarm.length > 0 && (!harvest_year || !harvestYearsForFarm.includes(harvest_year))) {
        // Reset the harvest year to the first available one, if it's not in the list of available harvest years for
        // the current farm. Note that we do not show an empty option in the list, so it's fine to always set it here.
        onFilter({farm_id, harvest_year: harvestYearsForFarm[0]});
      } else if (harvestYearsForFarm.length === 0 && harvest_year) {
        // Reset the harvest year to null, if there are no harvest years for the current farm.
        onFilter({farm_id, harvest_year: null});
      }
    }
  }, [farm_id, harvestYearsForFarm, harvest_year, onFilter]);

  const availableHarvestYears: [string, string][] =
    harvestYearsForFarm?.map(harvestYear => [harvestYear, t(harvestYear)]) ?? [];

  return (
    <Row gutter={[10, 10]} style={{marginBottom: 20}}>
      <Col span={18}>
        <label>{t('Farm')}</label>
        <div className="edit-field-harvests-farm">
          <InnerFormyEntitySelector
            enabled={true}
            entityType="farm"
            apis={apis}
            field=""
            entity_id={filter.farm_id}
            filter={null}
            error={false}
            onChange={farm_id => onFilter({harvest_year, farm_id})}
            onBlur={() => {}}
            location={null}
          />
        </div>
      </Col>
      <Col span={6}>
        <label>{t('harvest_year')}</label>
        <select
          data-testid={'harvest_year'}
          className={'formy-item-style'}
          disabled={false}
          placeholder={''}
          value={filter.harvest_year ?? undefined}
          onChange={e => onFilter({farm_id, harvest_year: (e.target.value as HarvestYear) ?? null})}>
          {!filter.harvest_year && <option key="undefined" value={undefined}></option>}
          {availableHarvestYears.map(([k, v]) => (
            <option key={k} value={k}>
              {v}
            </option>
          ))}
        </select>
      </Col>
    </Row>
  );
};
