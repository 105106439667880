import {CopyTwoTone} from '@ant-design/icons';
import {useQuery} from '@tanstack/react-query';
import {AutoComplete} from 'antd';
import React, {useMemo} from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {ImportedField} from '../../../src/gt-pack/gt-pack';
import {Field, Harvest, getFieldRows} from '../../../src/models/interfaces';
import {compareHarvestsChronologically} from '../../../src/selectors/harvest';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {fieldDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import {nullFilters} from '../../../src/util/req-util';
import {PD} from '../../../src/util/types';
import {Apis} from '../apis/Apis';
import {ApisContext, useApis} from '../apis/ApisContext';

interface ExternalFieldIdInputProps extends FormyComponentProps<ImportedField, 'external_field_id'> {
  farm_id: null | string;
  fields: {field: Field; harvest: null | Harvest}[];
  onEntitySelected: (f: Field) => void;
}

export class ExternalFieldIdFormy extends FormyComponent<
  ImportedField,
  'external_field_id',
  ExternalFieldIdInputProps
> {
  static contextType = ApisContext;
  context!: Apis;

  ref: null | {blur: () => void} = null;

  getFieldRefDesc = (x: Field, h: null | Harvest) =>
    fieldDesc(
      this.context.t,
      this.context.store.getState().crops.crops,
      getCountryCodeGroups(this.context.store.getState()),
      x,
      h,
      undefined,
      undefined,
      undefined,
      false,
    );

  onSelect = async (value: string, x: {field: Field}) => this.props.onEntitySelected(x.field);

  render() {
    const state = this.context.store.getState();
    const options = this.props.fields.map(f => ({
      value: f.field.field_id,
      field: f.field,
      label: (
        <span>
          <CopyTwoTone />
          {this.getFieldRefDesc(f.field, f.harvest)}
        </span>
      ),
    }));

    return (
      <span className="formy-text-str-deduping">
        <AutoComplete
          ref={ref => (this.ref = ref)}
          className={`formy-item-style ${this.error ? 'formy-item-error' : ''}`}
          onBlur={this.handleBlur}
          options={options}
          onSelect={this.onSelect}
          onChange={this.handleChange}
          showSearch
          allowClear
          value={this.value ?? ''}
          placeholder={this.context.t('FieldReference')}
        />
      </span>
    );
  }
}

export function ExternalFieldIdInput(props: Omit<ExternalFieldIdInputProps, 'fields'>) {
  const {authedFetcher} = useApis();
  const {data} = useQuery(['getFarmRows', props.farm_id], () =>
    !props.farm_id
      ? null
      : getFieldRows(authedFetcher, {
          ordering: 'added_on-desc',
          f: {...nullFilters, farm_ids: [props.farm_id]},
          row_count: 1000,
        }),
  );
  const fields = useMemo(() => {
    const fieldLatestHarvestPairs: PD<{field: Field; harvest: null | Harvest}> = {};
    for (const {field, harvest} of data ?? []) {
      if (!field) {
        continue;
      }

      const cur = fieldLatestHarvestPairs[field.field_id];
      if (!cur?.harvest || (harvest && compareHarvestsChronologically(cur.harvest, harvest))) {
        fieldLatestHarvestPairs[field.field_id] = {field, harvest};
      }
    }

    return filterNulls(Object.values(fieldLatestHarvestPairs));
  }, [data]);

  return <ExternalFieldIdFormy {...props} fields={fields} />;
}
