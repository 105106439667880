import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FormOutlined,
  LeftOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {Button, Col, PageHeader, Row, message} from 'antd';
import {saveAs} from 'file-saver';
import React, {CSSProperties, useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {ClockI} from '../../../src/Clock';
import {BadStatusResponse, FetcherFunc} from '../../../src/FetcherFunc';
import {FormyErrors, FormyEventListener, FormyI, FormyMode} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {getI18nOptions} from '../../../src/Formy/FormyEnum';
import {useFormy, useFormyValue} from '../../../src/Formy/hooks';
import {CROP_COLORS} from '../../../src/constants/colors';
import {
  deletePoliciesEnabled,
  disablePolicyNumberInput,
  enablePolicyDownload,
  enablePolicyInputsIgnoringStatus,
  enablePolicyValidation,
  isBrazilPolicy,
} from '../../../src/feature-flags';
import {I18nFunction, I18nParametric, I18nSimpleKey} from '../../../src/i18n/i18n';
import {
  Address,
  Beneficiary,
  Broker,
  Gender,
  HarvestCustomColumns,
  LegalEntity,
  MgaHarvestCustomColumns,
  MgaPolicyCustomColumns,
  PolicyCustomColumns,
  ProductType,
} from '../../../src/models/CustomColumns';
import {CoverageType, Field, Harvest, HarvestYear, Policy, PolicyStatus} from '../../../src/models/interfaces';
import {OmitDbColumns, PriceAreaValue, Uuid} from '../../../src/models/types';
import {queueDeleteMutation, queueUpdateMutation} from '../../../src/redux/actions/db';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getBaseCrop} from '../../../src/selectors/crops';
import {getPolicyFilter} from '../../../src/selectors/harvest';
import {harvestAggregationKeyEq} from '../../../src/selectors/harvest-key';
import {
  convertArea,
  deriveCurrencyFromUnitPrice,
  getCountryCodeGroups,
  getUnitSystemForUserGroup,
  getYieldUnits,
} from '../../../src/selectors/units';
import {getCurYear} from '../../../src/selectors/year';
import {fieldDesc, harvestDesc} from '../../../src/text/desc';
import {aggregate, remove} from '../../../src/util/arr-util';
import {fetchEntitiesBy, fetchEntity} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {
  calculateBasicPremium,
  calculateBasicSumInsured,
  calculateCommodityPrice,
  calculateCoveragePerArea,
  calculateReplantingPremium,
  calculateReplantingSumInsured,
} from '../../../src/util/policy-calculations';
import {Nullable} from '../../../src/util/types';
import {isArr, isStr} from '../../../src/validator-constraints';
import {FormyBool} from '../Formy/FormyBool';
import {FormyDatePicker} from '../Formy/FormyDatePicker';
import {FormyDateRange} from '../Formy/FormyDateRange';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {FormyEnum, FormyMultiEnum} from '../Formy/FormyEnum';
import {Label} from '../Formy/FormyLabel';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyTextNum, FormyTextStr, FormyUnit} from '../Formy/FormyText';
import FormyUserGroupSelector from '../Formy/FormyUserGroupSelector';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import {FileAttachment} from '../components/FileAttachment';
import SpinningDots from '../components/SpinningDots';
import {savePolicyAndRelatedHarvests} from '../mutate/SavePolicy';
import {State} from '../redux';
import {reportErr} from '../util/err';
import {AddOrEditPolicyBrazil} from './AddOrEditPolicyBrazil';
import './Policies.css';
import {commitMutations} from './commit';

interface HarvestForm {
  harvest_id: null | Harvest['harvest_id'];
  insured_percent: Harvest['insured_percent'];
  reference_yield: Harvest['reference_yield'];
  commodity_price: Harvest['commodity_price'];
  coverage_per_area: null | PriceAreaValue;
  premium_rate_percent: Harvest['premium_rate_percent'];
  selectedFieldLevelHarvestIds: Uuid[];
  custom_columns: FormHarvestCustomColumns;
}

// Note that inheritance on the classes below is used because we are only marking certain attributes as not null.
class FormHarvestCustomColumns extends HarvestCustomColumns {
  mga: MgaHarvestCustomColumns;

  constructor(x: null | Partial<HarvestCustomColumns>) {
    super(x);
    this.mga = x?.mga ?? new MgaHarvestCustomColumns(null);
  }
}

class FormLegalEntity extends LegalEntity {
  address: Address;

  constructor(x: null | Partial<LegalEntity>, address: Address) {
    super(x);
    this.address = address;
  }
}

class FormBroker extends Broker {
  entity: FormLegalEntity;

  constructor(x: null | Partial<Broker>, entity: FormLegalEntity) {
    super(x);
    this.entity = entity;
  }
}

class FormBeneficiary extends Beneficiary {
  entity: FormLegalEntity;

  constructor(x: null | Partial<Broker>, entity: FormLegalEntity) {
    super(x);
    this.entity = entity;
  }
}

const emptyLegalEntity = {
  type: null,
  external_id: null,
  address: null,
  email: null,
  phone: null,
  gender: null,
  alternative_name: null,
  name: null,
  inception_date: null,
  insured_status: null,
  marital_status: null,
};

const emptyAddress = {
  street: null,
  number: null,
  additional_information: null,
  neighborhood: null,
  city: null,
  state: null,
  zip_code: null,
  external_code: null,
  country: null,
};

class FormPolicyCustomColumns {
  insured: FormLegalEntity;
  representative: FormLegalEntity;
  valid_from: null | string;
  valid_to: null | string;
  brokers: FormBroker[];
  beneficiaries: FormBeneficiary[];
  mga: MgaPolicyCustomColumns;

  constructor(x: null | Partial<PolicyCustomColumns>) {
    this.valid_from = x?.valid_from ?? null;
    this.valid_to = x?.valid_to ?? null;

    // To make formy interface work, we need to make sure that nested objects (e.g. insured) and arrays are defined.
    this.insured = new FormLegalEntity(x?.insured ?? emptyLegalEntity, x?.insured?.address ?? emptyAddress);
    this.representative = new FormLegalEntity(
      x?.representative ?? emptyLegalEntity,
      x?.representative?.address ?? emptyAddress,
    );
    this.brokers =
      x?.brokers?.map(
        broker =>
          new FormBroker(
            broker,
            new FormLegalEntity(broker.entity ?? emptyLegalEntity, broker.entity?.address ?? emptyAddress),
          ),
      ) ?? [];
    this.beneficiaries =
      x?.beneficiaries?.map(
        beneficiary =>
          new FormBroker(
            beneficiary,
            new FormLegalEntity(beneficiary.entity ?? emptyLegalEntity, beneficiary.entity?.address ?? emptyAddress),
          ),
      ) ?? [];
    this.mga = x?.mga ?? {
      product_code: null,
      crop_already_planted: null,
      preexisting_policy_for_same_area: null,
      drainage_problems: null,
      flood_or_waterlogging_past_5_years: null,
      aware_of_zoagro_mapa: null,
      federal_subsidy_id: null,
      federal_subsidy: null,
      state_subsidy: null,
      product_type: null,
      ecampo_proposal_id: null,
      axa_policy_id: null,
      previous_crop: null,
      crop_already_damaged: null,
      insured_invoice_recipient: null,
      total_planted_area_insured: null,
      first_or_second_year: null,
      planting_type: null,
      production_cost: null,
      sum_insured: null,
      indigenous_area: null,
      intercropped_planting: null,
      certified_or_registered_seeds: null,
      homemade_seed: null,
      crop_planted_after_pasture: null,
      same_crop_whole_area: null,
      losses_in_other_harvests: null,
      insured_area_settlement: null,
      insured_area_sandy: null,
      predominantly_ad1_ad2_soil: null,
      aware_of_new_soil_classification: null,
      insured_area_fully_owned: null,
      responsible_for_paying_invoice: null,
      total_payment_days: null,
      coverage_type: null,
      installment_count: null,
    };
  }
}

export class PolicyForm implements Nullable<OmitDbColumns<Policy>, 'policy_id' | 'policy_number' | 'user_group'> {
  // Properties directly from the policy.
  policy_id: null | Uuid;
  user_group: null | Policy['user_group'];
  policy_number: null | Policy['policy_number'];
  comments: Policy['comments'];
  custom_columns: FormPolicyCustomColumns;
  metadata: Policy['metadata'];
  editors: Policy['editors'];
  status: Policy['status'];
  merged_ids: Policy['merged_ids'];
  attachments: Policy['attachments'];

  // Properties to manage the related (insured) farm harvests.
  farm_id: null | Uuid;
  harvest_year: HarvestYear;
  harvest_forms: HarvestForm[];

  constructor(clock: ClockI, policy: null | Policy, harvests: Harvest[]) {
    this.user_group = policy?.user_group ?? null;
    this.policy_number = policy?.policy_number ?? null;
    this.policy_id = policy?.policy_id ?? null;
    this.comments = policy?.comments ?? null;
    this.custom_columns = new FormPolicyCustomColumns(policy?.custom_columns ?? null);
    this.metadata = policy?.metadata ?? null;
    this.editors = policy?.editors ?? [];
    this.status = policy?.status ?? 'policy';
    this.merged_ids = policy?.merged_ids ?? [];
    this.harvest_forms = harvests
      .filter(harvest => harvest.farm_id)
      .map(farmLevelHarvest => {
        if (
          farmLevelHarvest.insured_yield &&
          farmLevelHarvest.reference_yield &&
          farmLevelHarvest.insured_yield.unit !== farmLevelHarvest.reference_yield.unit
        ) {
          reportErr(
            new Error(`PolicyForm: Insured and reference yield use different units, which will result in 
          incorrect calculated values for policy id ${policy?.policy_id}`),
          );
        }
        const insuredYield =
          farmLevelHarvest.insured_yield?.val ??
          (farmLevelHarvest.reference_yield?.val ?? 0) * ((farmLevelHarvest.insured_percent ?? 0) / 100);
        return {
          harvest_id: farmLevelHarvest.harvest_id,
          insured_percent: farmLevelHarvest.insured_percent,
          reference_yield: farmLevelHarvest.reference_yield,
          commodity_price: farmLevelHarvest.commodity_price,
          premium_rate_percent: farmLevelHarvest.premium_rate_percent,
          selectedFieldLevelHarvestIds: harvests
            .filter(harvest => harvest.field_id && harvestAggregationKeyEq(harvest, farmLevelHarvest))
            .map(harvest => harvest.harvest_id),
          coverage_per_area: calculateCoveragePerArea(
            farmLevelHarvest.reference_yield,
            farmLevelHarvest.commodity_price,
            insuredYield,
          ),
          custom_columns: new FormHarvestCustomColumns(farmLevelHarvest.custom_columns),
        };
      });
    this.farm_id =
      harvests
        .map(harvest => harvest.farm_id)
        .filter(remove.nulls)
        .shift() ?? null;
    this.harvest_year = harvests.map(harvest => harvest.harvest_year).shift() ?? getCurYear(clock);
    this.attachments = policy?.attachments ?? [];
  }
}

const errorStyle: CSSProperties = {display: 'flex', flexDirection: 'column', color: '#C23B22'};
const maxWidthResponsiveHeight = {width: '100%', height: 'inherit'};

function validatePolicyForm(x: PolicyForm): FormyErrors<PolicyForm> {
  return {
    user_group: !x.user_group,
    policy_number: !x.policy_number || !isStr(x.policy_number),
    harvest_forms:
      !x.harvest_forms ||
      !isArr(x.harvest_forms) ||
      x.harvest_forms.map(harvestForm => {
        return {
          harvest_id: !harvestForm.harvest_id,
        };
      }),
  };
}

export const AddOrEditPolicy: React.FC = () => {
  const {authedFetcher} = useApis();
  const location = useLocation();
  const policyId = useMemo(() => new URLSearchParams(location.search).get('policy_id') ?? '', [location]);
  const mode = useAsyncMemo(async () => {
    if (!policyId) {
      return 'new';
    }
    const policy = await fetchEntity(authedFetcher, 'policy', policyId);
    if (policy.status === 'quote_created' || enablePolicyInputsIgnoringStatus(policy.user_group)) {
      return 'edit';
    } else {
      return 'view';
    }
  }, [authedFetcher, policyId]);
  return mode ? <AddOrEditPolicyInit mode={mode} policyId={policyId} /> : null;
};

const AddOrEditPolicyInit: React.FC<{
  policyId: string;
  mode: FormyMode;
}> = ({mode, policyId}) => {
  const apis = useApis();
  const {t, clock, authedFetcher} = apis;
  const history = useHistory();
  const [error, setError] = React.useState<null | I18nParametric | I18nSimpleKey>(null);
  const onSubmit = useCallback(
    async (policyForm: PolicyForm) => {
      await savePolicyAndRelatedHarvests(apis, policyForm);
      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    },
    [apis, history],
  );
  const formy = useFormy(
    mode,
    async () => {
      if (!policyId) {
        return new PolicyForm(clock, null, []);
      }
      const policy = await fetchEntity(authedFetcher, 'policy', policyId);
      const policyHarvests = await fetchEntitiesBy(authedFetcher, 'harvest', getPolicyFilter(policy.policy_id));
      return new PolicyForm(clock, policy, policyHarvests);
    },
    t,
    onSubmit,
    validatePolicyForm,
    policyId,
  );
  return formy ? <AddOrEditPolicyForm formy={formy} error={error} setError={setError} /> : null;
};

async function validatePolicy(
  t: I18nFunction,
  authedFetcher: FetcherFunc,
  policyId: string,
  user_group: null | string,
): Promise<{
  success: boolean;
  errors: string[];
}> {
  if (enablePolicyValidation(user_group)) {
    message.loading({
      content: t('Validating') + '...',
      duration: 1,
      key: 'validating',
      icon: <SpinningDots size={16} />,
    });
    const response = await authedFetcher({
      method: 'GET',
      path: 'api2/validate/policy-brazil',
      params: [['policy_id', policyId]],
    });
    return {success: response.validation, errors: response.errors};
  }
  return {success: true, errors: []};
}

// Informational proposal report can be generated at any time before the approval. Once approved, the final proposal
// PDF is generated with a specific uri and saved to policy.attachments.
async function generatePolicy(
  apis: Apis,
  formy: Formy<PolicyForm>,
  policyId: string,
  userGroup: null | string,
  setValidations: React.Dispatch<React.SetStateAction<string[]>>,
) {
  if (!enablePolicyDownload(userGroup)) {
    return;
  }
  message.loading({
    content: apis.t('Downloading') + '...',
    duration: 0,
    key: 'download',
    icon: <SpinningDots size={16} />,
  });
  try {
    const policyReport = await apis.authedFetcher({
      method: 'POST',
      path: `api2/axa-seguros-policy-report`,
      responseType: 'response',
      json_body: {
        policy_id: policyId,
        locale: apis.locale,
      },
    });
    const blob = await policyReport.blob();
    const contentDisposition: undefined | string = policyReport.headers.get('content-disposition');
    const fileNameMatch = contentDisposition?.match(/filename=([^;]+)/);
    const uriMatch = contentDisposition?.match(/uri=([^;]+)/);
    const fileName = fileNameMatch ? fileNameMatch[1] : 'report.pdf';
    if (uriMatch?.length && fileName) {
      formy.getChangeHandler('attachments')([
        ...formy.getValue('attachments'),
        {
          uri: uriMatch[1],
          name: fileName,
          added_on: new Date(apis.clock.now()).toISOString().slice(0, 10),
          mime_type: 'pdf',
        },
      ]);
    }
    message.success({content: apis.t('Done'), duration: 2, key: 'download'});
    saveAs(blob, fileName);
  } catch (e) {
    if (e instanceof BadStatusResponse) {
      setValidations(e.json.errors);
    } else {
      reportErr(e, 'ReportRouter::download');
    }
    message.error({content: apis.t('Error'), duration: 5, key: 'download'});
  }
}

const AddOrEditPolicyForm: React.FC<{
  formy: Formy<PolicyForm>;
  error: null | I18nParametric | I18nSimpleKey;
  setError: React.Dispatch<React.SetStateAction<null | I18nParametric | I18nSimpleKey>>;
}> = ({formy, error, setError}) => {
  const apis = useApis();
  const {t, store, authedFetcher} = apis;
  const [validations, setValidations] = React.useState<string[]>([]);
  const history = useHistory();
  const policyId = useFormyValue(formy, 'policy_id');
  const farmId = useFormyValue(formy, 'farm_id');
  const userGroup = useFormyValue(formy, 'user_group');
  const harvestYear = useFormyValue(formy, 'harvest_year');
  const harvestForms = useFormyValue(formy, 'harvest_forms');
  const customColumns = formy.getSectionFormy('custom_columns');
  const attachments = useFormyValue(formy, 'attachments');
  const brokers = useFormyValue(customColumns, 'brokers');
  const productType = useFormyValue(customColumns.getSectionFormy('mga'), 'product_type');
  const coverageType = useFormyValue(customColumns.getSectionFormy('mga'), 'coverage_type');
  const beneficiaries = useFormyValue(customColumns, 'beneficiaries');
  const availableFarmLevelHarvests = useAsyncMemo(async () => {
    if (!farmId || !harvestYear) {
      return [];
    }
    return await fetchEntitiesBy(authedFetcher, 'harvest', {
      and: [
        {
          column: 'farm_id',
          operator: 'eq',
          value: farmId,
        },
        {
          column: 'harvest_year',
          operator: 'eq',
          value: harvestYear,
        },
      ],
    });
  }, [authedFetcher, farmId, harvestYear]);
  const availableFields = useAsyncMemo(async () => {
    if (!farmId) {
      return [];
    }
    return await fetchEntitiesBy(authedFetcher, 'field', {
      column: 'farm_id',
      operator: 'eq',
      value: farmId,
    });
  }, [authedFetcher, farmId]);
  const availableFieldLevelHarvests = useAsyncMemo(async () => {
    if (!availableFields?.length) {
      return [];
    }
    return await fetchEntitiesBy(authedFetcher, 'harvest', {
      and: [
        {
          column: 'field_id',
          operator: 'in',
          value: availableFields.map(field => field.field_id),
        },
        {
          column: 'harvest_year',
          operator: 'eq',
          value: harvestYear,
        },
      ],
    });
  }, [authedFetcher, availableFields, harvestYear]);

  const handleAddHarvestForm = useCallback(() => {
    const currentHarvestForms = formy.getValue('harvest_forms');
    const updatedHarvestForms: HarvestForm[] = [
      ...currentHarvestForms,
      {
        harvest_id: null,
        insured_percent: null,
        reference_yield: null,
        commodity_price: null,
        premium_rate_percent: null,
        selectedFieldLevelHarvestIds: [],
        coverage_per_area: null,
        custom_columns: new FormHarvestCustomColumns(null),
      },
    ];
    formy.getChangeHandler('harvest_forms')(updatedHarvestForms);
  }, [formy]);

  const handleAddBroker = useCallback(() => {
    const updatedBrokers = [
      ...(brokers ?? []),
      {
        entity: {
          type: null,
          name: null,
          alternative_name: null,
          address: {
            street: null,
            number: null,
            additional_information: null,
            neighborhood: null,
            city: null,
            state: null,
            zip_code: null,
            external_code: null,
            country: null,
          },
          email: null,
          phone: null,
          external_id: null,
          inception_date: null,
          gender: null,
          insured_status: null,
          marital_status: null,
        },
        leader: null,
        registration_number: null,
        percent: null,
      },
    ];
    customColumns.getChangeHandler('brokers')(updatedBrokers);
  }, [customColumns, brokers]);

  const handleDeleteHarvestForm = useCallback(
    (formIdx: number) => {
      const updatedHarvestForms = formy.getValue('harvest_forms').filter((_, index) => index != formIdx);
      formy.getChangeHandler('harvest_forms')(updatedHarvestForms);
    },
    [formy],
  );
  const handleDeleteBroker = useCallback(
    (formIdx: number) => {
      const currentBrokers = (brokers ?? []).filter((_, index) => index != formIdx);
      customColumns.getChangeHandler('brokers')(currentBrokers);
    },
    [customColumns, brokers],
  );

  const handleAddBeneficiary = useCallback(() => {
    const updatedBeneficiaries = [
      ...(beneficiaries ?? []),
      {
        entity: {
          type: null,
          name: null,
          alternative_name: null,
          address: {
            street: null,
            number: null,
            additional_information: null,
            neighborhood: null,
            city: null,
            state: null,
            zip_code: null,
            external_code: null,
            country: null,
          },
          email: null,
          phone: null,
          external_id: null,
          inception_date: null,
          gender: null,
          insured_status: null,
          marital_status: null,
        },
        percent: null,
        registration_number: null,
      },
    ];
    customColumns.getChangeHandler('beneficiaries')(updatedBeneficiaries);
  }, [customColumns, beneficiaries]);

  const handleDeleteBeneficiary = useCallback(
    (formIdx: number) => {
      const currentBrokers = (beneficiaries ?? []).filter((_, index) => index != formIdx);
      customColumns.getChangeHandler('beneficiaries')(currentBrokers);
    },
    [customColumns, beneficiaries],
  );

  formy.watchValue('farm_id', () => {
    formy.getChangeHandler('harvest_forms')([]);
  });
  formy.watchValue('user_group', () => {
    formy.getChangeHandler('farm_id')(null);
  });

  const handleDownloadAction = useCallback(async (): Promise<void> => {
    if (!policyId) {
      throw new Error('Policy ID is missing');
    }
    await generatePolicy(apis, formy, policyId, userGroup, setValidations);
  }, [policyId, apis, formy, setValidations, userGroup]);

  const handleDeletePolicyAction = useCallback(async () => {
    if (policyId) {
      const claims = await fetchEntitiesBy(authedFetcher, 'claim', {
        column: 'policy_id',
        operator: 'eq',
        value: policyId,
      });
      if (claims.length) {
        setError({
          type: 'NoDeletePolicy',
          claimCount: claims.length,
        });
        return;
      }
      store.dispatch(queueDeleteMutation('policy', policyId));
      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    }
  }, [apis, authedFetcher, store, policyId, history, setError]);

  const handleUpdatePolicyStatus = useCallback(
    async (formy: Formy<PolicyForm>, policyId: Policy['policy_id'], policyStatus: PolicyStatus) => {
      store.dispatch(queueUpdateMutation('policy', {status: policyStatus}, policyId));
      formy.getChangeHandler('status')(policyStatus);
      if (!(await commitMutations(apis))) {
        setError('FailedToSyncChanges');
      }
    },
    [apis, store, setError],
  );

  const handleSaveAndApprovePolicy = useCallback(async () => {
    if (!policyId) {
      throw new Error('Policy ID is missing');
    }

    // Save policy first so the up-to-date policy entity is available on the server for validation.
    // I opted for this approach, because the validation code will also run before sending the policy
    // to external APIs (federal subsidy, AXA) and this simplifies the validation logic
    // (e.g. read the policy entity from database and run the validations).
    await savePolicyAndRelatedHarvests(apis, formy.getValues());
    if (!(await commitMutations(apis))) {
      setError('FailedToSyncChanges');
    }
    const {success, errors} = await validatePolicy(t, authedFetcher, policyId, formy.getValues().user_group);
    if (success) {
      await handleUpdatePolicyStatus(formy, policyId, 'quote_accepted');
      await generatePolicy(apis, formy, policyId, userGroup, setValidations);
      formy.setMode('view');
    } else if (errors.length) {
      setValidations(errors);
    }
  }, [t, apis, authedFetcher, formy, policyId, setError, handleUpdatePolicyStatus, userGroup]);

  const insuredCustomColumns = customColumns.getSectionFormy('insured');
  const insuredAddress = insuredCustomColumns.getSectionFormy('address');
  const representative = customColumns.getSectionFormy('representative');
  const brazilPolicy = isBrazilPolicy(userGroup);
  const mode = formy.getMode();
  return (
    <div className={'policies-form'}>
      <PageHeader
        title={policyId ? t('EditPolicy') : t('AddPolicy')}
        className="no-print"
        avatar={{icon: <FormOutlined />}}
      />
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Portfolio')}:</Label>
          <FormyUserGroupSelector formy={formy} field={'user_group'} />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={formy}
            field={'policy_number'}
            placeholder={'PleaseEnterValue'}
            label={'PolicyNumber'}
            disabled={disablePolicyNumberInput(userGroup)}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{t('Farm')}:</Label>
          <FormyEntitySelector field="farm_id" formy={formy} entityType="farm" onNewEntity={null} />
        </Col>
        <Col span={6}>
          <Label>{t('HarvestYear')}:</Label>
          <FormyEnum
            formy={formy}
            field={'harvest_year'}
            selectMsg={'Select'}
            options={HarvestYear.map(harvestYear => {
              return [harvestYear, t(harvestYear)];
            })}
          />
        </Col>
      </Row>
      {brazilPolicy && (
        <Row gutter={16}>
          <Col span={6}>
            <Label>{apis.t('CropCoverage')}:</Label>
            <FormyEnum
              options={getI18nOptions(formy.t, ['basic', 'replanting'])}
              selectMsg="Select"
              formy={formy.getSectionFormy('custom_columns').getSectionFormy('mga')}
              field="coverage_type"
            />
          </Col>
          <Col span={6}>
            <Label>{apis.t('ProductType')}:</Label>
            <FormyEnum
              options={getI18nOptions(formy.t, ['costing', 'productivity'])}
              selectMsg="Select"
              formy={formy.getSectionFormy('custom_columns').getSectionFormy('mga')}
              field="product_type"
            />
          </Col>
        </Row>
      )}
      {farmId && <h3>{t('InsuredHarvests')}</h3>}
      {Object.values(harvestForms).map((harvestForm, harvestFormIndex) => {
        return (
          <HarvestRow
            key={harvestFormIndex + '-' + harvestForm.harvest_id}
            productType={productType}
            harvestFormy={formy.getSectionFormy('harvest_forms').getSectionFormy(harvestFormIndex)}
            deleteHarvestForm={() => handleDeleteHarvestForm(harvestFormIndex)}
            farmLevelHarvests={availableFarmLevelHarvests}
            fieldLevelHarvests={availableFieldLevelHarvests}
            fields={availableFields}
            userGroup={userGroup}
            coverageType={coverageType}
          />
        );
      })}
      {farmId && mode !== 'view' && (
        <Row gutter={16}>
          <Col className={'row-item-margin-top'}>
            <Button type="default" icon={<PlusOutlined />} onClick={handleAddHarvestForm}>
              {t('InsureAnotherHarvest')}
            </Button>
          </Col>
        </Row>
      )}

      <h3>{t('AdditionalInfo')}</h3>
      <Row gutter={16}>
        <Col span={12}>
          <Label>{t('Validity')}:</Label>
          <FormyDateRange formy={customColumns} range_from_field={'valid_from'} range_to_field={'valid_to'} />
        </Col>
      </Row>

      <h3>{t('Insured')}</h3>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={insuredCustomColumns}
            field={'external_id'}
            placeholder={'PleaseEnterValue'}
            label={'DocumentNumber'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={insuredCustomColumns} field={'name'} placeholder={'PleaseEnterValue'} label={'Name'} />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={insuredCustomColumns}
            field={'alternative_name'}
            placeholder={'PleaseEnterValue'}
            label={'AlternativeName'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyDatePicker
            formy={insuredCustomColumns}
            field={'inception_date'}
            label={'BirthDate'}
            placeholder={'PleaseEnterValue'}
          />
        </Col>
        <Col span={6}>
          <Label>{t('Gender')}:</Label>
          <FormyEnum
            formy={insuredCustomColumns}
            field={'gender'}
            selectMsg={null}
            options={Gender.map(gender => [gender, t(gender)])}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={insuredCustomColumns} field={'email'} placeholder={'PleaseEnterValue'} label={'Email'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={insuredCustomColumns} field={'phone'} placeholder={'PleaseEnterValue'} label={'Phone'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={insuredAddress} field={'street'} placeholder={'PleaseEnterValue'} label={'Address'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={insuredAddress} field={'number'} placeholder={'PleaseEnterValue'} label={'Number'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={insuredAddress}
            field={'neighborhood'}
            placeholder={'PleaseEnterValue'}
            label={'Neighbourhood'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={insuredAddress} field={'city'} placeholder={'PleaseEnterValue'} label={'City'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={insuredAddress} field={'state'} placeholder={'PleaseEnterValue'} label={'State'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={insuredAddress} field={'zip_code'} placeholder={'PleaseEnterValue'} label={'ZipCode'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={insuredAddress}
            field={'external_code'}
            placeholder={'PleaseEnterValue'}
            label={'ExternalCode'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={insuredAddress}
            field={'additional_information'}
            placeholder={'PleaseEnterValue'}
            label={'AdditionalInfo'}
          />
        </Col>
      </Row>

      <h3>{t('FarmerRepresentative')}</h3>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={representative}
            field={'external_id'}
            placeholder={'PleaseEnterValue'}
            label={'DocumentNumber'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={representative} field={'name'} placeholder={'PleaseEnterValue'} label={'Name'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={representative} field={'phone'} placeholder={'PleaseEnterValue'} label={'Phone'} />
        </Col>
      </Row>

      {brazilPolicy && <AddOrEditPolicyBrazil formy={formy} />}

      <h3>{t('Brokers')}</h3>

      {Object.values(brokers ?? []).map((_, index) => {
        const brokerSection = customColumns.getSectionFormy('brokers');
        return (
          <BrokerRow
            key={'broker-' + index}
            formy={brokerSection.getSectionFormy(index)}
            deleteBroker={() => handleDeleteBroker(index)}
          />
        );
      })}
      {mode !== 'view' && (
        <Row gutter={16}>
          <Col className={'row-item-margin-top'}>
            <Button type="default" icon={<PlusOutlined />} onClick={handleAddBroker}>
              {t('AddBroker')}
            </Button>
          </Col>
        </Row>
      )}

      <h3>{t('Beneficiaries')}</h3>
      {Object.values(beneficiaries ?? []).map((_, index) => {
        const beneficiariesSection = customColumns.getSectionFormy('beneficiaries');
        return (
          <BeneficiaryRow
            key={'beneficiary-' + index}
            formy={beneficiariesSection.getSectionFormy(index)}
            deleteBeneficiary={() => handleDeleteBeneficiary(index)}
          />
        );
      })}
      {mode !== 'view' && (
        <Row gutter={16}>
          <Col className={'row-item-margin-top'}>
            <Button type="default" icon={<PlusOutlined />} onClick={handleAddBeneficiary}>
              {t('AddBeneficiary')}
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={formy} field={'comments'} placeholder={'PleaseEnterValue'} label={'Comments'} />
        </Col>
      </Row>

      {validations.length > 0 && (
        <div style={errorStyle}>
          {validations.map((validation, idx) => (
            <div key={`error-${idx}`}>{validation}</div>
          ))}
        </div>
      )}
      {attachments.length > 0 && <h3>{t('Attachments')}</h3>}
      <Row gutter={16}>
        {attachments.map(file => (
          <FileAttachment key={file.uri} file={file} />
        ))}
      </Row>
      <div className="add-farms-buttons">
        <Button type="default" icon={<LeftOutlined />} onClick={() => history.goBack()}>
          {t('Back')}
        </Button>
        {mode !== 'view' && (
          <FormySubmit
            type="primary"
            icon={<SaveOutlined />}
            formy={formy}
            label="Save"
            doNotConfirmSubmissionToUser={true}
          />
        )}
        {policyId && mode !== 'view' && (
          <>
            <Button icon={<CheckOutlined />} onClick={handleSaveAndApprovePolicy}>
              {t('SaveApprove')}
            </Button>
            <Button
              icon={<CloseOutlined />}
              onClick={() => handleUpdatePolicyStatus(formy, policyId, 'quote_rejected')}>
              {t('Reject')}
            </Button>
            {enablePolicyDownload(userGroup) && (
              <Button icon={<DownloadOutlined />} onClick={handleDownloadAction}>
                {apis.t('Download')}
              </Button>
            )}
            {deletePoliciesEnabled(userGroup) && (
              <Button icon={<DeleteOutlined />} onClick={handleDeletePolicyAction}>
                {t('Delete')}
              </Button>
            )}
          </>
        )}
      </div>
      <div style={errorStyle}>{error && t(error)}</div>
    </div>
  );
};

interface HarvestRowProps {
  productType: null | ProductType;
  harvestFormy: FormyI<HarvestForm>;
  deleteHarvestForm: () => void;
  farmLevelHarvests?: Harvest[];
  // HarvestRow assumes that there is a field in the fields prop for each fieldLevelHarvest in fieldLevelHarvests prop.
  fieldLevelHarvests?: Harvest[];
  fields?: Field[];
  userGroup: null | string;
  coverageType: null | CoverageType;
}

const harvestFormStyle = (borderColor: string) => {
  return {
    borderLeft: `5px solid ${borderColor}`,
    marginTop: '0.5rem',
    borderRadius: '0.25rem',
  };
};
const marginLeft20 = {marginLeft: '20px'};

const HarvestRow: React.FC<HarvestRowProps> = ({
  harvestFormy,
  farmLevelHarvests,
  deleteHarvestForm,
  fieldLevelHarvests,
  fields,
  productType,
  userGroup,
  coverageType,
}) => {
  const {t, store} = useApis();
  const allCrops: IndexedCrops = useSelector((state: State) => state.crops.crops);
  const countryGroups: string[] = useSelector(getCountryCodeGroups);
  const harvest_id = useFormyValue(harvestFormy, 'harvest_id');
  const harvest = useMemo(
    () => farmLevelHarvests?.find(harvest => harvest.harvest_id === harvest_id),
    [harvest_id, farmLevelHarvests],
  );
  const borderColor = CROP_COLORS[getBaseCrop(allCrops, harvest?.crop_id)!] ?? 'transparent';
  const commodityPrice = useFormyValue(harvestFormy, 'commodity_price');
  const premiumRatePercent = useFormyValue(harvestFormy, 'premium_rate_percent');
  const coveragePerArea = useFormyValue(harvestFormy, 'coverage_per_area');
  const selectedFieldLevelHarvestIds = useFormyValue(harvestFormy, 'selectedFieldLevelHarvestIds');
  // Note that this fields harvests area calculation and the following policy calculations will only work,
  // if field-level harvests already exists for the selected farm (and can thus be selected on the user interface and stored
  // in formy's selectedFieldLevelHarvestIds). Currently, the feature is only for MGA, for which the field-level
  // harvests should already exist. Re-discuss this when the feature is open for other customers.
  const selectedFieldsHarvestAreaHa = useMemo(
    () =>
      selectedFieldLevelHarvestIds
        .map(id => {
          const {harvest_area, field_id} = fieldLevelHarvests?.find(h => h.harvest_id === id) ?? {};
          return harvest_area ?? fields?.find(f => f.field_id === field_id)?.field_area ?? null;
        })
        .map(area => convertArea('hectares', area)?.val ?? null)
        .reduce(...aggregate.sum) ?? 0,
    [selectedFieldLevelHarvestIds, fields, fieldLevelHarvests],
  );
  const monetaryUnit = deriveCurrencyFromUnitPrice(commodityPrice?.unit);
  const sumInsured = calculateBasicSumInsured(coveragePerArea, selectedFieldsHarvestAreaHa);
  const premium = calculateBasicPremium(sumInsured, premiumRatePercent ?? 0);
  const fieldLevelHarvestOptions = useMemo((): [string, string][] => {
    return (fieldLevelHarvests ?? [])
      .filter(fieldLevelHarvest => harvestAggregationKeyEq(fieldLevelHarvest, harvest))
      .map(fieldLevelHarvest => {
        const field = fields?.find(field => field.field_id === fieldLevelHarvest.field_id);
        if (!field) {
          reportErr(
            `Field level harvest (${fieldLevelHarvest.harvest_id}) has no matching field!`,
            'AddOrEditPolicy-HarvestRow',
          );
          return null;
        }
        return [
          fieldLevelHarvest.harvest_id,
          fieldDesc(t, allCrops, [], field, fieldLevelHarvest, null, null, false, true, true),
        ];
      })
      .filter((option): option is [string, string] => option !== null);
  }, [fieldLevelHarvests, fields, harvest, allCrops, t]);
  // If the policy's product type is 'costing', then the sum insured is calculated by entering coverage per area.
  // When the product type is 'productivity', then the sum insured is calculated by entering commodity price.
  // In both cases we calculate the value and unit that was not entered.
  useEffect(() => {
    const listener: FormyEventListener<HarvestForm> = (_, values) => {
      const insuredYield = (values.reference_yield?.val ?? 0) * ((values.insured_percent ?? 0) / 100);
      if (productType === 'costing') {
        harvestFormy.getChangeHandler('commodity_price')(
          calculateCommodityPrice(values.reference_yield, values.coverage_per_area, insuredYield),
        );
      } else if (productType === 'productivity') {
        harvestFormy.getChangeHandler('coverage_per_area')(
          calculateCoveragePerArea(values.reference_yield, values.commodity_price, insuredYield),
        );
      }
    };
    harvestFormy.addOnChangeListener(listener);
    return () => harvestFormy.removeOnChangeListener(listener);
  }, [harvestFormy, productType]);
  const unitSystem = getUnitSystemForUserGroup(store.getState(), userGroup);
  const baseCrop = getBaseCrop(allCrops, harvest?.crop_id);
  const customColumns = harvestFormy.getSectionFormy('custom_columns');
  const mgaHarvestCustomColumns = customColumns.getSectionFormy('mga');
  const replantingPremiumRatePercent = useFormyValue(mgaHarvestCustomColumns, 'replanting_premium_rate_percent');
  const translatedMonetaryUnit = monetaryUnit ? t(monetaryUnit) : null;
  const replantingSumInsured = calculateReplantingSumInsured(sumInsured);
  const replantingPremium = calculateReplantingPremium(replantingSumInsured, (replantingPremiumRatePercent ?? 0) / 100);
  return (
    <div style={harvestFormStyle(borderColor)}>
      <Row gutter={16}>
        <Col span={12}>
          <Label>{t('InsuredHarvest')}:</Label>
          <FormyEnum
            required
            formy={harvestFormy}
            field={'harvest_id'}
            options={
              farmLevelHarvests?.map(harvest => {
                return [harvest.harvest_id, harvestDesc(t, allCrops, harvest, null, countryGroups, null, true)];
              }) ?? []
            }
            selectMsg={'Select'}
          />
        </Col>
        <Col span={2}>
          <Button
            className="row-item-margin-top"
            icon={<DeleteOutlined />}
            onClick={deleteHarvestForm}
            size="small"
            danger
            type="ghost"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8} style={marginLeft20}>
          <Label>{t('InsuredFields')}:</Label>
          <FormyMultiEnum
            style={maxWidthResponsiveHeight}
            formy={harvestFormy}
            maxTagCount={20}
            field={'selectedFieldLevelHarvestIds'}
            selectMsg={'Select'}
            options={fieldLevelHarvestOptions}
          />
        </Col>
      </Row>
      <h3 style={marginLeft20}>{t('BasicCoverage')}</h3>
      <Row gutter={16}>
        <Col span={4} style={marginLeft20}>
          <Label>{t('CommodityPrice')}:</Label>
          <FormyUnit
            inline={true}
            formy={harvestFormy}
            units={[unitSystem.priceUnit]}
            field={'commodity_price'}
            disabled={productType === 'costing'}
            alwaysShowUnits={true}
          />
        </Col>
        <Col span={4}>
          <FormyTextNum formy={harvestFormy} field={'insured_percent'} min={0} max={100} label={'InsuredPercent'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4} style={marginLeft20}>
          <Label>{t('ReferenceYield')}:</Label>
          <FormyUnit
            units={getYieldUnits(unitSystem, baseCrop)}
            alwaysShowUnits={true}
            inline={true}
            formy={harvestFormy}
            field={'reference_yield'}
          />
        </Col>
        <Col span={4}>
          <FormyTextNum
            formy={harvestFormy}
            field={'premium_rate_percent'}
            min={0}
            max={100}
            label={'PremiumRatePercent'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4} style={marginLeft20}>
          <Label>{t('CoveragePerArea')}:</Label>
          <FormyUnit
            inline={true}
            units={[unitSystem.priceAreaUnit]}
            formy={harvestFormy}
            field={'coverage_per_area'}
            disabled={productType === 'productivity'}
            alwaysShowUnits={true}
          />
        </Col>
        <Col span={2}>
          <Label>{t('SumInsured')}:</Label>
          <div>
            {sumInsured.toFixed(2)} {translatedMonetaryUnit}
          </div>
        </Col>
        <Col span={2}>
          <Label>{t('Premium')}:</Label>
          <div>
            {premium.toFixed(2)} {translatedMonetaryUnit}
          </div>
        </Col>
      </Row>
      {coverageType === 'replanting' && (
        <>
          <h3 style={marginLeft20}>{t('ReplantingCoverage')}</h3>
          <Row gutter={16}>
            <Col span={4} style={marginLeft20}>
              <FormyTextNum
                formy={mgaHarvestCustomColumns}
                field={'replanting_premium_rate_percent'}
                min={0}
                max={100}
                label={'PremiumRatePercent'}
              />
            </Col>
            <Col span={2}>
              <Label>{t('SumInsured')}:</Label>
              <div>
                {replantingSumInsured.toFixed(2)} {translatedMonetaryUnit}
              </div>
            </Col>
            <Col span={2}>
              <Label>{t('Premium')}:</Label>
              <div>
                {replantingPremium.toFixed(2)} {translatedMonetaryUnit}
              </div>
            </Col>
          </Row>
          <h3 style={marginLeft20}>{t('Total')}</h3>
          <Row gutter={16}>
            <Col span={4} style={marginLeft20}>
              <Label>{t('SumInsured')}:</Label>
              <div>
                {sumInsured.toFixed(2)} {translatedMonetaryUnit}
              </div>
            </Col>
            <Col span={4}>
              <Label>{t('Premium')}:</Label>
              <div>
                <div>
                  {(premium + replantingPremium).toFixed(2)} {translatedMonetaryUnit}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

interface BrokerRowProps {
  formy: FormyI<FormBroker>;
  deleteBroker: () => void;
}

const BrokerRow: React.FC<BrokerRowProps> = ({formy, deleteBroker}) => {
  const entity = formy.getSectionFormy('entity');
  const address = entity.getSectionFormy('address');
  return (
    <div style={harvestFormStyle('gray')}>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={entity} field={'name'} label={'Name'} placeholder={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={entity}
            field={'alternative_name'}
            label={'AlternativeName'}
            placeholder={'PleaseEnterValue'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={entity} field={'email'} label={'Email'} placeholder={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={entity} field={'phone'} label={'Phone'} placeholder={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={address} field={'street'} placeholder={'PleaseEnterValue'} label={'Address'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={address} field={'number'} placeholder={'PleaseEnterValue'} label={'Number'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={address}
            field={'neighborhood'}
            placeholder={'PleaseEnterValue'}
            label={'Neighbourhood'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={address} field={'city'} placeholder={'PleaseEnterValue'} label={'City'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={address} field={'state'} placeholder={'PleaseEnterValue'} label={'State'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={address} field={'zip_code'} placeholder={'PleaseEnterValue'} label={'ZipCode'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={address}
            field={'external_code'}
            placeholder={'PleaseEnterValue'}
            label={'ExternalCode'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={address}
            field={'additional_information'}
            placeholder={'PleaseEnterValue'}
            label={'AdditionalInfo'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextNum formy={formy} field={'percent'} min={0} max={100} label={'Percent'} />
        </Col>
        <Col span={6}>
          <FormyBool formy={formy} field={'leader'} selectMsg={null} label={'IsBrokerLeader?'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={entity}
            field={'external_id'}
            label={'DocumentNumber'}
            placeholder={'PleaseEnterValue'}
          />
        </Col>
        {formy.getMode() !== 'view' && (
          <Col span={2}>
            <Button
              className="row-item-margin-top"
              icon={<DeleteOutlined />}
              onClick={deleteBroker}
              size="small"
              danger
              type="ghost"
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

interface BeneficiaryRowProps {
  formy: FormyI<FormBeneficiary>;
  deleteBeneficiary: () => void;
}

const BeneficiaryRow: React.FC<BeneficiaryRowProps> = ({formy, deleteBeneficiary}) => {
  const entity = formy.getSectionFormy('entity');
  const address = entity.getSectionFormy('address');
  return (
    <div style={harvestFormStyle('gray')}>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={entity} field={'name'} label={'Name'} placeholder={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={entity}
            field={'alternative_name'}
            label={'AlternativeName'}
            placeholder={'PleaseEnterValue'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={entity} field={'email'} placeholder={'PleaseEnterValue'} label={'Email'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={entity} field={'phone'} placeholder={'PleaseEnterValue'} label={'Phone'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={address} field={'street'} placeholder={'PleaseEnterValue'} label={'Address'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={address} field={'number'} placeholder={'PleaseEnterValue'} label={'Number'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={address}
            field={'neighborhood'}
            placeholder={'PleaseEnterValue'}
            label={'Neighbourhood'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={address} field={'city'} placeholder={'PleaseEnterValue'} label={'City'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr formy={address} field={'state'} placeholder={'PleaseEnterValue'} label={'State'} />
        </Col>
        <Col span={6}>
          <FormyTextStr formy={address} field={'zip_code'} placeholder={'PleaseEnterValue'} label={'ZipCode'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={address}
            field={'external_code'}
            placeholder={'PleaseEnterValue'}
            label={'ExternalCode'}
          />
        </Col>
        <Col span={6}>
          <FormyTextStr
            formy={address}
            field={'additional_information'}
            placeholder={'PleaseEnterValue'}
            label={'AdditionalInfo'}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <FormyTextStr
            formy={entity}
            field={'external_id'}
            placeholder={'PleaseEnterValue'}
            label={'DocumentNumber'}
          />
        </Col>
        <Col span={6}>
          <FormyTextNum formy={formy} field={'percent'} min={0} max={100} label={'Percent'} />
        </Col>
        {formy.getMode() !== 'view' && (
          <Col span={2}>
            <Button
              className="row-item-margin-top"
              icon={<DeleteOutlined />}
              onClick={deleteBeneficiary}
              size="small"
              danger
              type="ghost"
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
