import {defaultMemoize} from 'reselect';
import {UnreachableCaseError} from 'ts-essentials';
import {I18nFunction, I18nSimpleKey} from '../i18n/i18n';
import {LayerParams} from '../layers/layer-params';
import {Expression, MapLayers} from '../layers/map-layers';
import {MonitoredCrop} from '../models/crop-mon';
import {HarvestCrop} from '../models/interfaces';
import {InteryieldCropIds, interyieldYieldThresholds} from '../models/interyield';
import {IndexedCrops} from '../redux/reducers/crops';
import {getBaseCrop} from '../selectors/crops';
import {UnitSystem, convertYield, metricUnitSystem} from '../selectors/units';
import {yieldRangeTHa} from '../yield-meta';

export const PALETTE_COLORS = {
  primary: '#6bc19b',
  secondary: '#fbad30',
  baseGrey: '#a2a2a2',
} as const;

export const MAPBOX_BACKGROUND_GREY = '#dedede';
export const FIELD_BACKGROUND_GREY = '#d7d7d7';

const HarvestCropGroup = [
  'cereals',
  'corn',
  'oilseeds',
  'root-crops',
  'soybeans',
  'grapes',
  'other-perennial-fruits',
  'grassland',
  'pulses',
  'vegetables',
  'remaining-crops',
  'unknown',
] as const;
type HarvestCropGroup = (typeof HarvestCropGroup)[number];

export const CROP_GROUP_COLORS: {[P in HarvestCropGroup]: string} = {
  cereals: '#f5deb2',
  corn: '#008000',
  oilseeds: '#eadd04',
  'root-crops': '#9f9f5f',
  soybeans: '#e0d67d',
  grapes: '#741b47',
  'other-perennial-fruits': '#d94130',
  grassland: '#b0ce56',
  pulses: '#9f9f5f',
  vegetables: '#f0e6d3',
  'remaining-crops': '#e0ffff',
  unknown: '#ffffff',
};

const CROP_GROUPS: {[P in HarvestCrop]: HarvestCropGroup} = {
  // Cereals
  wheat: 'cereals',
  'wheat-hard': 'cereals',
  barley: 'cereals',
  'barley-malting': 'cereals',
  sorghum: 'cereals',
  'sorghum-silage': 'cereals',
  rye: 'cereals',
  triticale: 'cereals',
  oats: 'cereals',
  millet: 'cereals',
  spelt: 'cereals',
  rice: 'cereals',
  buckwheat: 'cereals',
  'mixed-cereal': 'cereals',
  // Corn
  'corn-grain': 'corn',
  'corn-silage': 'corn',
  'corn-seeds': 'corn',
  // Oilseed
  rapeseed: 'oilseeds',
  sunflower: 'oilseeds',
  'sunflower-seeds': 'oilseeds',
  // Root crops
  'sugar-beet': 'root-crops',
  potatoes: 'root-crops',
  // Soybeans
  soybeans: 'soybeans',
  // Grapes
  grapes: 'grapes',
  // Orchards
  apples: 'other-perennial-fruits',
  pears: 'other-perennial-fruits',
  peaches: 'other-perennial-fruits',
  apricots: 'other-perennial-fruits',
  nectarines: 'other-perennial-fruits',
  cherries: 'other-perennial-fruits',
  mangos: 'other-perennial-fruits',
  'other-perennial-fruits': 'other-perennial-fruits',
  // Grasslands
  grassland: 'grassland',
  // Pulses
  beans: 'pulses',
  lentils: 'pulses',
  chickpeas: 'pulses',
  peas: 'pulses',
  // Vegetables
  cauliflowers: 'vegetables',
  onions: 'vegetables',
  tomatoes: 'vegetables',
  'other-vegetables': 'vegetables',
  // Remaining crops
  linseed: 'remaining-crops',
  cotton: 'remaining-crops',
  'sugar-cane': 'remaining-crops',
  olives: 'remaining-crops',
  mustard: 'remaining-crops',
  tobacco: 'remaining-crops',
  hemp: 'remaining-crops',
  peanuts: 'remaining-crops',
  walnuts: 'remaining-crops',
  // Unknown
  unknown: 'unknown',
};
export const CROP_COLORS = Object.fromEntries(
  Object.entries(CROP_GROUPS).map((x, _y) => [x[0], CROP_GROUP_COLORS[x[1]]]),
);

export type AnomalyZones = 'b1' | 'b2' | 'b3' | 'g1' | 'g2' | 'g3' | 'na' | 'normal';
export const VEGETATION_COLORS: {[P in AnomalyZones]: string} = {
  g3: '#69ba91',
  g2: '#b0ce55',
  g1: '#cde7ac',
  normal: MAPBOX_BACKGROUND_GREY,
  b1: '#edc235',
  b2: '#eda135',
  b3: '#dd2403',
  na: '#ffffff',
};

export const VEGETATION_ZONE_TO_NAME: {[P in AnomalyZones]: I18nSimpleKey} = {
  g3: 'HighestVegetation',
  g2: 'HigherVegetation',
  g1: 'HighVegetation',
  normal: 'NormalVegetation',
  b1: 'LowVegetation',
  b2: 'LowerVegetation',
  b3: 'LowestVegetation',
  na: 'NotAvailable',
};

export const VEGETATION_OPACITY: {[P in AnomalyZones]: number} = {
  b1: 0.5,
  b2: 0.5,
  b3: 0.5,
  normal: 0.5,
  g1: 0.5,
  g2: 0.5,
  g3: 0.5,
  na: 0.7,
};

export const MIN_FIELD_LAYER_ZOOM = 12;
export const MIN_SAMPLE_LAYER_ZOOM = 15;
export const FIELDS_ONLY_ZOOM = 8;
export const MIN_ENTITY_FEATURES_ZOOM = MIN_FIELD_LAYER_ZOOM - 1;
export const MIN_PLANET_TILES_ZOOM = 12;

export type IntrafieldZones = 'cloud' | 'high-med' | 'high' | 'low-med' | 'low' | 'medium' | 'w';
export const INTRAFIELD_COLORS: {[P in IntrafieldZones]: string} = {
  low: '#FAAD30',
  'low-med': '#F2E228',
  medium: '#FFFFFF',
  'high-med': '#B7D752',
  high: '#6BC19B',
  w: '#0F8DDB',
  cloud: '#a2a2a2',
};

export const INTRAFIELD_OPACITY: {[P in IntrafieldZones]: number} = {
  low: 1.0,
  'low-med': 1.0,
  medium: 1.0,
  'high-med': 1.0,
  high: 1.0,
  cloud: 1.0,
  w: 1.0,
};

export const INTRAFIELD_ZONE_TO_NAME: {[P in IntrafieldZones]: I18nSimpleKey} = {
  low: 'Lowest',
  'low-med': 'Lower',
  medium: 'Median',
  'high-med': 'Higher',
  high: 'Highest',
  cloud: 'CloudCover',
  w: 'flood',
};

export type InterfieldZones = 'a' | 'b' | 'c' | 'cloud' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'w';
export const INTERFIELD_COLORS: {[P in InterfieldZones]: string} = {
  a: '#FF250F',
  b: '#EA7214',
  c: '#F7C705',
  d: '#F5F900',
  e: '#D6E80D',
  f: '#96B029',
  g: '#639902',
  h: '#30770F',
  i: '#026103',
  w: '#0F8DDB',
  cloud: '#a2a2a2',
};

export const INTERFIELD_OPACITY: {[P in InterfieldZones]: number} = {
  a: 1.0,
  b: 1.0,
  c: 1.0,
  d: 1.0,
  e: 1.0,
  f: 1.0,
  g: 1.0,
  h: 1.0,
  i: 1.0,
  cloud: 1.0,
  w: 1.0,
};

export const INTERFIELD_ZONE_TO_NAME: {[P in InterfieldZones]: I18nSimpleKey} = {
  a: 'interfield-a',
  b: 'interfield-b',
  c: 'interfield-c',
  d: 'interfield-d',
  e: 'interfield-e',
  f: 'interfield-f',
  g: 'interfield-g',
  h: 'interfield-h',
  i: 'interfield-i',
  cloud: 'CloudCover',
  w: 'flood',
};

export type InteryieldZones = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'na';
export const INTERYIELD_COLORS: {[P in InteryieldZones]: string} = {
  a: '#FF250F',
  b: '#EA7214',
  c: '#F7C705',
  d: '#F5F900',
  e: '#D6E80D',
  f: '#96B029',
  g: '#639902',
  h: '#30770F',
  i: '#026103',
  na: '#a2a2a2',
};

export const INTERYIELD_OPACITY: {[P in InteryieldZones]: number} = {
  a: 1.0,
  b: 1.0,
  c: 1.0,
  d: 1.0,
  e: 1.0,
  f: 1.0,
  g: 1.0,
  h: 1.0,
  i: 1.0,
  na: 1.0,
};

export const INTERYIELD_ZONE_TO_NAME: {[P in InteryieldZones]: I18nSimpleKey} = {
  a: 'interyield-a',
  b: 'interyield-b',
  c: 'interyield-c',
  d: 'interyield-d',
  e: 'interyield-e',
  f: 'interyield-f',
  g: 'interyield-g',
  h: 'interyield-h',
  i: 'interyield-i',
  na: 'NotAvailable',
};

export const SOIL_MOISTURE_COLORS: {[P in Exclude<AnomalyZones, 'na'>]: string} = {
  g3: '#0f8ddb',
  g2: '#2e90ad',
  g1: '#ace3e7',
  normal: MAPBOX_BACKGROUND_GREY,
  b1: '#fcc378',
  b2: '#f9833e',
  b3: '#dd2403',
};

export const SOIL_MOISTURE_ZONE_TO_NAME: {[P in Exclude<AnomalyZones, 'na'>]: I18nSimpleKey} = {
  g3: 'HighestSoilMoisture',
  g2: 'HigherSoilMoisture',
  g1: 'HighSoilMoisture',
  normal: 'NormalSoilMoisture',
  b1: 'LowSoilMoisture',
  b2: 'LowerSoilMoisture',
  b3: 'LowestSoilMoisture',
};

export const SOIL_MOISTURE_OPACITY: {[P in Exclude<AnomalyZones, 'na'>]: number} = {
  b1: 0.5,
  b2: 0.5,
  b3: 0.5,
  normal: 0.5,
  g1: 0.5,
  g2: 0.5,
  g3: 0.5,
};

export type HailZones = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'no-risk';
export const HAIL_COLORS: {[P in HailZones]: string} = {
  a: '#fee5d9',
  b: '#fcbba1',
  c: '#fc9272',
  d: '#fb6a4a',
  e: '#ef3b2c',
  f: '#cb181d',
  g: '#99000d',
  'no-risk': MAPBOX_BACKGROUND_GREY,
};

export const HAIL_OPACITY: {[P in HailZones]: number} = {
  a: 0.7,
  b: 0.7,
  c: 0.7,
  d: 0.7,
  e: 0.7,
  f: 0.7,
  g: 0.7,
  'no-risk': 0.7,
};

export const HAIL_ZONE_TO_NAME: {[P in HailZones]: I18nSimpleKey} = {
  a: 'LowHailRisk',
  b: 'LowHailRisk',
  c: 'ModerateHailRisk',
  d: 'ModerateHailRisk',
  e: 'HighHailRisk',
  f: 'HighHailRisk',
  g: 'SevereHailRisk',
  'no-risk': 'NoHailRisk',
};

type TemperatureZones = 'b4' | 'g4' | 'mixed' | AnomalyZones;

export const TEMPERATURE_COLORS: {[P in TemperatureZones]: string} = {
  g4: '#600202',
  g3: '#900000',
  g2: '#CC0000',
  g1: '#EA9999',
  mixed: '#674ea7',
  normal: MAPBOX_BACKGROUND_GREY,
  b1: '#A3C2F4',
  b2: '#3B78D7',
  b3: '#1300FF',
  b4: '#07015B',
  na: '#ffffff',
};

export const TEMPERATURE_OPACITY: {[P in TemperatureZones]: number} = {
  b4: 0.5,
  b3: 0.5,
  b2: 0.5,
  b1: 0.5,
  mixed: 0.5,
  normal: 0.5,
  g1: 0.5,
  g2: 0.5,
  g3: 0.5,
  g4: 0.5,
  na: 0.7,
};

export const TEMPERATURE_ZONE_TO_NAME: {[P in TemperatureZones]: I18nSimpleKey} = {
  g4: 'ExtremelyHighTemperature',
  g3: 'HighestTemperature',
  g2: 'HigherTemperature',
  g1: 'HighTemperature',
  mixed: 'MixedTemperature',
  normal: 'NormalTemperature',
  b1: 'LowTemperature',
  b2: 'LowerTemperature',
  b3: 'LowestTemperature',
  b4: 'ExtremelyLowTemperature',
  na: 'NotAvailable',
};

export type PrecipitationZones = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'no-risk';
export const PRECIPITATION_COLORS: {[P in PrecipitationZones]: string} = {
  'no-risk': MAPBOX_BACKGROUND_GREY,
  a: '#a3c2f4',
  b: '#6baed6',
  c: '#4292c6',
  d: '#2171b5',
  e: '#08519c',
  f: '#08306b',
  g: '#041835',
};

export const PRECIPITATION_OPACITY: {[P in PrecipitationZones]: number} = {
  'no-risk': 0.7,
  a: 0.7,
  b: 0.7,
  c: 0.7,
  d: 0.7,
  e: 0.7,
  f: 0.7,
  g: 0.7,
};

export const PRECIPITATION_ZONE_TO_NAME: {[P in PrecipitationZones]: I18nSimpleKey} = {
  'no-risk': 'NoPrecipitation',
  a: 'VeryLowPrecipitation',
  b: 'LowPrecipitation',
  c: 'ModeratePrecipitation',
  d: 'HighPrecipitation',
  e: 'VeryHighPrecipitation',
  f: 'SeverePrecipitation',
  g: 'ExtremePrecipitation',
};

export type RainstormZones = 'a' | 'b' | 'c' | 'd' | 'no-risk';
export const RAINSTORM_COLORS: {[P in RainstormZones]: string} = {
  'no-risk': MAPBOX_BACKGROUND_GREY,
  a: '#fdae61',
  b: '#f46d43',
  c: '#d73027',
  d: '#a50026',
};

export const RAINSTORM_OPACITY: {[P in RainstormZones]: number} = {
  'no-risk': 0.7,
  a: 0.7,
  b: 0.7,
  c: 0.7,
  d: 0.7,
};

export const RAINSTORM_ZONE_TO_NAME: {[P in RainstormZones]: I18nSimpleKey} = {
  'no-risk': 'NoRainstorm',
  a: 'RainstormLevel1',
  b: 'RainstormLevel2',
  c: 'RainstormLevel3',
  d: 'RainstormLevel4',
};

export type WindZones = 'a' | 'b' | 'c' | 'd' | 'no-risk';
export const WIND_COLORS: {[P in WindZones]: string} = {
  'no-risk': MAPBOX_BACKGROUND_GREY,
  a: '#bcbddc',
  b: '#807dba',
  c: '#6a51a3',
  d: '#3f007d',
};

export const WIND_OPACITY: {[P in WindZones]: number} = {
  'no-risk': 0.7,
  a: 0.7,
  b: 0.7,
  c: 0.7,
  d: 0.7,
};

export const WIND_ZONE_TO_NAME: {[P in WindZones]: I18nSimpleKey} = {
  'no-risk': 'NoWind',
  a: 'LowWind',
  b: 'ModerateWind',
  c: 'HighPWind',
  d: 'SevereWind',
};

export const MDA_CUSTOM_A_COLOR = '#ffffff';

export const MDA_CUSTOM_A_OPACITY = [
  'interpolate',
  ['exponential', 1.06],
  ['zoom'],
  // When zoom is 0, zones will be opaque.
  0,
  1,
  // When zoom is 22 or higher, zones be translucent (0.2).
  22,
  0.2,
] as Expression;

export function getZoneNameColors<Z extends {[k: string]: string}, Zk extends keyof Z>(
  t: I18nFunction,
  zoneColors: Z,
  zoneOpacities: {[P in Zk]?: number},
  zoneNames: {[P in Zk]?: I18nSimpleKey},
): [string, string, number][] {
  const res: [string, string, number][] = [];
  for (const zone_ in zoneColors) {
    const zone = zone_ as unknown as Zk;
    const zoneColor = zoneColors[zone]!;
    const zoneOpacity = zoneOpacities[zone]!;
    if (zone_ in zoneNames) {
      const zoneName: undefined | I18nSimpleKey = zoneNames[zone];
      if (zoneName) {
        res.push([t(zoneName), zoneColor, zoneOpacity]);
      }
    }
  }

  return res;
}

function rgbToHex(color: string): string {
  return color.length == 1 ? '0' + color : color;
}

export function blendColors(color1: string, color2: string, ratio: number): string {
  if (ratio == 1) return color1;
  if (ratio == 0) return color2;

  const r = Math.ceil(
    parseInt(color1.substring(1, 3), 16) * ratio + parseInt(color2.substring(1, 3), 16) * (1 - ratio),
  );
  const g = Math.ceil(
    parseInt(color1.substring(3, 5), 16) * ratio + parseInt(color2.substring(3, 5), 16) * (1 - ratio),
  );
  const b = Math.ceil(
    parseInt(color1.substring(5, 7), 16) * ratio + parseInt(color2.substring(5, 7), 16) * (1 - ratio),
  );

  return '#' + rgbToHex(r.toString(16)) + rgbToHex(g.toString(16)) + rgbToHex(b.toString(16));
}

export function getCropMonItems(
  t: I18nFunction,
  layerParams: null | LayerParams,
  units: null | UnitSystem,
): [string, string, number][] {
  if (!layerParams || layerParams.layer_type != 'crop-mon') {
    return [];
  }

  const [, type, harvest_crop] = layerParams.params;
  switch (type) {
    case 'benchmark-yield':
      if (harvest_crop in benchmarkYieldColors) {
        return getIntervalColorNames(benchmarkYieldColors[harvest_crop as MonitoredCrop], x => {
          const intervalYield = convertYield(
            units?.yieldUnit ?? metricUnitSystem.yieldUnit,
            {
              val: x,
              unit: 'tons-per-hectare',
            },
            harvest_crop,
          );
          return intervalYield ? t({type: 'ValueUnit', ...intervalYield}) : '';
        });
      } else {
        return [];
      }
    case 'expected-loss':
      return getIntervalColorNames(expectedLossColors, x => x + '%');
    case 'predicted-yield':
    case 'historical-yield':
      return getIntervalColorNames(yieldRatioColors, x => x + '%');
    default:
      return [];
  }
}

export function getLegendItems(
  t: I18nFunction,
  layer: null | MapLayers,
  layerParams: null | LayerParams,
  units: null | UnitSystem,
): null | [string, string, number][] {
  // returns [name, color, opacity]
  switch (layer) {
    case 'base':
    case 'satellite':
      return Object.entries(CROP_GROUP_COLORS).map(([k, v]) => [t(k as HarvestCropGroup), v!, 1.0]);
    case 'vegetation':
      return getZoneNameColors(t, VEGETATION_COLORS, VEGETATION_OPACITY, VEGETATION_ZONE_TO_NAME);
    case 'soil-moisture':
      return getZoneNameColors(t, SOIL_MOISTURE_COLORS, SOIL_MOISTURE_OPACITY, SOIL_MOISTURE_ZONE_TO_NAME).concat([
        [t('flood-risk'), 'diagonal-stripes', 0.7],
      ]);
    case 'intrafield':
      return getZoneNameColors(t, INTRAFIELD_COLORS, INTRAFIELD_OPACITY, INTRAFIELD_ZONE_TO_NAME);
    case 'interfield':
      return getZoneNameColors(t, INTERFIELD_COLORS, INTERFIELD_OPACITY, INTERFIELD_ZONE_TO_NAME);
    case 'interyield':
      return getZoneNameColors(t, INTERYIELD_COLORS, INTERYIELD_OPACITY, INTERYIELD_ZONE_TO_NAME);
    case 'temperature':
      return getZoneNameColors(t, TEMPERATURE_COLORS, TEMPERATURE_OPACITY, TEMPERATURE_ZONE_TO_NAME);
    case 'surface-temperature':
      return getZoneNameColors(t, TEMPERATURE_COLORS, TEMPERATURE_OPACITY, TEMPERATURE_ZONE_TO_NAME);
    case 'hail':
      return getZoneNameColors(t, HAIL_COLORS, HAIL_OPACITY, HAIL_ZONE_TO_NAME);
    case 'precipitation':
      return getZoneNameColors(t, PRECIPITATION_COLORS, PRECIPITATION_OPACITY, PRECIPITATION_ZONE_TO_NAME);
    case 'rainstorm':
      return getZoneNameColors(t, RAINSTORM_COLORS, RAINSTORM_OPACITY, RAINSTORM_ZONE_TO_NAME);
    case 'wind':
      return getZoneNameColors(t, WIND_COLORS, WIND_OPACITY, WIND_ZONE_TO_NAME);
    case 'crop-mon':
      return getCropMonItems(t, layerParams, units);
    case 'custom-a':
      return [[t('MunicipalMedianVegetation'), MDA_CUSTOM_A_COLOR, 0.7]];
    case null:
      return null;
    default:
      console.error(new UnreachableCaseError(layer));
      return null;
  }
}

export const BenchmarkYieldColorRange = [
  '#ffffe5',
  '#f7fcb9',
  '#d9f0a3',
  '#addd8e',
  '#78c679',
  '#41ab5d',
  '#238443',
  '#006837',
  '#004529',
];
const getBenchmarkYieldColors = (min: number, max: number): [number, string][] =>
  BenchmarkYieldColorRange.map((c, i) =>
    i == 0 ? [0, c] : [min + ((i - 1) * (max - min)) / (BenchmarkYieldColorRange.length - 2), c],
  );

export const LinearBrownScale = [
  '#F2F12D',
  '#EED322',
  '#E6B71E',
  '#DA9C20',
  '#CA8323',
  '#B86B25',
  '#A25626',
  '#8B4225',
  '#723122',
];

// Return color and associated cut-off values as a Mapbox step expression.
// See: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#step
export const getColorSteps = (scale: string[], min: number, max: number): (number | string)[] => {
  const res: (number | string)[] = [scale[0]];
  for (let i = 1; i < scale.length; ++i) {
    res.push(min + ((i - 1) * (max - min)) / (scale.length - 2), scale[i]);
  }

  return res;
};

export const InteryieldShapeTypes: Exclude<InteryieldZones, 'na'>[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];

export function getInteryieldLabels(
  crop_id: InteryieldCropIds,
  crops: IndexedCrops,
  units: UnitSystem,
  t: I18nFunction,
): string[] {
  // This function dynamically generates text labels for interyield (converted to appropriate units).
  // The result is of structure [shape_type, text_label, ...]
  // (e.g. ['a-wheat-winter', '<1.0t/ha', 'b-wheat-winter', '1.0-2.0t/ha', ..., 'i-wheat-winter', '>8.0t/ha'])
  const res: string[] = [];
  const [min, max] = interyieldYieldThresholds[crop_id];
  const harvest_crop = getBaseCrop(crops, crop_id);
  let yieldIntervalTHa = (max - min) / (InteryieldShapeTypes.length - 1);
  let yieldMinTHa = 0,
    yieldMaxTHa = yieldIntervalTHa;

  for (const shape_type of InteryieldShapeTypes) {
    const crop_shape_type = `${shape_type}-${crop_id}`;
    res.push(crop_shape_type);
    const minYield = convertYield(units.yieldUnit, {unit: 'tons-per-hectare', val: yieldMinTHa}, harvest_crop);
    const maxYield = convertYield(units.yieldUnit, {unit: 'tons-per-hectare', val: yieldMaxTHa}, harvest_crop);
    if (crop_shape_type == `a-${crop_id}` && maxYield) {
      res.push('<' + t({type: 'ValueUnit', ...maxYield}));
    } else if (crop_shape_type == `i-${crop_id}` && minYield) {
      res.push('>' + t({type: 'ValueUnit', ...minYield}));
    } else if (minYield && maxYield) {
      res.push(t({type: 'ValueUnitRange', min_val: minYield.val, max_val: maxYield.val, unit: units.yieldUnit}));
    }
    yieldMinTHa = yieldMaxTHa;
    yieldMaxTHa += yieldIntervalTHa;
  }

  return res;
}

export const getInteryieldTextField = defaultMemoize(function (
  crops: IndexedCrops,
  units: UnitSystem,
  t: I18nFunction,
): Expression {
  // This function dynamically generates expression for text-field property in the interyieldLabelLayer.
  // The result is of structure ['case', condition, output, ..., fallback].
  let res = [];
  res.push('case');
  for (const crop_id in interyieldYieldThresholds) {
    res.push(
      ['==', crop_id, ['slice', ['get', 'shape_type'], 2]], // Skip the letter and dash prefix (e.g. "a-")
      [
        'match',
        ['get', 'shape_type'],
        ...getInteryieldLabels(crop_id as InteryieldCropIds, crops, units, t),
        t('NotAvailable'),
      ],
    );
  }
  res.push(t('NotAvailable'));
  return res as Expression;
});

export function getInteryieldFillColor(): Expression {
  // This function dynamically generates expression for fill-color property in the interyieldLayer.
  // The result is of structure ['case', condition, output, ..., fallback].
  let res = [];
  res.push('case');
  for (const zone in INTERYIELD_COLORS) {
    if (zone != 'na') {
      res.push(['==', `${zone}-`, ['slice', ['get', 'shape_type'], 0, 2]]);
    }
    res.push(INTERYIELD_COLORS[zone as InteryieldZones]);
  }
  return res as Expression;
}

export const benchmarkYieldColors = Object.fromEntries(
  Object.entries(yieldRangeTHa).map(([crop, [min, max]]) => [crop, getBenchmarkYieldColors(min, max)]),
) as {[P in MonitoredCrop]: [number, string][]};

export const expectedLossColors: [number, string][] = [
  [0.0, '#ffffcc'],
  [0.25, '#ffeda0'],
  [0.5, '#fed976'],
  [1.0, '#feb24c'],
  [2.0, '#fd8d3c'],
  [5.0, '#fc4e2a'],
  [10, '#e31a1c'],
  [20, '#bd0026'],
  [40, '#800026'],
];

export const yieldRatioColors: [number, string][] = [
  [0, '#a50026'],
  [30, '#d73027'],
  [60, '#f46d43'],
  [70, '#fdae61'],
  [80, '#fee08b'],
  // The "break color" in a diverging class needs to be in the middle. See also:
  [90, '#ffffbf'], // https://colorbrewer2.org/learnmore/schemes_full.html#diverging
  [100, '#d9ef8b'],
  [110, '#a6d96a'],
  [120, '#66bd63'],
  [130, '#1a9850'],
  [140, '#006837'],
];

export function getIntervalColorNames(
  stops: [number, string][],
  fmt: (num: number) => string,
): [string, string, number][] {
  const res: [string, string, number][] = [];

  for (let i = 0; i < stops.length - 1; ++i) {
    const curStop = fmt(stops[i][0]);
    const nextStop = fmt(stops[i + 1][0]);
    res.push([curStop + ' - ' + nextStop, stops[i][1], 1.0]);
  }

  const lastStop = stops[stops.length - 1];
  res.push(['>' + fmt(lastStop[0]), lastStop[1], 1.0]);

  return res;
}

export const floodRiskSourceUrl = 'mapbox://savvopoulos.ceeugs1q';
export const floodRiskSourceLayer = 'flood_risk_map_2023_10-9zefan';

export const mapStatsSourceName = 'granular-regions';
export const mapStatsSourceLayer = 'granular-regions';

export const GRADIENT_PALETTE = [
  '#003017',
  '#004421',
  '#00592b',
  '#006d34',
  '#00823e',
  '#009648',
  '#00aa52',
  '#00bf5b',
  '#00d365',
  '#00e86f',
].reverse();
