import {FormyErrors} from '../Formy';
import {FarmCustomColumns} from '../models/CustomColumns';
import {Farm, UserGroup} from '../models/interfaces';
import {isEmail, isStr} from '../validator-constraints';

type FarmFormField =
  | 'address'
  | 'comments'
  | 'editors'
  | 'external_farm_id'
  | 'farm_location'
  | 'farm_name'
  | 'farmer_email'
  | 'farmer_name'
  | 'user_group';

export function validateFarmForm(x: FarmForm): FormyErrors<FarmForm> {
  return {
    farm_name: !isStr(x.farm_name, 3),
    address: !isStr(x.address, 3),
    user_group: !isStr(x.user_group, 1),
    farmer_email: !isEmail(x.farmer_email),
    editors: !x.editors.every(editor => !!editor && editor[0] == '@' && isEmail(editor.slice(1))),
  };
}

export class FarmForm implements Pick<Farm, FarmFormField> {
  farm_name: Farm['farm_name'];
  address: Farm['address'];
  user_group: Farm['user_group'];

  farm_location: Farm['farm_location'];
  external_farm_id: Farm['external_farm_id'];
  telepac_id: Farm['telepac_id'];

  comments: Farm['comments'];

  editors: Farm['editors'];

  farmer_email: Farm['farmer_email'];
  farmer_name: Farm['farmer_name'];
  custom_columns: FarmCustomColumns;
  attachments: Farm['attachments'];

  constructor(farm: null | Farm, user_group: null | UserGroup['user_group'], curUserEmail: null | string) {
    this.farm_name = farm?.farm_name ?? null;
    this.address = farm?.address ?? null;
    this.user_group = user_group ?? farm?.user_group ?? '';
    this.farm_location = farm?.farm_location ?? null;
    this.external_farm_id = farm?.external_farm_id ?? null;
    this.telepac_id = farm?.telepac_id ?? null;
    this.comments = farm?.comments ?? null;
    this.editors = farm?.editors ?? (curUserEmail ? ['@' + curUserEmail] : []);
    this.farmer_email = farm?.farmer_email ?? null;
    this.farmer_name = farm?.farmer_name ?? null;
    this.custom_columns = new FarmCustomColumns(farm?.custom_columns ?? null);
    this.attachments = farm?.attachments ?? [];
  }
}
