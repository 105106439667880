import {CloseOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import React, {Component, MouseEventHandler, ReactElement} from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {SampleImage} from '../../../src/models/types';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import './SampleImage.css';
import SpinningDots from './SpinningDots';

type GalleryProps = {images: SampleImage[]} & (
  | {showPreview: false; LinkComponent: React.ComponentType<{idx: number; onClick: MouseEventHandler}>}
  | {showPreview: true; ImageComponent: React.ComponentType<{src: string; onClick: MouseEventHandler}>}
);

interface GalleryState {
  currentImage: undefined | number;
  // in 'carousel-init' mode, we hide the carousel until a callback makes it "full-screen" (after images finished loading)
  mode: 'carousel-init' | 'carousel-ready' | 'gallery';
  imageUrls: {src: string}[];
}

class Gallery extends Component<GalleryProps, GalleryState> {
  static contextType = ApisContext;
  context!: Apis;
  carousel: React.RefObject<ImageGallery> = React.createRef();

  state: GalleryState = {
    currentImage: undefined,
    imageUrls: [],
    mode: 'gallery',
  };

  componentDidMount() {
    if (this.props.showPreview) {
      this.updateImageUrls();
    }
  }

  componentDidUpdate(prevProps: GalleryProps) {
    if (prevProps.images !== this.props.images) {
      if (this.props.showPreview) {
        this.updateImageUrls();
      }
    }
  }

  async updateImageUrls() {
    if (this.state.imageUrls.length > 0) {
      this.setState({imageUrls: []});
    }
    if (this.props.images.length == 0) {
      return;
    }

    const imageUrls = await this.context.authedFetcher({
      method: 'GET',
      path: 'authorize-s3',
      params: this.props.images.map(x => ['get', 'photo' + x.uri]),
    });
    this.setState({imageUrls: imageUrls.map((src: string) => ({src}))});
  }

  renderGallery = () => {
    const images: ReactElement[] = [];
    if (this.props.showPreview) {
      const ImageComponent = this.props.ImageComponent;
      for (const [idx, {src}] of this.state.imageUrls.entries()) {
        images.push(<ImageComponent key={`img-${idx}`} src={src} onClick={e => this.openCarousel(idx, e)} />);
      }
    } else {
      const LinkComponent = this.props.LinkComponent;
      for (const [idx, _] of this.props.images.entries()) {
        images.push(<LinkComponent key={`img-${idx}`} idx={idx} onClick={e => this.openCarousel(idx, e)} />);
      }
    }
    return <div className="Map__overlay-card__line Map__overlay-card__images">{images}</div>;
  };

  openCarousel = async (index: number, event: any) => {
    event.preventDefault();
    if (!this.props.showPreview) {
      this.updateImageUrls().catch(reportErr);
    }
    this.setState({currentImage: index, mode: 'carousel-init'});
  };

  showCarousel() {
    this.carousel.current && this.carousel.current.fullScreen();
    this.setState({mode: 'carousel-ready'});
  }

  closeCarousel() {
    this.setState({currentImage: 0, mode: 'gallery'});
  }

  onScreenChange(fullscreen: boolean) {
    if (!fullscreen) {
      this.closeCarousel();
    }
  }

  render() {
    const {mode, imageUrls, currentImage} = this.state;
    return (
      <div className="section">
        {this.renderGallery()}
        {mode == 'carousel-init' && (
          <div style={{position: 'fixed', top: '50vh', left: '50vw', marginLeft: -16, marginTop: -16}}>
            <SpinningDots size={32} />
          </div>
        )}
        {mode != 'gallery' && (
          <Modal closable={false} visible footer={null} bodyStyle={mode == 'carousel-ready' ? {} : {display: 'none'}}>
            <div style={{position: 'fixed', top: 20, right: 20, zIndex: 8}}>
              <Button
                data-testid="sample-list-close-button"
                icon={<CloseOutlined />}
                onClick={() => this.closeCarousel()}
              />
            </div>
            <ImageGallery
              showIndex={true}
              showFullscreenButton={false}
              ref={this.carousel}
              useBrowserFullscreen={false}
              startIndex={currentImage}
              items={imageUrls.map(x => ({original: x.src, thumbnail: x.src}))}
              onImageLoad={() => this.showCarousel()}
              onImageError={() => this.showCarousel()}
              onScreenChange={fullscreen => this.onScreenChange(fullscreen)}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default Gallery;
