import {GatewayOutlined} from '@ant-design/icons';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {Modal} from 'antd';
import {Polygon, Position} from 'geojson';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {LngLat, geometryAreaSqM, getBoundingBox, toMinMaxBbox} from '../../../src/geo';
import {getLayerDates} from '../../../src/layers/canonical-date';
import {Field} from '../../../src/models/interfaces';
import {AreaValue} from '../../../src/models/types';
import {convertArea, getUnitSystem} from '../../../src/selectors/units';
import {isGeojsonPolygon} from '../../../src/validator-constraints';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {DateSelector} from '../map/DateSelector';
import Map from '../map/Map';
import {ErrorBoundary} from '../util/ErrorBoundary';

interface FieldModalProps {
  onChange: (x: null | Polygon) => void;
  value: null | Polygon;
  field_location: null | LngLat;
  farm_location: null | LngLat;
  error: boolean;
}

interface FieldModalState {
  fieldModalOpen: boolean;
  polygonArea: null | AreaValue;
  satDate: string;
}

class FieldModal extends React.PureComponent<FieldModalProps, FieldModalState> {
  static contextType = ApisContext;
  context!: Apis;

  satDates = getLayerDates(this.context.store.getState().dbMeta.todaysDateUtc, 'satellite');
  state: FieldModalState = {fieldModalOpen: false, polygonArea: null, satDate: this.satDates[this.satDates.length - 1]};
  // A marker indicating the field's location, if set. It's added when the map is initialized and this.map is set.
  private marker: null | mapboxgl.Marker = null;

  private map: undefined | mapboxgl.Map;
  private draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      trash: true,
    },
    defaultMode: 'draw_polygon',
  });

  setSatDate = (satDate: string) => this.setState({satDate});

  async updateDrawShape() {
    const geometry = this.props.value;
    if (geometry) {
      this.draw.set({
        type: 'FeatureCollection',
        features: [{type: 'Feature', id: 'drawn', geometry: geometry, properties: {}}],
      });
      this.draw.changeMode('simple_select', {featureIds: ['drawn']});
    } else {
      this.draw.set({type: 'FeatureCollection', features: []});
      this.draw.changeMode('draw_polygon');
    }

    this.updateArea();

    if (this.map) {
      if (this.props.value) {
        const bbox = getBoundingBox(this.props.value);
        if (bbox) {
          this.map.fitBounds(toMinMaxBbox(bbox), {padding: 50, duration: 0});
        }
      } else {
        const location = this.props.field_location ?? this.props.farm_location;
        if (location) {
          this.map.setCenter(location);
          this.map.setZoom(12);
        }
      }
    }
  }

  updateFieldLocationMarker() {
    if (this.props.field_location) {
      if (!this.marker && this.map) {
        this.marker = new mapboxgl.Marker();
        this.marker.setLngLat(this.props.field_location);
        this.marker.addTo(this.map);
      }

      this.marker?.setLngLat(this.props.field_location);
    } else {
      this.marker?.remove();
      this.marker = null;
    }
  }

  componentDidUpdate(prevProps: Readonly<FieldModalProps>, prevState: Readonly<FieldModalState>) {
    if (this.state.fieldModalOpen && this.props.value != prevProps.value) {
      this.updateDrawShape();
    }
    if (this.state.fieldModalOpen && this.props.field_location != prevProps.field_location) {
      this.updateFieldLocationMarker();
    }
    if (!this.state.fieldModalOpen && this.state.fieldModalOpen != prevState.fieldModalOpen) {
      this.marker?.remove();
      this.marker = null;
    }
  }

  closeFieldModal = () => this.setState({fieldModalOpen: false});
  openFieldModal = () => this.setState({fieldModalOpen: true});

  onOk = () => {
    const features = this.draw.getAll().features[0];
    const polygon = isGeojsonPolygon(features.geometry) ? features.geometry : null;
    this.props.onChange(polygon);
    this.closeFieldModal();
  };

  mapCb = (map: mapboxgl.Map) => {
    this.map = map;
    this.map.addControl(this.draw);
    this.updateDrawShape();
    this.updateFieldLocationMarker();

    this.map.on('draw.create', this.onDrawCreate);
    this.map.on('draw.delete', this.onDrawDelete);
    this.map.on('draw.update', this.updateArea);
    this.map.on('draw.render', this.updateArea);
    this.map.on('draw.modechange', this.modeChange);
  };

  onDrawCreate = () => {
    this.updateArea();
    const features = this.draw.getAll().features;
    if (features.length > 1) {
      this.draw.set({
        type: 'FeatureCollection',
        features: features.filter(x => x.geometry.type == 'Polygon').slice(0, 1),
      });
    }
  };

  onDrawDelete = () => {
    this.updateArea();
    if (this.draw.getAll().features.length == 0) {
      this.draw.changeMode('draw_polygon');
    }
  };

  updateArea = () => {
    const units = getUnitSystem(this.context.store.getState());
    const data = this.draw.getAll();
    const polygon =
      data.features.length == 1 && data.features[0].geometry.type == 'Polygon'
        ? (data.features[0].geometry as Polygon)
        : null;
    const polygonArea = !polygon
      ? null
      : convertArea(units.areaUnit, {val: geometryAreaSqM(polygon) / 10000, unit: 'hectares'});
    this.setState({polygonArea});
  };

  modeChange = () => {
    if (this.draw.getAll().features.length == 0) {
      this.draw.changeMode('draw_polygon');
    }
  };

  nullIcon = (<></>);

  render() {
    const t = this.context.t;
    return (
      <>
        <GatewayOutlined
          title={t(this.props.value ? 'EditFieldShape' : 'AddFieldShape')}
          onClick={this.openFieldModal}
          className="field-cell-edit-shape"
          color={this.props.error ? 'red' : undefined}
        />
        <Modal
          open={this.state.fieldModalOpen}
          okText={t('Ok')}
          cancelText={t('Cancel')}
          onCancel={this.closeFieldModal}
          onOk={this.onOk}
          width="95vw"
          style={{top: '2em'}}
          destroyOnClose
          closeIcon={this.nullIcon}>
          <ErrorBoundary>
            <div className="Map map-modal">
              <span className="field-modal-top-right">
                <DateSelector
                  layer="satellite"
                  curDate={this.state.satDate}
                  layerDates={this.satDates}
                  onChange={this.setSatDate}
                  yearOnly={false}
                />
                {this.state.polygonArea && (
                  <span className="field-modal-area">
                    {this.context.t({type: 'AreaUnit', ...this.state.polygonArea})}
                  </span>
                )}
              </span>
              <Map
                onMapboxMapInstance={this.mapCb}
                layer="satellite"
                canonical_date={this.state.satDate}
                layerParams={null}
                showEntityFeatures={true}
                focus={null}
              />
            </div>
          </ErrorBoundary>
        </Modal>
      </>
    );
  }
}

interface FormyFieldModalProps<F extends Pick<Field, 'field_area' | 'field_location' | 'field_shape'>>
  extends FormyComponentProps<F, 'field_shape'> {
  farm_location: null | LngLat;
}

export class FormyFieldModal<
  F extends Pick<Field, 'field_area' | 'field_location' | 'field_shape'>,
> extends FormyComponent<F, 'field_shape', FormyFieldModalProps<F>> {
  static contextType = ApisContext;
  context!: Apis;
  fieldLocationCb = this.props.formy.watchValue('field_location', this);

  onChange = (x: null | Polygon) => {
    const units = getUnitSystem(this.context.store.getState());
    const area = !x ? null : convertArea(units.areaUnit, {val: geometryAreaSqM(x) / 10000, unit: 'hectares'});
    if (area) {
      area.val = Number(area.val.toFixed(2));
    }
    this.props.formy.getChangeHandler('field_area')(area as F['field_area']);
    const position: undefined | Position[] = x?.coordinates?.[0];
    if (position && position.length > 2) {
      this.handleChange(x);
    }
  };

  render() {
    return (
      <FieldModal
        onChange={this.onChange}
        value={this.value}
        farm_location={this.props.farm_location}
        field_location={this.fieldLocationCb()}
        error={this.error}
      />
    );
  }
}
