import {useQuery} from '@tanstack/react-query';
import {Badge, Button, Popover, Select, Slider, Spin, Tag, Tooltip} from 'antd';
import {SelectValue} from 'antd/es/select';
import debounce from 'lodash/debounce';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Dispatch} from 'redux';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {
  Claim,
  CropCondition,
  LossCause,
  Regions,
  VisitType,
  getSampleCountsByAdjuster,
} from '../../../src/models/interfaces';
import {resetDbFilter, setDbFilter} from '../../../src/redux/actions/filters';
import {dbFiltersInitialState} from '../../../src/redux/reducers/filters';
import {getFilters} from '../../../src/selectors/filters';
import {getSelectableHarvestYears} from '../../../src/selectors/harvest';
import {getUserGroups} from '../../../src/selectors/userGroups';
import {claimDesc} from '../../../src/text/desc';
import {filterNulls} from '../../../src/util/arr-util';
import cmp, {simplify} from '../../../src/util/cmp';
import {parseDate} from '../../../src/util/date-util';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {WithChildren} from '../../../src/util/react-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {ApisContext, useApis} from '../apis/ApisContext';
import {LocalizedEmptyImage} from '../dashboard/Dashboard';
import {EntityOptionType, useSearchEntity, useSelectedValues} from '../queries/options';
import {useCropOptions} from '../queries/useCropOptions';
import {Actions} from '../redux';
import './Filters.css';

type DbFilterArrayKeys =
  | 'added_by'
  | 'claim_id'
  | 'crop_condition'
  | 'crop_id'
  | 'farm_id'
  | 'harvest_year'
  | 'loss_cause'
  | 'policy_id'
  | 'user_group'
  | 'visit_type';

// Use a restricted options type, to facility easy search on the string label.
type StringLabelOptionType = {label: string; value: string};

interface DataFilterView {
  field: DbFilterArrayKeys;
  options: StringLabelOptionType[];
  minimized: boolean;
  loading?: boolean;
  placeholder?: I18nSimpleKey;
}

const minWidth = {minWidth: 200} as const;

function DataFilterView<K extends DbFilterArrayKeys>({
  field,
  options,
  minimized,
  loading,
  placeholder,
}: DataFilterView) {
  const {t} = useContext(ApisContext);
  const dispatch = useDispatch<Dispatch<Actions>>();
  const handleSelectClick = useCallback(
    (filterValues: SelectValue) => {
      if (!(filterValues instanceof Array)) {
        throw new Error(`Select handler didn't return array: ${filterValues}`);
      }
      if (filterValues.length) {
        dispatch(setDbFilter({[field]: filterValues as string[]}));
      } else {
        dispatch(resetDbFilter(field));
      }
    },
    [dispatch, field],
  );
  const handleDeselect = useCallback(() => {
    dispatch(resetDbFilter(field));
  }, [dispatch, field]);
  const selected = useSelector(getFilters)[field];

  if (minimized) {
    const label = (options || [])
      .filter(x => selected.includes(x.value as never))
      .map(x => x.label)
      .join(', ');
    return label ? (
      <Tag closable={true} onClose={handleDeselect}>
        {label}
      </Tag>
    ) : null;
  }

  return (
    <Select
      style={minWidth}
      mode="multiple"
      onChange={handleSelectClick}
      loading={loading ?? false}
      showSearch
      placeholder={t(placeholder ?? field)}
      filterOption={(input, option) => {
        if (option?.label) {
          return simplify(option.label).includes(simplify(input));
        } else {
          return false;
        }
      }}
      optionFilterProp="label"
      value={selected}
      options={options}
    />
  );
}

interface DataFilterQuery {
  minimized: boolean;
  entityType: 'claim' | 'farm' | 'policy';
  options: EntityOptionType[];
  loading: boolean;
  onSearch: (q: string) => void;
  onBlur: () => void;
}

function DataFilterQuery({entityType, minimized, options, loading, onSearch, onBlur: handleBlur}: DataFilterQuery) {
  const field = entityType === 'farm' ? 'farm_id' : entityType === 'policy' ? 'policy_id' : 'claim_id';
  const {t} = useContext(ApisContext);
  const dispatch = useDispatch<Dispatch<Actions>>();
  const handleSelectClick = useCallback(
    (key: SelectValue) => {
      if (!(key instanceof Array)) {
        throw new Error(`Select handler didn't return array: ${key}`);
      }
      if (key.length) {
        dispatch(setDbFilter({[field]: key as string[]}));
      } else {
        dispatch(resetDbFilter(field));
      }
      handleBlur();
    },
    [dispatch, field, handleBlur],
  );
  const handleCloseTag = useCallback(() => {
    dispatch(resetDbFilter(field));
  }, [dispatch, field]);
  const handleSearch = useMemo(() => debounce(onSearch, 250), [onSearch]);
  const selectedIds = useSelector(getFilters)[field];
  const selected = useSelectedValues(options, entityType, selectedIds);

  if (minimized) {
    const label = selected.map(x => x.label).join(', ');
    return label ? (
      <Tag closable={true} onClose={handleCloseTag}>
        {label}
      </Tag>
    ) : null;
  }

  return (
    <Select
      style={minWidth}
      filterOption={false}
      mode="multiple"
      onChange={handleSelectClick}
      onSearch={handleSearch}
      placeholder={t(field)}
      notFoundContent={loading ? <Spin size="small" /> : <LocalizedEmptyImage />}
      options={options}
      value={selected}
      onBlur={handleBlur}
    />
  );
}

interface FilterProps {
  minimized: boolean;
}

export function PolicyFilter({minimized}: FilterProps) {
  const [query, setQuery] = useState<string>('');
  const {isFetching, data: options} = useSearchEntity('policy', query, !minimized);

  return (
    <DataFilterQuery
      entityType="policy"
      minimized={minimized}
      loading={isFetching}
      options={options}
      onSearch={setQuery}
      onBlur={() => setQuery('')}
    />
  );
}

export function FarmFilter({minimized}: FilterProps) {
  const [query, setQuery] = useState<string>('');
  const {isFetching, data: options} = useSearchEntity('farm', query, !minimized);
  return (
    <DataFilterQuery
      entityType="farm"
      minimized={minimized}
      loading={isFetching}
      options={options}
      onSearch={setQuery}
      onBlur={() => setQuery('')}
    />
  );
}

export function CropFilter({minimized}: FilterProps) {
  const {options, isFetching} = useCropOptions(!minimized, false);
  return <DataFilterView field="crop_id" options={options} minimized={minimized} loading={isFetching} />;
}

export function YearFilter({minimized}: FilterProps) {
  const {clock} = useContext(ApisContext);
  const options: StringLabelOptionType[] = useMemo(() => {
    return getSelectableHarvestYears(clock).map(x => ({value: x, label: x}));
  }, [clock]);
  return <DataFilterView field="harvest_year" options={options} minimized={minimized} />;
}

export function ConditionFilter({minimized}: FilterProps) {
  const {t} = useContext(ApisContext);
  const options: StringLabelOptionType[] = useMemo(() => CropCondition.map(x => ({value: x, label: t(x)})), [t]);
  return <DataFilterView field="crop_condition" options={options} minimized={minimized} />;
}

export function CauseFilter({minimized}: FilterProps) {
  const {t} = useContext(ApisContext);
  const options: StringLabelOptionType[] = useMemo(() => LossCause.map(x => ({value: x, label: t(x)})), [t]);
  return <DataFilterView field="loss_cause" options={options} minimized={minimized} />;
}

export function AdjusterFilter({minimized}: FilterProps) {
  const {authedFetcher, clock} = useContext(ApisContext);
  const {data, isFetching} = useQuery(
    ['getSampleCountsByAdjuster', dbFiltersInitialState],
    () => getSampleCountsByAdjuster(authedFetcher, filtersToRequest(dbFiltersInitialState)),
    {keepPreviousData: true, enabled: !minimized},
  );

  const options: StringLabelOptionType[] = useMemo(() => {
    const adjusterCount: Record<string, number> = {};
    for (const {added_by, sample_date, count} of data ?? []) {
      const sampleDate = parseDate(sample_date);
      const yearAgo = new Date(clock.now() - 365 * 24 * 60 * 60 * 1000).getTime();
      if (!added_by || !sampleDate || sampleDate.getTime() < yearAgo) {
        continue;
      }
      adjusterCount[added_by] = (adjusterCount[added_by] ?? 0) + (count ?? 0);
    }
    const adjusters = Object.entries(adjusterCount)
      .sort((a, b) => b[1] - a[1])
      .map(x => x[0]);
    return adjusters.map(x => ({value: x, label: x}));
  }, [clock, data]);
  return (
    <DataFilterView
      field="added_by"
      options={options}
      minimized={minimized}
      loading={isFetching}
      placeholder="SampledBy"
    />
  );
}

export function UserGroupFilter({minimized}: FilterProps) {
  const groups = useSelector(getUserGroups).filter(u => u.preferred_owner);
  const options: StringLabelOptionType[] = useMemo(
    () =>
      groups
        .map(({user_group: value, name}) => ({
          value,
          label: name ?? value,
        }))
        .sort((a, b) => cmp(a.label, b.label)),
    [groups],
  );
  return <DataFilterView field="user_group" options={options} minimized={minimized} />;
}

export type MinimizableFilterProps = WithChildren & {
  minimized: boolean;
  value?: string;
  label?: string;
  newRibbon?: boolean;
  onClose?: () => void;
};

export const MinimizableFilter: React.FC<MinimizableFilterProps> = ({
  minimized,
  value,
  children,
  label,
  onClose,
  newRibbon,
}) => {
  const {t} = useApis();
  if (minimized) {
    if (value) {
      return (
        <Tag className="filter-minimizable-tag" closable={!!onClose} onClose={onClose}>
          {value}
        </Tag>
      );
    }
    return null;
  }

  const content = <Tooltip title={label}>{children}</Tooltip>;

  return newRibbon ? (
    <Badge.Ribbon text={t('New')} placement="end" className="filter-badge-ribbon">
      {content}
    </Badge.Ribbon>
  ) : (
    <Tooltip title={label}>{children}</Tooltip>
  );
};

function regionToLabel(region: Regions, skipLowestRegionLevel: boolean = false): string {
  return (
    region.region_info
      ?.slice(skipLowestRegionLevel && region.region_info.length > 1 ? 1 : 0)
      .map(ri => ri?.region_name)
      .join(' → ') ??
    region.name ??
    region.region_id
  );
}

function onlyKeepTopRegions(regions: Regions[]): Regions[] {
  const sorted = regions.sort((a, b) => a.region_id.length - b.region_id.length);
  const selected: Regions[] = [];
  for (const region of sorted) {
    if (!selected.some(r => region.region_id.startsWith(r.region_id))) {
      selected.push(region);
    }
  }
  return selected;
}

export const RegionFilter: React.FC<Pick<MinimizableFilterProps, 'minimized' | 'newRibbon'>> = ({
  minimized,
  newRibbon,
}) => {
  const apis = useApis();
  const {t} = apis;
  const dispatch = useDispatch();
  const userGroups = useSelector(getUserGroups);
  const singleCountry = userGroups.filter(u => /^[A-Z]{3}$/.test(u.user_group)).length == 1;
  const selectedRegions = useSelector(getFilters)['regions'];
  const [regions, setRegions] = useState<null | {label: string; value: string}[]>(null);

  const searchQuery = useRef('');

  const fetchRegions = useCallback(
    (query: string) => {
      const params: [string, string][] = [
        ['select', 'region_id,name,region_info'],
        ['limit', '50'],
      ];
      if (query) {
        params.push(
          ...getPostgrestQueryParams({
            column: 'name',
            operator: 'ilike',
            value: `${query}%`,
          }),
        );
      } else {
        params.push(
          ...getPostgrestQueryParams({
            column: 'gadm_level',
            operator: 'lte',
            value: 1,
          }),
        );
      }
      apis
        .authedFetcher({
          method: 'GET',
          path: 'api/regions',
          params,
        })
        .then((regionsData: Regions[]) => {
          if (searchQuery.current != query) {
            return;
          }
          setRegions(
            onlyKeepTopRegions(regionsData).map((region: Regions) => {
              return {
                label: regionToLabel(region, singleCountry),
                value: region.region_id,
              };
            }),
          );
        });
    },
    [apis, singleCountry],
  );

  useEffect(() => {
    if (!minimized && !regions) fetchRegions('');
  }, [fetchRegions, minimized, regions]);

  const fetchRegionsDebounced = useMemo(() => debounce(fetchRegions, 200), [fetchRegions]);

  const minimizedValue = useMemo(
    () =>
      minimized
        ? selectedRegions
            .map(sr => sr.label)
            .filter(Boolean)
            .join(', ')
        : '',
    [minimized, selectedRegions],
  );

  const selectOptions = useMemo(() => {
    const mergedOptions = [...selectedRegions, ...(regions ?? [])];
    const uniqueOptions: Record<string, {label: string; value: string}> = {};

    for (const option of mergedOptions) {
      uniqueOptions[option.value] = option;
    }

    return Object.keys(uniqueOptions).map(optionId => uniqueOptions[optionId]);
  }, [regions, selectedRegions]);

  // TODO(seb): Using TreeSelect would sound natual for hierarchical region data, but it's not straight forward how
  //   pagination would work here. Would we only fetch the (1st, 2nd, 3rd) highest hierarchy level initially (and only
  //   partially), and then expand to all hierarchies once the user issues a query? Return the n-most often filteres
  //   regions initially (requires keeping track of usage)?
  return (
    <MinimizableFilter
      newRibbon={newRibbon}
      minimized={minimized}
      value={minimizedValue}
      onClose={() => {
        dispatch(resetDbFilter('regions'));
      }}>
      <Select
        style={{minWidth: 200}}
        mode="multiple"
        allowClear
        placeholder={t('SelectRegions')}
        value={selectedRegions.map(r => r.value)}
        onSearch={input => {
          searchQuery.current = input;
          fetchRegionsDebounced(input);
        }}
        onBlur={() => {
          searchQuery.current = '';
          fetchRegionsDebounced('');
        }}
        filterOption={false}
        options={selectOptions}
        onChange={(_, option) => {
          dispatch(
            setDbFilter({
              regions: Array.isArray(option) ? option : [option],
            }),
          );
        }}
        showSearch={true}
      />
    </MinimizableFilter>
  );
};

export const NDVIValueFilter: React.FC<Pick<MinimizableFilterProps, 'minimized' | 'newRibbon'>> = ({
  minimized,
  newRibbon,
}) => {
  const {t} = useApis();
  const dispatch = useDispatch();
  const ndviValueRange = useSelector(getFilters)['max_ndvi_value'];

  const handleNdviValueRangeChange = useMemo(
    () =>
      debounce(([from, to]: number[]) => {
        dispatch(
          setDbFilter({
            max_ndvi_value: {
              from,
              to,
            },
          }),
        );
      }, 500),
    [dispatch],
  );

  const minimizedValue = useMemo(
    () => filterNulls([ndviValueRange.from, ndviValueRange.to]).join(' - '),
    [ndviValueRange],
  );

  return (
    <MinimizableFilter
      newRibbon={newRibbon}
      minimized={minimized}
      value={minimized && minimizedValue ? `${t('NDVIRange')} → ${minimizedValue}` : ''}
      onClose={() => {
        dispatch(resetDbFilter('max_ndvi_value'));
      }}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <Slider
            range={{draggableTrack: true}}
            defaultValue={[ndviValueRange.from ?? 0, ndviValueRange.to ?? 1]}
            className="filters-slider"
            marks={{
              0.2: '0.2',
              0.8: '0.8',
            }}
            step={0.01}
            min={0}
            max={1}
            onChange={handleNdviValueRangeChange}
          />
        }>
        <Button>{`${t('NDVIRange')} ${minimizedValue ? ` → ${minimizedValue}` : ''}`}</Button>
      </Popover>
    </MinimizableFilter>
  );
};

export function ShowConditionally<T extends {}>(
  WrappedComponent: (props: T) => React.ReactNode,
  urlPrefix: string,
): React.FC<T> {
  const ConditionalFilter: React.FC<T> = props => {
    const location = useLocation();
    return location.pathname.startsWith(urlPrefix) ? <WrappedComponent {...props} /> : null;
  };
  return ConditionalFilter;
}

export const ClaimFilter: React.FC<{minimized: boolean}> = ShowConditionally(({minimized}) => {
  const {authedFetcher, t} = useApis();
  const [query, setQuery] = useState<string>('');
  const {isFetching, data: options} = useQuery<EntityOptionType[]>(
    ['externalClaimIdFilter', query],
    async (): Promise<EntityOptionType[]> => {
      return (
        authedFetcher({
          method: 'GET',
          path: 'api/claim',
          params: [
            ...getPostgrestQueryParams({
              column: 'external_claim_id',
              operator: 'ilike',
              value: /%/.test(query) ? query : `${query}%`,
            }),
            ['limit', '25'],
          ],
        }) as Promise<Claim[]>
      ).then(claims => {
        return claims.map<EntityOptionType>(c => ({
          value: c.claim_id,
          label: claimDesc(t, c, 'long'),
          shortLabel: c.external_claim_id ?? '-',
        }));
      });
    },
    {
      keepPreviousData: true,
      // Make sure to only fetch the options when the filter is visible.
      enabled: !minimized,
    },
  );
  return (
    <DataFilterQuery
      entityType="claim"
      minimized={minimized}
      loading={isFetching}
      options={options ?? []}
      onSearch={setQuery}
      onBlur={() => setQuery('')}
    />
  );
}, '/list/reports');

export const VisitTypeFilter: React.FC<{minimized: boolean}> = ShowConditionally(({minimized}) => {
  const {t} = useApis();
  return (
    <DataFilterView
      field="visit_type"
      minimized={minimized}
      loading={false}
      options={VisitType.map(v => ({
        value: v,
        label: t(v),
      }))}
    />
  );
}, '/list/reports');
