import {Polygon, Position} from 'geojson';
import React from 'react';
import {getBoundingBox} from '../../../src/geo';

// This overlaps to a certain extent with code in src/report/harvest-map-images.ts. Before reusing this code fragment
// here, we should probably refactor the code in harvest-map-images.ts to be more generic and at all three locations.
function polygonToPath(polygon: Polygon, options: {width?: number; height?: number}): string {
  const {
    ne: [maxLng, maxLat],
    sw: [minLng, minLat],
  } = getBoundingBox(polygon)!;
  const translateCoordinates = ([lng, lat]: Position): [number, number] => {
    return [
      ((lng - minLng) / (maxLng - minLng)) * (options?.width ?? 100),
      ((lat - minLat) / (maxLat - minLat)) * (options?.height ?? 100),
    ];
  };
  return polygon.coordinates[0]
    .map(translateCoordinates)
    .map(([lng, lat], ix) => {
      if (ix === 0) {
        return `M ${lng} ${lat}`;
      }
      return `L ${lng} ${lat}`;
    })
    .join(' ');
}

export const FieldShape: React.FC<{polygon?: Polygon; color?: string}> = ({polygon, color}) => {
  if (!polygon) return null;
  // Note, polygonToPath simply normalized the geo-coordinates in [0,{width,height}]. As the origin is different in the
  // coordinate system, the polygon gets painted mirrored on the horizontal axis. We can simply compensate for this by
  // applying a scale(-1,1). With the resulting path outside the viewport, we need another translate to move it back
  // into the viewport.
  return (
    <svg width="100%" height="100%" viewBox="0 0 50 50">
      <g transform="translate(2,2)">
        <path
          transform="scale(-1,1) translate(-46,0)"
          d={polygonToPath(polygon, {width: 46, height: 46})}
          fill={color}
        />
      </g>
    </svg>
  );
};
