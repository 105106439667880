import {Buffer} from 'buffer';
import {Feature, FeatureCollection, Polygon} from 'geojson';
import {defaultMemoize} from 'reselect';
import {FetcherFunc, FetcherOpts} from '../../../src/FetcherFunc';
import {deepCopy} from '../../../src/deepCopy';
import equal from '../../../src/fast-deep-equal';
import {Layer} from '../../../src/layers/layer';
import {layerBundleToGeoJSON} from '../../../src/layers/layer-bundle';
import {LayerParams, LayerParamsCropMon} from '../../../src/layers/layer-params';
import {CropMonRes, CropMonType} from '../../../src/models/crop-mon';
import {CropMonGeoProps, LayerGeoProps} from '../../../src/models/geojson';
import {CropMonitoring, HarvestCrop, HarvestSeason, HarvestYear, LayerType} from '../../../src/models/interfaces';
import {isSubsetOf} from '../../../src/util/arr-util';
import {uuidUtil} from '../../../src/uuid-util';

function parseUint32(buffer: Buffer, pos: number): number {
  return (buffer[pos] << 24) + (buffer[pos + 1] << 16) + (buffer[pos + 2] << 8) + buffer[pos + 3];
}

export function layerPackageToGeoJSON(buffer: Buffer): FeatureCollection {
  if (buffer.length <= 8) {
    return {type: 'FeatureCollection', features: []};
  }

  // We skip over max_updated_at.

  const numBundles = parseUint32(buffer, 32);
  let features: Feature[] = [];
  let bundleStart = 36 + numBundles * 20;
  for (let i = 0; i < numBundles; ++i) {
    let pos = 36 + i * 20;
    const id = uuidUtil(buffer.slice(pos, pos + 16));
    pos += 16;
    const bundleEnd = 36 + numBundles * 20 + parseUint32(buffer, pos);
    const layerBundle = layerBundleToGeoJSON(buffer.slice(bundleStart, bundleEnd));
    layerBundle.features.forEach(x => (x.id = id));
    bundleStart = bundleEnd;
    features.push(...layerBundle.features);
  }

  return {type: 'FeatureCollection', features};
}

interface GetLayerPackage2RequestBody {
  layer_type: LayerType;
  canonical_date: Layer['canonical_date'];
  min_updated_at?: string;
  region?: string;
  field_ids?: string;
}

const fetchLayer = defaultMemoize(
  async (
    authedFetcher: FetcherFunc,
    layer_type: LayerType,
    canonical_date: Layer['canonical_date'],
    fieldIds: null | string[],
    region: null | string,
  ): Promise<FeatureCollection<Polygon, CropMonGeoProps | LayerGeoProps>> => {
    if (fieldIds && fieldIds.length == 0) {
      return {type: 'FeatureCollection', features: []};
    }

    const headers: [string, string][] = [['Accept', 'application/octet-stream']];

    const json_body: GetLayerPackage2RequestBody = {
      layer_type: layer_type,
      canonical_date: canonical_date,
    };

    if (region) {
      json_body.region = region;
    }
    if (fieldIds) {
      json_body.field_ids = fieldIds.join(',');
    }

    // Context: Use POST instead of GET to handle requests with more than 200-250 field ids without triggering a 414 or
    // 431 status code from the server.
    const opts: FetcherOpts = {method: 'POST', path: 'api/rpc/get_layer_package3', headers, json_body};
    const buffer = await authedFetcher(opts);
    return (await layerPackageToGeoJSON(buffer)) as FeatureCollection<Polygon, LayerGeoProps>;
  },
);

export class LayerFetcher {
  static cropMonCache: {[params: string]: Promise<null | CropMonRes>} = {};
  layer_type: null | LayerType = null;
  canonical_date: null | string = null;
  max_updated_at: null | string = null;
  fieldIds: null | Set<string> = null;
  layerParams: null | LayerParams = null;
  callback: (geojson: null | FeatureCollection) => void;
  private authedFetcher: FetcherFunc;
  private setLoading: (loading: boolean) => void;
  private numFetches = 0;
  private currentExecution: null | Promise<[FeatureCollection, null | CropMonitoring, null | CropMonitoring]> = null;

  constructor(
    authedFetcher: FetcherFunc,
    callback: (geojson: null | FeatureCollection) => void,
    setLoading: (loading: boolean) => void,
  ) {
    this.authedFetcher = authedFetcher;
    this.callback = callback;
    this.setLoading = setLoading;
  }

  isLayerLoaded(
    layer_type: null | LayerType,
    canonical_date: null | string,
    max_updated_at: null | string,
    fieldIds: null | Set<string>,
    layerParams: string[],
  ) {
    return (
      this.layer_type == layer_type &&
      this.canonical_date == canonical_date &&
      this.max_updated_at == max_updated_at &&
      // We need to use equal instead of isSubsetOf to ensure we don't keep displaying fields
      // that have just been filtered out
      equal(fieldIds, this.fieldIds) &&
      isSubsetOf(new Set(layerParams), new Set(this.layerParams?.params))
    );
  }

  isSignificantLayerChange(
    layer_type: null | LayerType,
    canonical_date: null | string,
    max_updated_at: null | string,
    fieldIds: null | Set<string>,
    layerParams: string[],
  ) {
    if (this.layer_type != layer_type) {
      return true;
    }

    if (layer_type == 'crop-mon') {
      return this.canonical_date != canonical_date || !equal(this.layerParams?.params || [], layerParams);
    } else {
      return this.canonical_date != canonical_date;
    }
  }

  async fetch(
    layer_type: null | LayerType,
    canonical_date: null | string,
    max_updated_at: null | string,
    fieldIds: null | Set<string>,
    layerParams: null | LayerParams,
  ) {
    if (this.isLayerLoaded(layer_type, canonical_date, max_updated_at, fieldIds, layerParams?.params || [])) {
      // Don't do anything if we have already processed this fetch.
      return;
    }

    if (
      this.isSignificantLayerChange(layer_type, canonical_date, max_updated_at, fieldIds, layerParams?.params || [])
    ) {
      // Set the current layer's geojson to null, if this was more than a small update, while the new one is fetching.
      this.callback(null);
    }

    this.layer_type = layer_type;
    this.canonical_date = canonical_date;
    this.max_updated_at = max_updated_at;
    this.fieldIds = fieldIds;
    this.layerParams = layerParams;

    if (!layer_type || (layer_type == 'crop-mon' && !layerParams)) {
      this.callback(null);
      return;
    }

    const layerDate = layer_type == 'crop-mon' ? '2020-01-01' : canonical_date;
    const region = (layer_type == 'crop-mon' && layerParams?.params[0]) || null;
    if (!layerDate) {
      console.error(`Couldn't determine layer date`, layer_type, canonical_date, layerParams);
      return;
    }

    // For the predicted yield, also fetch the benchmark yield, so that we can calculate the yield in absolute terms.
    const benchmarkLayerParams: null | LayerParamsCropMon =
      layerParams && layerParams.layer_type == 'crop-mon' && layerParams.params[1] == 'predicted-yield'
        ? {...layerParams, params: [...layerParams.params]}
        : null;
    if (benchmarkLayerParams) {
      benchmarkLayerParams.params[1] = 'benchmark-yield';
    }

    // Don't start while other layer requests are ongoing
    if (this.currentExecution) {
      await this.currentExecution;
    }
    // Return if layer request has been superceded by a new one
    if (
      this.layer_type != layer_type ||
      this.canonical_date != canonical_date ||
      this.max_updated_at != max_updated_at ||
      fieldIds !== this.fieldIds ||
      layerParams !== this.layerParams
    ) {
      return;
    }

    this.numFetches++;
    this.setLoading(true);
    try {
      this.currentExecution = Promise.all([
        fetchLayer(this.authedFetcher, layer_type, layerDate, fieldIds && Array.from(fieldIds), region),
        layerParams && layerParams.layer_type == 'crop-mon' ? this.getCropMon(layerParams, canonical_date) : null,
        benchmarkLayerParams ? this.getCropMon(benchmarkLayerParams, canonical_date) : null,
      ]);
      let [geojson, cropMon, benchmark] = await this.currentExecution;

      if (!this.isLayerLoaded(layer_type, canonical_date, max_updated_at, fieldIds, layerParams?.params || [])) {
        return;
      }

      if (layer_type == 'crop-mon') {
        // deep copy geojson to ensure its always a new object downstream and react correctly rerenders UI
        geojson = deepCopy(geojson);
        for (const x of geojson.features) {
          if (!x.properties) {
            continue;
          }

          const properties = x.properties as CropMonGeoProps;
          const region_id = properties.shape_type;
          properties.value = region_id && cropMon ? cropMon.data[region_id] : null;
          properties.benchmark_yield_t_ha = region_id && benchmark ? benchmark.data[region_id] : null;
        }
      }
      this.callback(geojson);
    } finally {
      this.numFetches--;
      this.currentExecution = null;
      this.setLoading(this.numFetches > 0);
    }
  }

  async getCropMon(layerParams: LayerParamsCropMon, canonical_date: null | string): Promise<null | CropMonRes> {
    const paramsStr = JSON.stringify([layerParams.params, canonical_date]);
    if (!(paramsStr in LayerFetcher.cropMonCache)) {
      LayerFetcher.cropMonCache[paramsStr] = this.fetchCropMon(layerParams, canonical_date);
    }

    return LayerFetcher.cropMonCache[paramsStr];
  }

  async fetchCropMon(layerParams: LayerParamsCropMon, canonical_date: null | string): Promise<null | CropMonRes> {
    const type: CropMonType = layerParams.params[1];
    const harvest_crop: HarvestCrop = layerParams.params[2];
    const harvest_season: HarvestSeason = layerParams.params[3];
    const harvest_year: HarvestYear = layerParams.params[4];

    const params: [string, string][] = [
      ['country_code', 'eq.' + layerParams.params[0]],
      ['type', 'eq.' + type],
      ['harvest_crop', 'eq.' + harvest_crop],
      ['harvest_season', 'eq.' + harvest_season],
    ];
    if (type == 'historical-yield' || type == 'predicted-yield') {
      if (!canonical_date) {
        return null;
      }
      params.push(['canonical_date', 'eq.' + canonical_date]);
    }

    if (type == 'benchmark-yield' || type == 'predicted-yield' || type == 'expected-loss') {
      params.push(['harvest_year', 'eq.' + harvest_year]);
    }

    const opts: FetcherOpts = {
      method: 'GET',
      path: 'api/crop_monitoring',
      params,
      headers: [['Accept', 'application/vnd.pgrst.object+json']],
    };
    try {
      return await this.authedFetcher(opts);
    } catch (e) {
      return null;
    }
  }
}
