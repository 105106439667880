import {Feature, FeatureCollection} from 'geojson';
import {open, read} from 'shapefile';
import {Readable} from 'stream';

export interface ShpOptions {
  encoding?: string;
  highWaterMark?: number;
}

export interface ShpSource<RecordType> {
  bbox: number[];

  read(): Promise<{done: boolean; value: RecordType}>;

  cancel(): Promise<void>;
}

export type Openable = string | ArrayBuffer | NodeJS.ReadableStream | Readable | Uint8Array;

export function openShapefile(shp: Openable, dbf?: Openable, options?: ShpOptions): Promise<ShpSource<Feature>> {
  return open(shp, dbf, options);
}

export function readShapefile(shp: Openable, dbf?: Openable, options?: ShpOptions): Promise<FeatureCollection> {
  return read(shp, dbf, options);
}
