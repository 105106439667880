import produce from 'immer';
import {
  Claim,
  ClaimDamage,
  Farm,
  Field,
  Harvest,
  Policy,
  Sample,
  UserGroup,
  UserGroupMembership,
  Visit,
  VisitLite,
} from '../../models/interfaces';
import {EntityType, SqlTransportFormat, TableName} from '../../models/serialization';
import {DoesInterfaceCoverEnum} from '../../util/types';
import {DbMetaActions} from '../actions/db';

export type IndexedFarms = {[farm_id: string]: Farm};
export type IndexedFields = {[field_id: string]: Field};
export type IndexedHarvests = {[harvest_id: string]: Harvest};
export type IndexedSamples = {[sample_id: string]: Sample};
export type IndexedPolicies = {[policy_id: string]: Policy};
export type IndexedVisits = {[visit_id: string]: VisitLite};
export type IndexedClaims = {[claim_id: string]: Claim};
export type IndexedClaimDamage = {[claim_damage_id: string]: ClaimDamage};

export type UserState = undefined | {email: string; name: string};

export interface MutationQueueItemCommon {
  // A unique identifier which is used to identify this request and eventually remove it from the queue.
  // Can be the primary id of the added entity.
  requestId: string;
  retryAfter?: number;
  retries: number;
}

export interface MutationQueueItemInsert extends MutationQueueItemCommon {
  mutationType: 'insert';
  table: TableName;
  // JSON object containing the queued entity.
  entityTransport: SqlTransportFormat<EntityType>;
}

export interface MutationQueueItemDelete extends MutationQueueItemCommon {
  mutationType: 'delete';
  table: TableName;
  // For deletes, this contains the UUID of the deleted entity.
  primaryKey: string;
}

export interface MutationQueueItemUpdate extends MutationQueueItemCommon {
  mutationType: 'update';
  table: TableName;
  primaryKey: string; // For updates, this contains the UUID of the edited entity.
  // JSON object containing the queued entity.
  entityTransport: Partial<SqlTransportFormat<EntityType>>;
}

export interface MutationQueueItemInsertMany extends MutationQueueItemCommon {
  mutationType: 'insert-many';
  // JSON objects containing the inserted entities.
  entityTransport: {
    farms: SqlTransportFormat<Farm>[];
    policies: SqlTransportFormat<Policy>[];
    fields: SqlTransportFormat<Field>[];
    harvests: SqlTransportFormat<Harvest>[];
    claims: Claim[];
    claim_damages: SqlTransportFormat<ClaimDamage>[];
    visits: SqlTransportFormat<Visit>[];
  };
}

export type MutationQueueItem =
  | MutationQueueItemDelete
  | MutationQueueItemInsert
  | MutationQueueItemInsertMany
  | MutationQueueItemUpdate;

export type DbMetaState = {
  mutationQueueLocked: boolean;
  mutationQueue: MutationQueueItem[];
  lastFullSync: null | string; // lastFullSync === null only before the first full load has finished (after logging in).
  updated_at: null | string; // The max updated_at of any entity in state.db.

  curUser: UserState;

  userGroups: UserGroup[];

  userGroupMemberships: UserGroupMembership[];

  todaysDateUtc: string;
};

export interface DbState {
  farm: IndexedFarms;
  field: IndexedFields;
  harvest: IndexedHarvests;
  sample: IndexedSamples;
  policy: IndexedPolicies;
  visit: IndexedVisits;
  claim: IndexedClaims;
  claim_damage: IndexedClaimDamage;
}

'covered' as DoesInterfaceCoverEnum<DbState, TableName>;

export type CoreDbState = Pick<DbState, 'farm' | 'field' | 'harvest' | 'policy' | 'sample'>;

export const initialDbMetaState: DbMetaState = {
  mutationQueueLocked: false,
  curUser: undefined,
  mutationQueue: [],
  lastFullSync: null,
  updated_at: null,
  userGroups: [],
  userGroupMemberships: [],
  todaysDateUtc: '',
};

export function dbMeta(state = initialDbMetaState, action: DbMetaActions): DbMetaState {
  switch (action.type) {
    case 'RESET':
      return initialDbMetaState;
    case 'ADD_TO_MUTATION_QUEUE':
      return {
        ...state,
        mutationQueue: [...state.mutationQueue, action.item],
      };
    case 'LOCK_MUTATION_QUEUE':
      return {
        ...state,
        mutationQueueLocked: true,
      };
    case 'UNLOCK_MUTATION_QUEUE':
      return {
        ...state,
        mutationQueueLocked: false,
      };
    case 'REMOVE_FROM_INSERT_QUEUE':
      return {
        ...state,
        mutationQueue: state.mutationQueue.filter(i => i.requestId !== action.requestId),
      };
    case 'SET_LAST_FULL_SYNC':
      return {
        ...state,
        lastFullSync: action.lastFullSync,
      };
    case 'SET_UPDATED_AT':
      return {
        ...state,
        updated_at: action.updated_at,
      };
    case 'SET_USER_GROUPS':
      return {
        ...state,
        userGroups: action.userGroups,
      };
    case 'SET_USER_GROUP_MEMBERSHIPS':
      return {
        ...state,
        userGroupMemberships: action.userGroupMemberships,
      };
    case 'UPDATE_USER': {
      const curUser = {email: action.email, name: action.name};
      return {...state, curUser};
    }
    case 'SET_TODAYS_DATE':
      if (action.todaysDateUtc != state.todaysDateUtc) {
        return {...state, todaysDateUtc: action.todaysDateUtc};
      } else {
        return state;
      }
    case 'RETRY_MUTATION_ITEM':
      return {
        ...state,
        mutationQueue: state.mutationQueue.map(item => {
          if (item.requestId === action.requestId) {
            return {
              ...item,
              retries: item.retries + 1,
              retryAfter: action.retryAfter,
            };
          }
          return item;
        }),
      };
    case 'UPDATE_MUTATION_ITEM':
      return produce(state, draft => {
        const item = draft.mutationQueue.find(item => item.requestId === action.requestId);
        if (item?.mutationType == 'insert' || item?.mutationType == 'update') {
          item.entityTransport = action.entityTransport;
        } else {
          console.info('mutation item not found or had invalid type:', JSON.stringify(action), JSON.stringify(item));
        }
      });
    default:
      return state;
  }
}
