import MapboxGeocoder, {Result} from '@mapbox/mapbox-gl-geocoder';
import {Button, Menu, message} from 'antd';
import {Feature, Point} from 'geojson';
import mapboxgl, {MapLayerMouseEvent} from 'mapbox-gl';
import queryString from 'query-string';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {ConnectedProps, connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import alpha3to2 from '../../../src/alpha-3-to-2.json';
import {LngLat, parseLatlng} from '../../../src/geo';
import {getNearestLayerDate} from '../../../src/layers/canonical-date';
import {LayerParams, LayerParamsCropMon, parseInteryieldCombo} from '../../../src/layers/layer-params';
import {isMapLayer} from '../../../src/layers/map-layers';
import {EntityGeoProps} from '../../../src/models/geojson';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import Header from '../components/Header';
import CropMonToolbox from '../map/CropMonToolbox';
import InteryieldMapToolbox from '../map/InteryieldMapToolbox';
import Map, {MapProps} from '../map/Map';
import '../map/Map.css';
import {MapOverlay} from '../map/MapOverlay';
import {MapToolbox} from '../map/MapToolbox';
import {SelectableMapStatValues} from '../map/useMapStatsLayer';
import {State} from '../redux';

const mapStateToProps = (_: State, props: RouteComponentProps) => {
  let [, , layer, canonical_date, layerParamStr] = props.history.location.pathname.split('/');

  let res: Omit<MapProps, 'onMapboxMapInstance' | 'showEntityFeatures'> = {
    layer: isMapLayer(layer) ? layer : 'base',
    canonical_date,
    layerParams: null,
    focus: null,
  };

  if (layer == 'interyield') {
    const params = parseInteryieldCombo(layerParamStr ?? '');
    if (params) {
      res.layerParams = {layer_type: 'interyield', params};
    }
  } else if (layer == 'crop-mon' && layerParamStr?.length) {
    res.layerParams = {
      layer_type: 'crop-mon',
      params: layerParamStr.split('+') as LayerParamsCropMon['params'],
    };
  } else if (layer == 'satellite' && layerParamStr?.length) {
    res.layerParams = {
      layer_type: 'planet',
      params: [layerParamStr],
    };
  }

  const query = queryString.parse(props.history.location.search);

  if (query.sample_id) {
    res.focus = {type: 'sample', id: query.sample_id as string};
  } else if (query.field_id) {
    res.focus = {type: 'field', id: query.field_id as string};
  } else if (query.farm_id) {
    res.focus = {type: 'farm', id: query.farm_id as string};
  } else if (query.latlng) {
    const latlngStr: string = (query.latlng instanceof Array ? query.latlng[0] : query.latlng) as string;
    const location = parseLatlng(latlngStr) ?? undefined;
    if (location) {
      res.focus = {type: 'lnglat', location};
    }
  }

  return res;
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MapRouterProps = PropsFromRedux & RouteComponentProps;
type MapRouterState = {
  overlayExpanded: boolean;
  showEntityFeatures: boolean;
  selectedMapStat: SelectableMapStatValues;
};

export class MapRouter_ extends React.Component<MapRouterProps, MapRouterState> {
  static contextType = ApisContext;
  context!: Apis;

  state = {
    overlayExpanded: false,
    showEntityFeatures: true,
    selectedMapStat: 'total_area_ha',
  } as MapRouterState;
  map?: mapboxgl.Map;

  static getDerivedStateFromProps(props: MapRouterProps, state: MapRouterState) {
    if (props.focus?.type != 'field' && state.overlayExpanded) {
      return {overlayExpanded: false};
    }

    return null;
  }

  menuMarker: null | mapboxgl.Marker = null;

  onMapboxMapInstance = (map: mapboxgl.Map) => {
    this.map = map;
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      localGeocoder: this.coordinatesGeocoder,
      marker: false,
      mapboxgl: mapboxgl as any,
    });
    geocoder.on('result', this.handleGeocoderResult);
    const countries = getCountryCodeGroups(this.context.store.getState());
    geocoder.setCountries(
      countries
        .map(alpha3 => alpha3to2[alpha3 as keyof typeof alpha3to2])
        .filter(x => !!x)
        .slice(0, 15)
        .join(','),
    );
    document.getElementById('geocoder')?.appendChild(geocoder.onAdd(this.map));
    this.map.on('click', this.handleMapClick);
    this.map.on('contextmenu', this.handleContextMenu);
  };

  handleMapClick = (_: MapLayerMouseEvent) => {
    this.menuMarker?.remove();
  };

  handleContextMenu = (e: MapLayerMouseEvent) => {
    this.menuMarker?.remove();

    const el = document.createElement('div');
    const root = createRoot(el);
    root.render(
      <Menu
        style={{width: 256}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={[{label: this.context.t('LinkToThisLocation'), key: 'set-lat-lng'}]}
        onClick={() => {
          this.setLngLatFocus([e.lngLat.lng, e.lngLat.lat]);
          navigator.clipboard.writeText(window.location.href);
          message.info(this.context.t('LinkCopiedToClipboard'));
        }}
      />,
    );
    this.menuMarker = new mapboxgl.Marker(el, {anchor: 'top-left'});
    this.map && this.menuMarker.setLngLat(e.lngLat).addTo(this.map);
  };

  componentDidUpdate(prevProps: Readonly<MapRouterProps>) {
    if (prevProps.layer != this.props.layer && this.props.layer != 'interyield') {
      let nearestDate: null | string = getNearestLayerDate(
        new Date(this.context.clock.now()),
        this.props.layer,
        prevProps.canonical_date,
      );
      this.onCanonicalDateChanged(nearestDate);
    }
  }

  coordinatesGeocoder = (q: string): Result[] => {
    const parsed = parseLatlng(q);
    if (!parsed) {
      return [];
    }

    const result: Partial<Result> = {
      type: 'Feature',
      id: 'lnglat',
      center: parsed,
      geometry: {
        type: 'Point',
        coordinates: parsed,
      },
      place_name: 'Lat: ' + parsed[1] + ' Lng: ' + parsed[0],
      place_type: ['coordinate'],
      properties: {},
    };
    return [result as Result];
  };

  onTsDateSelected = (date: string) => {
    let nearestDate: null | string = getNearestLayerDate(new Date(this.context.clock.now()), this.props.layer, date);
    if (nearestDate) {
      this.onCanonicalDateChanged(nearestDate);
    }
  };

  toggleEntityFeatures = () => this.setState({showEntityFeatures: !this.state.showEntityFeatures});

  render() {
    const layerParams = this.props.layerParams;
    const canonical_date = this.props.canonical_date;

    return (
      <span className="map-router-container">
        <Header showFilters={true}>
          <div className="filters-map">
            <Button id="filtersButton" className="header-button" size="small" onClick={this.toggleEntityFeatures}>
              {this.state.showEntityFeatures ? this.context.t('HideDataFromMap') : this.context.t('ShowDataOnMap')}
            </Button>
            <div id="geocoder" className="geocoder"></div>
          </div>
        </Header>
        <div className="Map map-router-map">
          <Map
            onMapboxMapInstance={this.onMapboxMapInstance}
            showEntityFeatures={this.state.showEntityFeatures}
            canonical_date={canonical_date}
            onFeaturesClicked={this.onFeaturesClicked}
            focus={this.props.focus}
            layerParams={layerParams}
            layer={this.props.layer}
            selectedMapStat={this.state.selectedMapStat}
          />

          <MapOverlay
            expanded={this.state.overlayExpanded}
            onToggleSize={this.toggleOverlay}
            onClose={this.closeOverlay}
            onTsDateSelected={this.onTsDateSelected}
            focus={this.props.focus}
            canonical_date={canonical_date}
          />

          {this.state.overlayExpanded ? null : this.props.layer == 'crop-mon' ? (
            <CropMonToolbox
              canonical_date={canonical_date}
              layerParams={layerParams}
              onCanonicalDateChanged={this.onCanonicalDateChanged}
              onLayerParamsChanged={this.onLayerParamsChanged}
            />
          ) : this.props.layer == 'interyield' ? (
            <InteryieldMapToolbox
              layerParams={layerParams?.layer_type == 'interyield' ? layerParams : null}
              onCanonicalDateChanged={this.onCanonicalDateChanged}
              onLayerParamsChanged={this.onLayerParamsChanged}
              canonical_date={canonical_date}
            />
          ) : (
            <MapToolbox
              layer={this.props.layer}
              canonical_date={canonical_date}
              layerParams={layerParams}
              onCanonicalDateChanged={this.onCanonicalDateChanged}
              onSelectedMapStatChanged={this.onSelectedMapStatChanged}
              showEntityFeatures={this.state.showEntityFeatures}
              selectedMapStat={this.state.selectedMapStat}
              onLayerParamsChanged={this.onLayerParamsChanged}
            />
          )}
        </div>
      </span>
    );
  }

  toggleOverlay = (overlayExpanded: boolean) => this.setState({overlayExpanded});

  closeOverlay = () => {
    this.props.history.push({search: ''});
    this.setState({overlayExpanded: false});
  };

  onCanonicalDateChanged = (date: null | string) => {
    const urlPath = this.props.history.location.pathname.split('/');
    urlPath[3] = date || '';
    this.props.history.replace({...this.props.history.location, pathname: urlPath.join('/')});
  };

  onSelectedMapStatChanged = (selected: SelectableMapStatValues) => {
    this.setState({selectedMapStat: selected});
  };

  onLayerParamsChanged = (layerParams: null | LayerParams) => {
    const urlPath = this.props.history.location.pathname.split('/');
    urlPath[4] = layerParams ? layerParams.params.join('+') : '';
    this.props.history.replace({...this.props.history.location, pathname: urlPath.join('/')});
  };

  setLngLatFocus = (x: LngLat) => {
    const latlng = `${x[1].toFixed(5)},${x[0].toFixed(5)}`;
    const search = queryString.stringify({latlng});
    const history = this.props.history;
    history.replace({...history.location, search});
  };

  handleGeocoderResult = (x: {result: Result}) => {
    if (x.result.id == 'lnglat') {
      this.setLngLatFocus(x.result.center as LngLat);
    } else {
      const history = this.props.history;
      history.replace({...history.location, search: ''});
    }
  };

  onFeaturesClicked = (features: Feature<Point, EntityGeoProps>[]): boolean => {
    const history = this.props.history;
    for (const f of features) {
      if (f.properties.sample_id) {
        const search = queryString.stringify({sample_id: f.properties.sample_id});
        history.replace({...history.location, search});
        return true;
      } else if (f.properties.field_id) {
        const search = queryString.stringify({field_id: f.properties.field_id});
        history.replace({...history.location, search});
        return true;
      } else if (f.properties.farm_id) {
        const search = queryString.stringify({farm_id: f.properties.farm_id});
        history.replace({...history.location, search});
        return true;
      }
    }

    return false;
  };
}

export default connector(MapRouter_);
