import {CommonViewStyle} from '../components/CommonViewStyle';
import {I18nFunction, I18nSimpleKey} from '../i18n/i18n';
import cmp from '../util/cmp';
import {FormyComponentProps} from './FormyComponent';
import {FormyI} from './index';

export function getI18nOptions<O extends I18nSimpleKey>(
  t: I18nFunction,
  options: readonly O[],
  sort?: boolean,
): [O, string][] {
  const res: [O, string][] = options.map(x => [x, t(x)]);
  if (sort) {
    res.sort((a, b) => cmp(a[1], b[1]));
  }
  return res;
}

export function identityOptions(x: string[]): [string, string][] {
  const res = x.map(i => [i, i] as [string, string]);
  res.sort((a, b) => cmp(a[1], b[1]));
  return res;
}

export interface FormyEnumProps<O, F extends {[P in Fk]: null | O}, Fk extends keyof F>
  extends FormyComponentProps<F, Fk> {
  options: [O, string][];
  selectMsg: null | I18nSimpleKey;
  required?: boolean;
  style?: CommonViewStyle;
  disabled?: boolean;
}

export interface FormyMultiEnumProps<O, F extends {[P in Fk]: O[]}, Fk extends keyof F> {
  options: [O, string][];
  selectMsg: I18nSimpleKey;
  field: Fk;
  formy: FormyI<F>;
  style?: CommonViewStyle;
  maxTagCount?: number | 'responsive';
}
