import React from 'react';
import {CROP_COLORS} from '../../../src/constants/colors';
import {MGA_DEFAULT_PAYMENT_DAYS, axaContacts, axaQrCode, axaSignatures} from '../../../src/custom/mga';
import {
  FarmCustomColumns,
  HarvestCustomColumns,
  PolicyCustomColumns,
  ProductType,
} from '../../../src/models/CustomColumns';
import {SoilType} from '../../../src/models/types';
import {PolicyReportPackage} from '../../../src/report/report-types';
import {convertYield} from '../../../src/selectors/units';
import {formatDate} from '../../../src/text/date';
import {remove, unique} from '../../../src/util/arr-util';
import {
  calculateBasicPremium,
  calculateBasicSumInsured,
  calculateCoveragePerArea,
  calculateReplantingPremium,
  calculateReplantingSumInsured,
} from '../../../src/util/policy-calculations';
import {FieldShape} from '../components/FieldShape';
import './AxaPolicyReport.css';
import {ReportApis} from './StandaloneReport';

function formatSoilType(soilType: null | undefined | SoilType) {
  if (soilType === 'clay') {
    return 'argiloso';
  } else if (soilType === 'sandy') {
    return 'arenoso';
  } else if (soilType === 'average') {
    return 'médio';
  }
  return '';
}

function formatProductType(productType: null | undefined | ProductType) {
  switch (productType) {
    case 'productivity':
      return 'Produtividade';
    case 'costing':
      return 'Custeio';
    default:
      return null;
  }
}

function formatBraNumber(input: null | undefined | number, isCurrency: boolean) {
  return input?.toLocaleString('pt-BR', {useGrouping: true, minimumFractionDigits: isCurrency ? 2 : 0});
}

function formatQuestionAnswer(answer: null | undefined | boolean) {
  return answer ? 'Sim' : 'Não';
}

function getDueDate(added_on: string, totalPaymentDays: number) {
  const dueDate = new Date(added_on);
  dueDate.setDate(dueDate.getDate() + totalPaymentDays);
  return dueDate.toLocaleDateString();
}

export const AxaPolicyReport: React.FC<PolicyReportPackage & ReportApis> = props => {
  const policy = props.policyData.policy;
  const farm = props.policyData.farm;
  const farmLevelHarvest = props.policyData.farmLevelHarvest;
  const fieldLevelHarvests = props.policyData.fieldHarvests;
  const fields = props.policyData.fields;
  const policyCustomColumns = new PolicyCustomColumns(policy.custom_columns);
  const farmCustomColumns = new FarmCustomColumns(farm.custom_columns);
  const harvestCustomColumns = new HarvestCustomColumns(farmLevelHarvest.custom_columns);
  const coveragePerArea = calculateCoveragePerArea(
    farmLevelHarvest.reference_yield,
    farmLevelHarvest.commodity_price,
    props.policyData.insuredYieldInBagsPerHa,
  );
  const basicSumInsured = calculateBasicSumInsured(coveragePerArea, props.policyData.totalArea);
  const basicPremium = calculateBasicPremium(basicSumInsured, props.policyData.premiumRatePercent);
  const replantingSumInsured = calculateReplantingSumInsured(basicSumInsured);
  const replantingPremium = calculateReplantingPremium(
    replantingSumInsured,
    (harvestCustomColumns.mga?.replanting_premium_rate_percent ?? 0) / 100,
  );
  const totalPremium = basicPremium + replantingPremium;
  const federalSubsidy = policyCustomColumns.mga?.federal_subsidy ?? 0;
  const stateSubsidy = policyCustomColumns.mga?.state_subsidy ?? 0;
  const premiumPaidByInsured: number = totalPremium - federalSubsidy - stateSubsidy;
  // TODO(kristjan): verify with AXA what are the default payment days in case we don't receive them from eCampo
  const dueDate = getDueDate(policy.added_on, policyCustomColumns.mga?.total_payment_days ?? MGA_DEFAULT_PAYMENT_DAYS);
  const policyExternalId = policyCustomColumns.mga?.axa_policy_id;
  return (
    <div className="policy-report-container">
      <div className="policy-report-title">
        <h1 className="bold">Proposta de Seguros</h1>
        <h2>AXA Seguro Agrícola</h2>
      </div>
      <div className="mb-20">
        <h3>Dados do Seguro</h3>
        <div className="grid four-columns four-columns-stripes emphasized-stripes">
          <div>Data Emissão da Proposta</div>
          <div>{new Date(policy.added_on).toLocaleDateString()} às 00:00h</div>
          <div>Início de vigência</div>
          <div>
            {policyCustomColumns.valid_from
              ? `${new Date(policyCustomColumns.valid_from).toLocaleDateString()} às 24h`
              : null}
          </div>
          <div>Versão</div>
          {/* The version is most likely hardcoded based on the discussion with the customer. */}
          <div>Janeiro/2025</div>
          <div>Fim de vigência</div>
          <div>
            {policyCustomColumns.valid_to
              ? `${new Date(policyCustomColumns.valid_to).toLocaleDateString()} às 24h`
              : null}
          </div>
          <div>Proposta</div>
          <div>{policy.policy_number}</div>
          <div>Tipo de vigência</div>
          {/* Number of days is most likely hardcoded based on the discussion with the customer. */}
          <div>180 dias</div>
          <div>Apólice</div>
          <div>{policyExternalId}</div>
          <div>Cultura</div>
          <div>{props.policyData.cropId}</div>
          <div>Ramo</div>
          {/* Ramo is most likely hardcoded based on the discussion with the customer. */}
          <div>01.11 – Seguro Agrícola</div>
          <div>Tipo de Seguro</div>
          <div>{formatProductType(policyCustomColumns.mga?.product_type)}</div>
          <div>Subvenção Federal</div>
          <div>{formatQuestionAnswer(!!policyCustomColumns.mga?.federal_subsidy)}</div>
          <div>NC (%)</div>
          <div>{farmLevelHarvest.insured_percent}</div>
          <div>Subvenção Estadual</div>
          <div>{formatQuestionAnswer(!!policyCustomColumns.mga?.state_subsidy)}</div>
          <div>Subvenção Municipal</div>
          {/* Municipality subsidies are done manually so the value here is always false. */}
          <div>Não</div>
        </div>
      </div>
      <div className="mb-20">
        <h3>Dados do Segurado</h3>
        <div className="grid four-columns four-columns-stripes">
          <div>Segurado</div>
          <div>{policyCustomColumns.insured?.name}</div>
          <div>CNPJ/CPF</div>
          <div>{policyCustomColumns.insured?.external_id}</div>
          <div>Nome Social do Segurado</div>
          <div>{policyCustomColumns.insured?.alternative_name}</div>
          <div>Profissão/Ocupação</div>
          {/* Profissao/Ocupacao is most likely hardcoded based on the discussion with the customer. */}
          <div>Produtor Rural</div>
          <div>Endereço</div>
          <div>{policyCustomColumns.insured?.address?.street}</div>
          <div>Número</div>
          <div>{policyCustomColumns.insured?.address?.number}</div>
          <div>Complemento</div>
          <div>{policyCustomColumns.insured?.address?.additional_information}</div>
          <div>Bairro</div>
          <div>{policyCustomColumns.insured?.address?.neighborhood}</div>
          <div>Cidade</div>
          <div>{policyCustomColumns.insured?.address?.city}</div>
          <div>UF</div>
          <div>{policyCustomColumns.insured?.address?.state}</div>
          <div>CEP</div>
          <div>{policyCustomColumns.insured?.address?.zip_code}</div>
          <div>E-mail</div>
          <div>{policyCustomColumns.insured?.email}</div>
          <div>Telefone com DDD</div>
          <div>{policyCustomColumns.insured?.phone}</div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="mb-20">
        {/* Dados da Segurardora is most likely hardcoded based on the discussion with the customer. */}
        <h3>Dados da Seguradora</h3>
        <div className="grid four-columns four-columns-stripes">
          <div>Seguradora</div>
          <div>AXA Seguros S/A</div>
          <div>Registro SUSEP</div>
          <div>0285-2</div>
          <div>CNPJ</div>
          <div>19.323.190/0001-06</div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="mb-20">
        {/* Representante de Seguro is most likely hardcoded based on the discussion with the customer. */}
        <h3>Representante de Seguro</h3>
        <div className="grid four-columns four-columns-stripes">
          <div>Nome/Razão Social</div>
          <div>Seguros Brasileira Rural</div>
          <div>Participação sobre o Prêmio Total</div>
          <div>6%</div>
          <div>CNPJ</div>
          <div>48.849.229/0001-21</div>
          <div></div>
          <div></div>
        </div>
        <div className="description">
          * Informamos que o Contrato de Representação em Seguros firmado entre a Seguradora e o Representante de
          Seguros, prevê a possibilidade de reversão de parte do resultado operacional positivo ao Representante. Na
          ocasião, tal reversão será paga diretamente ao Representante de Seguros.
        </div>
      </div>
      <div className="gt-report-page-separator-before" />
      <div className="gt-report-page-separator-after" />
      {policyCustomColumns.brokers?.map((broker, index) => {
        return (
          <div className="mb-20">
            <h3>Dados do Corretor {index}</h3>
            <div className="grid four-columns four-columns-stripes">
              <div>Nome/Razão Social</div>
              <div>{broker.entity?.name}</div>
              <div>Participação</div>
              <div>{broker.percent}</div>
              <div>CNPJ</div>
              <div>{broker.entity?.external_id}</div>
              {/* TODO(kristjan): waiting for AXA to send us detailed broker information. */}
              <div>Sucursal</div>
              <div></div>
              <div>Número na SUSEP</div>
              <div></div>
              <div>E-mail</div>
              <div>{broker.entity?.email}</div>
            </div>
          </div>
        );
      })}
      <div className="mb-20">
        <h3>Resumo do Risco</h3>
        <div className="grid four-columns four-columns-stripes">
          <div>Cultura</div>
          <div>{props.policyData.cropId}</div>
          <div>Área Total (ha)</div>
          <div>{formatBraNumber(props.policyData.totalArea, false)}</div>
          <div>Total de itens</div>
          <div>{fieldLevelHarvests.length}</div>
          <div>Nome da propriedade</div>
          <div>{farm.farm_name}</div>
          <div>Endereço do Risco</div>
          <div>{farmCustomColumns.address?.street}</div>
          <div>Nº</div>
          <div>{farmCustomColumns.address?.number ?? 's/n'}</div>
          <div>Complemento</div>
          <div>{farmCustomColumns.address?.additional_information}</div>
          <div>Cidade</div>
          <div>{farmCustomColumns.address?.city}</div>
          <div>UF</div>
          <div>{farmCustomColumns.address?.state}</div>
          <div>CEP</div>
          <div>{farmCustomColumns.address?.zip_code}</div>
          <div>Valor do Risco</div>
          <div>R$ {formatBraNumber(basicSumInsured, true)}</div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="mb-20">
        <h3>Prêmio (Preço do Seguro)</h3>
        <div className="grid two-columns two-columns-stripes">
          <div>Preço do Seguro Líquido</div>
          <div>R$ {formatBraNumber(totalPremium, true)}</div>
          <div>Valor do IOF</div>
          <div>Isento</div>
          <div>Preço do Seguro Total</div>
          <div>R$ {formatBraNumber(totalPremium, true)}</div>
          <div>Preço do Seguro para o Segurado</div>
          <div>R$ {formatBraNumber(premiumPaidByInsured, true)}</div>
          <div>Preço do Seguro referente a Subvenção Federal</div>
          <div>
            {policyCustomColumns.mga?.federal_subsidy
              ? 'R$' + formatBraNumber(policyCustomColumns.mga?.federal_subsidy, true)
              : null}
          </div>
          <div>Preço do Seguro referente a Subvenção Estadual</div>
          <div>
            {policyCustomColumns.mga?.federal_subsidy
              ? 'R$' + formatBraNumber(policyCustomColumns.mga?.state_subsidy, true)
              : null}
          </div>
          <div>Adicional de Fracionamento 2% ao mês (Moeda Real)</div>
          <div>R$ 0,00</div>
          <div>Condições de Pagamento</div>
          <div>
            1
            {policyCustomColumns.mga?.installment_count && policyCustomColumns.mga.installment_count > 1
              ? ' +' + policyCustomColumns.mga?.installment_count
              : ''}{' '}
            BOLETO
          </div>
        </div>
      </div>
      <div className="mb-20">
        <h3>Vencimento das Parcelas</h3>
        <div className="grid three-columns">
          <div>Parcela Nº</div>
          <div>Vencimento</div>
          <div>Valor</div>

          <div>
            <span className="bold">1</span> Segurado
          </div>
          <div>{dueDate}</div>
          <div>R$ {formatBraNumber(premiumPaidByInsured, true)}</div>

          <div>
            <span className="bold">2</span> Subvenção Federal
          </div>
          <div>{dueDate}</div>
          <div>R$ {formatBraNumber(federalSubsidy, true)}</div>

          <div>
            <span className="bold">3</span> Subvenção Estadual
          </div>
          <div>{dueDate}</div>
          <div>R$ {formatBraNumber(stateSubsidy, true)}</div>

          {/* Municipality subsidies are not supported. */}
          <div>
            <span className="bold">4</span> Subvenção Municipal
          </div>
          <div>{dueDate}</div>
          <div>R$ {formatBraNumber(0, true)}</div>
        </div>
        <p className="description">
          Conforme Lei n°12.741 de 08 de Dezembro de 2012 a AXA Seguros S.A. vem informar a seus aos seus segurados a
          alíquota de 0,65% de PIS/PASEP e de 4% de COFINS sobre os prêmios de seguros, deduzidos do estabelecido em
          legislação específica
        </p>
      </div>
      <div className="gt-report-page-separator-before" />
      <div className="gt-report-page-separator-after" />

      {policyExternalId && (
        <>
          <div className="mb-20">
            <div className="plain-grid two-columns-extra">
              <div>
                <h3 className="emphasized">Olá</h3>
                <p>Antes de iniciarmos, nós da AXA precisamos te explicar algumas coisas, tudo bem?</p>
                <p>
                  Esta é a <span className="emphasized">Apólice do seu Seguro</span>, contratada de acordo com a
                  Proposta descrita na primeira página deste documento. Essa apólice irá trazer alguns detalhes
                  importantes e informações específicas do seu Seguro, como Limite da Indenização, valor do Preço de
                  Seguro e da POS (Participação Obrigatória do Segurado), etc.
                </p>
                <p>
                  O documento completo com todas as regras e obrigações é aquele que chamamos de{' '}
                  <span className="emphasized">Condições Contratuais</span> e pode ser acessado por este QR Code ao
                  lado. É muito importante que você leia o documento completo para entender o que está coberto pelo
                  seguro e seus direitos e deveres como Segurado.
                </p>
                <p>
                  Importante ressaltar ainda que as Condições Contratuais preveem as regras de todas as coberturas
                  oferecidas pela AXA neste Seguro Agrícola, mas no seu caso específico só estarão inclusas no seu
                  Seguro as coberturas contratadas nesta Apólice.
                </p>
              </div>
              <div className="gt-flex-card">
                <span>Condições Gerais do Seguro Agrícola</span>
                <img src={axaQrCode} />
              </div>
            </div>
          </div>
          <div className="mb-20">
            <img src={axaContacts} />
          </div>
          <div className="gt-report-page-separator-before" />
          <div className="gt-report-page-separator-after" />
        </>
      )}
      <div className="mb-20">
        <h3>Questionário de Avaliação do Risco</h3>
        <div className="grid two-columns two-columns-stripes emphasized-stripes">
          <div>Utiliza Crédito Rural?</div>
          <div>{formatQuestionAnswer(!!policyCustomColumns.beneficiaries?.length)}</div>
          <div>Qual a cultura antecessora?</div>
          <div>{formatQuestionAnswer(!!policyCustomColumns.mga?.previous_crop)}</div>
          <div>Cultura já implantada?</div>
          <div>{formatQuestionAnswer(!!policyCustomColumns.mga?.crop_already_planted)}</div>
          <div>
            Caso a cultura já esteja implantada, possui danos ocasionados por eventos que serão cobertos pelo seguro?
          </div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.crop_already_damaged)}</div>
          <div>Cultura está implantada em área de 1º ou 2º plantio da cultura em questão?</div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.first_or_second_year)}</div>
          <div>Notas fiscais serão faturadas em nome do segurado?</div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.insured_invoice_recipient)}</div>
          <div>Você conhece e adotou as recomendações do Zoneamento Agrícola de Riscos Climáticos do MAPA?</div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.aware_of_zoagro_mapa)}</div>
          <div>As áreas declaradas no seguro representam toda a área cultivada na propriedade?</div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.total_planted_area_insured)}</div>
          <div>As áreas declaradas nesta apólice possuem outro seguro?</div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.preexisting_policy_for_same_area)}</div>
          <div>As áreas declaradas possuem solo tipo 01 (terras arenosas) ou AD equivalente a solo tipo 1?</div>
          <div>{formatQuestionAnswer(policyCustomColumns.mga?.predominantly_ad1_ad2_soil)}</div>
          <div>Cultura irrigada?</div>
          <div>{formatQuestionAnswer(farmLevelHarvest.irrigated)}</div>
        </div>
      </div>
      <div className="mb-20">
        <h3>Campo Observações</h3>
        <p className="comment-box">{policy.comments}</p>
      </div>
      <div className="mb-20">
        <h3>1 Coberturas e POS</h3>
        <h3>Cobertura Básica</h3>
        <div className="grid four-columns">
          <div className="bold">Cobertura</div>
          <div className="bold">Limite Máximo da Indenização</div>
          <div className="bold">Preço do Seguro Líquido</div>
          <div className="bold">POS</div>
          <div>
            Incêndio/raio; tromba d’água; alagamento/inundação; ventos fortes; ventos frios; granizo; chuva excessiva;
            seca; geada; variação excessiva de temperatura.
          </div>
          <div>R$ ${formatBraNumber(basicSumInsured, true)}</div>
          <div>R$ {formatBraNumber(basicPremium, true)}</div>
          <div></div>
        </div>
      </div>
      {policyCustomColumns.mga?.coverage_type === 'replanting' && (
        <div className="mb-20">
          <h3>Cobertura Adicional</h3>
          <div className="grid four-columns">
            <div className="bold">Replantio</div>
            <div className="bold">Limite Máximo da Indenização</div>
            <div className="bold">Preço do Seguro Líquido</div>
            <div className="bold">POS</div>
            <div>tromba d’água; granizo; e chuva excessiva.</div>
            <div>R$ ${formatBraNumber(replantingSumInsured, true)}</div>
            <div>R$ {formatBraNumber(replantingPremium, true)}</div>
            <div></div>
          </div>
        </div>
      )}
      <div className="gt-report-page-separator-before" />
      <div className="gt-report-page-separator-after" />
      <div className="mb-20">
        <h3>2 Detalhamento do risco</h3>
        <div className="grid four-columns four-columns-stripes emphasized-stripes">
          <div className="bold">Dados do Item</div>
          <div>{fieldLevelHarvests.length}</div>
          <div></div>
          <div></div>
          <div className="bold">Dados da propriedade</div>
          <div></div>
          <div></div>
          <div></div>
          <div>Nome da Propriedade</div>
          <div>{farm.farm_name}</div>
          <div></div>
          <div></div>
          <div>Endereço do Risco</div>
          <div>{farmCustomColumns.address?.street}</div>
          <div>Nº</div>
          <div>{farmCustomColumns.address?.number ?? 's/n'}</div>
          <div>Complemento</div>
          <div>{farmCustomColumns.address?.additional_information}</div>
          <div>Cidade</div>
          <div>{farmCustomColumns.address?.city}</div>
          <div>UF</div>
          <div>{farmCustomColumns.address?.state}</div>
          <div>CEP</div>
          <div>{farmCustomColumns.address?.zip_code}</div>
          <div className="bold">Características técnicas do Risco</div>
          <div></div>
          <div></div>
          <div></div>
          <div>Cultura</div>
          <div>{props.policyData.cropId}</div>
          <div>Contratação</div>
          <div>Talhão</div>
          <div>Área Segurada (ha)</div>
          <div>{formatBraNumber(props.policyData.totalArea, false)}</div>
          <div>NC %</div>
          <div>{formatBraNumber(farmLevelHarvest.insured_percent, false)}</div>
          <div>Valor da Saca (R$/saca)</div>
          <div>{formatBraNumber(props.policyData.commodityPricePerBag, false)}</div>
          <div></div>
          <div></div>
          <div>Produtividade Esperada (sc/ha)</div>
          <div>
            {formatBraNumber(convertYield('bags-per-hectare', farmLevelHarvest.reference_yield, null)?.val, false)}
          </div>
          <div>Produtividade Segurada (sc/ha)</div>
          <div>{formatBraNumber(props.policyData.insuredYieldInBagsPerHa, false)}</div>
          <div>Data prevista de plantio</div>
          <div>
            {unique(
              fieldLevelHarvests
                .map(harvest => new HarvestCustomColumns(harvest.custom_columns).mga?.expected_planting_date)
                .filter(remove.falsy)
                .map(date => formatDate(props.t, date)),
            ).join(', ')}
          </div>
          <div>Grupo/Variedade</div>
          <div>{farmLevelHarvest.variety}</div>
          <div>Tipo de solo</div>
          <div>
            {unique(
              fieldLevelHarvests
                .map(harvest => new HarvestCustomColumns(harvest.custom_columns).mga?.soil_type)
                .filter(remove.falsy)
                .map(soilType => formatSoilType(soilType)),
            ).join(', ')}
          </div>
          <div>Nível de Manejo</div>
          <div>4</div>
          <div>IS Cobertura Básica</div>
          <div>R$ {formatBraNumber(basicSumInsured, true)}</div>
          <div>IS Cobertura Replantio</div>
          <div>R$ {formatBraNumber(replantingSumInsured, true)}</div>
          <div>Prêmio Líquido</div>
          <div>R$ {formatBraNumber(totalPremium, true)}</div>
          <div></div>
          <div></div>

          <div className="bold">Coordenadas Geográficas</div>
          <div></div>
          <div></div>
          <div></div>
          {fields.map(field => {
            return (
              <>
                <div>Latitude</div>
                <div>{field.field_location?.[1]}</div>
                <div>Longitude</div>
                <div>{field.field_location?.[0]}</div>
                <div>KML</div>
                <div>
                  <FieldShape
                    polygon={field.field_shape!}
                    color={props.policyData.baseCrop ? CROP_COLORS[props.policyData.baseCrop] : undefined}
                  />
                </div>
                <div></div>
                <div></div>
              </>
            );
          })}
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="mb-20">
        <h3>Vistorias</h3>
        <div className="grid four-columns four-columns-stripes">
          <div>Nome do acompanhante das vistorias</div>
          <div>{policyCustomColumns.representative?.name}</div>
          <div>CPF do acompanhante das vistorias</div>
          <div>{policyCustomColumns.representative?.external_id}</div>
          <div>Telefone com DDD</div>
          <div>{policyCustomColumns.representative?.phone}</div>
        </div>
      </div>
      <div className="mb-20">
        <h3>Roteiro de Acesso</h3>
        <div className="comment-box">{farmCustomColumns.address?.additional_information}</div>
      </div>
      <div className="gt-report-page-separator-before" />
      <div className="gt-report-page-separator-after" />
      {policyCustomColumns.beneficiaries?.map((beneficiary, index) => {
        return (
          <div className="mb-20">
            <h3>Cláusula Beneficiária {index}</h3>
            <div className="grid four-columns four-columns-stripes">
              <div>Nome do Beneficiário</div>
              <div>{beneficiary.entity?.name}</div>
              <div>CPF/CNPJ</div>
              <div>{beneficiary.entity?.external_id}</div>
              <div>Participação (%)</div>
              <div>{beneficiary.percent}</div>
              <div></div>
              <div></div>
            </div>
          </div>
        );
      })}
      <div className="mb-20">
        <h3>Informações Processo SUSEP</h3>
        <p>
          SUSEP – Superintendência de Seguros Privados – Autarquia Federal responsável pela fiscalização, normatização e
          controle dos mercados de seguro, previdência complementar aberta, capitalização, resseguro e corretagem de
          seguros.
        </p>
        <p>Condições do Seguro conforme processo(s) SUSEP</p>
        <div className="grid two-columns">
          {/* TODO(kristjan): hardcode it once we receive it from AXA */}
          <div>Processo SUSEP Principal</div>
          <div>XXXX.XXXXXXX/XX</div>
          <div>Produto Agrícola</div>
          <div>XXXX.XXXXXXX/XX</div>
        </div>
        {policyExternalId && (
          <p>
            As condições contratuais deste produto protocoladas pela AXA junto à SUSEP poderão ser consultadas no
            endereço eletrônico <span className="link">www.susep.gov.br</span>, de acordo com o número do processo
            constante nesta apólice.
          </p>
        )}
        <p>
          O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação à sua
          comercialização. SUSEP – Superintendência de Seguros Privados – Autarquia Federal é a responsável pela
          fiscalização, normatização e controle dos mercados de seguro, previdência complementar aberta, capitalização,
          resseguro e corretagem de seguros.
        </p>
        <p>
          O segurado poderá consultar a situação cadastral do corretor de seguros e da sociedade seguradora no sítio
          eletrônico <span className="link">www.susep.gov.br</span>.
        </p>
        <p>
          Ressaltamos que, de acordo com a Resolução CNSP 382/2020, deverá ser dada ciência ao proponente, sobre as
          informações relativas ao produto e remuneração antes da aquisição do mesmo.
        </p>
        {!policyExternalId && (
          <>
            <p>
              A Seguradora, após recebimento da Proposta, terá 15 (quinze) dias para definir pela aceitação ou recusa do
              risco, nos casos de propostas subvencionadas terão 45 (quarenta e cinco) dias para definir pela aceitação
              ou recusa do risco. A aceitação da proposta de seguro está sujeita à análise do risco;
            </p>

            <p>
              O registro do produto é automático e não representa aprovação ou recomendação por parte da Susep; Ao
              aceitar esse documento, autorizo que a AXA SEGUROS S/A, as empresas do mesmo Grupo Econômico, seus
              fornecedores e/ou parceiros, procedam ao tratamento dos meus dados pessoais em atenção a celebração deste
              contrato, de acordo com a
            </p>

            <p>
              Política de Privacidade de Dados, disponível em{' '}
              <span className="link">https://www.axa.com.br/politica-de-privacidade/</span>. Mais detalhes sobre o
              tratamento de dados pessoais, estão disponíveis nas Condições Gerais deste produto.”
            </p>
          </>
        )}
        <p>ATENDIMENTO AO PÚBLICO SUSEP: 0800-0218484</p>
        <p>
          Link da plataforma digital oficial para registro de reclamações dos consumidores dos mercados supervisionados{' '}
          <span className="link">www.consumidor.gov.br</span>.
        </p>
        <img src={axaContacts} />
        <div className="plain-grid two-columns">
          <img src={axaSignatures} />
        </div>
      </div>
    </div>
  );
};
