export function parseDate(s: null | string): null | Date {
  if (!s || !s.match(/\d\d\d\d-\d\d-\d\d/)) {
    return null;
  }

  const cmp = s.split('-').map(x => Number(x));
  if (cmp.length != 3 || cmp.some(x => isNaN(x))) {
    return null;
  }

  return new Date(Date.UTC(cmp[0], cmp[1] - 1, cmp[2]));
}

export function tsToStr(x: number): string {
  return new Date(x).toISOString();
}

export function toDateStr(x: number | string | Date): string {
  if (typeof x == 'string') {
    x = Date.parse(x);
  }
  if (typeof x == 'number') {
    x = new Date(x);
  }

  return x.toISOString().slice(0, 10);
}

export function getDateRange(aStr: string, bStr: string) {
  let res: string[] = [];
  const a = parseDate(aStr)!,
    b = parseDate(bStr)!;
  for (let d = a; d.getTime() <= b.getTime(); d = new Date(d.getTime() + 24 * 60 * 60 * 1000)) {
    res.push(toDateStr(d));
  }
  return res;
}

export function addDaysToDate(currentDate: Date, addDays: number): Date {
  return new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + addDays),
  );
}

export function parseDMYDate(s: null | string): null | number {
  const cmp = s?.trim().match(/^(\d\d?)[/.](\d\d?)[/.](\d\d\d\d)$/);
  if (cmp && cmp.length == 4) {
    return Date.UTC(Number(cmp[3]), Number(cmp[2]) - 1, Number(cmp[1])) ?? null;
  } else {
    return null;
  }
}
