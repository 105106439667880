import {Col, Row} from 'antd';
import React from 'react';
import {Formy} from '../../../src/Formy/Formy';
import {useFormyValue} from '../../../src/Formy/hooks';
import {RequestStatus} from '../../../src/models/interfaces';
import {useAsyncMemo} from '../../../src/util/hooks';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {FormyBool} from '../Formy/FormyBool';
import {Label} from '../Formy/FormyLabel';
import {FormyTextNum, FormyTextStr} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';
import {PolicyForm} from './AddOrEditPolicy';

const STATUS_COLORS: Partial<Record<RequestStatus, React.CSSProperties>> = {
  new: {
    padding: '5px',
    backgroundColor: '#fff9db',
    border: '2px solid #ffeb3b',
    color: '#fbc02d',
    borderRadius: '5px',
  },
  ok: {
    padding: '5px',
    backgroundColor: '#e0f7e9',
    border: '2px solid #4caf50',
    color: '#388e3c',
    borderRadius: '5px',
  },
  error: {
    padding: '5px',
    backgroundColor: '#fde0e0',
    border: '2px solid #f44336',
    color: '#d32f2f',
    borderRadius: '5px',
  },
};

export const AddOrEditPolicyBrazil: React.FC<{
  formy: Formy<PolicyForm>;
}> = ({formy}) => {
  const apis = useApis();
  const policyId = useFormyValue(formy, 'policy_id');
  const subsidy = useAsyncMemo(async () => {
    if (!policyId) {
      return null;
    }
    const result = await apis.authedFetcher({
      method: 'GET',
      path: 'api/request',
      params: getPostgrestQueryParams({
        and: [
          {column: 'request->>policy_id', operator: 'eq', value: policyId},
          {column: 'request_type', operator: 'eq', value: 'subsidy'},
        ],
      }),
    });
    if (!result.length) {
      return null;
    }
    function formatStatus(status: string): string {
      if (status === 'new') {
        return apis.t('Pending');
      } else if (status === 'error') {
        return apis.t('Error');
      } else {
        return apis.t('Submitted');
      }
    }
    return {
      formattedStatus: formatStatus(result[0].status),
      status: result[0].status as RequestStatus,
      response: result[0].errors?.join('\n'),
    };
  }, [apis, policyId]);
  const customColumns = formy.getSectionFormy('custom_columns');
  const mgaCustomColumns = customColumns.getSectionFormy('mga');
  return (
    <>
      <Row gutter={16}>
        <h3>{apis.t('AdditionalInfo')}</h3>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('ExternalPolicyId')}:</Label>
          <FormyTextStr formy={mgaCustomColumns} field={'axa_policy_id'} disabled={true} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('NumberOfInstallments')}:</Label>
          <FormyTextNum formy={mgaCustomColumns} field={'installment_count'} min={1} max={10} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('CropAlreadyPlanted?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'crop_already_planted'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('PreexistingPolicy?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'preexisting_policy_for_same_area'} selectMsg={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('CertifiedOrRegisteredSeeds?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'certified_or_registered_seeds'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('DrainageProblems?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'drainage_problems'} selectMsg={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('FloodOrWaterloggingPast5Years?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'flood_or_waterlogging_past_5_years'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('AwareOfZoagroMapa?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'aware_of_zoagro_mapa'} selectMsg={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('InsuredInvoiceRecipient?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'insured_invoice_recipient'} selectMsg={null} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('CropAlreadyDamaged?')}:</Label>
          <FormyBool formy={mgaCustomColumns} field={'crop_already_damaged'} selectMsg={null} />
        </Col>
      </Row>

      {subsidy && (
        <>
          <h3>{apis.t('Subsidy')}</h3>
          <Row gutter={16}>
            <Col span={4}>
              <Label>{apis.t('Status')}:</Label>
              <div style={STATUS_COLORS[subsidy.status]}>{subsidy.formattedStatus}</div>
            </Col>
            {subsidy.response && (
              <Col span={12}>
                <Label>{apis.t('Message')}:</Label>
                <div>{subsidy.response}</div>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};
