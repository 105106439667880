import {FileExcelTwoTone, FilePdfTwoTone, FileTextTwoTone, FileTwoTone, FileZipTwoTone} from '@ant-design/icons';
import {Button, message} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {File} from '../../../src/models/interfaces';
import {useApis} from '../apis/ApisContext';
import SpinningDots from './SpinningDots';

const getFileIcon = (mimeType: null | string): React.FC => {
  switch (mimeType) {
    case 'application/pdf':
      return FilePdfTwoTone;
    case 'application/zip':
    case 'application/x-zip-compressed':
      return FileZipTwoTone;
    case 'application/xml':
    case 'text/xml':
      return FileTextTwoTone;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return FileExcelTwoTone;
    default:
      return FileTwoTone;
  }
};

interface FileAttachmentProps {
  file: File;
}

export const FileAttachment: React.FC<FileAttachmentProps> = ({file}) => {
  const {authedFetcher, t} = useApis();
  const FileIcon = useMemo(() => getFileIcon(file.mime_type), [file.mime_type]);

  const onFileDownload = useCallback(async () => {
    try {
      message.loading({
        content: t('Loading') + '...',
        duration: 0,
        key: 'download',
        icon: <SpinningDots size={16} />,
      });
      const s3SignedUrlRes = await authedFetcher({
        method: 'GET',
        path: 'authorize-s3',
        params: [['get', 'photo' + file.uri]],
      });
      window.open(s3SignedUrlRes[0]);
    } catch (e) {
      message.error({content: t('SomethingWentWrongError'), duration: 3, key: 'error'});
    } finally {
      message.destroy('download');
    }
  }, [authedFetcher, file.uri, t]);

  return (
    <Button type="link" onClick={onFileDownload} icon={<FileIcon />}>
      {file.name}
    </Button>
  );
};
