// The function needs to support getting base languages from cases like:
// 'en-US' => ['en'],
// 'en-US-client' => ['en-US', 'en']
// 'en--client' => ['en']

export function getBaseLanguages(locale: null | string): string[] {
  if (!locale) {
    return [];
  }
  const groups = /^(?<language>[a-z]{2})?((-(?<location>[A-Z]{2})?)(-(?<user_group>[^-][a-z0-9-]+))?)?$/.exec(
    locale,
  )?.groups;
  if (!groups) {
    return [];
  }
  const {language, location, user_group} = groups;
  if (!location && !user_group) {
    return []; // No base language.
  } else if (!user_group || !location) {
    return [language];
  }
  return [language, language + '-' + location];
}
