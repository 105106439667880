import {FetcherFunc, acceptSingleRow} from '../FetcherFunc';
import {Claim, ClaimDamage, Farm, Field, Harvest, Policy, Sample, VisitLite} from '../models/interfaces';
import {EntityType, TableName, isTableName, transportToEntity} from '../models/serialization';
import {PostgrestQuery, getPostgrestQueryParams} from './postgrest-query';

export async function fetchEntity(authedFetcher: FetcherFunc, table: 'farm', id: string): Promise<Farm>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'policy', id: string): Promise<Policy>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'field', id: string): Promise<Field>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'harvest', id: string): Promise<Harvest>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'sample', id: string): Promise<Sample>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'visit', id: string): Promise<VisitLite>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'claim', id: string): Promise<Claim>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: 'claim_damage', id: string): Promise<ClaimDamage>;
export async function fetchEntity(authedFetcher: FetcherFunc, table: TableName, id: string): Promise<EntityType> {
  const params = getPostgrestQueryParams({column: table + '_id', operator: 'eq', value: id});
  const path = table == 'visit' ? 'api/visit_lite' : `api/${table}`;
  const resp = await authedFetcher({method: 'GET', path, params, headers: [acceptSingleRow]});
  if (resp?.code?.startsWith('PGRST')) {
    throw new Error(`fetchEntity for ${table} got error ${resp.code}: ${resp.details}`);
  }

  return transportToEntity(table, resp);
}

export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'farm',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<Farm[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'policy',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<Policy[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'field',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<Field[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'harvest',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<Harvest[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'sample',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<Sample[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'visit',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<VisitLite[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'claim',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<Claim[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: 'claim_damage',
  q: PostgrestQuery | PostgrestQuery[],
): Promise<ClaimDamage[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: string,
  q: PostgrestQuery | PostgrestQuery[],
): Promise<any[]>;
export async function fetchEntitiesBy(
  authedFetcher: FetcherFunc,
  table: string,
  q: PostgrestQuery | PostgrestQuery[],
): Promise<any> {
  const params: [string, string][] = getPostgrestQueryParams(q);
  const path = table == 'visit' ? 'api/visit_lite' : `api/${table}`;
  const resp: any[] = await authedFetcher({method: 'GET', path, params});
  return resp.map(x => (isTableName(table) ? transportToEntity(table, x) : x));
}

export async function fetchEntities(authedFetcher: FetcherFunc, table: 'farm', ids: string[]): Promise<Farm[]>;
export async function fetchEntities(authedFetcher: FetcherFunc, table: 'policy', ids: string[]): Promise<Policy[]>;
export async function fetchEntities(authedFetcher: FetcherFunc, table: 'field', ids: string[]): Promise<Field[]>;
export async function fetchEntities(authedFetcher: FetcherFunc, table: 'harvest', ids: string[]): Promise<Harvest[]>;
export async function fetchEntities(authedFetcher: FetcherFunc, table: 'sample', ids: string[]): Promise<Sample[]>;
export async function fetchEntities(authedFetcher: FetcherFunc, table: 'visit', ids: string[]): Promise<VisitLite[]>;
export async function fetchEntities(authedFetcher: FetcherFunc, table: TableName, ids: string[]): Promise<EntityType[]>;
export async function fetchEntities(
  authedFetcher: FetcherFunc,
  table: TableName,
  ids: string[],
): Promise<EntityType[]> {
  if (ids.length == 0) {
    return [];
  }

  return fetchEntitiesBy(authedFetcher, table, {column: table + '_id', operator: 'in', value: ids});
}

export async function getFarmFields(authedFetcher: FetcherFunc, farm_id: null | undefined | string): Promise<Field[]> {
  return !farm_id ? [] : fetchEntitiesBy(authedFetcher, 'field', {column: 'farm_id', operator: 'eq', value: farm_id});
}

export async function getFarmHarvests(
  authedFetcher: FetcherFunc,
  farm_id: null | undefined | string,
): Promise<Harvest[]> {
  return !farm_id ? [] : fetchEntitiesBy(authedFetcher, 'harvest', {column: 'farm_id', operator: 'eq', value: farm_id});
}
