import {HarvestCrop} from '../models/interfaces';
import {CropState, IndexedCrops} from '../redux/reducers/crops';
import {isGrapes} from './harvest';

export const getCrops = (state: Readonly<{crops: CropState}>): Readonly<IndexedCrops> => state.crops.crops;

export function getBaseCrop(crops: IndexedCrops, crop_id: null | undefined | string): null | HarvestCrop {
  if (isGrapes(crop_id ?? null)) {
    return 'grapes';
  }
  return crop_id ? (crops[crop_id]?.harvest_crop ?? null) : null;
}
