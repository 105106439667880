import {CheckCircleOutlined, SyncOutlined} from '@ant-design/icons';
import React, {useCallback} from 'react';
import {UnreachableCaseError} from 'ts-essentials';
import {useSyncFormy} from '../../../src/Formy/hooks';
import {paAllGroups, paHiddenName} from '../../../src/constants/group-consts';
import {ImportedData} from '../../../src/gt-pack/gt-pack';
import {validateImportedData} from '../../../src/gt-pack/validators';
import {TelepacReportPackage} from '../../../src/report/report-types';
import {FormySubmit} from '../Formy/FormySubmit';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {AllFieldsForm} from './EntityForms';
import {ImportUploadForm} from './ImportUploadForm';
import {TelepacImportSummary, generateTelepacReport} from './TelepacImportSummary';
import './import-data.css';
import {saveImportedData} from './save';
import {EntityFormStage} from './stages';

type ImportStage = EntityFormStage | {type: 'upload-data'};

export default function ImportData() {
  const apis = useApis();
  const [stage, setStage] = React.useState<ImportStage>({type: 'upload-data'});

  if (stage.type == 'upload-data') {
    return <ImportUploadForm onImportedData={setStage} />;
  } else if (stage.type == 'entity-form') {
    return <ImportDataForm telepacData={stage.telepacData} initialValues={stage.initialValues} />;
  } else {
    reportErr(new UnreachableCaseError(stage));
    return <h1>{apis.t('Error')}!</h1>;
  }
}

interface ImportDataFormProps {
  telepacData: null | TelepacReportPackage;
  initialValues: ImportedData;
}

type Stage = 'done' | 'entity-form' | 'saving' | 'telepac-preview';

async function processPacFile(apis: Apis, telepacData: TelepacReportPackage, farm_id?: string) {
  const deliveryEmails = [];
  const email = apis.store.getState().dbMeta.curUser?.email;
  if (email && email.toLowerCase() != `telepac@ca-${paHiddenName}.fr`) {
    // Do not send the recap pac to this address, as it will reforward the recap pac back to us..!
    deliveryEmails.push(email);
  }

  // Override email to use dedicated inbox
  if (paAllGroups.includes(telepacData.user_group)) {
    deliveryEmails.push('recap-PAC@ca-' + paHiddenName + '.fr');
  }

  await generateTelepacReport(apis, telepacData, deliveryEmails, farm_id);
}

// This component handles the last parts of the import flow, such as the main form, saving, and telepac report generation.
function ImportDataForm({telepacData, initialValues}: ImportDataFormProps) {
  const apis = useApis();
  const [stage, setStage] = React.useState<Stage>('entity-form');
  const onSubmit = useCallback(
    async (data: ImportedData) => {
      window.scrollTo(0, 0);
      setStage('saving');

      // Save the entities.
      const entities = await saveImportedData(apis, data, false);

      // If we have telepac data, generate the telepac report.
      if (telepacData) {
        let farm_id = data.farms[0]?.farm_id;
        if (!farm_id && entities) {
          for (const entity of entities) {
            if (entity.entity_type == 'farm') {
              farm_id = entity.entity_id;
              break;
            }
          }
        }
        if (farm_id) {
          await processPacFile(apis, telepacData, farm_id);
        }

        setStage('telepac-preview');
      } else {
        setStage('done');
      }
    },
    [apis, telepacData],
  );
  const formy = useSyncFormy(
    'new',
    () => initialValues,
    apis.t,
    onSubmit,
    x => validateImportedData(apis.store.getState().crops.crops, x),
    null,
  );

  if (stage == 'entity-form') {
    return (
      <span>
        <AllFieldsForm formy={formy} field="farms" />
        <span className="import-save">
          <FormySubmit label="Save" formy={formy} doNotConfirmSubmissionToUser={true} />
        </span>
      </span>
    );
  } else if (stage == 'saving') {
    return (
      <h1 className="import-done">
        {apis.t('Saving')} <SyncOutlined spin />
      </h1>
    );
  } else if (stage == 'done' || !telepacData) {
    return (
      <h1 className="import-done">
        {apis.t('Done')} <CheckCircleOutlined />
      </h1>
    );
  } else if (stage == 'telepac-preview') {
    return <TelepacImportSummary telepacData={telepacData} />;
  } else {
    console.error('Unreachable stage:', stage, new UnreachableCaseError(stage));
    return null;
  }
}
