import type {FeatureCollection} from 'geojson';
import type {AnyLayer, AnySourceData, FillLayer, LineLayer} from 'mapbox-gl';
import {UnreachableCaseError} from 'ts-essentials';
import {MIN_PLANET_TILES_ZOOM, floodRiskSourceUrl, getInteryieldTextField} from '../../../src/constants/colors';
import {I18nFunction} from '../../../src/i18n/i18n';
import {LayerParams} from '../../../src/layers/layer-params';
import {MapLayers, getSentinelHubTileUrl, isCompositeSatLayer, isFieldLayer} from '../../../src/layers/map-layers';
import {CropMonType, MonitoredCrop} from '../../../src/models/crop-mon';
import {UserConfig} from '../../../src/selectors/userConfig';
import {
  benchmarkYieldLayers,
  expectedLossLayer,
  farmLayer,
  fieldBgShapeLayer,
  fieldLayer,
  fieldShapeLayerBase,
  fieldShapeLayerSat,
  floodRiskFillLayer,
  floodRiskLineLayer,
  hailLayer,
  interfieldLayer,
  interyieldLabelLayer,
  interyieldLayer,
  intrafieldLayer,
  mdaCustomALayer,
  ndviLayer,
  precipitationLayer,
  rainstormLayer,
  sampleLayer,
  sampleShapeLayer,
  soilLayer,
  surfaceTempLayer,
  tempLayer,
  windLayer,
  yieldRatioLayer,
} from './layers-specs';
import {EntityFeatureCollection} from './useEntityFeatures';

export type Sources = {
  [sourceName: string]: {
    source: AnySourceData;
  };
};

export interface Layer {
  layer: AnyLayer;

  // The ID of an existing layer to insert the new layer before. If this argument is omitted,
  // the layer will be appended to the end of the layers array. (https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer).
  displayBefore?: string;
}

export type Layers = Layer[];

export const layerConfMapping: {
  [P in Exclude<CropMonType | MapLayers, 'benchmark-yield' | 'crop-mon'>]: FillLayer | LineLayer;
} = {
  intrafield: intrafieldLayer,
  interfield: interfieldLayer,
  interyield: interyieldLayer,
  'soil-moisture': soilLayer,
  vegetation: ndviLayer,
  hail: hailLayer,
  temperature: tempLayer,
  'surface-temperature': surfaceTempLayer,
  precipitation: precipitationLayer,
  rainstorm: rainstormLayer,
  wind: windLayer,
  'predicted-yield': yieldRatioLayer,
  'historical-yield': yieldRatioLayer,
  'expected-loss': expectedLossLayer,
  base: fieldShapeLayerBase,
  satellite: fieldShapeLayerSat,
  'custom-a': mdaCustomALayer,
};

export function getMapboxLayersSources(
  {crops, units, customers}: UserConfig,
  layer: MapLayers,
  canonical_date: null | string,
  zoom: undefined | number,
  layersGeojson: null | FeatureCollection,
  entityFeatures: EntityFeatureCollection,
  layerParams: null | LayerParams,
  t: null | I18nFunction,
) {
  const sources: Sources = {},
    layers: Layers = [];
  if (!zoom) {
    return {sources, layers};
  }

  const fieldShapeLayer = layerConfMapping[layer == 'base' ? 'base' : 'satellite'];

  sources['entity-features'] = {
    source: {
      type: 'geojson',
      data: entityFeatures ?? {type: 'FeatureCollection', features: []},
    },
  };

  layers.push(
    {layer: fieldShapeLayer, displayBefore: 'road-minor'},
    {layer: sampleLayer},
    {layer: fieldLayer},
    {layer: farmLayer, displayBefore: ''},
  );

  if (
    layer == 'soil-moisture' ||
    layer == 'vegetation' ||
    layer == 'temperature' ||
    layer == 'surface-temperature' ||
    layer == 'hail' ||
    layer == 'precipitation' ||
    layer == 'rainstorm' ||
    layer == 'wind' ||
    layer == 'custom-a'
  ) {
    if (layersGeojson) {
      const layerConf = layerConfMapping[layer];

      sources[layerConf.source as string] = {source: {type: 'geojson', data: layersGeojson}};
      layers.push({layer: layerConf, displayBefore: 'road-minor'});

      if (layer == 'soil-moisture') {
        sources[floodRiskFillLayer.source as string] = {source: {type: 'vector', url: floodRiskSourceUrl}};
        layers.push({layer: floodRiskFillLayer, displayBefore: 'soil-layer'});
        layers.push({layer: floodRiskLineLayer, displayBefore: 'flood-fill-layer'});
      }
    }
  } else if (layer == 'crop-mon') {
    if (layersGeojson && layerParams?.layer_type == 'crop-mon') {
      const type: CropMonType = layerParams?.params[1] || 'benchmark-yield';
      const harvest_crop: MonitoredCrop = layerParams?.params[2] || 'corn-grain';
      const layerConf = type === 'benchmark-yield' ? benchmarkYieldLayers[harvest_crop] : layerConfMapping[type];
      if (!layerConf) {
        console.error(`getMapboxLayersSources couldn't find layerConf for ${type}/${harvest_crop}`);
      } else {
        sources[layerConf.source as string] = {source: {type: 'geojson', data: layersGeojson}};
        layers.push({layer: layerConf, displayBefore: 'road-minor'});
      }
    }
  } else if (isFieldLayer(layer)) {
    if (layersGeojson) {
      const layerConf = layerConfMapping[layer];
      if (layer == 'interyield') {
        const crop_id = layerParams?.params[0];
        layersGeojson.features = layersGeojson.features.filter(x => x.properties?.shape_type.slice(2) == crop_id);
      }
      sources[layerConf.source as string] = {source: {type: 'geojson', data: layersGeojson}};
      layers.push({layer: layerConf, displayBefore: 'road-minor'});

      if (layer == 'interyield') {
        if (crops && units && t) {
          interyieldLabelLayer.layout!['text-field'] = getInteryieldTextField(crops, units, t);
        }
        layers.push({layer: interyieldLabelLayer, displayBefore: 'road-minor'});
      }
    }

    sources[fieldBgShapeLayer.source as string] = {source: {type: 'geojson', data: entityFeatures}};
    layers.push({layer: fieldBgShapeLayer, displayBefore: layersGeojson ? layer : 'road-minor'});
  } else if (layer == 'base' || layer == 'satellite') {
    layers.push({layer: sampleShapeLayer, displayBefore: 'samplePoints'});

    if (layer == 'satellite' && layerParams?.layer_type == 'planet' && layerParams.params[0]) {
      const planetItemId = layerParams.params[0];
      const source_id = `sat-raster-planet-${planetItemId}`;
      sources[source_id] = {
        source: {
          type: 'raster',
          tiles: [getPlanetTileUrl(customers, planetItemId)],
          tileSize: 256,
          minzoom: MIN_PLANET_TILES_ZOOM,
          maxzoom: 14,
        },
      };
      layers.push({
        layer: {id: `planet-tiles-${planetItemId}`, type: 'raster', source: source_id, minzoom: MIN_PLANET_TILES_ZOOM},
        displayBefore: fieldShapeLayer.id,
      });
    }

    if (isCompositeSatLayer(layer, canonical_date)) {
      const source_id = `sat-raster-${canonical_date}`;
      sources[source_id] = {
        source: {
          type: 'raster',
          tiles: [getSentinelHubTileUrl(canonical_date!)],
          tileSize: 256,
          minzoom: 7,
          maxzoom: 14,
        },
      };
      layers.push({
        layer: {id: `simple-tiles-${canonical_date}`, type: 'raster', source: source_id, minzoom: 7},
        displayBefore: fieldShapeLayer.id,
      });
    }
  } else {
    throw new UnreachableCaseError(layer);
  }

  return {layers, sources};
}

function getPlanetTileUrl(customers: string[], planetItemId: string): string {
  // Note that NGINX will drop the customers param before forwarding, and add the API key.
  return (
    location.origin + `/planet-tiles/data/v1/PSScene/${planetItemId}/{z}/{x}/{y}.png?customers=${customers.join(',')}`
  );
}
