export function debounce<F extends Function>(fn: F, ms: number) {
  let lastCall: null | Date = null;
  return function (this: any) {
    const newDate = new Date();
    if (!lastCall || newDate.getTime() - lastCall.getTime() > ms) {
      lastCall = newDate;
      return fn.apply(this, arguments);
    }
  };
}
