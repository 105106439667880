import * as d3 from 'd3';
import React from 'react';

export interface LineChartDatum {
  x: number | Date;
  y: number;
}

interface LineChartProps {
  width: number;
  height: number;
  id: string;
  data: LineChartDatum[];
  formatDate: (x: Date) => string;
  formatValue: (y: number) => string;
}

class LineChart extends React.PureComponent<LineChartProps> {
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const {data} = this.props;
    // set the dimensions and margins of the graph
    const margin = {top: 10, right: 10, bottom: 40, left: 50};

    const el = document.getElementById(this.props.id);
    if (el) {
      el.innerHTML = '';
    }

    // X axis.
    const x = d3
      .scaleUtc()
      .domain([d3.min(data.map(i => i.x))!, d3.max(data.map(i => i.x))!])
      .range([margin.left, this.props.width - margin.right]);

    // Y axis.
    var y = d3
      .scaleLinear()
      .domain([d3.min(data.map(i => i.y))!, d3.max(data.map(i => i.y))!])
      .range([this.props.height - margin.bottom, margin.top]);

    var valueline = d3
      .line<{x: number | Date; y: number}>()
      .x(function (d) {
        return x(d.x);
      })
      .y(function (d) {
        return y(d.y);
      });

    // Svg.
    const svg = d3
      .select('#' + this.props.id)
      .append('svg')
      .attr('viewBox', `0 0 ${this.props.width} ${this.props.height}`);
    const g = svg.append('g');

    g.append('path')
      .data([data])
      .attr('fill', 'none')
      .attr('class', 'line')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr('d', valueline);

    g.append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + (this.props.height - margin.bottom) + ')')
      .call(
        d3
          .axisBottom(x)
          .ticks(5)
          .tickFormat(x => this.props.formatDate(x as Date)),
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', '15px')
      .attr('dx', '-.4em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-20)');

    // Add the y Axis
    g.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickFormat(x => this.props.formatValue(x.valueOf())))
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .select('.tick:last-of-type text')
          .clone()
          .attr('x', 3)
          .attr('text-anchor', 'start')
          .attr('font-weight', 'bold'),
      )
      .style('font-size', '15px');

    const _bisect = d3.bisector<LineChartDatum, Date>(d => d.x as Date).left;
    const bisect = (mx: number): LineChartDatum => {
      const date = x.invert(mx);
      const index = _bisect(data, date, 1);
      const a = data[index - 1];
      const b = data[index];
      return b && date.getTime() - (a.x as Date).getTime() > (b.x as Date).getTime() - date.getTime() ? b : a;
    };

    const tooltip = svg.append('g');
    const callout = (g: any, value: any) => {
      if (!value) return g.style('display', 'none');

      g.style('display', null).style('pointer-events', 'none').style('font', '10px sans-serif');

      const path = g.selectAll('path').data([null]).join('path').attr('fill', 'white').attr('stroke', 'black');

      const text = g
        .selectAll('text')
        .data([null])
        .join('text')
        .call((text: any) =>
          text
            .selectAll('tspan')
            .data((value + '').split(/\n/))
            .join('tspan')
            .attr('x', 0)
            .style('font-size', '15px')
            .text((d: any) => d),
        );

      const {y, width: w, height: h} = text.node().getBBox();

      text.attr('transform', `translate(${-w / 2},${15 - y})`);
      path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
    };

    svg.on('touchmove mousemove', event => {
      const {x: date, y: value} = bisect(d3.pointer(event)[0]);
      tooltip
        .attr('transform', `translate(${x(date)},${y(value)})`)
        .call(callout, `${this.props.formatDate(date as Date)}: ${this.props.formatValue(value)}`);
    });

    svg.on('touchend mouseleave', () => tooltip.call(callout, null));
  }

  render() {
    return <div id={this.props.id}></div>;
  }
}

export default LineChart;
