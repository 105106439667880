import {X2jOptions, XMLParser} from 'fast-xml-parser';
import {FeatureCollection, Geometry, Polygon} from 'geojson';
import he from 'he';
import proj4 from 'proj4';
import {LngLat, geometryArea} from '../geo';
import {HarvestCustomColumns} from '../models/CustomColumns';
import {HarvestYear} from '../models/interfaces';
import {IndexedCrops} from '../redux/reducers/crops';
import {TelepacReportPackage} from '../report/report-types';
import {getBaseCrop} from '../selectors/crops';
import {filterNulls, remove} from '../util/arr-util';
import {ImportedData, ImportedFarm, ImportedField, ImportedHarvest} from './gt-pack';
import {readShapefile} from './shapefile';
import {StateOrm} from './stateOrm';

// TODO(savv): import harvest.irrigated and harvest.organic, from telepac.

type CropMapping = {[name: string]: string};

const lambert93 = `
PROJCS["RGF93 / Lambert-93",
    GEOGCS["RGF93",
        DATUM["Reseau_Geodesique_Francais_1993",
            SPHEROID["GRS 1980",6378137,298.257222101,
                AUTHORITY["EPSG","7019"]],
            TOWGS84[0,0,0,0,0,0,0],
            AUTHORITY["EPSG","6171"]],
        PRIMEM["Greenwich",0,
            AUTHORITY["EPSG","8901"]],
        UNIT["degree",0.0174532925199433,
            AUTHORITY["EPSG","9122"]],
        AUTHORITY["EPSG","4171"]],
    PROJECTION["Lambert_Conformal_Conic_2SP"],
    PARAMETER["standard_parallel_1",49],
    PARAMETER["standard_parallel_2",44],
    PARAMETER["latitude_of_origin",46.5],
    PARAMETER["central_meridian",3],
    PARAMETER["false_easting",700000],
    PARAMETER["false_northing",6600000],
    UNIT["metre",1,
        AUTHORITY["EPSG","9001"]],
    AXIS["X",EAST],
    AXIS["Y",NORTH],
    AUTHORITY["EPSG","2154"]]`;

// The interfaces below describe the JSON output of parsing a Telepac file using fast-xml-parser. They are heavily
// tied to the options with which the parser is configured! These are at the top.
// The data contained in these interfaces is otherwise *not* parsed in any other way. Coordinates remain as text,
// in the original coordinate system (EPSG:2154).
// Not every part of Telepac is specified; we will add the missing bits as we go.
// We avoid parsing numbers, because many of them will be identifiers, and we don't want to lose precision.

const telepacXmlParserOptions: Partial<X2jOptions> = {
  isArray: (_tagName: string, _jPath: string, isLeafNode: boolean, _isAttribute: boolean) => !isLeafNode,
  ignoreAttributes: false,
  attributeNamePrefix: '_',
  attributeValueProcessor: (_attrName: string, attrValue: string, _jPath: string) =>
    he.decode(attrValue, {isAttributeValue: true}),
  tagValueProcessor: (
    _tagName: string,
    tagValue: string,
    _jPath: string,
    _hasAttributes: boolean,
    _isLeafNode: boolean,
  ) => he.decode(tagValue),
  parseTagValue: false,
  parseAttributeValue: false,
};

export interface GmlLinearRing {
  'gml:coordinates': string;
}

export interface GmlOuterBoundaryIs {
  'gml:LinearRing': GmlLinearRing[];
}

export interface GmlPolygon {
  'gml:outerBoundaryIs': GmlOuterBoundaryIs[];
}

export interface Geometrie {
  'gml:Polygon': GmlPolygon[];
}

export interface Parcelle {
  'descriptif-parcelle': {
    '_numero-parcelle': string;
    'culture-principale': [
      {
        '_production-semences': 'false' | 'true';
        'code-culture': string;
        precision: string;
        'retournement-pp': boolean;
        'declare-sie': boolean;
      },
    ];
    'agri-bio'?: [
      {
        '_conduite-bio': boolean;
        '_conduite-maraichage': boolean;
      },
    ];
  }[];
  geometrie: Geometrie[];
  'surface-admissible': string;
}

export interface Ilot {
  '_numero-ilot': string;
  commune: string;
  geometrie: Geometrie[];
  parcelles: {parcelle: Parcelle[]}[];
}

export interface SnaDeclaree {
  numeroSna: string;
  categorieSna: string;
  typeSna: string;
  intersectionsSnaIlots: [
    {
      intersectionSnaIlot: [
        {
          numeroIlot: string;
          largeur: string;
          'declare-sie': 'false' | 'true';
        },
      ];
    },
  ];
  intersectionsSnaParcelles: [
    {
      intersectionSnaParcelle: [
        {
          numeroIlot: string;
          numeroParcelle: string;
          'longueur-sie': string;
          'declare-sie': 'false' | 'true';
        },
      ];
    },
  ];
}

export interface Rpg {
  ilots?: {ilot: Ilot[]}[];
  ilot?: Ilot[];
  'sna-declaree': SnaDeclaree;
}

interface IdentificationIndividuelle {
  '_numero-fiscal': string;
  identite: [
    {
      civilite: string;
      nom: string;
      prenoms: string;
    },
  ];
}

export interface Demandeur {
  '_certificat-environnemental': 'false' | 'true';
  'identification-societe'?: [{exploitation: string}];
  'identification-individuelle'?: [IdentificationIndividuelle];
  siret: string;
  iban: [
    {
      '_compte-iban': string;
      _bic: string;
      _titulaire: string;
    },
  ];
  courriel: string;
}

export interface DemandesAidesPilier1 {
  '_paiement-jeunes-agriculteurs': 'false' | 'true';
  _soja: 'false' | 'true';
  _proteagineux: 'false' | 'true';
  '_legumineuses-fourrageres-deshydratation': 'false' | 'true';
  '_semences-legumineuses-fourrageres': 'false' | 'true';
  '_ble-dur': 'false' | 'true';
  '_prunes-transformation': 'false' | 'true';
  '_cerises-transformation': 'false' | 'true';
  '_peches-transformation': 'false' | 'true';
  '_poires-transformation': 'false' | 'true';
  '_tomates-industrie': 'false' | 'true';
  '_pommes-terre-feculieres': 'false' | 'true';
  _chanvre: 'false' | 'true';
  _houblon: 'false' | 'true';
  '_semences-graminees': 'false' | 'true';
  _riz: 'false' | 'true';
  'demande-aides-decouplees': [
    {
      '_aides-decouplees': 'false' | 'true';
      '_derogation-AB': 'false' | 'true';
      '_schema-certification-mais': 'false' | 'true';
    },
  ];
  'demande-legumineuses-fourrageres': [{'_legumineuse-fourragere': 'false' | 'true'}];
  'demande-assurance-recolte': [{'_assurance-recolte': 'false' | 'true'}];
}

export interface DemandesAidesPilier2 {
  '_demande-ab': 'false' | 'true';
  '_demande-maec': 'false' | 'true';
  '_demande-agroforesterie': 'false' | 'true';
  ichn: [{'_demande-ichn': 'false' | 'true'}];
}

export interface Producteur {
  '_numero-pacage': string;
  _campagne: string;
  '_fichier-xsd'?: string;
  demandeur?: Demandeur[];
  rpg: Rpg[];
  'demandes-aides-pilier1-et-AR': DemandesAidesPilier1[];
  'demandes-aides-pilier2': DemandesAidesPilier2[];
}

export interface Telepac {
  producteurs: {producteur: Producteur[]}[];
}

function validateOneEl<F extends {[P in Fk]?: any[]}, Fk extends keyof F>(obj: F, field: Fk) {
  const v = obj[field];
  if (!v) {
    console.error('validateOneEl called with non-array field ' + String(field) + '! ' + JSON.stringify(obj));
    return false;
  }

  return v.length == 1;
}

function parseGmlGeometrie(geom: Geometrie): null | Geometry {
  if (
    !validateOneEl(geom, 'gml:Polygon') ||
    !validateOneEl(geom['gml:Polygon'][0], 'gml:outerBoundaryIs') ||
    !validateOneEl(geom['gml:Polygon'][0]['gml:outerBoundaryIs'][0], 'gml:LinearRing')
  ) {
    return null;
  }

  const strCoords = geom['gml:Polygon'][0]['gml:outerBoundaryIs'][0]['gml:LinearRing'][0]['gml:coordinates'];
  const coords: LngLat[] = [];
  for (const s of strCoords.split(/\s/)) {
    if (!s) {
      continue;
    }
    const strPair = s.split(',');
    if (strPair.length !== 2) {
      return null;
    }

    coords.push([Number(strPair[0]), Number(strPair[1])]);
  }

  return {
    type: 'Polygon',
    coordinates: [coords.map(pos => proj4(lambert93, 'EPSG:4326', pos))],
  };
}

export interface TelepacHarvestContents {
  codeCulture: null | string;
  precision: null | string;
  surfaceAdmissible: null | number;
  semences: null | boolean;
  crop_id: null | string;
  imports?: {emailKey?: string; filename: string}[];
}

export interface TelepacContents {
  areaType: 'admissible' | 'graphical';
  farms: {
    harvest_year: null | HarvestYear;
    telepac_id: null | string;
    fields: TelepacHarvestContents[];
  }[];
}

function getCropId(
  mapping: CropMapping,
  codeCulture: null | string,
  precision: null | string,
  semences: null | boolean,
) {
  return (
    (codeCulture && precision && semences && mapping[`${codeCulture}-${precision}-semences`]) ||
    (codeCulture && semences && mapping[`${codeCulture}-semences`]) ||
    (codeCulture && precision && mapping[`${codeCulture}-${precision}`]) ||
    (codeCulture && mapping[`${codeCulture}`]) ||
    null
  );
}

class NoXmlTelepacError extends Error {
  constructor() {
    super('NoXmlTelepacError');
  }
}

class NoProducteursError extends Error {
  constructor() {
    super('NoProducteursError');
  }
}

class NoProducteurRpgError extends Error {
  constructor(telepacId: string) {
    super(`NoProducteurRpgError telepac_id=${telepacId}!`);
  }
}

export function xmlTelepacToGreenTriangle(
  xml: string,
  cropMapping: CropMapping,
): {
  data: ImportedData;
  contents: TelepacContents;
} {
  const telepacXmlParser = new XMLParser(telepacXmlParserOptions);
  const obj: Telepac = telepacXmlParser.parse(xml);
  const data = new ImportedData(null);
  const contents: TelepacContents = {areaType: 'admissible', farms: []};
  if (!obj) {
    throw new NoXmlTelepacError();
  }
  if (!obj.producteurs) {
    throw new NoProducteursError();
  }
  if (obj.producteurs.some(p => p.producteur.some(y => !y.rpg))) {
    throw new NoProducteurRpgError(obj.producteurs[0].producteur[0]['_numero-pacage']);
  }

  for (const producteurs of obj.producteurs) {
    for (const producteur of producteurs.producteur) {
      const harvestYearMatch = producteur['_fichier-xsd']?.match(/20\d\d/);
      const harvest_year = (harvestYearMatch && (harvestYearMatch[0] as HarvestYear)) || null;

      const telepac_id = producteur['_numero-pacage'];
      let farm = data.farms.find(x => x.telepac_id === telepac_id);
      const farmContent: TelepacContents['farms'][number] = {telepac_id, harvest_year, fields: []};

      if (!farm) {
        farm = new ImportedFarm(null);
        farm.telepac_id = telepac_id;
        data.farms.push(farm);
      }
      if (producteur.demandeur && validateOneEl(producteur, 'demandeur')) {
        const demandeur = producteur.demandeur[0];
        farm.farmer_email = demandeur.courriel;

        if (demandeur['identification-societe'] && validateOneEl(demandeur, 'identification-societe')) {
          farm.farm_name = demandeur['identification-societe'][0].exploitation;
        }
        if (
          demandeur['identification-individuelle'] &&
          validateOneEl(demandeur, 'identification-individuelle') &&
          demandeur['identification-individuelle'][0].identite &&
          validateOneEl(demandeur['identification-individuelle'][0], 'identite')
        ) {
          const {prenoms, nom} = demandeur['identification-individuelle'][0].identite[0];
          const name = `${prenoms} ${nom}`;
          farm.farm_name = name;
          farm.farmer_name = name;
        }
      }

      for (const rpg of producteur.rpg) {
        const ilots: Ilot[] = (rpg.ilot || []).concat((rpg.ilots || []).map(x => x.ilot).flat(1));
        for (const ilot of ilots) {
          const numero_ilot = ilot['_numero-ilot'];
          for (const parcelles of ilot.parcelles) {
            for (const parcelle of parcelles.parcelle) {
              if (!validateOneEl(parcelle, 'descriptif-parcelle') || !validateOneEl(parcelle, 'geometrie')) {
                continue;
              }
              const parcelDesc = parcelle['descriptif-parcelle'][0];
              const numero_parcelle = parcelDesc['_numero-parcelle'];
              if (!numero_ilot || !numero_parcelle) {
                continue;
              }

              const external_field_id = `i${numero_ilot},p${numero_parcelle}`;
              let field = farm.fields.find(x => x.external_field_id === external_field_id);
              if (field) {
                const err = 'Duplicate field id in PAC file! ' + external_field_id;
                console.warn(err);
                continue;
              }

              field = new ImportedField(null);
              field.external_field_id = external_field_id;
              let field_shape = parseGmlGeometrie(parcelle['geometrie'][0]);
              if (field_shape?.type !== 'Polygon') {
                field_shape = null;
              }

              let field_area_value: null | number = Number(parcelle['surface-admissible']);
              if (isNaN(field_area_value)) {
                field.field_area = field_shape ? geometryArea(field_shape, 'hectares') : null;
              } else {
                field.field_area = field_area_value == null ? null : {val: field_area_value / 100, unit: 'hectares'};
              }
              field.field_shape = field_shape;
              farm.fields.push(field);

              let pacHarvest = new ImportedHarvest(null);
              pacHarvest.harvest_year = harvest_year;

              const code_culture =
                (validateOneEl(parcelDesc, 'culture-principale') &&
                  parcelDesc['culture-principale'][0]['code-culture']) ||
                null;
              const precision =
                (validateOneEl(parcelDesc, 'culture-principale') && parcelDesc['culture-principale'][0].precision) ||
                null;
              const production_semences =
                (validateOneEl(parcelDesc, 'culture-principale') &&
                  parcelDesc['culture-principale'][0]['_production-semences']) ||
                null;

              const isSeeds = production_semences == 'true' ? true : production_semences == 'false' ? false : null;
              pacHarvest.crop_id = getCropId(cropMapping, code_culture, precision, isSeeds);
              const telepacData: TelepacHarvestContents = {
                surfaceAdmissible: field_area_value / 100,
                codeCulture: code_culture,
                precision: precision,
                semences: isSeeds,
                crop_id: pacHarvest.crop_id,
              };
              pacHarvest.organic =
                (parcelDesc['agri-bio'] &&
                  validateOneEl(parcelDesc, 'agri-bio') &&
                  parcelDesc['agri-bio']?.[0]['_conduite-bio']) ??
                null;

              if (code_culture) {
                telepacData.codeCulture = code_culture;
              }
              if (precision) {
                telepacData.precision = precision;
              }
              pacHarvest.custom_columns = new HarvestCustomColumns({telepacData});
              farmContent.fields.push(telepacData);
              if (pacHarvest.crop_id) {
                field.harvests.push(pacHarvest);
              }
            }
          }
        }
      }
      contents.farms.push(farmContent);
    }
  }

  return {data, contents};
}

class InvalidShapefileZip extends Error {}

// TODO(savv): Replace unzip libraries with DecompressionStream when it's a bit older, and when we upgrade to node 18.
export async function zipTelepacToGreenTriangle(
  files: {path: string; data: Uint8Array}[],
  cropMapping: CropMapping,
): Promise<{data: ImportedData; contents: TelepacContents}> {
  const shp = files.find(x => x.path.toLocaleLowerCase().endsWith('.shp'));
  const dbf = files.find(x => x.path.toLocaleLowerCase().endsWith('.dbf'));
  if (!shp || !dbf) {
    throw InvalidShapefileZip;
  }

  return geojsonToGtPack(await readShapefile(shp.data, dbf.data), cropMapping);
}

// TODO(savv): Add support for getters so that this can be generalized to Geojson files with other types.
export function geojsonToGtPack(collection: FeatureCollection, cropMapping: CropMapping) {
  const contents: TelepacContents = {areaType: 'graphical', farms: []};
  const data = new ImportedData(null);
  for (const feature of collection.features) {
    if (!feature.properties?.PACAGE) {
      continue;
    }

    const telepac_id: string = feature.properties.PACAGE;
    const harvest_year = feature.properties.CAMPAGNE ? (String(feature.properties.CAMPAGNE) as HarvestYear) : null;

    let farm = data.farms.find(x => x.telepac_id === telepac_id);
    let farmContent = contents.farms.find(x => x.telepac_id == telepac_id && x.harvest_year == harvest_year);

    if (!farm) {
      farm = new ImportedFarm(null);
      farm.telepac_id = telepac_id;
      farm.address = feature.properties.COMMUNE ? feature.properties.COMMUNE + ', France' : null;
      data.farms.push(farm);
    }
    if (!farmContent) {
      farmContent = {telepac_id, harvest_year, fields: []};
      contents.farms.push(farmContent);
    }

    const numero_i = feature.properties.NUMERO_I;
    const numero_p = feature.properties.NUMERO_P;
    if (
      numero_i == null ||
      numero_p == null ||
      (feature.geometry.type !== 'Polygon' && feature.geometry.type !== 'MultiPolygon')
    ) {
      const err = 'Skipping field from shapefile: ' + feature.geometry.type + ' ' + Object.keys(feature.properties);
      console.warn(err);
      continue;
    }

    const external_field_id = `i${numero_i},p${numero_p}`;
    let field = farm.fields.find(x => x.external_field_id === external_field_id);
    if (!field) {
      field = new ImportedField(null);
      farm.fields.push(field);
      field.external_field_id = external_field_id;
      let field_shape: Polygon;
      if (feature.geometry.type === 'Polygon') {
        field_shape = feature.geometry;
      } else {
        field_shape = {type: 'Polygon', coordinates: feature.geometry.coordinates[0]};
      }
      field_shape.coordinates = field_shape.coordinates.map(ring =>
        ring.map(pos => proj4(lambert93, 'EPSG:4326', pos)),
      );

      field.external_field_id = external_field_id;
      field.field_shape = field_shape;
      field.field_area =
        typeof feature.properties.SURF == 'number'
          ? {
              val: feature.properties.SURF,
              unit: 'hectares',
            }
          : field_shape
            ? geometryArea(field_shape, 'hectares')
            : null;
    }

    let pacHarvest = new ImportedHarvest(null);
    pacHarvest.harvest_year = harvest_year;
    pacHarvest.organic = feature.properties.AGRIBIO == '0' ? false : feature.properties.AGRIBIO == '1' ? true : null;
    const isSeeds = feature.properties.PROD_SEM == '1' ? true : feature.properties.PROD_SEM == '0' ? false : null;
    pacHarvest.crop_id = getCropId(cropMapping, feature.properties.TYPE, feature.properties.CODE_VAR, isSeeds);
    const telepacData: TelepacHarvestContents = {
      codeCulture: feature.properties.TYPE,
      precision: feature.properties.CODE_VAR,
      surfaceAdmissible: feature.properties.SURF,
      semences: isSeeds,
      crop_id: pacHarvest.crop_id,
    };
    if (feature.properties.CODE_VAR) {
      telepacData.precision = feature.properties.CODE_VAR;
    }
    pacHarvest.custom_columns = new HarvestCustomColumns({telepacData});

    farmContent.fields.push(telepacData);
    if (pacHarvest.crop_id) {
      field.harvests.push(pacHarvest);
    }
  }

  return {data, contents};
}

export async function getTelepacReportPackage(
  stateOrm: StateOrm,
  crops: IndexedCrops,
  contents: null | TelepacContents,
  data: ImportedData,
): Promise<TelepacReportPackage> {
  if (data.farms.length > 1) {
    throw new Error('Telepac data contains multiple farms!');
  }
  const farm = data.farms[0];
  const harvest_year = contents?.farms[0]?.harvest_year || null;
  const existingFarm = farm.farm_id ? await stateOrm.getFarmById(farm.farm_id) : null;

  let existing: TelepacReportPackage['existing'] = [];
  if (farm.farm_id && harvest_year) {
    // Overwrite farm name with existing one to cater for cases where no farm_name is available, e.g. zip Pac files
    if (existingFarm?.farm_name) {
      farm.farm_name = existingFarm.farm_name;
    }

    const fields = await stateOrm.getFieldsByFarmId(farm.farm_id);
    const harvests = await stateOrm.fetchEntitiesBy('harvest', {
      column: 'field_id',
      operator: 'in',
      value: fields.map(x => x.field_id),
    });
    const fieldsByKey = Object.fromEntries(fields.map(x => [x.field_id, x]));
    existing = harvests
      .filter(x => x.harvest_year == harvest_year)
      .filter(x => x.field_id)
      .map(x => ({
        crop_id: getBaseCrop(crops, x.crop_id) == 'grapes' ? 'grapes' : x.crop_id,
        area: x.harvest_area || fieldsByKey[x.field_id!]?.field_area,
      }));
  }

  // List of strings that can be used to identify this import job. Used for the title and file name.
  const policy_numbers: string[] = await getFarmPoliciesNumbers(stateOrm, farm.farm_id);
  const identifiers: string[] = filterNulls(['Import Telepac', harvest_year, farm.farm_name, ...policy_numbers]);
  const title = identifiers.join(' - ');
  const filename = title + '.pdf';

  return {
    type: 'TelepacReport',
    areaType: contents?.areaType || 'graphical',
    farm,
    contents: contents?.farms[0]?.fields || [],
    existing,
    policy_numbers,
    harvest_year,
    user_group: farm.user_group || existingFarm?.user_group || '',
    filename,
    crops,
    title,
  };
}

async function getFarmPoliciesNumbers(stateOrm: StateOrm, farm_id: null | string): Promise<string[]> {
  if (!farm_id) {
    return [];
  }
  const farm = await stateOrm.fetchEntitiesBy('farm', {column: 'farm_id', operator: 'eq', value: farm_id});
  const fields = await stateOrm.fetchEntitiesBy('field', {column: 'farm_id', operator: 'eq', value: farm_id});
  const harvests = await stateOrm.fetchEntitiesBy('harvest', {
    or: [
      {column: 'farm_id', operator: 'eq', value: farm_id},
      {column: 'field_id', operator: 'in', value: fields.map(x => x.field_id)},
    ],
  });
  const policies = await stateOrm.fetchEntitiesBy('policy', {
    column: 'policy_id',
    operator: 'in',
    value: harvests.map(x => x.policy_id).filter(remove.nulls),
  });
  const policyNumbers = policies.map(x => x.policy_number);
  const paPolicyNumber = farm[0]?.metadata?.row?.contrat;
  if (paPolicyNumber && !policyNumbers.includes(paPolicyNumber)) {
    policyNumbers.push(paPolicyNumber);
  }

  return policyNumbers;
}
