import {Filters} from '../models/interfaces';
import {DbFilterState} from '../redux/reducers/filters';

export function filtersToRequest(filters: DbFilterState): {f: Filters} {
  return {
    f: {
      farm_ids: filters.farm_id.length ? filters.farm_id : null,
      policy_ids: filters.policy_id.length ? filters.policy_id : null,
      user_groups: filters.user_group.length ? filters.user_group : null,
      crop_ids: filters.crop_id.length ? filters.crop_id : null,
      years: filters.harvest_year.length ? filters.harvest_year : null,
      sampled_by: filters.added_by.length ? filters.added_by : null,
      loss_causes: filters.loss_cause.length ? filters.loss_cause : null,
      crop_conditions: filters.crop_condition.length ? filters.crop_condition : null,
      sample_date_from: filters.added_on.startDate,
      sample_date_to: filters.added_on.endDate,
      max_vi_value_from: filters.max_ndvi_value.from,
      max_vi_value_to: filters.max_ndvi_value.to,
      emergence_date_from: filters.emergence_date.startDate,
      emergence_date_to: filters.emergence_date.endDate,
      max_vi_date_from: filters.max_ndvi_date.startDate,
      max_vi_date_to: filters.max_ndvi_date.endDate,
      region_ids: filters.regions.length ? filters.regions.map(r => `${r.value}%`) : null,
      claim_ids: filters.claim_id.length ? filters.claim_id : null,
      visit_types: filters.visit_type.length ? filters.visit_type : null,
    },
  };
}

export const nullFilters: Filters = {
  farm_ids: null,
  policy_ids: null,
  user_groups: null,
  crop_ids: null,
  years: null,
  sampled_by: null,
  loss_causes: null,
  crop_conditions: null,
  sample_date_from: null,
  sample_date_to: null,
  max_vi_date_from: null,
  max_vi_date_to: null,
  max_vi_value_from: null,
  max_vi_value_to: null,
  emergence_date_from: null,
  emergence_date_to: null,
  region_ids: null,
  claim_ids: null,
  visit_types: null,
};

export const ContentTypes = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  geojson: 'application/geo+json',
} as const;

export type DownloadContentType = keyof typeof ContentTypes;

// Taking in an array of http headers entries, a map of supported content-type and a fallback content type,
// this function returns the supported content type most closely matching the request.
export function getAccepts(
  headers: undefined | [string, string][],
  supported: DownloadContentType[],
  fallback: DownloadContentType,
): DownloadContentType {
  const acceptHeader: undefined | [string, string] = headers?.find(([key]) => key.toLowerCase() === 'accept');
  if (!acceptHeader) {
    return fallback;
  }
  const [, value] = acceptHeader;
  const acceptOptions: string[] = value.split(',').map(s => s.trim());
  for (const acceptOption of acceptOptions) {
    const matched: undefined | DownloadContentType = supported.find(contentTypeKey =>
      acceptOption.includes(ContentTypes[contentTypeKey]),
    );
    if (matched) {
      return matched;
    }
  }
  return fallback;
}

export function parseContentDisposition(contentDisposition: null | string): undefined | string {
  if (!contentDisposition) return undefined;
  const match = /.*;\s*filename="([^"]+)"/i.exec(contentDisposition);
  if (match) {
    const [, fileName] = match;
    return fileName;
  }
  return undefined;
}
