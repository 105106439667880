import React from 'react';
import {createSelector} from 'reselect';
import {UnreachableCaseError} from 'ts-essentials';
import {
  AnomalyZones,
  HAIL_COLORS,
  HAIL_ZONE_TO_NAME,
  HailZones,
  INTERFIELD_COLORS,
  INTERFIELD_ZONE_TO_NAME,
  InterfieldZones,
  PRECIPITATION_COLORS,
  PRECIPITATION_ZONE_TO_NAME,
  PrecipitationZones,
  RAINSTORM_COLORS,
  RAINSTORM_ZONE_TO_NAME,
  RainstormZones,
  SOIL_MOISTURE_COLORS,
  SOIL_MOISTURE_ZONE_TO_NAME,
  TEMPERATURE_COLORS,
  TEMPERATURE_ZONE_TO_NAME,
  VEGETATION_COLORS,
  VEGETATION_ZONE_TO_NAME,
  WIND_COLORS,
  WIND_ZONE_TO_NAME,
  WindZones,
} from '../constants/colors';
import {Flags} from '../feature-flags';
import {I18nFunction, I18nSimpleKey} from '../i18n/i18n';
import {getEnabledLayers, isEnabledLayer} from '../layers/map-layers';
import {GetFieldSeriesRow, LayerType} from '../models/interfaces';
import {AreaValue} from '../models/types';
import {formatDate} from '../text/date';
import cmp from '../util/cmp';
import {CommonViewStyle} from './CommonViewStyle';

export interface FieldSeriesItem {
  'soil-moisture'?: Exclude<AnomalyZones, 'na'>;
  vegetation?: AnomalyZones;
  temperature?: AnomalyZones;
  'surface-temperature'?: AnomalyZones;
  hail?: HailZones;
  precipitation?: PrecipitationZones;
  rainstorm?: RainstormZones;
  wind?: WindZones;
  a?: number;
  b?: number;
  c?: number;
  d?: number;
  e?: number;
  f?: number;
  g?: number;
  h?: number;
  i?: number;
  cloud?: number;
  w?: number;
  s1?: 1;
}

export interface FieldSeries {
  [date: string]: null | FieldSeriesItem;
}

export interface FieldSeriesResponse extends GetFieldSeriesRow {
  series: FieldSeries;
}

export type Anomaly = Exclude<
  LayerType,
  'crop-mon' | 'custom-a' | 'flood-risk' | 'interfield' | 'interyield' | 'intrafield'
>;
type BoxPlotZones = {[K in InterfieldZones]: number};

export const boxPlotZones: BoxPlotZones = {
  i: 0,
  h: 1,
  g: 2,
  f: 3,
  e: 4,
  d: 5,
  c: 6,
  b: 7,
  a: 8,
  w: 9,
  cloud: 9,
};
const boxPlotZonesKeys = Object.keys(boxPlotZones) as (keyof BoxPlotZones)[];
export const hasInterfield = (item: null | FieldSeriesItem): boolean => !!item && boxPlotZonesKeys.some(x => item[x]);
export const bandCount = 4;

const flexDirectionRow: CommonViewStyle = {display: 'flex', flexDirection: 'row'};
export const bandsContainerStyle: CommonViewStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
};

const groupContainerStyle: CommonViewStyle = {
  marginTop: 5,
  marginLeft: 5,
  marginBottom: 0,
  marginRight: 5,
  paddingTop: 5,
  paddingLeft: 5,
  paddingBottom: 0,
  paddingRight: 5,
};

export const dateLabelStyle = {display: 'flex', height: 20};

export function normalizeInterfield(data: FieldSeries) {
  for (const date in data) {
    // Scale interfield values to 1, to make it easier to calculate the graphs.
    const datum = data[date]!;
    const sum =
      (datum.w || 0) +
      (datum.a || 0) +
      (datum.b || 0) +
      (datum.c || 0) +
      (datum.d || 0) +
      (datum.e || 0) +
      (datum.f || 0) +
      (datum.g || 0) +
      (datum.h || 0) +
      (datum.i || 0) +
      (datum.cloud || 0);
    if (sum > 0) {
      if (datum.w) {
        datum.w /= sum;
      }
      if (datum.cloud) {
        datum.cloud /= sum;
      }
      if (datum.a) {
        datum.a /= sum;
      }
      if (datum.b) {
        datum.b /= sum;
      }
      if (datum.c) {
        datum.c /= sum;
      }
      if (datum.d) {
        datum.d /= sum;
      }
      if (datum.e) {
        datum.e /= sum;
      }
      if (datum.f) {
        datum.f /= sum;
      }
      if (datum.g) {
        datum.g /= sum;
      }
      if (datum.h) {
        datum.h /= sum;
      }
      if (datum.i) {
        datum.i /= sum;
      }
    }
  }

  const dates = Object.keys(data).sort();
  let prevDate: null | string = null;
  for (let dateIdx = 0; dateIdx < dates.length; ++dateIdx) {
    const curDate = dates[dateIdx];
    const nextDate = dateIdx + 1 < dates.length ? dates[dateIdx + 1] : null;
    const curDatum = data[curDate];
    if (!curDatum) {
      prevDate = null;
      continue;
    }

    const shapeTypes = curDatum ? (Object.keys(curDatum) as (keyof FieldSeriesItem)[]) : [];
    if (shapeTypes.length == 1 && shapeTypes[0] == 'soil-moisture') {
      const prevDateDistDays = prevDate
        ? (new Date(curDate).getTime() - new Date(prevDate).getTime()) / 1000 / 60 / 60 / 24
        : null;
      const nextDateDistDays = nextDate
        ? (new Date(nextDate).getTime() - new Date(curDate).getTime()) / 1000 / 60 / 60 / 24
        : null;
      if (
        prevDateDistDays != null &&
        prevDateDistDays <= 4 &&
        // NOTE: this only works as long as the interval before the cur date (4 days) is larger than the one for
        // after the cur date (3 days)
        (nextDateDistDays == null || prevDateDistDays <= nextDateDistDays)
      ) {
        data[prevDate!]!['soil-moisture'] = curDatum?.['soil-moisture'];
        delete data[curDate!];
      } else if (nextDateDistDays != null && nextDateDistDays <= 3) {
        data[nextDate!]!['soil-moisture'] = curDatum?.['soil-moisture'];
        delete data[curDate!];
      }

      prevDate = data[curDate] ? curDate : null; // Set the prevDate to the current one, unless we deleted it.
    }
  }

  return data;
}

export function groupFieldTimeseries(data: null | FieldSeries, groupFn: (date: string) => string): FieldSeries[] {
  const res: {[group: string]: FieldSeries} = {};

  function getSubObject(date: string) {
    const group = groupFn(date);
    if (!(group in res)) {
      res[group] = {};
    }
    return res[group];
  }

  for (const date in data) {
    getSubObject(date)[date] = data[date];
  }

  const resArr: [string, FieldSeries][] = Object.entries(res);
  resArr.sort((a, b) => cmp(b[0], a[0]));
  return resArr.map(x => x[1]);
}

export function getFieldSeriesItemDesc(
  t: I18nFunction,
  anomalies: {[P in Anomaly]?: boolean},
  date: string,
  item: FieldSeriesItem,
  fieldArea: null | AreaValue,
): string[] {
  const interfieldElements: string[] = [];
  const interfieldDesc: {[P in I18nSimpleKey]?: number} = {};
  for (const k of boxPlotZonesKeys) {
    const value = item[k];
    if (!value) {
      continue;
    }
    const zoneName = INTERFIELD_ZONE_TO_NAME[k];
    interfieldDesc[zoneName] = (interfieldDesc[zoneName] || 0) + value;
  }

  for (const zoneName in interfieldDesc) {
    const value = interfieldDesc[zoneName as I18nSimpleKey]!;
    if (value && zoneName) {
      let zoneElement = t(zoneName as I18nSimpleKey) + ' (' + (value * 100).toFixed(1) + '%';
      const zoneArea = fieldArea && {unit: fieldArea.unit, val: fieldArea.val * value};
      if (zoneArea) {
        zoneElement += ' / ' + t({type: 'ValueUnit', ...zoneArea});
      }
      zoneElement += ')';
      interfieldElements.push(zoneElement);
    }
  }
  const riskElements: string[] = [];
  if (item.vegetation && anomalies.vegetation) {
    riskElements.push(t(VEGETATION_ZONE_TO_NAME[item.vegetation]!));
  }
  if (item['soil-moisture'] && anomalies['soil-moisture']) {
    riskElements.push(t(SOIL_MOISTURE_ZONE_TO_NAME[item['soil-moisture']]!));
  }
  if (item.temperature && anomalies.temperature) {
    riskElements.push(t(TEMPERATURE_ZONE_TO_NAME[item.temperature]!));
  }
  if (item['surface-temperature'] && anomalies['surface-temperature']) {
    riskElements.push(t(TEMPERATURE_ZONE_TO_NAME[item['surface-temperature']]!));
  }
  if (item.hail && anomalies.hail) {
    riskElements.push(t(HAIL_ZONE_TO_NAME[item.hail]!));
  }
  if (item.precipitation && anomalies.precipitation) {
    riskElements.push(t(PRECIPITATION_ZONE_TO_NAME[item.precipitation]!));
  }
  if (item.rainstorm && anomalies.rainstorm) {
    riskElements.push(t(RAINSTORM_ZONE_TO_NAME[item.rainstorm]!));
  }
  if (item.wind && anomalies.wind) {
    riskElements.push(t(WIND_ZONE_TO_NAME[item.wind]!));
  }

  const elements = [];
  if (interfieldElements.length || riskElements.length) {
    elements.push(formatDate(t, date) + ':');
    if (interfieldElements.length) {
      elements.push(t('Biomass') + ': ' + interfieldElements.join(', '));
    }
    if (riskElements.length) {
      elements.push(t('Risks') + ': ' + riskElements.join(', '));
    }
  }
  return elements;
}

export function getItemAggregateValue(item: FieldSeriesItem) {
  let average = 0;
  for (const zone of boxPlotZonesKeys) {
    const value = item[zone] || 0;
    let i = boxPlotZones[zone];
    average += i * value;
  }
  return average / (boxPlotZonesKeys.length - 2);
}

export type FieldSeriesPlotProps = {
  selectedDate: null | string;
  onDateSelected: (date: string) => void;
  anomalies?: {[P in Anomaly]?: boolean};
  hasInterfield: boolean;
  series: FieldSeries;
  formatDate?: (date: string) => null | string;
  bandWidth: number;
  bandHeight: number;
  gapHeight: number;
  Box: 'span' | typeof React.Component;
  Touchable: 'span' | typeof React.Component;
  Text: 'span' | typeof React.Component;
  selectedItemStyle?: any;
};

export function minObjectKey<T>(obj: {[k: string]: T}): string {
  let min = null;
  for (const k in obj) {
    if (min === null || k < min) {
      min = k;
    }
  }

  return min || '';
}

export class FieldSeriesPlot extends React.PureComponent<FieldSeriesPlotProps> {
  state = {data: null, hasInterfield: null, loading: true, selectedRegionLayer: 'soil-moisture', predictedDates: null};

  render = () => {
    const groupDates = Object.keys(this.props.series);
    groupDates.sort();

    let weeks = [];
    let prevItem: null | FieldSeriesItem = null;
    for (const date of groupDates) {
      const weekElement = this.renderWeekItem(date, this.props.series[date], prevItem);
      if (weekElement) {
        weeks.push(weekElement);
        prevItem = this.props.series[date];
      }
    }

    const formattedDate = this.props.formatDate && this.props.formatDate(groupDates[0]);
    let style = groupContainerStyle;
    if (formattedDate) {
      style = {...groupContainerStyle};
      style.borderStyle = 'solid';
      style.borderTopColor = 'black';
      style.borderTopWidth = 1;
      style.borderLeftWidth = 0;
      style.borderBottomWidth = 0;
      style.borderRightWidth = 0;
    }
    const Box = this.props.Box,
      Text = this.props.Text;
    return (
      <Box style={style}>
        {formattedDate ? <Text style={dateLabelStyle}>{formattedDate}</Text> : null}
        <Box style={flexDirectionRow}>{weeks}</Box>
      </Box>
    );
  };

  renderAnomalyItem = (_: string, item: FieldSeriesItem, anomaly: Anomaly) => {
    const vegetation = item?.vegetation;
    const soilMoisture = item?.['soil-moisture'];
    const temperature = item?.temperature;
    const surfaceTemperature = item?.['surface-temperature'];
    const hail = item?.hail;
    const precipitation = item?.precipitation;
    const rainstorm = item?.rainstorm;
    const wind = item?.wind;

    let color = 'transparent';
    if (anomaly == 'vegetation') {
      if (vegetation) {
        color = VEGETATION_COLORS[vegetation];
      }
    } else if (anomaly == 'soil-moisture') {
      if (soilMoisture) {
        color = SOIL_MOISTURE_COLORS[soilMoisture];
      }
    } else if (anomaly == 'temperature') {
      if (temperature) {
        color = TEMPERATURE_COLORS[temperature];
      }
    } else if (anomaly == 'surface-temperature') {
      if (surfaceTemperature) {
        color = TEMPERATURE_COLORS[surfaceTemperature];
      }
    } else if (anomaly == 'hail') {
      if (hail) {
        color = HAIL_COLORS[hail];
      }
    } else if (anomaly == 'precipitation') {
      if (precipitation) {
        color = PRECIPITATION_COLORS[precipitation];
      }
    } else if (anomaly == 'rainstorm') {
      if (rainstorm) {
        color = RAINSTORM_COLORS[rainstorm];
      }
    } else if (anomaly == 'wind') {
      if (wind) {
        color = WIND_COLORS[wind];
      }
    } else {
      console.error(new UnreachableCaseError(anomaly));
    }

    const circleStyle: CommonViewStyle = {
      width: 0,
      height: 0,
      borderWidth: this.props.bandWidth / 5,
      borderRadius: this.props.bandWidth / 5,
      marginTop: this.props.bandWidth / 20 + this.props.gapHeight,
      marginLeft: this.props.bandWidth / 20,
      marginBottom: 0,
      marginRight: this.props.bandWidth / 20,
      borderColor: color,
      backgroundColor: color,
      borderStyle: 'solid',
      alignSelf: 'center',
    };

    const Box = this.props.Box;
    return <Box style={circleStyle} />;
  };

  renderAnomalies = (date: string, item: FieldSeriesItem) => {
    return (
      <>
        {!this.props.anomalies || this.props.anomalies['soil-moisture']
          ? this.renderAnomalyItem(date, item, 'soil-moisture')
          : null}
        {!this.props.anomalies || this.props.anomalies['temperature']
          ? this.renderAnomalyItem(date, item, 'temperature')
          : null}
        {!this.props.anomalies || this.props.anomalies['surface-temperature']
          ? this.renderAnomalyItem(date, item, 'surface-temperature')
          : null}
        {!this.props.anomalies || this.props.anomalies['vegetation']
          ? this.renderAnomalyItem(date, item, 'vegetation')
          : null}
        {!this.props.anomalies || this.props.anomalies['hail'] ? this.renderAnomalyItem(date, item, 'hail') : null}
        {!this.props.anomalies || this.props.anomalies['precipitation']
          ? this.renderAnomalyItem(date, item, 'precipitation')
          : null}
        {!this.props.anomalies || this.props.anomalies['rainstorm']
          ? this.renderAnomalyItem(date, item, 'rainstorm')
          : null}
        {!this.props.anomalies || this.props.anomalies['wind'] ? this.renderAnomalyItem(date, item, 'wind') : null}
      </>
    );
  };

  renderInterfield = (_: string, item: FieldSeriesItem) => {
    if (!this.props.hasInterfield) {
      return null;
    }

    const Box = this.props.Box;
    if (!hasInterfield(item)) {
      return <Box style={{height: this.props.bandHeight * bandCount}} />;
    }

    const bands = [];
    const opacity = item.s1 ? 0.75 : 1;
    for (const zone of boxPlotZonesKeys) {
      const value = item[zone] || 0;
      // Round the value to the nearest 0.5 to avoid rendering artifacts.
      const height = Math.round(2 * value * this.props.bandHeight) / 2;
      if (value == 0 && zone == 'cloud') {
        continue;
      }
      bands.push(<Box key={zone} style={{height, backgroundColor: INTERFIELD_COLORS[zone], opacity: opacity}} />);
    }

    const boxStyle = item.s1
      ? {...bandsContainerStyle, borderColor: '#6e6d6d', borderWidth: 2, borderStyle: 'dashed'}
      : bandsContainerStyle;

    const average = getItemAggregateValue(item);
    return (
      <>
        <Box style={{height: this.props.bandHeight * average * (bandCount - 1)}} />
        <Box style={boxStyle}>{bands}</Box>
        <Box style={{height: this.props.bandHeight * (bandCount - 1 - average * (bandCount - 1))}} />
      </>
    );
  };
  hasAnomalies = (item: FieldSeriesItem) => {
    return (
      ((!this.props.anomalies || this.props.anomalies['vegetation']) && !!item.vegetation) ||
      ((!this.props.anomalies || this.props.anomalies['soil-moisture']) && !!item['soil-moisture']) ||
      ((!this.props.anomalies || this.props.anomalies['temperature']) && !!item.temperature) ||
      ((!this.props.anomalies || this.props.anomalies['surface-temperature']) && !!item['surface-temperature']) ||
      ((!this.props.anomalies || this.props.anomalies['hail']) && !!item.hail) ||
      ((!this.props.anomalies || this.props.anomalies['precipitation']) && !!item.precipitation) ||
      ((!this.props.anomalies || this.props.anomalies['rainstorm']) && !!item.rainstorm) ||
      ((!this.props.anomalies || this.props.anomalies['wind']) && !!item.wind)
    );
  };

  renderWeekItem = (date: string, item: null | FieldSeriesItem, prevItem: null | FieldSeriesItem) => {
    if (!item || (!hasInterfield(item) && !this.hasAnomalies(item))) {
      return null;
    }

    const shortItem = !hasInterfield(item);
    const shiftedItem =
      (!hasInterfield(item) && hasInterfield(prevItem)) ||
      (hasInterfield(item) && prevItem && !hasInterfield(prevItem));
    const itemContainerStyle: CommonViewStyle = {
      width: shortItem ? this.props.bandWidth / 2 : this.props.bandWidth,
      marginLeft: shiftedItem ? -this.props.bandWidth / 4 : 0,
      paddingLeft: this.props.bandWidth / 10,
      paddingRight: this.props.bandWidth / 10,
      display: 'flex',
      flexDirection: 'column',
    };

    const Touchable = this.props.Touchable;
    const extraProps: any = {};
    extraProps[Touchable == 'span' ? 'onClick' : 'onPress'] = () => this.props.onDateSelected(date);
    if (Touchable == 'span') {
      extraProps.className = 'week-item';
    } else {
      extraProps.testID = 'week-item';
    }

    const selected = this.props.selectedDate == date;
    if (selected && this.props.selectedItemStyle) {
      Object.assign(itemContainerStyle, this.props.selectedItemStyle);
    }
    if (selected) {
      extraProps.id = 'fieldcharts-selected';
    }

    return (
      <Touchable key={date} {...extraProps} style={itemContainerStyle}>
        {this.renderInterfield(date, item)}
        {this.renderAnomalies(date, item)}
      </Touchable>
    );
  };
}

export const getEnabledAnomalies = createSelector(getEnabledLayers, (layers): {[P in Anomaly]: boolean} => ({
  temperature: layers.has('temperature'),
  'surface-temperature': layers.has('surface-temperature'),
  hail: layers.has('hail'),
  vegetation: layers.has('vegetation'),
  precipitation: layers.has('precipitation'),
  rainstorm: layers.has('rainstorm'),
  wind: layers.has('wind'),
  'soil-moisture': layers.has('soil-moisture'),
}));

export function getEnabledAnomalies2(flags2: Flags[], client: 'app' | 'web') {
  const flags = new Set(flags2);
  return {
    temperature: isEnabledLayer(flags, client, 'temperature'),
    'surface-temperature': isEnabledLayer(flags, client, 'surface-temperature'),
    hail: isEnabledLayer(flags, client, 'hail'),
    vegetation: isEnabledLayer(flags, client, 'vegetation'),
    precipitation: isEnabledLayer(flags, client, 'precipitation'),
    rainstorm: isEnabledLayer(flags, client, 'rainstorm'),
    wind: isEnabledLayer(flags, client, 'wind'),
    'soil-moisture': isEnabledLayer(flags, client, 'soil-moisture'),
  };
}
