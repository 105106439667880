import {Alert, Button} from 'antd';
import React from 'react';

export interface ListActionsError {
  message: string;
  description: string;
  action: {text: string; cb: () => void};
}

export interface ListActionsErrorProps {
  error: false | ListActionsError;
}

export const ListActionsError: React.FC<ListActionsErrorProps> = ({error}) => {
  if (!error) return null;
  return (
    <div className="list-action-error">
      <Alert
        type="error"
        style={{marginBottom: '10px'}}
        closable={true}
        message={error.message}
        description={error.description}
        action={
          <Button type="ghost" onClick={error.action.cb}>
            {error.action.text}
          </Button>
        }
      />
    </div>
  );
};

export const disableSelectAllButton = (dataCount: number) => {
  return dataCount > 50 || dataCount === 0;
};
