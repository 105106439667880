import {
  aaHiddenName,
  assHiddenName,
  etHiddenName,
  gaHiddenName,
  grHiddenName,
  paHiddenName,
  txHiddenName,
} from '../constants/group-consts';
import {LossCause} from '../models/interfaces';
import {getMainUserGroup} from '../report/report-util';
import {remove} from '../util/arr-util';

// Clarify which loss-causes are customer specific
const nonGenericLossCauses: Readonly<LossCause[]> = [
  // Added for ass
  'heat-wave',
  // Added for gr
  'undeclared-risk',
  'unguaranteed-risk',
];
// List of all non-customer-specific loss causes.
export const genericLossCauses: Readonly<LossCause[]> = LossCause.filter(remove.allOf(nonGenericLossCauses));

interface CustomLossCauses {
  guaranteed: LossCause[];
  unguaranteed: LossCause[];
  quality: LossCause[];
  recognizableLossCauses: 'claimed' | 'guaranteed' | 'none';
}

// We list loss causes explicitly for customers to make sure additions to the global list don't automatically
// get added to all customers.
// TODO(seb): Find a better way to handle customer specific loss causes than listing them explicitly in code.
export function getCustomLossCauses(user_group: string): CustomLossCauses {
  switch (getMainUserGroup(user_group)) {
    case assHiddenName:
      const guaranteed: LossCause[] = [
        'hail',
        'storm',
        'frost',
        'drought',
        'heavy-rainfall',
        'heat-wave',
        'flood',
        'snow',
        'cold',
      ];
      return {
        guaranteed,
        unguaranteed: genericLossCauses.filter(remove.allOf(guaranteed)),
        quality: guaranteed,
        recognizableLossCauses: 'guaranteed',
      };
    case etHiddenName:
      return {
        guaranteed: ['hail', 'storm', 'frost', 'excess-water', 'drought'],
        unguaranteed: [
          'sandstorm',
          'weeds',
          'decree',
          'disease',
          'pest',
          'wild-animals',
          'fire',
          'none',
          'other-loss',
          'unknown',
        ],
        quality: [],
        recognizableLossCauses: 'none',
      };
    case paHiddenName:
    case txHiddenName:
      return {
        guaranteed: [
          'snow',
          'drought',
          'frost',
          'flood',
          'hail',
          'storm',
          'heavy-rainfall',
          'sunburn',
          'excess-water',
          'downpour',
          'excessive-air-humidity',
          'heatstroke',
          'cold',
          'lack-of-sunlight',
          'tornado',
          'sandstorm',
        ],
        unguaranteed: ['disease', 'pest', 'weeds', 'unknown', 'none', 'wild-animals', 'fire', 'other-loss', 'decree'],
        quality: [],
        recognizableLossCauses: 'guaranteed',
      };
    case grHiddenName:
    case aaHiddenName:
    case gaHiddenName:
      return {
        guaranteed: [
          'snow',
          'drought',
          'frost',
          'flood',
          'hail',
          'storm',
          'heavy-rainfall',
          'sunburn',
          'excess-water',
          'downpour',
          'excessive-air-humidity',
          'heatstroke',
          'cold',
          'lack-of-sunlight',
          'tornado',
          'sandstorm',
        ],
        unguaranteed: [
          'disease',
          'pest',
          'weeds',
          'wild-animals',
          'fire',
          'other-loss',
          'decree',
          'unguaranteed-risk',
          'undeclared-risk',
        ],
        quality: [],
        recognizableLossCauses: 'guaranteed',
      };
    default:
      return {
        unguaranteed: [],
        guaranteed: [...genericLossCauses],
        quality: [],
        recognizableLossCauses: 'none',
      };
  }
}
