import {I18nFunction} from '../i18n/i18n';
import {CropMonType, MonitoredCrop} from '../models/crop-mon';
import {HarvestSeason, HarvestYear} from '../models/interfaces';
import {IndexedCrops} from '../redux/reducers/crops';
import {cropDesc} from '../text/desc';

export type LayerParams = LayerParamsCropMon | LayerParamsInteryield | LayerParamsPlanet;

export type CropMonCountryCode = 'BRA' | 'FRA' | 'HUN' | 'ROU' | 'UKR' | 'ZAF';

// TODO(savv): adapt crop mon to use crop_ids instead of crop/season combos.

export type LayerParamsPlanet = {
  layer_type: 'planet';
  params: [string /* planet item id */];
};

export type LayerParamsCropMon = {
  layer_type: 'crop-mon';
  params: [CropMonCountryCode, CropMonType, MonitoredCrop, HarvestSeason, HarvestYear];
};

export type InteryieldCombo = [string, HarvestYear];

export type LayerParamsInteryield = {
  layer_type: 'interyield';
  params: InteryieldCombo;
};

export function parseInteryieldCombo(s: string): null | InteryieldCombo {
  const res = s.split('+');
  if (res.length != 2 || !HarvestYear.includes(res[1] as any)) {
    return null;
  }

  return res as InteryieldCombo;
}

export function interyieldComboDesc(t: I18nFunction, crops: IndexedCrops, x: string): string {
  const combo = parseInteryieldCombo(x);
  return combo ? `${cropDesc(t, crops, combo[0])} ${t(combo[1])}` : ' - ';
}
