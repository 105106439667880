import React from 'react';
import {useSelector} from 'react-redux';
import {HarvestCrop} from '../../../src/models/interfaces';
import {YieldUnit, YieldValue} from '../../../src/models/types';
import {getBaseCrop, getCrops} from '../../../src/selectors/crops';
import {convertYield, getIdealYieldUnit, getUnitSystem} from '../../../src/selectors/units';
import {useApis} from '../apis/ApisContext';

interface YieldProps {
  value?: null | YieldValue;
  cropId: null | string;
}

// Component to render a localized `YieldValue` converted to the current unit system, derived from the current locale.
// Null values will be rendered as '-'.
export const Yield: React.FC<YieldProps> = React.memo(({value, cropId}) => {
  const {t} = useApis();
  const crops = useSelector(getCrops);
  const unitSystem = useSelector(getUnitSystem);

  // cropId is only needed to convert bushels-per-acre. And will return null if the cropId is omitted.
  if (!value) return <>-</>;

  const harvestCrop: null | HarvestCrop = getBaseCrop(crops, cropId);
  const targetUnit: YieldUnit = getIdealYieldUnit(unitSystem, getBaseCrop(crops, cropId));
  const convertedValue: YieldValue = convertYield(targetUnit, value, harvestCrop)!;
  if (convertedValue === null) {
    console.warn('Converting %d %s of %s to %s failed', value.val, value.unit, harvestCrop, targetUnit);
  }
  // Under rare circumstances, the conversion may fail, in which case we just show the original value.
  // This primarily happens when we use bushels-per-acre as the target unit.
  return <>{t({type: 'YieldUnit', ...(convertedValue || value)})}</>;
});

interface YieldTHaProps {
  value?: null | number;
  cropId: null | string;
}

// Component to render a localized yield number given in tons per hectares converted to the current unit system, derived
// from the current locale. Null values will be rendered as '-'.
export const YieldTHa: React.FC<YieldTHaProps> = React.memo(({value, cropId}) => (
  <Yield value={typeof value === 'number' ? {val: value, unit: 'tons-per-hectare'} : null} cropId={cropId} />
));
